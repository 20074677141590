import React, { FC, ReactNode, useReducer } from "react";
import { RetrievalState } from "src/constants";
import { DocumentLineState } from "./types";
import documentLineItemsReducer from "./reducer";

export const DEFAULT_DOCUMENT_LINE_ITEM_LISTING: DocumentLineState = {
  documentLineItems: [],
  retrieveState: RetrievalState.SUCCESS,
};

export const DocumentLineItemListContext = React.createContext<{
  state: DocumentLineState;
  dispatch: React.Dispatch<any>;
}>({ state: DEFAULT_DOCUMENT_LINE_ITEM_LISTING, dispatch: () => {} });

export const DocumentLineItemsListContextProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(
    documentLineItemsReducer,
    DEFAULT_DOCUMENT_LINE_ITEM_LISTING
  );

  return (
    <DocumentLineItemListContext.Provider value={{ state, dispatch }}>
      {children}
    </DocumentLineItemListContext.Provider>
  );
};
