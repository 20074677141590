export type UserActionMetadata = { [key: string]: any };

export interface UserActionsMetadataState {
  metadata: UserActionMetadata;
}

export enum UserActionsMetadataActions {
  ADD = 0,
}

export interface UserActionsMetadataAction {
  type: UserActionsMetadataActions;
  payload: any;
}

export type ActionFunction = (
  state: UserActionsMetadataState,
  actionPayload: any
) => UserActionsMetadataState;
