import React from "react";
import { get, isNull, omitBy } from "lodash";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { AmountDetails } from "src/api/interfaces/line_item";
import ExpandableCard from "src/components/ExpandableCard/ExpandableCard";

const AmountDetailsCard = ({
  title,
  amountDetails: amountDetailsProp,
}: {
  title: string;
  amountDetails: AmountDetails;
}) => {
  const amountDetails = omitBy(amountDetailsProp, isNull);

  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const notApplicable = t("aic_doc_page_product_items_tab_not_applicable");

  const unitPriceLabel = t(
    "aic_doc_page_product_items_tab_item_details_amount_details_unit_cost"
  );
  const discountLabel = t(
    "aic_doc_page_product_items_tab_item_details_amount_details_discount"
  );
  const quantityLabel = t(
    "aic_doc_page_product_items_tab_item_details_amount_details_quantity"
  );

  const unitPrice = get(amountDetails, "unitPrice", notApplicable);
  const discount = get(amountDetails, "discount", notApplicable);
  const quantity = get(amountDetails, "quantity", notApplicable);

  return (
    <ExpandableCard expanded title={title}>
      <div>
        <strong>{unitPriceLabel}</strong>: {unitPrice}
      </div>
      <div>
        <strong>{discountLabel}</strong>: {discount}
      </div>
      <div>
        <strong>{quantityLabel}</strong>: {quantity}
      </div>
    </ExpandableCard>
  );
};

export default AmountDetailsCard;
