import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import SideBar from "src/components/SideBar/SideBar";
import TranslatedTextInput from "src/components/Inputs/TranslatedTextInput";
import TranslatedSelectInput from "src/components/Inputs/TranslatedSelectInput";
import classnames from "classnames";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { useFilterOptions } from "src/components/Contexts/FilterOptions/Provider";
import {
  DomainName,
  FilterActions,
  FilterValue,
} from "src/components/Contexts/FilterOptions/types";
import { FiltersDefinition } from "src/components/Contexts/FilterOptions/definition";
import { useHistory } from "react-router-dom";
import { updateUrlParams } from "src/utils";
import styles from "../../../../../styles.module.scss";
import ReferencedDocumentsFilter from "./ReferencedDocumentsFilter";

export type SideBarFilterProps = {
  onCancel: () => void;
  onSubmit: () => void;
  visible: boolean;
};

const SideBarFilter: FC<SideBarFilterProps> = ({
  onCancel,
  onSubmit,
  visible,
}) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const history = useHistory();

  const {
    state: { selectedValues, optionValues },
    dispatch,
  } = useFilterOptions();
  const onSubmitHandler = () => {
    updateUrlParams(Object.entries(selectedValues), history);
    onSubmit();
  };

  const onCancelHandler = () => {
    onCancel();
  };

  const onChangeHandler = (domainName: string) => {
    return (value?: FilterValue) => {
      dispatch({
        type: FilterActions.SET_SELECTED_VALUES,
        payload: { domainName, value },
      });
    };
  };

  const textFilters = [DomainName.DOCUMENT_ID, DomainName.PARTNER_CODE];

  const selectFilters = [
    DomainName.DOCUMENT_TYPE,
    DomainName.DOCUMENT_SUBTYPE,
    DomainName.USE_CASE,
    DomainName.VALIDATION_GROUPS,
    DomainName.ORCHESTRATION_EVENTS,
  ];

  return (
    <>
      <SideBar
        title={t("aic_more_filters_side_bar_title")}
        visible={visible}
        onSubmit={onSubmitHandler}
        onCancel={onCancelHandler}
      >
        <div>
          {textFilters.map((domainName) => (
            <TranslatedTextInput
              key={domainName}
              initialValue={selectedValues[domainName]}
              onChange={onChangeHandler(domainName)}
              classname={classnames(styles.direction_inherit, styles.ma_16)}
              translationPrefix={`aic_more_filters_side_bar_${domainName}`}
            />
          ))}
          <ReferencedDocumentsFilter />
          {selectFilters.map((domainName) => (
            <TranslatedSelectInput
              key={domainName}
              translationPrefix={`aic_more_filters_side_bar_${domainName}`}
              onChange={onChangeHandler(domainName)}
              initialValue={selectedValues[domainName]}
              classname={classnames(
                styles.direction_inherit,
                styles.ma_16,
                styles.min_w_40
              )}
              options={optionValues[domainName]}
              searchable
              multiple={FiltersDefinition[domainName].multiple}
            />
          ))}
        </div>
      </SideBar>
    </>
  );
};

export default SideBarFilter;
