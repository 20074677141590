import React, { FC, useEffect } from "react";
import { KatDropdown, KatIcon, KatOption } from "@amzn/katal-react";
import classnames from "classnames";
import { useCountrySelection } from "src/components/Contexts/CountrySelectionContext";
import { usePermissions } from "src/components/Contexts/PermissionsContext";
import { withTranslation, WithTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getUrlParam, updateUrlParams } from "src/utils";
import { Country, CountrySelection } from "src/api/interfaces/country";
import { setRealmBasedOnCountrySelection } from "src/api/interfaces/realm";
import {
  DomainName,
  FilterValue,
} from "src/components/Contexts/FilterOptions/types";
import styles from "../../../../styles.module.scss";

export const COUNTRY_QS_KEY = "country";

const SEARCH_COUNTRY_TITLE = "aic_country_selection_label";
const COUNTRY_INITIAL_VALUE = [{ countryCode: "" }];

const CountrySelector: FC<CountrySelectorProps & WithTranslation> = ({
  disableCountryChange,
  fixedCountry,
  disableUrlSearchParamsUpdate,
  t,
}) => {
  const { availableCountries, selectedCountry, setCountrySelection } =
    useCountrySelection();

  const { permissions } = usePermissions();
  const history = useHistory();

  const countryCodeParam = getUrlParam(COUNTRY_QS_KEY);

  const shouldUseCountryCodeFromUrl = (
    newlyAvailableCountries: Country[],
    countryCode?: string
  ): boolean => {
    return (
      !!countryCode &&
      newlyAvailableCountries.some(
        (country) => country.countryCode === countryCode
      )
    );
  };

  useEffect(() => {
    const newlyAvailableCountries = Array.from(permissions.keys()).map(
      (countryCode) => {
        return { countryCode };
      }
    );

    const countryCodeFromURL = countryCodeParam;
    let newlySelectedCountry: Country | undefined;

    if (fixedCountry) {
      newlySelectedCountry = { countryCode: fixedCountry };
    } else if (newlyAvailableCountries && newlyAvailableCountries.length) {
      newlySelectedCountry = shouldUseCountryCodeFromUrl(
        newlyAvailableCountries,
        countryCodeFromURL
      )
        ? {
            countryCode: countryCodeFromURL as string,
          }
        : newlyAvailableCountries[0];
    } else {
      newlySelectedCountry = undefined;
    }

    setRealmBasedOnCountrySelection(newlySelectedCountry);

    setCountrySelection({
      availableCountries: newlyAvailableCountries,
      selectedCountry: newlySelectedCountry,
    });

    // setting country code on URL at first loading
    if (
      !disableUrlSearchParamsUpdate &&
      newlySelectedCountry?.countryCode !== countryCodeFromURL &&
      !!newlySelectedCountry
    ) {
      updateUrlParams(
        [[COUNTRY_QS_KEY, newlySelectedCountry?.countryCode]],
        history
      );
    }
  }, [
    countryCodeParam,
    disableUrlSearchParamsUpdate,
    fixedCountry,
    history,
    permissions,
    setCountrySelection,
  ]);

  const countryFlagIconName = (country: string) =>
    `flag-${country.toLowerCase()}` as KatIcon.Name;

  const options: KatalDropdownOption[] = availableCountries.map(
    ({ countryCode }) => ({
      name: countryCode,
      value: countryCode,
      iconName: countryFlagIconName(countryCode),
    })
  );

  const handleCountryChange = ({
    detail: { value },
  }: KatDropdown.ChangeEvent) => {
    if (!value) return;

    const urlParamsToUpdate = [
      [COUNTRY_QS_KEY, value] as [string, FilterValue],
      ...(Object.values(DomainName).map((domainName: string) => [
        domainName,
        undefined,
      ]) as [string, undefined][]),
    ];

    updateUrlParams(urlParamsToUpdate, history);

    setCountrySelection((prevCountrySelection: CountrySelection) => ({
      ...prevCountrySelection,
      selectedCountry: {
        countryCode: value,
      },
    }));
  };

  const isFetching = availableCountries === COUNTRY_INITIAL_VALUE;
  return (
    <div
      className={classnames(
        styles.ml_8,
        styles.flex,
        styles.flex_direction_row,
        styles.flex_nowrap
      )}
    >
      <div
        className={classnames(
          styles.ml_8,
          styles.mr_8,
          styles.flex,
          styles.align_items_center,
          styles.justify_center
        )}
      >
        <h5 className={styles.mb_zero_important}>{t(SEARCH_COUNTRY_TITLE)}</h5>
      </div>
      <div className={classnames(styles.display_inline_flex, styles.w_100)}>
        <KatDropdown
          id="document-listing-country-dropdown"
          options={options}
          value={
            isFetching
              ? availableCountries[0].countryCode
              : fixedCountry || selectedCountry?.countryCode
          }
          onChange={handleCountryChange}
          disabled={isFetching || disableCountryChange}
          className={classnames(styles.direction_inherit, styles.w_95_px)}
        >
          {options.map(({ value, iconName, name }) => (
            <KatOption value={value} key={name}>
              <div className={classnames(styles.flex, styles.items_center)}>
                {iconName && (
                  <KatIcon
                    name={iconName}
                    size="small"
                    className={styles.mr_8}
                  />
                )}
                <div>{name}</div>
              </div>
            </KatOption>
          ))}
        </KatDropdown>
        {!isFetching && selectedCountry && (
          <KatIcon
            name={countryFlagIconName(
              fixedCountry || selectedCountry?.countryCode
            )}
            size="small"
            className={classnames(styles.ml_10, styles.mt_6)}
          />
        )}
      </div>
    </div>
  );
};

export interface CountrySelectorProps {
  disableCountryChange?: boolean;
  fixedCountry?: string;
  disableUrlSearchParamsUpdate?: boolean;
}

export default withTranslation()(CountrySelector);
