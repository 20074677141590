import cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const MIDWAY_COOKIE_NAME = "amzn_sso_token";
const REALM_COOKIE_NAME = "aic_realm_token";
const FOURTEEN_MINUTES_IN_MILLISECONDS = 840000;
// Cookies cannot be set to not expire. So, if we want this,
// this value should be big. This constant should be used for
// tokens that should not expire constantly so usability is not affected.
const AN_YEAR_IN_MILLISECONDS = 86400000;

export const setMidwayCookie = (token: string): void => {
  const cookieExpirationDate = new Date(
    Date.now() + FOURTEEN_MINUTES_IN_MILLISECONDS
  );

  const midwayCookieOptions = {
    path: "/",
    expires: cookieExpirationDate,
  };

  cookies.set(MIDWAY_COOKIE_NAME, token, midwayCookieOptions);
};

export const getMidwayCookie = (): string | undefined =>
  cookies.get(MIDWAY_COOKIE_NAME);

export const isMidwayCookieSet = (): boolean =>
  cookies.get(MIDWAY_COOKIE_NAME) !== undefined;

export const setRealmCookie = (realm: string): void => {
  const cookieExpirationDate = new Date(Date.now() + AN_YEAR_IN_MILLISECONDS);

  const cookieOptions = {
    path: "/",
    expires: cookieExpirationDate,
  };

  cookies.set(REALM_COOKIE_NAME, realm, cookieOptions);
};

export const getRealmCookie = (): string | undefined =>
  cookies.get(REALM_COOKIE_NAME);

export const isRealmCookieSet = (): boolean =>
  cookies.get(REALM_COOKIE_NAME) !== undefined;

const getJwtClaim = (encodedToken: string, claim: string) => {
  const decodedToken = jwt_decode<Record<string, unknown>>(encodedToken);
  return decodedToken[claim];
};

const getUsernameFromJwt = (encodedToken: string) => {
  return getJwtClaim(encodedToken, "sub") as string;
};

export const getUsernameFromCookie = (): string | undefined =>
  isMidwayCookieSet()
    ? getUsernameFromJwt(getMidwayCookie() as string)
    : undefined;
