import cloneDeep from "lodash/cloneDeep";
import {
  ActionFunction,
  UserActionMetadata,
  UserActionsMetadataState,
} from "./types";

const setUserActionsMetadata: ActionFunction = (
  state: UserActionsMetadataState,
  payload: {
    metadata: UserActionMetadata;
  }
) => {
  return {
    metadata: cloneDeep(payload.metadata),
  };
};

export default setUserActionsMetadata;
