import React, { FC, useState } from "react";
import classnames from "classnames";
import { KatModal } from "@amzn/katal-react";
import { useTranslation } from "react-i18next";
import { getTranslatedValue } from "src/utils";
import modal_wrapper_style from "src/components/Modal/modal_wrapper_styles.module.scss";
import ValidationSeverityDescription from "src/components/Pages/DocumentInbound/ValidationSeverityDescription/ValidationSeverityDescription";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";

const ValidationSeverityDescriptionModal: FC<{
  ClickableComponent: FC<{ onClick: () => void }>;
}> = ({ ClickableComponent }) => {
  const [showSeverityDescription, setShowSeverityDescription] =
    useState<boolean>(false);
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const translatedSeverityDescriptionLabel = getTranslatedValue(
    "aic_rule_severity_description",
    t
  );
  return (
    <>
      <ClickableComponent
        onClick={() => setShowSeverityDescription(!showSeverityDescription)}
      />
      <KatModal
        title={translatedSeverityDescriptionLabel}
        className={classnames(modal_wrapper_style.modal_wrapper_style)}
        visible={showSeverityDescription}
        onClose={() => setShowSeverityDescription(false)}
      >
        <ValidationSeverityDescription />
      </KatModal>
    </>
  );
};

export default ValidationSeverityDescriptionModal;
