import React from "react";
import { hot } from "react-hot-loader";

import publishMetric, { MetricAction } from "../metrics";
import { i18nWhenReady } from "../i18n";

enum InitializationStatus {
  Failure = 0,
  Success = 1,
}

interface PropsType {
  children: JSX.Element;
}

/**
 * Simple class to manage the application initialization metric.
 *
 * Note this is meant as a demonstration of how to use the initialization metric, and
 * is almost certainly inadequate for a more complex application.
 *
 * It waits for itself to be mounted (if it is the outermost component that means all of
 * its children are rendered and mounted by React, though are not necessarily fully rendered
 * in the browser), then waits for the translations to be ready.
 *
 * In your actual application, you are likely not fully initialized until you have completed
 * your initial REST calls.  You will need to either integrate that logic here, or else move
 * the initialization metric elsewhere.
 *
 * For applications using a state manager (e.g. Redux) it may make more sense to determine if
 * your application is initialized from that layer instead.
 */
export class AppInitWrapper extends React.Component<
  PropsType,
  Record<string, unknown>
> {
  private isInitialized = false;

  componentDidMount(): void {
    // Our application is initialized, with the possible exception of i18n
    // Use this callback to log our final initialization time and status
    i18nWhenReady((err) => {
      this.logInitializationMetric(
        err ? InitializationStatus.Failure : InitializationStatus.Success
      );
    });
  }

  componentDidCatch(error: Error): void {
    this.logInitializationMetric(InitializationStatus.Failure);
    // In your application you may want to update the UI; here we will re-throw and fail the whole app, after logging
    throw error;
  }

  // Helper method to publish the initialization metric
  private logInitializationMetric(status: InitializationStatus): void {
    if (!this.isInitialized) {
      this.isInitialized = true;
      publishMetric(MetricAction.APP_INIT, {
        errored: status !== InitializationStatus.Success,
      });
    }
  }

  render(): React.ReactElement {
    const { children } = this.props;
    return children;
  }
}

// Hot reload any react changes when running the dev server
export default hot(module)(AppInitWrapper);
