import React, { FC } from "react";
import AICHeader from "src/components/AICLayout/AICHeader/AICHeader";
import classnames from "classnames";
import styles from "src/styles.module.scss";

const Unauthorized: FC = () => {
  const wikiHref = (
    <a
      target="_blank"
      href="https://w.amazon.com/bin/view/IntechBrazil/Products/AICPortal/Access"
      rel="noreferrer"
    >
      wiki
    </a>
  );

  return (
    <div>
      <AICHeader disableCountryChange />
      <div className={classnames(styles.mt_16, styles.tex_aligned_center)}>
        <h1>Unauthorized</h1>
        <p className={classnames(styles.w_30, styles.m_auto)}>
          You are not authorized to view the contents of this page. Please refer
          to this {wikiHref} in order to understand the AIC permissions model
          and request the appropriate permissions for the use cases you are
          interested in.
        </p>
      </div>
    </div>
  );
};

export default Unauthorized;
