import { InboundComplianceGroupDetail } from "src/api/interfaces/inbound_compliance_group";
import { InboundComplianceGroupDetailsField } from "src/constants";
import {
  ColumnDefinition,
  DataTableDefinition,
} from "src/components/DataTable/DataTableDefinition";

const EMPTY_FIELD = "-";

const createTableEmtpyRow = (
  columns: ColumnDefinition[]
): Record<string, string> => {
  const tableRow: Record<string, string> = {};
  columns.forEach((column) => {
    tableRow[column.property] = EMPTY_FIELD;
  });
  return tableRow;
};

const convertToDynamicTableFields = (
  inboundComplianceGroupDetail: InboundComplianceGroupDetail,
  columns: DataTableDefinition<InboundComplianceGroupDetail>
): Record<string, string> => {
  const tableRow = createTableEmtpyRow(columns.columnsDefinition);

  tableRow[InboundComplianceGroupDetailsField.ITEM_ID.valueOf()] =
    inboundComplianceGroupDetail.itemId;

  inboundComplianceGroupDetail.fields
    .filter((field) => !!field?.receiptType)
    .forEach((field) => {
      tableRow[field.receiptType] = field?.quantity || EMPTY_FIELD;
    });

  return tableRow;
};

export default convertToDynamicTableFields;
