import { aws4Interceptor } from "aws4-axios";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

import { getMidwayCookie } from "src/cookies";
import { retrieveMidwayToken } from "src/midway/MidwayConnector";

const EXECUTE_API = "execute-api";
const AUTHORIZATION_HEADER_KEY = "Authorization";

export const awsIAMAuthAxiosInterceptor = aws4Interceptor(
  {
    service: EXECUTE_API,
    region: process.env.API_AWS_REGION,
  },
  {
    accessKeyId: process.env.AWS_ACCESS_KEY_ID || "",
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY || "",
    sessionToken: process.env.AWS_SESSION_TOKEN,
  }
);

export const createRecordForMidwayCookie = (): Record<string, string> => {
  return {
    [AUTHORIZATION_HEADER_KEY]: getMidwayCookie() || "unauthenticated",
  };
};

export const awsJWTAuthAxiosInterceptor = (
  requestConfig: AxiosRequestConfig
): AxiosRequestConfig => {
  // eslint-disable-next-line no-param-reassign
  requestConfig.headers = {
    ...requestConfig.headers,
    ...createRecordForMidwayCookie(),
  };

  return requestConfig;
};

export const awsJWTAuthErrorAxiosInterceptor = (
  error: AxiosError
): Promise<AxiosRequestConfig | AxiosError> => {
  if (error?.response?.status === 401) {
    retrieveMidwayToken();

    // eslint-disable-next-line no-param-reassign
    error.config.headers = {
      ...error.config.headers,
      ...createRecordForMidwayCookie(),
    };
    return axios.request(error.config);
  }

  return Promise.reject(error);
};
