import React, { FC } from "react";
import { KatStatusindicator } from "@amzn/katal-react";
import styles from "src/styles.module.scss";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import {
  InboundComplianceGroup,
  InboundComplianceGroupStatus,
} from "src/api/interfaces/inbound_compliance_group";

const INFO_TYPE = "information";
const ALERT_TYPE = "alert";
const SUCCESS_TYPE = "success";
const PENDING_TYPE = "pending";

const INBOUND_COMPLIANCE_GROUP_STATUS_TO_STATUS_INDICATOR_TYPE: Map<
  string,
  KatStatusindicator.Variant
> = new Map([
  [InboundComplianceGroupStatus.ERROR.valueOf(), ALERT_TYPE],
  [InboundComplianceGroupStatus.PENDING.valueOf(), PENDING_TYPE],
  [InboundComplianceGroupStatus.SEVERE.valueOf(), ALERT_TYPE],
  [InboundComplianceGroupStatus.SKIPPED.valueOf(), INFO_TYPE],
  [InboundComplianceGroupStatus.SUCCESS.valueOf(), SUCCESS_TYPE],
]);

const InboundComplianceGroupStatusIndicator: FC<
  InboundComplianceGroupStatusIndicatorProps
> = ({ content }) => {
  const statusText = content.status.valueOf();
  const statusIndicatorType: KatStatusindicator.Variant =
    INBOUND_COMPLIANCE_GROUP_STATUS_TO_STATUS_INDICATOR_TYPE.get(statusText) ||
    INFO_TYPE;
  return (
    <KatStatusindicator
      label={statusText}
      variant={statusIndicatorType}
      className={styles.capitalizedText}
    />
  );
};

export type InboundComplianceGroupStatusIndicatorProps =
  DataTableCellChildComponentProps<InboundComplianceGroup>;

export default InboundComplianceGroupStatusIndicator;
