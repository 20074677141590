import React, { FC, useEffect } from "react";

import client, { UNAUTHORIZED_HTTP_STATUS_CODE } from "src/api/client";
import { log, LogLevel } from "src/logger";
import { AxiosError } from "axios";
import { useInboundCompliance } from "src/components/Contexts/InboundComplianceContext";
import { GetInboundComplianceGroupRequest } from "src/api/interfaces/inbound_compliance_group";
import { useAuthorizationErrors } from "src/components/Contexts/AuthorizationErrorsContext";
import useUnauthorizedRedirect from "src/components/AICLayout/AICHeader/Permissions/WithRedirectWhenAuthorized";
import { AuthorizationErrors } from "src/api/interfaces/permissions";

const InboundComplianceFetcher: FC<InboundComplianceFetcherProps> = ({
  countryCode,
  documentType,
  documentId,
  executionNumber,
}: InboundComplianceFetcherProps) => {
  useUnauthorizedRedirect();

  const { setInboundComplianceGroups } = useInboundCompliance();
  const { setAuthorizationErrors } = useAuthorizationErrors();

  useEffect(() => {
    const areParametersPresent = (
      parameters: GetInboundComplianceGroupRequest
    ) => {
      return (
        parameters &&
        parameters.countryCode &&
        parameters.documentType &&
        parameters.documentId &&
        parameters.executionNumber
      );
    };

    const params: GetInboundComplianceGroupRequest = {
      countryCode,
      documentType,
      documentId,
      executionNumber,
    };

    const fetchInboundCompliance = () => {
      client
        .getInboundComplianceGroups(params)
        .then((result) => {
          log({
            level: LogLevel.INFO,
            message: "Inbound Compliance fetch completed successfully",
            operationNamespace:
              "InboundComplianceFetcher.getInboundComplianceGroups",
          });
          setInboundComplianceGroups((prevState) => ({
            ...prevState,
            inboundComplianceGroups: result.inboundComplianceGroups,
          }));
        })
        .catch((error: AxiosError) => {
          log({
            level: LogLevel.ERROR,
            message:
              "There was an error while trying to fetch Inbound Compliance",
            operationNamespace:
              "InboundComplianceFetcher.getInboundComplianceGroups",
            exception: error?.message,
            attributes: { params },
          });
          if (error.response?.status === UNAUTHORIZED_HTTP_STATUS_CODE) {
            setAuthorizationErrors((prevState: AuthorizationErrors) => ({
              authorizationErrors: prevState.authorizationErrors.add(
                error.message
              ),
            }));
          }
        });
    };

    if (areParametersPresent(params)) {
      fetchInboundCompliance();
    }
  }, [
    countryCode,
    documentType,
    documentId,
    executionNumber,
    setAuthorizationErrors,
    setInboundComplianceGroups,
  ]);

  return <div />;
};

interface InboundComplianceFetcherProps {
  countryCode: string;
  documentType: string;
  documentId: string;
  executionNumber: number;
}

export default InboundComplianceFetcher;
