import { ComplianceUserActionHandler } from "src/components/UserActions/Handlers/Compliance/types";
import client from "src/api/client";

const DOCUMENT_COMPLIANCE_OVERRIDE_ENTRY_KEY = "documentComplianceOverride";
const ACCEPT_DOCUMENT_COMPLIANCE_ENTRY_VALUE = "ACCEPT";

const handleForceAccept: ComplianceUserActionHandler = async (
  documentDetails
) => {
  const { overriddenMetadataIndexStarted } =
    await client.saveOverriddenMetadata({
      documentType: documentDetails.documentType,
      documentId: documentDetails.documentId,
      countryCode: documentDetails.countryCode,
      attributes: {
        [DOCUMENT_COMPLIANCE_OVERRIDE_ENTRY_KEY]:
          ACCEPT_DOCUMENT_COMPLIANCE_ENTRY_VALUE,
      },
      shouldRestartComplianceProcess: true,
    });

  return overriddenMetadataIndexStarted;
};

export default handleForceAccept;
