import { KatIcon, KatTableCell, KatTableRow } from "@amzn/katal-react";
import classnames from "classnames";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import styles from "src/styles.module.scss";
import { v1 } from "uuid";
import DataTableCell from "./DataTableCell";
import {
  ColumnDefinition,
  DataTableEspecialProperties,
} from "./DataTableDefinition";
import { DataTableRowProps } from "./DataTableRow";

export interface DataTableExpandableRowProps extends DataTableRowProps<any> {
  onExpandHandler: (expanded: boolean) => void;
  expandedProp?: boolean;
}

const DataTableExpandableRow: FC<
  PropsWithChildren<DataTableExpandableRowProps>
> = ({
  id,
  content,
  contents,
  columnsDefinition,
  children,
  onExpandHandler,
  expandedProp,
}) => {
  const [expanded, toggleExpansion] = useState(!!expandedProp);

  useEffect(() => {
    toggleExpansion(!!expandedProp);
  }, [expandedProp]);

  const buildTableCell = (
    columnDefinition: ColumnDefinition,
    index: number
  ) => (
    <DataTableCell
      key={`${id}-data-table-cell-${columnDefinition.property}-${index}`}
      id={id}
      columnDefinition={columnDefinition}
      content={content}
      contents={contents}
    />
  );

  const expandClickHandler = () => {
    onExpandHandler(expanded);
    toggleExpansion(!expanded);
  };

  const buildIconTableCell = () => (
    <KatTableCell
      id={`${id}-data-table-cell-value-icon`}
      key={`${id}-data-table-cell-value-${v1()}`}
      className={classnames(styles.max_w_15)}
    >
      <KatIcon
        className={classnames(styles.max_w_15, styles.pointer)}
        onClick={expandClickHandler}
        slot="action"
        name={expanded ? "expand_less" : "expand_more"}
        size="medium"
      />
    </KatTableCell>
  );

  const buildTableRowCells = () => {
    return columnsDefinition.map((columnDefinition, index) =>
      columnDefinition.property === DataTableEspecialProperties.ICON.valueOf()
        ? buildIconTableCell()
        : buildTableCell(columnDefinition, index)
    );
  };

  return (
    <>
      <KatTableRow
        onClick={expandClickHandler}
        id={`${id}-data-table-row`}
        className={classnames(
          styles.w_100,
          styles.mt_16,
          styles.relative,
          styles.background_white,
          styles.pointer
        )}
      >
        {buildTableRowCells()}
      </KatTableRow>
      {expanded && (
        <KatTableRow id={`${id}-expanded-data-table-row`}>
          <td
            colSpan={columnsDefinition.length}
            className={classnames(styles.w_85)}
          >
            {children}
          </td>
        </KatTableRow>
      )}
    </>
  );
};

export default DataTableExpandableRow;
