import React, { FC } from "react";
import { KatStatusindicator } from "@amzn/katal-react";
import styles from "src/styles.module.scss";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import {
  ValidationGroup,
  ValidationGroupStatus,
} from "src/api/interfaces/document_validation";
import { useTranslation } from "react-i18next";
import { getTranslatedValue } from "src/utils";

const INFO_TYPE = "information";
const ALERT_TYPE = "alert";
const SUCCESS_TYPE = "success";
const PENDING_TYPE = "pending";
const ERROR_TYPE = "error";

const VALIDATION_GROUP_STATUS_TO_STATUS_INDICATOR_TYPE: Map<
  string,
  KatStatusindicator.Variant
> = new Map([
  [ValidationGroupStatus.CRITICAL.valueOf(), ERROR_TYPE],
  [ValidationGroupStatus.ERROR.valueOf(), ALERT_TYPE],
  [ValidationGroupStatus.INFO.valueOf(), INFO_TYPE],
  [ValidationGroupStatus.PENDING.valueOf(), PENDING_TYPE],
  [ValidationGroupStatus.SEVERE_ERROR.valueOf(), ALERT_TYPE],
  [ValidationGroupStatus.SKIPPED.valueOf(), INFO_TYPE],
  [ValidationGroupStatus.SUCCESS.valueOf(), SUCCESS_TYPE],
]);

const ValidationGroupStatusIndicator: FC<
  ValidationGroupStatusIndicatorProps
> = ({ content, translateValue }) => {
  const { t } = useTranslation();
  const validationGroupStatusText = content.validationGroupStatus.valueOf();
  const translatedValidationGroupStatusText = translateValue
    ? getTranslatedValue(validationGroupStatusText, t)
    : validationGroupStatusText;

  const statusIndicatorType: KatStatusindicator.Variant =
    VALIDATION_GROUP_STATUS_TO_STATUS_INDICATOR_TYPE.get(
      validationGroupStatusText
    ) || INFO_TYPE;
  return (
    <KatStatusindicator
      label={translatedValidationGroupStatusText}
      variant={statusIndicatorType}
      className={styles.capitalizedText}
    />
  );
};

export type ValidationGroupStatusIndicatorProps =
  DataTableCellChildComponentProps<ValidationGroup>;

export default ValidationGroupStatusIndicator;
