import setHistory from "./actionFunctions";
import {
  ActionFunction,
  HistoryAction,
  HistoryActions,
  HistoryState,
} from "./types";

const reducerMap: Record<HistoryActions, ActionFunction> = {
  [HistoryActions.SET]: setHistory,
};

export default function historyReducer(
  state: HistoryState,
  emittedAction: HistoryAction
): HistoryState {
  const { type, payload } = emittedAction;

  return reducerMap[type](state, payload);
}
