import React, { Dispatch, FC, SetStateAction } from "react";
import { ModalWrapper } from "src/components/Modal/ModalWrapper";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { KatSpinner } from "@amzn/katal-react";
import client from "src/api/client";
import { WithTranslation, withTranslation } from "react-i18next";
import { actionTranslation } from "src/components/Pages/DocumentList/BulkOperations/index";
import { alertHandler } from "src/components/Alert/handlers";
import { useAlertContext } from "src/components/Alert/AlertContext";
import {
  BulkOperationsActions,
  BulkOperationsResponse,
} from "src/api/interfaces/bulk_operations";
import { log, LogLevel } from "src/logger";
import BulkOperationsConfirmationModalBody from "src/components/Pages/DocumentList/BulkOperations/BulkOperationsConfirmationModalBody";
import BulkOperationsConfirmationModalFooter from "src/components/Pages/DocumentList/BulkOperations/BulkOperationsConfirmationModalFooter";

enum ModalSteps {
  CONFIRMATION,
  LOADING,
  RESULTS,
}

const MODAL_CONFIRMATION_STEP_TITLE =
  "aic_internal_bulk_operations_confirmation_modal_title";
const MODAL_LOADING_STEP_TITLE =
  "aic_internal_bulk_operations_confirmation_modal_loading";
const MODAL_RESULTS_STEP_SUCCESS_TITLE =
  "aic_internal_bulk_operations_confirmation_modal_title_response_without_error";
const MODAL_RESULTS_STEP_ERROR_TITLE =
  "aic_internal_bulk_operations_confirmation_modal_title_response_with_error";
const ALERT_ERROR_TITLE = "aic_internal_bulk_operations_api_error_header";
const ALERT_ERROR_BODY = "aic_internal_bulk_operations_api_error_text";

const BulkOperationsConfirmationModal: FC<
  WithTranslation & BulkOperationConfirmationModalProps
> = ({
  t,
  documentList,
  documentType,
  countryCode,
  useCases,
  action,
  setHandlerModalAction,
}) => {
  const [step, setStep] = React.useState<ModalSteps>(ModalSteps.CONFIRMATION);
  const [bulkOperationResponse, setBulkOperationResponse] =
    React.useState<BulkOperationsResponse>();
  const { setAlerts, alerts } = useAlertContext();
  const haveErroredDocuments: boolean =
    (bulkOperationResponse?.totalErrorDocuments ?? 0) > 0;

  const modalTitle: Record<ModalSteps, string> = {
    [ModalSteps.CONFIRMATION]: t(MODAL_CONFIRMATION_STEP_TITLE, {
      documentListLength: documentList.length,
      action: t(
        actionTranslation[action as BulkOperationsActions]
      )?.toLowerCase(),
    }),
    [ModalSteps.LOADING]: t(MODAL_LOADING_STEP_TITLE),
    [ModalSteps.RESULTS]: haveErroredDocuments
      ? t(MODAL_RESULTS_STEP_ERROR_TITLE)
      : t(MODAL_RESULTS_STEP_SUCCESS_TITLE),
  };

  const handleModalClose = () => {
    setHandlerModalAction("");
    setStep(ModalSteps.CONFIRMATION);
    setBulkOperationResponse(undefined);
  };

  const handleModalCancel = () => {
    setHandlerModalAction("");
  };

  const handleModalSubmit = () => {
    setStep(ModalSteps.LOADING);
    client
      .performBulkOperations({
        action,
        countryCode,
        documentType,
        documentIds: documentList,
        useCases,
      })
      .then((response) => {
        log({
          level: LogLevel.INFO,
          message: "Bulk Operations request completed successfully",
          operationNamespace: "BulkOperationConfirmationModal",
        });
        setStep(ModalSteps.RESULTS);
        setBulkOperationResponse(response);
      })
      .catch((error) => {
        log({
          level: LogLevel.ERROR,
          message: "There was an error while trying to perform BulkOperations",
          operationNamespace: "BulkOperationConfirmationModal",
          exception: error?.message,
          attributes: {
            action,
            documentType,
            countryCode,
            documentList,
          },
        });
        setStep(ModalSteps.CONFIRMATION);
        alertHandler.createNewAlertEntry({
          alerts,
          setAlerts,
          header: t(ALERT_ERROR_TITLE),
          fade: true,
          isSuccess: false,
          text: t(ALERT_ERROR_BODY),
        });
      });
  };

  return (
    <ModalWrapper
      title={modalTitle[step]}
      visible={action !== ""}
      setVisible={(visible) => setHandlerModalAction(visible ? action : "")}
      onClose={handleModalClose}
      footer={
        <BulkOperationsConfirmationModalFooter
          submitHandler={
            step === ModalSteps.CONFIRMATION ? handleModalSubmit : undefined
          }
          cancelHandler={
            step === ModalSteps.CONFIRMATION ? handleModalCancel : undefined
          }
          closeHandler={
            step === ModalSteps.RESULTS ? handleModalClose : undefined
          }
        />
      }
    >
      {step === ModalSteps.LOADING ? (
        <>
          <div
            className={classnames(
              styles.display_flex,
              styles.flex_direction_column,
              styles.align_items_center,
              styles.w_100
            )}
          >
            <KatSpinner />
          </div>
        </>
      ) : (
        <BulkOperationsConfirmationModalBody
          countryCode={countryCode}
          documentType={documentType}
          documentList={documentList}
          action={action}
          errorDetails={bulkOperationResponse?.errorDetails}
          totalSuccessDocuments={bulkOperationResponse?.totalSuccessDocuments}
        />
      )}
    </ModalWrapper>
  );
};

export interface BulkOperationConfirmationModalProps {
  documentList: string[];
  countryCode: string;
  documentType: string;
  action: string;
  setHandlerModalAction: Dispatch<SetStateAction<string>>;
  useCases: string;
}

export default withTranslation()(BulkOperationsConfirmationModal);
