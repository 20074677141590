import React, { FC } from "react";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { KatIcon } from "@amzn/katal-react";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { LineItemRepresentation } from "src/api/interfaces/line_item";

const IndexLabel: FC<
  DataTableCellChildComponentProps<LineItemRepresentation>
> = ({ content }) => {
  const { validationErrors } = content;
  const hasValidationErrors = validationErrors && validationErrors.length;
  return (
    <>
      <span>{content.index}</span>
      {hasValidationErrors && (
        <KatIcon
          className={classnames(styles.color_crimson)}
          name="priority_high"
          size="tiny"
        />
      )}
    </>
  );
};

export default IndexLabel;
