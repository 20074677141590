export const statesAfterDOMContentLoaded = ["complete", "interactive"];

const afterDOMContentLoaded = (): boolean => {
  return statesAfterDOMContentLoaded.indexOf(document.readyState) >= 0;
};

export const whenDOMContentLoaded = (listener: () => void): void => {
  if (afterDOMContentLoaded()) {
    listener();
  } else {
    document.addEventListener("DOMContentLoaded", listener);
  }
};
