import {
  ColumnDefinition,
  DataTableDefinition,
} from "src/components/DataTable/DataTableDefinition";
import DocumentStatusBadge from "src/components/Pages/DocumentInbound/DocumentStatusBadge";
import DownloadDocumentButton from "src/components/Pages/DocumentInbound/Tabs/Documents/DownloadDocumentButton";
import DocumentIdCell from "src/components/Pages/DocumentInbound/Tabs/Documents/RelatedDocumentsListing/DocumentIdCell";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import { InboundDocumentField } from "src/constants";
import styles from "../../../../../../styles.module.scss";

export const documentColumnsDefinition: ColumnDefinition[] = [
  {
    label: "aic_doc_page_documents_tab_header_doc_id",
    property: InboundDocumentField.DOCUMENT_ID.valueOf(),
    childComponentOnly: true,
    ChildComponent: DocumentIdCell,
  },
  {
    label: "aic_doc_page_documents_tab_header_doc_type",
    property: InboundDocumentField.DOCUMENT_TYPE.valueOf(),
    valueStyle: [styles.capitalizedText],
  },
  {
    label: "aic_doc_page_documents_tab_header_status",
    property: InboundDocumentField.STATUS.valueOf(),
    translateValue: true,
    ChildComponent: DocumentStatusBadge,
    childComponentOnly: true,
  },
  {
    label: "aic_doc_page_documents_tab_header_action",
    property: InboundDocumentField.DOCUMENT_ID.valueOf(),
    childComponentOnly: true,
    ChildComponent: DownloadDocumentButton,
  },
];

const documentDataTableDefinition: DataTableDefinition<InboundDocument> = {
  columnsDefinition: documentColumnsDefinition,
};

export default documentDataTableDefinition;
