import React from "react";
import i18n, { AIC_SELECTED_LANGUAGE_COOKIE_NAME } from "src/i18n";
import { KatDropdown } from "@amzn/katal-react";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { Cookies } from "react-cookie";
import publishMetric, { MetricAction } from "../../../metrics";

const locales = [
  { name: "English", value: "en-US" },
  { name: "Español", value: "es-MX" },
  { name: "Português", value: "pt-BR" },
];

const cookies = new Cookies();
export default class LanguagePicker extends React.Component {
  handleChange = (e: CustomEvent<{ value: string }>) => {
    const language = e.detail.value;

    cookies.set(AIC_SELECTED_LANGUAGE_COOKIE_NAME, language, {
      path: "/",
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    i18n.changeLanguage(language, (err, t) => {
      publishMetric(MetricAction.CHANGE_LOCALE, {
        errored: !!err,
        value: language,
      });
    });
  };

  render() {
    const selectedLanguage =
      cookies.get(AIC_SELECTED_LANGUAGE_COOKIE_NAME) || "en-US";
    return (
      <div className={classnames(styles.justify_end, styles.flex)}>
        <KatDropdown
          options={locales}
          value={selectedLanguage}
          onChange={this.handleChange}
          className={classnames(
            styles.katal_dropdown_option_padding_02rem,
            styles.language_picker_dropdown_width
          )}
        />
      </div>
    );
  }
}
