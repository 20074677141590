import React, { FC, ReactNode, useCallback, useState } from "react";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import { RelatedDocuments } from "src/api/interfaces/related_documents";

const DEFAULT_RELATED_DOCUMENTS: RelatedDocuments = {
  relatedDocuments: [],
  setRelatedDocuments: () => {},
  relatesToDocuments: [],
  setRelatesToDocuments: () => {},
};

export const RelatedDocumentsContext = React.createContext<RelatedDocuments>(
  DEFAULT_RELATED_DOCUMENTS
);

export const useRelatedDocuments = () =>
  React.useContext(RelatedDocumentsContext);

export const RelatedDocumentsContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [relatedDocuments, setRelatedDocuments] = useState<
    Array<InboundDocument>
  >([]);

  const [relatesToDocuments, setRelatesToDocuments] = useState<
    Array<InboundDocument>
  >([]);

  const getRelatedDocumentsContextValue = useCallback(
    () => ({
      relatedDocuments,
      setRelatedDocuments,
      relatesToDocuments,
      setRelatesToDocuments,
    }),
    [
      relatedDocuments,
      setRelatedDocuments,
      relatesToDocuments,
      setRelatesToDocuments,
    ]
  );
  return (
    <RelatedDocumentsContext.Provider value={getRelatedDocumentsContextValue()}>
      {children}
    </RelatedDocumentsContext.Provider>
  );
};
