import React, { FC, useEffect } from "react";
import client, { UNAUTHORIZED_HTTP_STATUS_CODE } from "src/api/client";
import { log, LogLevel } from "src/logger";
import { AxiosError } from "axios";
import {
  GetInboundComplianceGroupDetailsRequest,
  InboundComplianceGroupDetails,
} from "src/api/interfaces/inbound_compliance_group";
import { useInboundComplianceGroupDetails } from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundComplianceModals/Listing/InboundComplianceGroupDetailsContext";
import { RetrievalState } from "src/constants";
import { useAuthorizationErrors } from "src/components/Contexts/AuthorizationErrorsContext";
import useUnauthorizedRedirect from "src/components/AICLayout/AICHeader/Permissions/WithRedirectWhenAuthorized";
import { AuthorizationErrors } from "src/api/interfaces/permissions";

const InboundComplianceGroupDetailsFetcher: FC<
  GetInboundComplianceGroupDetailsRequest
> = ({
  countryCode,
  documentType,
  documentId,
  executionNumber,
  eventSubtype,
}: GetInboundComplianceGroupDetailsRequest) => {
  useUnauthorizedRedirect();

  const { setInboundComplianceGroupDetails, groupDetailsRetrievalState } =
    useInboundComplianceGroupDetails();
  const { setAuthorizationErrors } = useAuthorizationErrors();

  const areParametersPresent = (
    parameters: GetInboundComplianceGroupDetailsRequest
  ) => {
    return (
      parameters &&
      parameters.countryCode &&
      parameters.documentType &&
      parameters.documentId &&
      parameters.executionNumber &&
      parameters.eventSubtype
    );
  };

  useEffect(() => {
    const params: GetInboundComplianceGroupDetailsRequest = {
      documentId,
      documentType,
      countryCode,
      executionNumber,
      eventSubtype,
    };

    const fetchInboundComplianceGroupDetails = () => {
      setInboundComplianceGroupDetails((prevState: any) => ({
        ...prevState,
        groupDetailsRetrievalState: RetrievalState.LOADING,
      }));

      client
        .getInboundComplianceGroupDetails(params)
        .then((result) => {
          log({
            level: LogLevel.INFO,
            message:
              "Inbound Compliance Group Details fetch completed successfully",
            operationNamespace:
              "InboundComplianceGroupDetailsFetcher.fetchInboundComplianceGroupDetails",
          });

          setInboundComplianceGroupDetails({
            inboundComplianceGroupDetails: result.inboundComplianceGroupDetails,
            inMemoryInboundComplianceGroupDetails:
              result.inboundComplianceGroupDetails,
            groupDetailsRetrievalState: RetrievalState.SUCCESS,
          });
        })
        .catch((error: AxiosError) => {
          log({
            level: LogLevel.ERROR,
            message:
              "There was an error while trying to fetch Inbound Compliance Group Detail",
            operationNamespace:
              "InboundComplianceGroupDetailsFetcher.fetchInboundComplianceGroupDetails",
            exception: error?.message,
            attributes: { params },
          });
          if (error.response?.status === UNAUTHORIZED_HTTP_STATUS_CODE) {
            setAuthorizationErrors((prevState: AuthorizationErrors) => ({
              authorizationErrors: prevState.authorizationErrors.add(
                error.message
              ),
            }));
          }
          setInboundComplianceGroupDetails({
            inboundComplianceGroupDetails: [],
            inMemoryInboundComplianceGroupDetails: [],
            groupDetailsRetrievalState: RetrievalState.ERROR,
          });
        });
    };

    const shouldFetchFromAPI = (
      request: GetInboundComplianceGroupDetailsRequest
    ) => {
      return (
        areParametersPresent(request) && groupDetailsRetrievalState == null
      );
    };

    if (shouldFetchFromAPI(params)) {
      fetchInboundComplianceGroupDetails();
    }
  }, [
    documentId,
    documentType,
    countryCode,
    executionNumber,
    eventSubtype,
    groupDetailsRetrievalState,
    setAuthorizationErrors,
    setInboundComplianceGroupDetails,
  ]);

  useEffect(() => {
    const fetchInboundComplianceGroupDetailsFromInMemory = () => {
      setInboundComplianceGroupDetails(
        (prevState: InboundComplianceGroupDetails) => ({
          ...prevState,
          inboundComplianceGroupDetails:
            prevState.inMemoryInboundComplianceGroupDetails,
        })
      );
    };

    const shouldFetchFromInMemory = () => {
      return groupDetailsRetrievalState === RetrievalState.SUCCESS;
    };

    if (shouldFetchFromInMemory()) {
      fetchInboundComplianceGroupDetailsFromInMemory();
    }
  }, [groupDetailsRetrievalState, setInboundComplianceGroupDetails]);

  return <div />;
};

export default InboundComplianceGroupDetailsFetcher;
