import { isStandalone } from "src/api/config";
import {
  DomainName,
  FilterType,
  FilterValues,
  InboundStatusOptionsExternal,
  InboundStatusOptionsInternal,
  TimeRangeOptions,
} from "./types";

export interface FilterDefinition {
  defaultOptionValues?: string[];
  defaultSelectedValues: string | string[];
  multiple?: boolean;
  type: FilterType;
}

export const FiltersDefinition: Record<DomainName, FilterDefinition> = {
  documentId: {
    defaultSelectedValues: [],
    type: FilterType.MULTI_VALUE,
  },
  documentSubtype: {
    defaultOptionValues: [],
    defaultSelectedValues: "",
    type: FilterType.SINGLE_VALUE,
  },
  documentType: {
    defaultOptionValues: [],
    defaultSelectedValues: "",
    type: FilterType.SINGLE_VALUE,
  },
  endIssuanceDate: {
    defaultSelectedValues: "",
    type: FilterType.SINGLE_VALUE,
  },
  issuanceDate: {
    defaultOptionValues: Object.values(TimeRangeOptions),
    defaultSelectedValues: "",
    type: FilterType.SINGLE_VALUE,
  },
  partnerCode: {
    defaultSelectedValues: [],
    type: FilterType.MULTI_VALUE,
  },
  referencedDocument: {
    defaultSelectedValues: [],
    type: FilterType.MULTI_VALUE,
  },
  referencedDocumentsIsEmpty: {
    defaultSelectedValues: "",
    type: FilterType.SINGLE_VALUE,
  },
  statuses: {
    defaultOptionValues: isStandalone()
      ? InboundStatusOptionsInternal
      : InboundStatusOptionsExternal,
    defaultSelectedValues: [],
    multiple: true,
    type: FilterType.MULTI_VALUE,
  },
  startIssuanceDate: {
    defaultSelectedValues: "",
    type: FilterType.SINGLE_VALUE,
  },
  useCase: {
    defaultOptionValues: [],
    defaultSelectedValues: [],
    multiple: true,
    type: FilterType.MULTI_VALUE,
  },
  validationGroups: {
    defaultOptionValues: [],
    defaultSelectedValues: [],
    multiple: true,
    type: FilterType.MULTI_VALUE,
  },
  orchestrationEvents: {
    defaultOptionValues: [],
    defaultSelectedValues: [],
    multiple: true,
    type: FilterType.MULTI_VALUE,
  },
  search: {
    defaultSelectedValues: "",
    type: FilterType.SINGLE_VALUE,
  },
};

export const getDefaultSelectedValues = (): FilterValues => {
  return Object.keys(FiltersDefinition).reduce((prev, key) => {
    return {
      ...prev,
      [key]: FiltersDefinition[key as DomainName].defaultSelectedValues,
    };
  }, {} as FilterValues);
};

export const getDefaultOptionValues = (): Record<DomainName, string[]> => {
  return Object.keys(FiltersDefinition).reduce((prev, key) => {
    return {
      ...prev,
      [key]: FiltersDefinition[key as DomainName].defaultOptionValues,
    };
  }, {} as Record<DomainName, string[]>);
};
