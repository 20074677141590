import React, { FC } from "react";
import { KatTooltip } from "@amzn/katal-react";
import Card from "src/components/Card/Card";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { CardFieldProps, CardFieldSlot } from "src/components/Card/CardField";
import {
  DocumentDetails,
  MetadataOverrides,
  ReferencedDocument,
} from "src/api/interfaces/inbound_document";
import i18n from "src/i18n";
import {
  HEADER_OVERRIDDEN_METADATA_KEY_BY_DOCUMENT_TYPE,
  LINE_ITEM_OVERRIDDEN_METADATA_KEY_BY_DOCUMENT_TYPE,
} from "src/components/UserActions/Handlers/Validation/OverrideMetadata/constants";
import styles from "../../../../styles.module.scss";

const DEFAULT_FOR_MISSING_VALUES = "--";
const EMPTY_VALUE = "";
const FIRST_ELEMENT_INDEX = 0;

function getOverriddenDocumentType(
  metadataOverrides?: MetadataOverrides
): string | undefined {
  if (!metadataOverrides) {
    return undefined;
  }

  const headerEntries = Array.from(
    HEADER_OVERRIDDEN_METADATA_KEY_BY_DOCUMENT_TYPE.entries()
  );

  for (let index = 0; index < headerEntries.length; index += 1) {
    const [documentTypeCandidate, overriddenMetadataKey] = headerEntries[index];

    if (metadataOverrides[overriddenMetadataKey]) {
      return documentTypeCandidate;
    }
  }

  const lineItemEntries = Array.from(
    LINE_ITEM_OVERRIDDEN_METADATA_KEY_BY_DOCUMENT_TYPE.entries()
  );

  for (let index = 0; index < lineItemEntries.length; index += 1) {
    const [documentTypeCandidate, overriddenMetadataKey] =
      lineItemEntries[index];

    if (metadataOverrides[overriddenMetadataKey]) {
      return documentTypeCandidate;
    }
  }

  return undefined;
}

function uniqueFilter(value: any, index: any, self: string | any[]) {
  return self.indexOf(value) === index;
}

function containsMultipleReferencedDocuments(
  originalReferencedDocs: string[],
  overrideReferencedDocs?: string[],
  metadataOverrides?: MetadataOverrides,
  documentType?: string
): boolean {
  if (
    !documentType ||
    !overrideReferencedDocs ||
    overrideReferencedDocs.length === 0
  ) {
    return originalReferencedDocs.length > 1;
  }
  const lineItemOverrideMetadataKey =
    LINE_ITEM_OVERRIDDEN_METADATA_KEY_BY_DOCUMENT_TYPE.get(documentType) ??
    EMPTY_VALUE;

  return (
    overrideReferencedDocs?.length > 1 ||
    (metadataOverrides !== undefined &&
      lineItemOverrideMetadataKey in metadataOverrides &&
      originalReferencedDocs.length > 1)
  );
}

function getDocumentIds(referencedDocuments?: ReferencedDocument[]): string[] {
  if (!referencedDocuments) {
    return [];
  }
  const documentIds = [];

  for (let index = 0; index < referencedDocuments.length; index += 1) {
    if (referencedDocuments[index].documentId?.length > 0) {
      documentIds.push(referencedDocuments[index].documentId);
    }
  }
  return documentIds.filter(uniqueFilter);
}

function getOverriddenDocumentIdByType(
  documentType?: string,
  metadataOverrides?: MetadataOverrides
): string[] | undefined {
  if (!documentType || !metadataOverrides) {
    return undefined;
  }

  const relatedDocuments = (
    metadataOverrides.documentDetails?.referencedDocuments ?? []
  ).filter(
    (document: { [key: string]: string }) =>
      document.documentType === documentType
  );

  const metadataKey =
    HEADER_OVERRIDDEN_METADATA_KEY_BY_DOCUMENT_TYPE.get(documentType) ??
    EMPTY_VALUE;
  const legacyRelatedDocumentId = metadataOverrides[metadataKey];

  const documentIds = getDocumentIds(relatedDocuments);
  if (documentIds.length > 0) return documentIds;

  if (legacyRelatedDocumentId) {
    documentIds.push(legacyRelatedDocumentId);
  }

  return documentIds.filter(uniqueFilter);
}

const DocumentDetailCard: FC<DocumentDetailCardProps> = ({
  documentDetails,
  metadataOverrides,
  className,
}) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const detailsHeader: CardFieldProps<string, any> = {
    value: documentDetails.documentType
      .toUpperCase()
      .concat(` ${t("aic_doc_page_doc_card_header_details")}`),
    slot: CardFieldSlot.TITLE,
  };
  const cardFields = [detailsHeader];
  const { referencedDocuments } = documentDetails;

  const documentType =
    (referencedDocuments &&
      referencedDocuments[FIRST_ELEMENT_INDEX]?.documentType) ||
    getOverriddenDocumentType(metadataOverrides);

  const overriddenValues = getOverriddenDocumentIdByType(
    documentType,
    metadataOverrides
  );

  const documentIds = getDocumentIds(referencedDocuments);

  const originalValues =
    documentIds && documentIds.length > 0
      ? documentIds
      : [DEFAULT_FOR_MISSING_VALUES];
  const currentValues =
    overriddenValues && overriddenValues.length > 0
      ? overriddenValues
      : originalValues;

  const containsReferencedDocs =
    referencedDocuments && referencedDocuments.length >= 1;
  const containsOverride = overriddenValues && overriddenValues.length >= 1;

  const isSingleDocCurrently = !containsMultipleReferencedDocuments(
    originalValues,
    overriddenValues,
    metadataOverrides,
    documentType
  );

  const valueToShow = isSingleDocCurrently
    ? currentValues[FIRST_ELEMENT_INDEX]
    : `${t(
        "aic_doc_page_doc_card_header_multiple_referenced_documents_key"
      )} ${documentType}s`;

  const documentIdComponent = containsOverride ? (
    <div className={styles.tooltip_info_wrapper}>
      {valueToShow}
      <KatTooltip
        label={`${t(
          "aic_original_value_overridden_original_value_is"
        )} ${originalValues}`}
        trigger-icon="info-circle"
        position="right"
        size="tiny"
        className={styles.kat_component_bold_label}
      />
    </div>
  ) : (
    valueToShow
  );

  const referencedDocumentContent: CardFieldProps<any, any> = {
    label: documentType,
    value: documentIdComponent,
    valueStyle: [styles.bold],
    slot: CardFieldSlot.BODY,
  };

  if (containsReferencedDocs || containsOverride) {
    cardFields.push(referencedDocumentContent);
  }

  const documentTotalCostContent: CardFieldProps<string, any> = {
    label: t("aic_doc_page_doc_card_field_total_cost"),
    value: documentDetails.documentTotalCost || DEFAULT_FOR_MISSING_VALUES,
    valueStyle: [styles.bold],
    slot: CardFieldSlot.BODY,
  };
  cardFields.push(documentTotalCostContent);

  const documentIssuanceDate: CardFieldProps<string, any> = {
    label: t("aic_doc_page_doc_card_field_issuance_date"),
    value: documentDetails.issuanceDate || DEFAULT_FOR_MISSING_VALUES,
    valueStyle: [styles.bold],
    slot: CardFieldSlot.BODY,
    formatter: (date: string) => {
      if (date === DEFAULT_FOR_MISSING_VALUES) return date;

      return new Date(date).toLocaleString(i18n.language);
    },
  };
  cardFields.push(documentIssuanceDate);

  return <Card content={cardFields} className={className} />;
};

export interface DocumentDetailCardProps {
  documentDetails: DocumentDetails;
  metadataOverrides?: MetadataOverrides;
  className?: string;
}

export default DocumentDetailCard;
