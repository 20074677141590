import React, { FC } from "react";
import { KatTable } from "@amzn/katal-react";
import classnames from "classnames";
import {
  CheckboxColumnDefinition,
  ColumnDefinition,
  DataTableDefinition,
  DataTableSelectionAction,
  DataTableSelectionProps,
  RetrievalState,
} from "src/components/DataTable/DataTableDefinition";
import DataTableWarning from "./DataTableWarning";
import DataTableHead from "./DataTableHead";
import DataTableBody from "./DataTableBody";
import styles from "../../styles.module.scss";

const DataTable: FC<DataTableProps<any>> = ({
  id,
  tableDefinition,
  contents,
  retrievalState,
  selectEnabled,
  selectDispatcher,
  shouldBeSelectable,
  shouldEnableSelectAll,
  CustomDataTableRow,
  customDataTableRowProps,
}) => {
  const className = classnames(styles.mt_16, styles.relative, {
    loading: retrievalState === RetrievalState.LOADING,
  });
  const buildColumnDefinition = () => {
    const base: ColumnDefinition[] = [];
    if (selectEnabled) {
      base.push(CheckboxColumnDefinition);
    }

    return [...base, ...tableDefinition.columnsDefinition];
  };

  return (
    <KatTable id={`${id}-data-table`} className={className}>
      <DataTableHead
        id={id}
        columnsDefinition={buildColumnDefinition()}
        contents={contents}
        selectEnabled={selectEnabled}
        selectDispatcher={selectDispatcher}
        shouldEnableSelectAll={shouldEnableSelectAll}
      />
      <DataTableBody
        id={id}
        columnsDefinition={buildColumnDefinition()}
        rowClickHandler={tableDefinition.rowClickHandler}
        rowURLBuilder={tableDefinition.rowURLBuilder}
        contents={contents}
        selectDispatcher={selectDispatcher}
        shouldBeSelectable={shouldBeSelectable}
        CustomDataTableRow={CustomDataTableRow}
        customDataTableRowProps={customDataTableRowProps}
      />
      <DataTableWarning
        id={id}
        retrievalState={retrievalState}
        contents={contents}
      />
    </KatTable>
  );
};

export interface DataTableProps<ContentType> {
  id: string;
  tableDefinition: DataTableDefinition<ContentType>;
  retrievalState?: RetrievalState;
  contents?: Array<ContentType & DataTableSelectionProps>;
  selectEnabled?: boolean;
  shouldBeSelectable?: (
    content: ContentType & DataTableSelectionProps,
    contents: Array<ContentType & DataTableSelectionProps>
  ) => boolean;
  selectDispatcher?: (event: DataTableSelectionAction<ContentType>) => void;
  shouldEnableSelectAll?: boolean;
  CustomDataTableRow?: FC<ContentType>;
  customDataTableRowProps?: any;
}

export default DataTable;
