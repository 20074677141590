import React, { FC } from "react";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { withTranslation, WithTranslation } from "react-i18next";

const Avatar: FC<{ userAlias?: string } & WithTranslation> = ({
  userAlias,
  t,
}) => {
  const userAvatar = userAlias ? (
    <div className={classnames(styles.justify_end, styles.flex)}>
      <div className={classnames(styles.justify_end, styles.flex)}>
        <img
          alt={t("aic_avatar_authenticated_user")}
          src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?login=${userAlias}`}
          className={classnames(
            styles.b_2_solid,
            styles.b_radius_50,
            styles.w_fixed_35,
            styles.h_fixed_35,
            styles.obj_fit_cover
          )}
        />
      </div>
      <div
        className={classnames(
          styles.ml_16,
          styles.flex,
          styles.align_items_center,
          styles.justify_center
        )}
      >
        <h5 className={styles.mb_zero_important}>
          {t("aic_avatar_user_welcome")}
          <span className={styles.capitalizedText}>{userAlias}!</span>
        </h5>
      </div>
    </div>
  ) : (
    <span />
  );
  return userAvatar;
};

export default withTranslation()(Avatar);
