import { FC, ReactNode } from "react";
import DataTableCheckbox from "src/components/DataTable/DataTableCheckbox";

export interface ColumnDefinition {
  label: string;
  property: string;
  valueStyle?: Array<string>;
  valueStyleBuilder?: (content: any) => Array<string>;
  formatter?: (value: string) => ReactNode;
  translateValue?: boolean;
  childComponentOnly?: boolean;
  ChildComponent?: FC<DataTableCellChildComponentProps<any>>;
  shouldDisableLink?: boolean;
}

export interface DataTableCellChildComponentProps<ContentType> {
  content: ContentType;
  contents?: ContentType[];
  translateValue?: boolean;
  valueStyle?: Array<string>;
  selectDispatcher?: (event: DataTableSelectionAction<ContentType>) => void;
  shouldBeSelectable?: (
    content: ContentType & DataTableSelectionProps,
    contents: Array<ContentType & DataTableSelectionProps>
  ) => boolean;
  shouldEnableSelection?: boolean;
}

export interface DataTableDefinition<ContentType> {
  columnsDefinition: Array<ColumnDefinition>;
  rowClickHandler?: (content: ContentType) => void;
  rowURLBuilder?: (content: ContentType) => string;
}

export interface DataTableSelectionProps {
  selected: boolean;
  index: number;
}

export interface DataTableSelection<ContentType> {
  contents: Array<ContentType & DataTableSelectionProps>;
  setDataTableSelectionContext: (updates: any) => void;
}

export enum RetrievalState {
  LOADING,
  ERROR,
  SUCCESS,
}

export const SELECT_ALL_PROPERTY = "SELECT_ALL";
export const CheckboxColumnDefinition: ColumnDefinition = {
  label: "aic_doc_listing_select_all",
  property: SELECT_ALL_PROPERTY,
  childComponentOnly: true,
  ChildComponent: DataTableCheckbox,
  shouldDisableLink: true,
};

export enum DataTableSelectionActions {
  SELECT,
  UNSELECT,
  SELECT_ALL,
  UNSELECT_ALL,
}

export enum DataTableEspecialProperties {
  ICON = "icon",
}

export interface DataTableSelectionAction<ContentType> {
  type: DataTableSelectionActions;
  payload?: ContentType & DataTableSelectionProps;
}
