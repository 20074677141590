import React, { FC, useCallback, useEffect, useRef } from "react";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import SideBarFooter from "src/components/SideBar/SideBarFooter";
import SideBarHeader from "src/components/SideBar/SideBarHeader";
import { handleClickOutside } from "src/utils";
import side_bar_style from "./side_bar_styles.module.scss";
import style from "../../styles.module.scss";

export type SideBarProps = {
  title: string;
  visible: boolean;
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const CLICK_EVENT = "click";

const SideBar: FC<SideBarProps> = ({
  onSubmit,
  onCancel,
  title,
  visible,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const onCancelHandler = useCallback(
    (e: any) => {
      onCancel(e);
    },
    [onCancel]
  );

  useEffect(() => {
    if (visible) {
      document.addEventListener(
        CLICK_EVENT,
        handleClickOutside(ref, onCancelHandler),
        true
      );
    }

    return () => {
      document.removeEventListener(
        CLICK_EVENT,
        handleClickOutside(ref, onCancelHandler),
        true
      );
    };
  }, [visible, onCancelHandler]);

  return (
    <>
      <div
        ref={ref}
        className={classnames(
          side_bar_style.side_bar_style,
          style.position_fixed,
          style.top_zero,
          style.right_zero,
          style.z_index_max,
          { [side_bar_style.side_bar_close_style]: !visible }
        )}
      >
        <SideBarHeader title={title} />
        <section
          className={classnames(styles.ma_16, side_bar_style.side_bar_content)}
        >
          {children}
        </section>
        <SideBarFooter onSubmit={onSubmit} onCancel={onCancelHandler} />
      </div>
    </>
  );
};

export default SideBar;
