import React, { FC, ReactNode, useContext, useReducer } from "react";
import documentsReducer from "src/components/Contexts/DocumentList/reducer";
import { DocumentListState } from "src/components/Contexts/DocumentList/types";
import { RetrievalState } from "src/constants";

export const DEFAULT_DOCUMENT_LISTING: DocumentListState = {
  documents: {},
  retrieveState: RetrievalState.SUCCESS,
};

export const DocumentListContext = React.createContext<{
  state: DocumentListState;
  dispatch: React.Dispatch<any>;
}>({ state: DEFAULT_DOCUMENT_LISTING, dispatch: () => {} });

export const useDocumentList = () => useContext(DocumentListContext);

export const DocumentListContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    documentsReducer,
    DEFAULT_DOCUMENT_LISTING
  );

  return (
    <DocumentListContext.Provider value={{ state, dispatch }}>
      {children}
    </DocumentListContext.Provider>
  );
};
