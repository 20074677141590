import classnames from "classnames";
import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFilterOptions } from "src/components/Contexts/FilterOptions/Provider";
import {
  DomainName,
  FilterActions,
  FilterValue,
} from "src/components/Contexts/FilterOptions/types";
import TranslatedSelectInput from "src/components/Inputs/TranslatedSelectInput";
import { updateUrlParams } from "src/utils";
import styles from "src/styles.module.scss";
import { KatDateRangePicker, KatToggle } from "@amzn/katal-react";
import i18n from "src/i18n";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import {
  dateIsGreaterThanNow,
  isDateDisabledWhenEndDate,
  isDateDisabledWhenStartDate,
} from "./dateDisablingFunctions";

const IssuanceDateFilter: FC = () => {
  const {
    state: { selectedValues, optionValues },
    dispatch,
  } = useFilterOptions();
  const { startIssuanceDate, endIssuanceDate } = selectedValues;

  const history = useHistory();
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const defaultEmptyValue = "";
  const initialShowDateRangePicker = !!(startIssuanceDate && endIssuanceDate);

  const [showDateRangePicker, setShowDateRangePicker] = useState(
    initialShowDateRangePicker
  );
  const dateRangePickerRef: RefObject<any> = useRef(null);

  const updateIssuanceDateUrlParams = (
    issuanceDateValue: string,
    startIssuanceDateValue: string,
    endIssuanceDateValue: string
  ) => {
    updateUrlParams(
      [
        [DomainName.ISSUANCE_DATE, issuanceDateValue],
        [DomainName.START_ISSUANCE_DATE, startIssuanceDateValue],
        [DomainName.END_ISSUANCE_DATE, endIssuanceDateValue],
      ],
      history
    );
  };

  const customDateToggleOnChangeHandler = () => {
    [
      DomainName.START_ISSUANCE_DATE,
      DomainName.END_ISSUANCE_DATE,
      DomainName.ISSUANCE_DATE,
    ].forEach((domainName: DomainName) => {
      dispatch({
        type: FilterActions.SET_SELECTED_VALUES,
        payload: {
          domainName,
          value: defaultEmptyValue,
        },
      });
    });

    setShowDateRangePicker(!showDateRangePicker);
  };

  const preDefinedDatesOnChangeHandler = (value?: FilterValue) => {
    dispatch({
      type: FilterActions.SET_SELECTED_VALUES,
      payload: {
        domainName: DomainName.ISSUANCE_DATE,
        value,
      },
    });

    updateIssuanceDateUrlParams(
      value as string,
      defaultEmptyValue,
      defaultEmptyValue
    );
  };

  const dateRangePickerOnChangeHandler = (
    event: KatDateRangePicker.ChangeEvent
  ) => {
    const { startDate, endDate } = event.detail;

    dispatch({
      type: FilterActions.SET_SELECTED_VALUES,
      payload: {
        domainName: DomainName.START_ISSUANCE_DATE,
        value: startDate,
      },
    });
    dispatch({
      type: FilterActions.SET_SELECTED_VALUES,
      payload: {
        domainName: DomainName.END_ISSUANCE_DATE,
        value: endDate,
      },
    });

    if (startDate && endDate) {
      updateIssuanceDateUrlParams(defaultEmptyValue, startDate, endDate);
    }
  };

  useEffect(() => {
    if (
      !dateRangePickerRef ||
      !dateRangePickerRef.current ||
      !dateRangePickerRef.current.startPicker ||
      !dateRangePickerRef.current.endPicker
    ) {
      return;
    }

    dateRangePickerRef.current.endPicker.isDateDisabled = startIssuanceDate
      ? isDateDisabledWhenStartDate(startIssuanceDate as string)
      : dateIsGreaterThanNow;

    dateRangePickerRef.current.startPicker.isDateDisabled = endIssuanceDate
      ? isDateDisabledWhenEndDate(endIssuanceDate as string)
      : dateIsGreaterThanNow;
  }, [startIssuanceDate, endIssuanceDate, showDateRangePicker]);

  const issuanceDateFilterComponent = (
    <div>
      {!showDateRangePicker && (
        <TranslatedSelectInput
          translationPrefix="aic_filter_bar_quick_filter_issuance_date"
          onChange={preDefinedDatesOnChangeHandler}
          initialValue={selectedValues.issuanceDate}
          options={optionValues.issuanceDate}
          classname={classnames(
            styles.flex,
            styles.align_items_start,
            styles.w_210_px,
            styles.justify_center
          )}
        />
      )}

      {showDateRangePicker && (
        <KatDateRangePicker
          locale={i18n.language}
          ref={dateRangePickerRef}
          startValue={selectedValues.startIssuanceDate as string}
          startLabel={t("aic_issuance_date_start_date_picker")}
          endLabel={t("aic_issuance_date_end_date_picker")}
          endValue={selectedValues.endIssuanceDate as string}
          onChange={dateRangePickerOnChangeHandler}
          className={classnames(
            styles.flex,
            styles.flex_direction_row,
            styles.mt_neg_23,
            styles.align_items_start
          )}
        />
      )}

      <KatToggle
        checked={showDateRangePicker}
        textChecked={t("aic_issuance_date_filter_toggle")}
        textUnchecked={t("aic_issuance_date_filter_toggle")}
        onChange={customDateToggleOnChangeHandler}
        className={classnames(
          styles.mt_8,
          styles.justify_end,
          styles.flex,
          styles.align_items_start
        )}
      />
    </div>
  );

  return issuanceDateFilterComponent;
};

export default IssuanceDateFilter;
