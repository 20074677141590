import {
  ColumnDefinition,
  DataTableDefinition,
  DataTableEspecialProperties,
} from "src/components/DataTable/DataTableDefinition";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import { ProductItemField } from "src/constants";
import IndexLabel from "src/components/Pages/DocumentInbound/Tabs/DocumentItems/DocumentItemsTable/IndexLabel";
import { cloneDeep, isEmpty } from "lodash";
import { UserActionMetadata } from "src/components/Contexts/UserActionsMetadata/types";
import { OverrideBy } from "src/components/UserActions/types";
import ItemIdLabel from "src/components/Pages/DocumentInbound/Tabs/DocumentItems/DocumentItemsTable/ItemIdLabel";
import DocumentItemDetails from "./DocumentItemDetails/DocumentItemDetails";
import ReferencedDocumentLink from "../DocumentItemsListing/ReferencedDocumentLink";
import AmazonIdentifiersLabel from "./AmazonIdentifierLabel";

const productItemsColumnsDefinition: ColumnDefinition[] = [
  {
    label: "aic_doc_page_product_items_tab_header_item_index",
    property: ProductItemField.INDEX.valueOf(),
    ChildComponent: IndexLabel,
    childComponentOnly: true,
  },
  {
    label: "aic_doc_page_product_items_tab_header_item_product_id",
    property: ProductItemField.PRODUCT_ID.valueOf(),
    ChildComponent: ItemIdLabel,
    childComponentOnly: true,
  },
  {
    label: "aic_doc_page_product_items_tab_header_item_description",
    property: ProductItemField.DESCRIPTION.valueOf(),
  },
  {
    label: "aic_doc_page_product_items_tab_header_item_quantity",
    property: ProductItemField.QUANTITY.valueOf(),
  },
  {
    label: "aic_doc_page_product_items_tab_header_item_total",
    property: ProductItemField.TOTAL.valueOf(),
  },
  {
    label: "",
    property: "info",
    ChildComponent: DocumentItemDetails,
    childComponentOnly: true,
  },
  {
    label: "",
    property: DataTableEspecialProperties.ICON.valueOf(),
  },
];

export const referencedColumn: ColumnDefinition = {
  label: "aic_doc_page_product_items_tab_header_item_reference",
  property: ProductItemField.REFERENCE.valueOf(),
  ChildComponent: ReferencedDocumentLink,
  childComponentOnly: true,
};

export const amazonIdentifierColumn: ColumnDefinition = {
  label: "aic_doc_page_product_items_tab_header_item_amazon_identifiers",
  property: ProductItemField.AMAZON_IDENTIFIERS.valueOf(),
  ChildComponent: AmazonIdentifiersLabel,
  childComponentOnly: true,
};

const documentItemsDataTableDefinition: DataTableDefinition<InboundDocument> = {
  columnsDefinition: productItemsColumnsDefinition,
};

export const buildTableDefinition = (
  metadata: UserActionMetadata,
  tablePage: any
): DataTableDefinition<InboundDocument> => {
  const newTableDefinition = cloneDeep(documentItemsDataTableDefinition);
  let referencedIndex = 4;

  const shouldShowAmazonIdentifiersColumn = tablePage?.some(
    (item: any) => !isEmpty(item.amazonIdentifiers)
  );

  if (shouldShowAmazonIdentifiersColumn) {
    referencedIndex += 1;
    newTableDefinition.columnsDefinition.splice(2, 0, amazonIdentifierColumn);
  }

  const shouldShowReferencedColumn = metadata.referencedDocuments?.some(
    (referenced: any) => referenced.overrideBy === OverrideBy.LINE_ITEM
  );

  if (shouldShowReferencedColumn) {
    newTableDefinition.columnsDefinition.splice(
      referencedIndex,
      0,
      referencedColumn
    );
  }

  return newTableDefinition;
};

export default documentItemsDataTableDefinition;
