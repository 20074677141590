import React, { FC, ReactNode, useCallback, useState } from "react";
import { Permissions } from "src/api/interfaces/permissions";

const DEFAULT_PERMISSIONS: Permissions = {
  permissions: new Map(),
  setPermissions: () => {},
};

export const PermissionsContext =
  React.createContext<Permissions>(DEFAULT_PERMISSIONS);

export const usePermissions = () => React.useContext(PermissionsContext);

export const PermissionsContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [permissions, setPermissions] =
    useState<Permissions>(DEFAULT_PERMISSIONS);

  const getPermissionsContextValue = useCallback(
    () => ({
      ...permissions,
      setPermissions,
    }),
    [permissions, setPermissions]
  );
  return (
    <PermissionsContext.Provider value={getPermissionsContextValue()}>
      {children}
    </PermissionsContext.Provider>
  );
};
