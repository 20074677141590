import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Card from "src/components/Card/Card";
import { CardFieldProps, CardFieldSlot } from "src/components/Card/CardField";
import { Agent } from "src/api/interfaces/inbound_document";
import { KatTooltip } from "@amzn/katal-react";
import styles from "src/styles.module.scss";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";

export const DEFAULT_TAX_REGISTRATION_TYPE = "VAT ID";
const SEPARATOR = ", ";
const DEFAULT_FOR_MISSING_VALUES = "--";

const AgentCard: FC<AgentCardProps> = ({ agent, className }) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const agentTitle: CardFieldProps<string, any> = {
    value: agent.agentType,
    translateValue: true,
    slot: CardFieldSlot.TITLE,
  };

  const firstAgentCode = agent.agentCodes?.length ? agent.agentCodes[0] : "";
  const remainingAgentCodes =
    agent.agentCodes?.length > 1
      ? agent.agentCodes.slice(1).join(SEPARATOR)
      : undefined;

  const agentActionComponent = remainingAgentCodes ? (
    <KatTooltip
      label={remainingAgentCodes}
      trigger-text={firstAgentCode}
      position="right"
    />
  ) : (
    <span id={`agent-code-${firstAgentCode}`}> {firstAgentCode} </span>
  );
  const agentActionChildComponent = () => {
    return agentActionComponent;
  };

  const agentAction: CardFieldProps<string, any> = {
    value: "",
    slot: CardFieldSlot.ACTION,
    ChildComponent: agentActionChildComponent,
  };

  const agentName: CardFieldProps<string, any> = {
    label: t("aic_doc_page_agent_card_field_name"),
    value: agent.name || DEFAULT_FOR_MISSING_VALUES,
    valueStyle: [styles.bold],
    slot: CardFieldSlot.BODY,
  };

  const agentTaxRegistration: CardFieldProps<string, any> = {
    label: agent.taxRegistrationType || DEFAULT_TAX_REGISTRATION_TYPE,
    value: agent.taxRegistrationNumber || DEFAULT_FOR_MISSING_VALUES,
    valueStyle: [styles.bold],
    slot: CardFieldSlot.BODY,
  };

  const agentAddress: CardFieldProps<string, any> = {
    label: t("aic_doc_page_agent_card_field_address"),
    value: agent.address || DEFAULT_FOR_MISSING_VALUES,
    valueStyle: [styles.bold],
    slot: CardFieldSlot.BODY,
  };

  const cardFields = [
    agentTitle,
    agentAction,
    agentName,
    agentTaxRegistration,
    agentAddress,
  ];

  return <Card content={cardFields} className={className} />;
};

export interface AgentCardProps {
  agent: Agent;
  className?: string;
}

export default AgentCard;
