import { setRealmCookie } from "src/cookies";
import { Country } from "src/api/interfaces/country";

export enum Realm {
  EU = "EU",
  NA = "NA",
}

export const DEFAULT_REALM = Realm.NA;

export const RealmByCountryCode: Map<string, Realm> = new Map()
  // EU Country Codes
  .set("EG", Realm.EU)
  .set("FR", Realm.EU)
  .set("PL", Realm.EU)
  .set("TR", Realm.EU)
  .set("RO", Realm.EU)
  .set("MY", Realm.EU)
  // NA Country Codes
  .set("BR", Realm.NA)
  .set("MX", Realm.NA);

export const setRealmBasedOnCountrySelection: (
  selectedCountry: Country | undefined
) => void = (selectedCountry) => {
  let realm: Realm;
  if (!selectedCountry) {
    realm = DEFAULT_REALM;
  } else {
    realm =
      RealmByCountryCode.get(selectedCountry.countryCode) || DEFAULT_REALM;
  }

  setRealmCookie(realm);
};
