import React, { FC } from "react";
import {
  InboundDocument,
  InboundStatus,
} from "src/api/interfaces/inbound_document";
import { KatBadge } from "@amzn/katal-react";
import styles from "src/styles.module.scss";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { useTranslation } from "react-i18next";
import { getTranslatedValue } from "src/utils";
import TranslationKey, {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";

const DEFAULT_TYPE = "default";
const INFO_TYPE = "info";
const DANGER_TYPE = "danger";
const SUCCESS_TYPE = "success";

const INBOUND_STATUS_TO_BADGE_TYPE: Map<string, KatBadge.Type> = new Map([
  [InboundStatus.CANCELED.valueOf(), DEFAULT_TYPE],
  [InboundStatus.INGESTED.valueOf(), INFO_TYPE],
  [InboundStatus.UNALLOCATED.valueOf(), DANGER_TYPE],
  [InboundStatus.ACCEPTED.valueOf(), SUCCESS_TYPE],
  [InboundStatus.REJECTED.valueOf(), DANGER_TYPE],
  [InboundStatus.PENDING_ACTIONS.valueOf(), DANGER_TYPE],
  [InboundStatus.TEMPORARILY_REJECTED.valueOf(), DANGER_TYPE],
  [InboundStatus.FINALIZED.valueOf(), DEFAULT_TYPE],
  [InboundStatus.PROCESSING.valueOf(), INFO_TYPE],
  [InboundStatus.SKIPPED.valueOf(), INFO_TYPE],
]);

const DocumentStatusBadge: FC<DocumentStatusBadgeProps> = ({
  content,
  translateValue,
}) => {
  // useSuspense parameter is required to pass the tests
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const documentComplianceBadgeText = content.status.valueOf();
  const translationKey = new TranslationKey(
    content.countryCode.valueOf(),
    content.documentType.valueOf(),
    content.documentSubtype?.valueOf(),
    content.useCase?.valueOf()
  );
  const translatedDocumentComplianceBadgeText = translateValue
    ? translationKey.translateValue(documentComplianceBadgeText, t) ||
      getTranslatedValue(documentComplianceBadgeText, t)
    : documentComplianceBadgeText;

  const badgeType: KatBadge.Type =
    INBOUND_STATUS_TO_BADGE_TYPE.get(documentComplianceBadgeText) || INFO_TYPE;
  return (
    <KatBadge
      label={translatedDocumentComplianceBadgeText}
      type={badgeType}
      className={styles.capitalizedText}
    />
  );
};

export type DocumentStatusBadgeProps =
  DataTableCellChildComponentProps<InboundDocument>;

export default DocumentStatusBadge;
