import React, { FC } from "react";
import { KatTableBody } from "@amzn/katal-react";
import classnames from "classnames";
import {
  ColumnDefinition,
  DataTableSelectionAction,
  DataTableSelectionProps,
} from "src/components/DataTable/DataTableDefinition";
import DataTableRow from "./DataTableRow";

const DataTableBody: FC<DataTableBodyProps<any>> = ({
  id,
  contents,
  columnsDefinition,
  rowClickHandler,
  rowURLBuilder,
  selectDispatcher,
  shouldBeSelectable,
  CustomDataTableRow,
  customDataTableRowProps,
  children,
}) => {
  const className = classnames({ empty: !contents?.length });

  const DataTableRowConstructor = CustomDataTableRow ?? DataTableRow;

  return (
    <KatTableBody id={`${id}-data-table-body`} className={className}>
      {contents?.map((currentRecord, index) => {
        return (
          <DataTableRowConstructor
            id={`${id}-${index}`}
            // as this table is static (the order of items and the items themselves doesn't change without a full re-render)
            // using the list index as key shouldn't be an issue
            // https://reactjs.org/docs/lists-and-keys.html#keys
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${id}-data-table-row-${index}`}
            content={currentRecord}
            contents={contents}
            columnsDefinition={columnsDefinition}
            rowClickHandler={rowClickHandler}
            rowURLBuilder={rowURLBuilder}
            selectDispatcher={selectDispatcher}
            shouldBeSelectable={shouldBeSelectable}
            customDataTableRowProps={customDataTableRowProps}
          >
            {children}
          </DataTableRowConstructor>
        );
      })}
    </KatTableBody>
  );
};

export interface DataTableBodyProps<ContentType> {
  id: string;
  columnsDefinition: Array<ColumnDefinition>;
  contents?: Array<ContentType>;
  rowClickHandler?: (content: ContentType) => void;
  rowURLBuilder?: (content: ContentType) => string;
  selectDispatcher?: (event: DataTableSelectionAction<ContentType>) => void;
  shouldBeSelectable?: (
    content: ContentType & DataTableSelectionProps,
    contents: Array<ContentType & DataTableSelectionProps>
  ) => boolean;
  CustomDataTableRow?: FC<ContentType>;
  customDataTableRowProps?: any;
}

export default DataTableBody;
