import { FC } from "react";
import { InboundDocument } from "src/api/interfaces/inbound_document";

export type ValidationUserActionHandler = (
  inboundDocument: InboundDocument,
  metadata: any
) => Promise<FC>;

export enum ReferencedDocumentType {
  PO = "PO",
}

export enum OverriddenMetadataEntry {
  HeaderPurchaseOrder = "HeaderPurchaseOrder",
  LineItemPurchaseOrder = "LineItemPurchaseOrder",
}

export enum ViewUserActionMetadataEntry {
  Default = "Default",
  LineItemMismatch = "LineItemMismatch",
}

export interface ReferencedDocumentOverride {
  documentType?: string;
  documentId?: string;
}

export interface DocumentDetailsOverride {
  referencedDocuments?: Array<ReferencedDocumentOverride>;
}
