import React, { FC } from "react";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { HistoryContextProvider } from "src/components/Contexts/History/Provider";
import HistoryTable from "./HistoryTable";

const History: FC = () => {
  return (
    <HistoryContextProvider>
      <div className={classnames(styles.ma_16)}>
        <HistoryTable />
      </div>
    </HistoryContextProvider>
  );
};

export default History;
