import React, { FC, ReactNode, useReducer } from "react";
import { getFilterFromUrl } from "src/utils";
import { getDefaultOptionValues } from "./definition";
import filterOptionsReducer from "./reducer";
import { FilterOptionsState } from "./types";

const DEFAULT_FILTER_OPTIONS: FilterOptionsState = {
  selectedValues: getFilterFromUrl(),
  optionValues: getDefaultOptionValues(),
  filterableData: {},
  shouldTriggerFetchDocuments: false,
};

export const FilterOptionsContext = React.createContext<{
  state: FilterOptionsState;
  dispatch: React.Dispatch<any>;
}>({ state: DEFAULT_FILTER_OPTIONS, dispatch: () => {} });

export const useFilterOptions = () => React.useContext(FilterOptionsContext);

export const FilterOptionsContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    filterOptionsReducer,
    DEFAULT_FILTER_OPTIONS
  );

  return (
    <FilterOptionsContext.Provider value={{ state, dispatch }}>
      {children}
    </FilterOptionsContext.Provider>
  );
};
