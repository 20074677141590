import React, { FC } from "react";
import { KatTab, KatTabs } from "@amzn/katal-react";
import { useHistory } from "react-router-dom";
import { useInboundDocument } from "src/components/Contexts/InboundDocumentContext";
import classnames from "classnames";
import { InboundDocumentTabs } from "src/api/interfaces/inbound_document";
import RelatedDocumentsListing from "src/components/Pages/DocumentInbound/Tabs/Documents/RelatedDocumentsListing";
import DocumentCompliance from "src/components/Pages/DocumentInbound/Tabs/Validations/DocumentCompliance";
import InboundCompliance from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundCompliance";
import { isLocal, isStandalone, isVendorCentral } from "src/api/config";
import { withTranslation, WithTranslation } from "react-i18next";
import OperationalActions from "src/components/Pages/DocumentInbound/Tabs/OperationalActions/OperationalActions";
import DocumentItemsListing from "src/components/Pages/DocumentInbound/Tabs/DocumentItems/DocumentItemsListing";
import History from "src/components/Pages/DocumentInbound/Tabs/History";
import { usePermissions } from "src/components/Contexts/PermissionsContext";
import { Operation } from "src/api/interfaces/permissions";
import checkPermission from "src/components/AICLayout/AICHeader/Permissions/PermissionCheckers";
import useDocumentComplianceFetcher from "src/components/Pages/DocumentInbound/Hooks/useDocumentCompliance";
import { useDocumentCompliance } from "src/components/Contexts/DocumentComplianceContext";
import useValidationResultFetcher from "src/components/Pages/DocumentInbound/Hooks/useValidationResults";
import publishMetric, { MetricAction } from "src/metrics";
import { RelatedDocumentsContextProvider } from "../../Contexts/RelatedDocumentsContext";
import styles from "../../../styles.module.scss";
import inbound_document_styles from "./inbound_document_styles.module.scss";
import MigrationToAICPortalWarningModal from "./MigrationToAICPortalWarningModal";

const VALIDATIONS_TAB = "aic_doc_page_navbar_tab_validations";
const INBOUND_COMPLIANCE_TAB = "aic_doc_page_navbar_tab_inbound_compliance";
const DOCUMENTS_TAB = "aic_doc_page_navbar_tab_documents";
const PRODUCT_ITEMS_TAB = "aic_doc_page_navbar_tab_product_items";
const HISTORY_TAB = "aic_doc_page_navbar_tab_history";
const OPERATIONAL_ACTIONS_TAB = "aic_doc_page_navbar_tab_operational_actions";

const InboundDocumentNavbar: FC<
  InboundDocumentNavbarProps & WithTranslation
> = ({ inboundDocumentTab, t }) => {
  const {
    documentId,
    documentType,
    countryCode,
    executionNumber,
    inboundExecutionNumber,
    useCase,
  } = useInboundDocument();
  useDocumentComplianceFetcher({
    countryCode,
    documentType,
    documentId,
    executionNumber,
  });
  const {
    validationGroups,
    responseReceived: documentComplianceResponseReceived,
  } = useDocumentCompliance();
  useValidationResultFetcher({
    countryCode,
    documentType,
    documentId,
    executionNumber,
    validationGroups,
  });

  const history = useHistory();
  const { permissions } = usePermissions();
  const shouldShowOperationalTab = checkPermission(
    permissions,
    countryCode,
    useCase,
    Operation.PerformInternalActions
  );

  function getTabRoute(tabName: string): string {
    return `/inbound-central/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/${tabName}`;
  }

  function redirectTabRoute(tabName: string): void {
    const currentLocation = history.location.pathname;
    if (!currentLocation.includes(tabName)) {
      history.push({
        pathname: getTabRoute(tabName),
        search: `?${new URLSearchParams(window.location.search).toString()}`,
      });
    }
  }

  function getValidationsIcon(): any {
    // TODO: implement using icons from StatusIcons.tsx
    // Task reference: https://sim.amazon.com/issues/AICPLAT-658
    return undefined;
  }

  const isVendorCentralForCountry = (countries: string[]) => {
    return isVendorCentral() && countries.includes(countryCode);
  };

  const isInboundComplianceTabEnabled = () =>
    isStandalone() || isLocal() || isVendorCentralForCountry(["BR"]);

  return (
    <div className={classnames(styles.mt_16)}>
      <MigrationToAICPortalWarningModal
        countryCode={countryCode}
        documentType={documentType}
        useCase={useCase}
        documentId={documentId}
        validationGroups={validationGroups}
        apiResponseReceived={documentComplianceResponseReceived}
      />
      <KatTabs
        className={classnames(
          inbound_document_styles.inbound_document_navbar_style
        )}
        selected={inboundDocumentTab}
      >
        <KatTab
          className={classnames(styles.details_tab)}
          onClick={() => redirectTabRoute(InboundDocumentTabs.Validations)}
          status={getValidationsIcon()}
          tabId={InboundDocumentTabs.Validations}
          label={t(VALIDATIONS_TAB)}
        >
          <DocumentCompliance />
        </KatTab>
        <KatTab
          className={classnames(styles.details_tab)}
          onClick={() => {
            publishMetric(MetricAction.DOCUMENT_ITEMS_TAB_CLICK);
            redirectTabRoute(InboundDocumentTabs.ProductItems);
          }}
          tabId={InboundDocumentTabs.ProductItems}
          label={t(PRODUCT_ITEMS_TAB)}
        >
          <DocumentItemsListing />
        </KatTab>
        {isInboundComplianceTabEnabled() && (
          <KatTab
            className={classnames(styles.details_tab)}
            onClick={() =>
              redirectTabRoute(InboundDocumentTabs.InboundCompliance)
            }
            tabId={InboundDocumentTabs.InboundCompliance}
            label={t(INBOUND_COMPLIANCE_TAB)}
          >
            <InboundCompliance
              countryCode={countryCode}
              documentType={documentType}
              documentId={documentId}
              executionNumber={inboundExecutionNumber}
            />
          </KatTab>
        )}
        <KatTab
          className={classnames(styles.details_tab)}
          onClick={() => redirectTabRoute(InboundDocumentTabs.Documents)}
          tabId={InboundDocumentTabs.Documents}
          label={t(DOCUMENTS_TAB)}
        >
          <RelatedDocumentsContextProvider>
            <RelatedDocumentsListing />
          </RelatedDocumentsContextProvider>
        </KatTab>
        <KatTab
          className={classnames(styles.details_tab)}
          onClick={() => {
            publishMetric(MetricAction.DOCUMENT_HISTORY_TAB_CLICK);
            redirectTabRoute(InboundDocumentTabs.History);
          }}
          tabId={InboundDocumentTabs.History}
          label={t(HISTORY_TAB)}
        >
          <History />
        </KatTab>
        {shouldShowOperationalTab && (
          <KatTab
            className={classnames(styles.details_tab)}
            onClick={() =>
              redirectTabRoute(InboundDocumentTabs.OperationalActions)
            }
            tabId={InboundDocumentTabs.OperationalActions}
            label={t(OPERATIONAL_ACTIONS_TAB)}
          >
            <OperationalActions
              countryCode={countryCode}
              documentType={documentType}
              documentId={documentId}
            />
          </KatTab>
        )}
      </KatTabs>
    </div>
  );
};

interface InboundDocumentNavbarProps {
  inboundDocumentTab: string;
}

export default withTranslation()(InboundDocumentNavbar);
