import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { KatIcon, KatInput, KatInputGroup } from "@amzn/katal-react";
import styles from "src/styles.module.scss";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "../TranslatedField/TranslationKey";

export type SearchBarProps = {
  searchablePattern: string;
  title: string;
  placeholder?: string;
  showClearButton: boolean;
  onChange: (searchPattern: string) => void;
  onClear: () => void;
  classes?: Array<string>;
};

const SearchBar: FC<SearchBarProps> = ({
  searchablePattern,
  title,
  placeholder,
  showClearButton,
  onChange,
  onClear,
  classes,
}: SearchBarProps) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const onChangeInput = (e: KatInput.ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    onChange(value);
  };

  return (
    <div
      className={classnames(
        styles.flex,
        styles.justify_end,
        styles.w_50,
        styles.align_items_stretch,
        classes
      )}
    >
      <div
        className={classnames(
          styles.flex,
          styles.flex_direction_row,
          styles.flex_nowrap,
          styles.align_items_start,
          styles.justify_end,
          styles.w_100
        )}
      >
        <div className={classnames(styles.mb_zero_important, styles.ma_6)}>
          <h5>{title}</h5>
        </div>
        <KatInputGroup className={styles.w_90}>
          <KatInput
            id="document-search-input"
            placeholder={placeholder}
            value={searchablePattern}
            onChange={onChangeInput}
          />
          {showClearButton ? (
            <>
              <button
                id="clear-document-search-button"
                onClick={onClear}
                aria-label={t("aic_search_clear_button")}
                type="button"
              >
                <KatIcon
                  id="clear-document-search-icon"
                  name="x-circle-fill"
                  size="tiny"
                />
              </button>
            </>
          ) : (
            <></>
          )}
          <button
            id="document-search-button"
            aria-label={t("aic_search_submit_button")}
            type="button"
          >
            <KatIcon id="document-search-icon" name="search" size="small" />
          </button>
        </KatInputGroup>
      </div>
    </div>
  );
};

export default SearchBar;
