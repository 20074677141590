export enum DocumentField {
  DOCUMENT_TYPE = "documentType",
  DOCUMENT_SUBTYPE = "documentSubtype",
  DOCUMENT_ID = "documentId",
  USE_CASE = "useCase",
  ISSUANCE_DATE = "documentDetails.issuanceDate",
  REFERENCED_DOCUMENT = "documentDetails.referencedDocument",
  OFFICIAL_DOCUMENT_ID = "documentDetails.officialDocumentId",
  EXTERNAL_PARTNER = "externalPartner",
  EXTERNAL_PARTNERS = "externalPartners",
  TOTAL_COST = "documentDetails.documentTotalCost",
  STATUS = "status",
  COUNTRY_CODE = "countryCode",
}

export enum InboundDocumentField {
  DOCUMENT_TYPE = "documentType",
  DOCUMENT_SUBTYPE = "documentSubtype",
  DOCUMENT_ID = "documentId",
  STATUS = "status",
}

export enum DocumentRelationshipField {
  RELATIONSHIP_TYPE = "relationshipType",
}

export enum InboundComplianceGroupField {
  INBOUND_COMPLIANCE_GROUP_STATUS = "status",
  INBOUND_COMPLIANCE_GROUP_NAME = "name",
  DESCRIPTION = "description",
}

export enum InboundComplianceGroupDetailsField {
  ITEM_ID = "itemId",
}

export enum ValidationGroupField {
  VALIDATION_GROUP_STATUS = "validationGroupStatus",
  VALIDATION_GROUP_NAME = "validationGroupName",
  DESCRIPTION = "description",
  DETAILS = "details",
}

export enum HistoryField {
  USER = "user",
  DATE = "date",
  TITLE = "title",
  DETAILS = "details",
}

export enum ProductItemField {
  INDEX = "index",
  PRODUCT_ID = "externalId",
  AMAZON_IDENTIFIERS = "amazonIdentifiers",
  DESCRIPTION = "description",
  REFERENCE = "referencedDocuments",
  QUANTITY = "amountDetails.quantity",
  TOTAL = "amountDetails.cost",
}

export enum ProductItemErrorField {
  RULE = "rule",
  VALUE = "value",
  EXPECTED_VALUE = "expectedValue",
  RULE_SEVERITY = "ruleSeverity",
  DESCRIPTION = "details",
  SOP_DETAILS = "sopDetails",
}

export enum RetrievalState {
  LOADING,
  ERROR,
  SUCCESS,
}

export enum Stage {
  DEVO = "devo",
  BETA = "beta",
  PROD = "prod",
}

export enum KatalReplacement {
  REGION = "{{Region}}",
  STAGE = "{{Stage}}",
  CLOUD_FRONT_PROTOCOL = "{{CloudFrontProtocol}}",
  CLOUD_FRONT_DOMAIN = "{{CloudFrontDomain}}",
  KATAL_LOGGER_URL = "{{KatalLoggerUrl}}",
  API_GATEWAY_DOMAIN = "{{ApiGatewayDomain}}",
  API_GATEWAY_BY_REALM = "{{ApiGatewayByRealm}}",
  IS_PORTAL_HAVING_INTERNAL_ISSUES = "{{IsPortalHavingInternalIssues}}",
  PROCUREMENT_SERVICE_DOMAIN_BY_REALM = "{{ProcurementServiceDomain}}",
}
