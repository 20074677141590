import React, { FC, useCallback } from "react";
import { PageInformation } from "src/api/interfaces/pagination";
import Pagination from "./index";

type PaginationInMemoryProps = {
  items: Array<any>;
  page: number;
  pageSize: number;
  perPage?: Array<number>;
  ExtraPaginationInfo?: FC<any>;
  onChange: (items: Array<any>, pageInformation: PageInformation) => void;
  updateUrl?: boolean;
};

const PaginationInMemory: FC<PaginationInMemoryProps> = ({
  items,
  page,
  pageSize,
  perPage,
  ExtraPaginationInfo,
  onChange,
  updateUrl,
}) => {
  const onChangePage = useCallback(
    (pageInformation: PageInformation) => {
      const { page: currentPage, pageSize: currentPageSize } = pageInformation;

      const startIndex = (currentPage - 1) * currentPageSize;
      const endSecond = currentPage * currentPageSize;
      const newPage = items.slice(startIndex, endSecond);

      onChange(newPage, pageInformation);
    },
    [items, onChange]
  );

  return (
    <Pagination
      perPage={perPage}
      page={page}
      pageSize={pageSize}
      totalItems={items.length}
      onChange={onChangePage}
      ExtraPaginationInfo={ExtraPaginationInfo}
      updateUrl={updateUrl}
    />
  );
};

export default PaginationInMemory;
