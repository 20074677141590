import { Document } from "src/api/interfaces/document";
import {
  ActionFunction,
  DocumentListState,
  DocumentRow,
} from "src/components/Contexts/DocumentList/types";
import { RetrievalState } from "src/constants";

export const setDocuments: ActionFunction = (
  state: DocumentListState,
  payload: {
    documents: Array<Document>;
    retrieveState: RetrievalState;
  }
) => {
  const newDocuments: Record<string, DocumentRow> = {};
  payload.documents.forEach((document: Document, index: number) => {
    newDocuments[document.documentId] = { ...document, index, selected: false };
  });

  return {
    retrieveState: payload.retrieveState,
    documents: newDocuments,
  };
};

export const selectAllDocuments: ActionFunction = (
  state: DocumentListState,
  _: null
) => {
  Object.keys(state.documents).forEach((key, _index) => {
    state.documents[key].selected = true;
  });

  return { ...state };
};

export const unselectAllDocument: ActionFunction = (
  state: DocumentListState,
  _: null
) => {
  Object.keys(state.documents).forEach((key, _index) => {
    state.documents[key].selected = false;
  });
  return { ...state };
};

export const selectDocument: ActionFunction = (
  state: DocumentListState,
  payload: DocumentRow
) => {
  state.documents[payload.documentId].selected = true;
  return { ...state };
};

export const unselectDocument: ActionFunction = (
  state: DocumentListState,
  payload: DocumentRow
) => {
  state.documents[payload.documentId].selected = false;
  return { ...state };
};
