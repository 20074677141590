import React, { FC } from "react";
import { KatStatusindicator } from "@amzn/katal-react";
import styles from "src/styles.module.scss";
import { ValidationRuleSeverity } from "src/api/interfaces/document_validation";
import { useTranslation } from "react-i18next";
import { getTranslatedValue } from "src/utils";
import classnames from "classnames";
import Field from "src/components/Field/Field";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";

const INFO_TYPE = "information";
const ALERT_TYPE = "alert";
const ERROR_TYPE = "error";

const SEVERITY_TYPE_INFORMATION_TEXT =
  "aic_rule_severity_RuleSeverityValue_information_text_value";
const SEVERITY_TYPE_INFORMATION_TEXT_SEVERITY_KEY = "RuleSeverityValue";

const VALIDATION_RULE_SEVERITY_TO_STATUS_INDICATOR_TYPE: Map<
  ValidationRuleSeverity,
  KatStatusindicator.Variant
> = new Map([
  [ValidationRuleSeverity.CRITICAL, ERROR_TYPE],
  [ValidationRuleSeverity.ERROR, ERROR_TYPE],
  [ValidationRuleSeverity.INFO, INFO_TYPE],
  [ValidationRuleSeverity.SEVERE, ERROR_TYPE],
  [ValidationRuleSeverity.WARNING, ALERT_TYPE],
]);
const ValidationSeverityDescriptionItem: FC<
  ValidationSeverityDescriptionItemProps
> = ({ severityType }) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const translatedSeverityTypeName = getTranslatedValue(
    severityType.valueOf(),
    t
  );
  const informationText = getTranslatedValue(
    SEVERITY_TYPE_INFORMATION_TEXT.replace(
      SEVERITY_TYPE_INFORMATION_TEXT_SEVERITY_KEY,
      severityType.valueOf().toLowerCase()
    ),
    t
  );
  return (
    <div
      className={classnames(
        styles.display_flex,
        styles.flex_direction_row,
        styles.mt_16
      )}
    >
      <div className={classnames(styles.flex_initial, styles.min_w_15)}>
        <KatStatusindicator
          label={translatedSeverityTypeName}
          variant={VALIDATION_RULE_SEVERITY_TO_STATUS_INDICATOR_TYPE.get(
            severityType
          )}
          className={styles.capitalizedText}
        />
      </div>
      <div className={classnames(styles.flex_initial, styles.min_w_85)}>
        <Field value={informationText} />
      </div>
    </div>
  );
};

export type ValidationSeverityDescriptionItemProps = {
  severityType: ValidationRuleSeverity;
};

export default ValidationSeverityDescriptionItem;
