import React, { FC } from "react";
import classnames from "classnames";

import PortalLogo from "src/components/AICLayout/AICHeader/PortalLogo";
import Avatar from "src/components/AICLayout/AICHeader/Avatar";
import CountrySelector from "src/components/AICLayout/AICHeader/CountrySelector/CountrySelector";
import LanguagePicker from "src/components/AICLayout/AICHeader/LanguagePicker";
import { withTranslation, WithTranslation } from "react-i18next";
import { getUsernameFromCookie, isMidwayCookieSet } from "src/cookies";

import styles from "../../../styles.module.scss";

const AICHeader: FC<AICHeaderProps & WithTranslation> = ({
  disableCountryChange,
  fixedCountry,
  disableUrlSearchParamsUpdate,
  t,
}) => {
  const userName = getUsernameFromCookie();
  return (
    <div
      className={classnames(
        styles.w_100,
        styles.top_zero,
        styles.left_zero,
        styles.z_index_max,
        styles.box_shadow,
        styles.padding_8,
        styles.background_white,
        styles.h_55_px
      )}
    >
      <div
        className={classnames(
          styles.display_flex,
          styles.flex_direction_row,
          styles.justify_space_between
        )}
      >
        <div className={classnames(styles.flex)}>
          <PortalLogo />
        </div>
        <div
          className={classnames(
            styles.display_flex,
            styles.flex_direction_row,
            styles.justify_end
          )}
        >
          <div
            className={classnames(
              styles.display_flex,
              styles.flex_direction_row,
              styles.justify_space_between
            )}
          >
            <div
              className={classnames(styles.flex, styles.pr_16, styles.pl_16)}
            >
              <CountrySelector
                disableCountryChange={disableCountryChange}
                fixedCountry={fixedCountry}
                disableUrlSearchParamsUpdate={disableUrlSearchParamsUpdate}
              />
            </div>
            {isMidwayCookieSet() ? (
              <>
                <div
                  className={classnames(
                    styles.flex,
                    styles.b_l_solid,
                    styles.pr_16,
                    styles.pl_16
                  )}
                >
                  <Avatar userAlias={userName} />
                </div>
                <div
                  className={classnames(
                    styles.flex,
                    styles.pl_16,
                    styles.b_l_solid
                  )}
                >
                  <div className={classnames(styles.justify_end, styles.flex)}>
                    <div
                      className={classnames(
                        styles.flex,
                        styles.align_items_center,
                        styles.justify_center
                      )}
                    >
                      <h5
                        className={classnames(
                          styles.mb_zero_important,
                          styles.background_white
                        )}
                      >
                        {t("aic_select_preferred_language")} &nbsp;
                      </h5>
                    </div>
                    <LanguagePicker />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export interface AICHeaderProps {
  disableCountryChange?: boolean;
  fixedCountry?: string;
  disableUrlSearchParamsUpdate?: boolean;
}

export default withTranslation()(AICHeader);
