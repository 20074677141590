import React, { FC, ReactNode, useReducer } from "react";
import { UserActionsMetadataState } from "./types";
import userActionsMetadataReducer from "./reducer";

export const DEFAULT_USER_ACTIONS_METADATA: UserActionsMetadataState = {
  metadata: {},
};

export const UserActionsMetadataContext = React.createContext<{
  state: UserActionsMetadataState;
  dispatch: React.Dispatch<any>;
}>({ state: DEFAULT_USER_ACTIONS_METADATA, dispatch: () => {} });

export const UserActionsMetadataContextProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(
    userActionsMetadataReducer,
    DEFAULT_USER_ACTIONS_METADATA
  );

  return (
    <UserActionsMetadataContext.Provider value={{ state, dispatch }}>
      {children}
    </UserActionsMetadataContext.Provider>
  );
};
