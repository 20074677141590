import React, { FC } from "react";
import { KatExpander } from "@amzn/katal-react";
import { useTranslation } from "react-i18next";
import { getTranslatedValue } from "src/utils";
import ValidationSeverityDescription from "./ValidationSeverityDescription";

const ValidationSeverityDescriptionExpander: FC = () => {
  const { t } = useTranslation();
  const translatedSeverityDescriptionLabel = getTranslatedValue(
    "aic_rule_severity_description",
    t
  );
  return (
    <KatExpander expanded={false} label={translatedSeverityDescriptionLabel}>
      <ValidationSeverityDescription />
    </KatExpander>
  );
};

export default ValidationSeverityDescriptionExpander;
