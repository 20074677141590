import { useHistory } from "react-router-dom";
import { useAuthorizationErrors } from "src/components/Contexts/AuthorizationErrorsContext";

export const useUnauthorizedRedirect = () => {
  const { authorizationErrors } = useAuthorizationErrors();
  const history = useHistory();

  if (authorizationErrors.size) {
    history.push("/unauthorized");
  }
};

export default useUnauthorizedRedirect;
