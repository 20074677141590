import { InboundDocument } from "src/api/interfaces/inbound_document";
import { ValidationGroupField } from "src/constants";
import ValidationGroupStatusIndicator from "src/components/Pages/DocumentInbound/Tabs/Validations/DocumentCompliance/DocumentComplianceListing/ValidationGroupStatusIndicator";
import {
  ColumnDefinition,
  DataTableDefinition,
} from "src/components/DataTable/DataTableDefinition";
import ValidationUserActions from "src/components/UserActions/ValidationUserActions";

const documentComplianceColumnsDefinition: ColumnDefinition[] = [
  {
    label: "aic_doc_page_validations_tab_header_validation_rule",
    property: ValidationGroupField.VALIDATION_GROUP_NAME.valueOf(),
    translateValue: true,
  },
  {
    label: "aic_doc_page_validations_tab_header_description",
    property: ValidationGroupField.DESCRIPTION.valueOf(),
    translateValue: true,
  },
  {
    label: "aic_doc_page_validations_tab_header_status",
    property: ValidationGroupField.VALIDATION_GROUP_STATUS.valueOf(),
    translateValue: true,
    ChildComponent: ValidationGroupStatusIndicator,
    childComponentOnly: true,
  },
  {
    label: "aic_doc_page_validations_tab_header_actions",
    property: ValidationGroupField.VALIDATION_GROUP_NAME.valueOf(),
    translateValue: true,
    childComponentOnly: true,
    ChildComponent: ValidationUserActions,
  },
];

const documentComplianceDataTableDefinition: DataTableDefinition<InboundDocument> =
  {
    columnsDefinition: documentComplianceColumnsDefinition,
  };

export default documentComplianceDataTableDefinition;
