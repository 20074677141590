import i18n from "src/i18n";
import React, { FC, useCallback, useEffect, useState } from "react";
import DataTable from "src/components/DataTable";
import useHistory from "src/components/Pages/DocumentInbound/Hooks/useHistory";
import { useTranslation } from "react-i18next";
import TranslationKey, {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import PaginationInMemory from "src/components/Pagination/PaginationInMemory";
import usePageInformation from "src/components/Pagination/hooks/usePageInformation";
import { PageInformation } from "src/api/interfaces/pagination";
import { HistoryEntry } from "src/api/interfaces/history";
import SearchInMemoryBar from "src/components/SearchBar/SearchBarInMemory";
import publishMetric, { MetricAction } from "src/metrics";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { isEmpty } from "lodash";
import { useInboundDocument } from "src/components/Contexts/InboundDocumentContext";
import historyDataTableDefinition from "./history-data-table-definition";

const HistoryTable: FC = () => {
  const { countryCode } = useInboundDocument();
  const { history, retrieveState } = useHistory();
  const [tableContents, setTableContents] = useState<Array<any>>();
  const { pageInformation, setPageInformation } = usePageInformation({
    updateUrl: true,
  });
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const [translatedHistory, setTranslatedHistory] = useState(history);
  const [filteredHistory, setFilteredHistory] = useState(history);

  useEffect(() => {
    const translationKey = new TranslationKey(countryCode);
    const translated = history.map((entry) => {
      const countryOverrideTitle = translationKey.buildCountryCodeKey(
        entry.title
      );
      const countryOverrideDetails = translationKey.buildCountryCodeKey(
        entry.details
      );
      return {
        ...entry,
        title: t([countryOverrideTitle, entry.title]),
        user: t(entry.user),
        details: t([countryOverrideDetails, entry.details], {
          ...entry.detailsParameters,
          targetDate: new Date(
            entry.detailsParameters?.targetDate
          ).toLocaleString(i18n.language),
        }),
      };
    });

    if (!isEmpty(translated)) {
      setTranslatedHistory(translated);
      setFilteredHistory(translated);
    }
  }, [history, t, countryCode]);

  const onChangePage = useCallback(
    (entries: Array<HistoryEntry>, newPageInformation: PageInformation) => {
      setTableContents(entries);
      setPageInformation(newPageInformation);
      publishMetric(MetricAction.DOCUMENT_HISTORY_TAB_PAGINATION);
    },
    [setPageInformation]
  );

  const onMatchSearch = useCallback(
    (matchedList: Array<any>) => {
      if (!isEmpty(matchedList) || !isEmpty(history)) {
        setFilteredHistory(matchedList);
        setPageInformation({ page: 1, pageSize: pageInformation.pageSize });
      }
    },
    [history, pageInformation.pageSize, setPageInformation]
  );

  const onClearSearch = useCallback(() => {
    setFilteredHistory(translatedHistory);
    setPageInformation({ page: 1, pageSize: pageInformation.pageSize });
  }, [translatedHistory, pageInformation.pageSize, setPageInformation]);

  const buildSearchableHistory = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return translatedHistory.map(({ detailsParameters, ...rest }) => rest);
  };

  return (
    <>
      <div
        className={classnames(
          styles.flex,
          styles.flex_direction_row,
          styles.flex_nowrap,
          styles.justify_end
        )}
      >
        <SearchInMemoryBar
          title={t("aic_search_document_title")}
          searchableList={buildSearchableHistory()}
          metricsPublisher={() =>
            publishMetric(MetricAction.DOCUMENT_HISTORY_TAB_SEARCHES)
          }
          onMatch={onMatchSearch}
          onClear={onClearSearch}
        />
      </div>
      <DataTable
        id="history"
        contents={tableContents}
        tableDefinition={historyDataTableDefinition}
        retrievalState={retrieveState}
      />
      <PaginationInMemory
        page={pageInformation.page}
        pageSize={pageInformation.pageSize}
        items={filteredHistory}
        onChange={onChangePage}
        updateUrl
      />
    </>
  );
};

export default HistoryTable;
