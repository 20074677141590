import { log, LogLevel } from "src/logger";
import { getRealmCookie } from "src/cookies";
import { KatalReplacement } from "../constants";
import env, { ProcessEnvCustomVariables } from "../env";

const AIC_STANDALONE_URL = "aic.intech.amazon.dev";
const CLOUD_FRONT_RAW_URL = ".cloudfront.net";
const INTERNAL_URL_SUFFIX = "/internal";
const EXTERNAL_URL_SUFFIX = "/external";
const VENDOR_CENTRAL_HREF_BASE = "vendorcentral";
const GET_API_BASE_URL_OPERATIONAL_NAMESPACE = "Configuration.getApiBaseUrl";

export function isStandalone(): boolean {
  return (
    window.location.href.includes(AIC_STANDALONE_URL) ||
    window.location.href.includes(CLOUD_FRONT_RAW_URL)
  );
}

export function isDirectAccess(): boolean {
  return window.location.href.includes(CLOUD_FRONT_RAW_URL);
}

export function isLocal(): boolean {
  return (
    process.env.SERVER_LOCAL === "true" && SERVER_MODE.mode === "standalone"
  );
}

export function isVendorCentral(): boolean {
  return window.location.href.includes(VENDOR_CENTRAL_HREF_BASE);
}

export function getProcurementServiceDomain(): string {
  if (isLocal()) {
    return env.getVariable(
      ProcessEnvCustomVariables.PROCUREMENT_SERVICE_DOMAIN
    )!;
  }
  return KatalReplacement.PROCUREMENT_SERVICE_DOMAIN_BY_REALM;
}

export function getApiBaseUrl(): string {
  const apiGatewayUrls = {
    local: `${env.getVariable(
      ProcessEnvCustomVariables.PERSONAL_URL
    )}${EXTERNAL_URL_SUFFIX}`,
    mons: EXTERNAL_URL_SUFFIX,
    server: KatalReplacement.API_GATEWAY_DOMAIN,
    serverByRealm: KatalReplacement.API_GATEWAY_BY_REALM,
  };

  log({
    level: LogLevel.INFO,
    message: "User ViewPort information",
    operationNamespace: "UI-UX.Research",
    attributes: {
      width: Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ),
      height: Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      ),
    },
  });

  let apiBaseUrl;

  if (isVendorCentral()) {
    log({
      level: LogLevel.INFO,
      message: "Vendor Central detected, selecting mons URL",
      operationNamespace: GET_API_BASE_URL_OPERATIONAL_NAMESPACE,
      attributes: { apiGatewayUrls },
    });

    apiBaseUrl = apiGatewayUrls.mons;
  } else if (isStandalone()) {
    try {
      const apiGatewayByRealm = JSON.parse(apiGatewayUrls.serverByRealm);
      const realm = getRealmCookie();

      log({
        level: LogLevel.INFO,
        message: "Realm retrieved from cookies, selecting API",
        operationNamespace: GET_API_BASE_URL_OPERATIONAL_NAMESPACE,
        attributes: { apiGatewayByRealm, realm },
      });

      apiBaseUrl = realm ? apiGatewayByRealm[realm] : apiGatewayUrls.server;
    } catch (error) {
      log({
        level: LogLevel.WARNING,
        message: "There was an error while parsing API Gateway by Realm Map",
        operationNamespace: GET_API_BASE_URL_OPERATIONAL_NAMESPACE,
        exception: (error as Error)?.message,
        attributes: { apiGatewayByRealm: apiGatewayUrls.serverByRealm },
      });
      apiBaseUrl = apiGatewayUrls.server;
    }

    apiBaseUrl = `${apiBaseUrl}${INTERNAL_URL_SUFFIX}`;
  } else {
    apiBaseUrl = apiGatewayUrls.local;
  }

  log({
    level: LogLevel.INFO,
    message: "API URL selected",
    operationNamespace: GET_API_BASE_URL_OPERATIONAL_NAMESPACE,
    attributes: { apiBaseUrl },
  });

  return apiBaseUrl;
}

export default getApiBaseUrl;
