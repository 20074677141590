import { PageInformation } from "src/api/interfaces/pagination";

export interface DocumentListingFilter {
  readonly countryCode?: string;
  readonly documentIds?: string;
  readonly documentTypes?: string[];
  readonly documentSubtypes?: string[];
  readonly issuanceDate?: string;
  readonly partnersName?: string;
  readonly references?: string;
  readonly search?: string;
  readonly statuses?: string[];
  readonly useCases?: string[];
  readonly validationGroupsFailed?: string[];
}

export enum DocumentListingURLParamsKey {
  countryCode = "country",
  documentIds = "documentId",
  documentTypes = "documentType",
  documentSubtypes = "documentSubtype",
  issuanceDate = "issuanceDate",
  page = "page",
  pageSize = "page-size",
  partnersName = "partnerName",
  references = "reference",
  search = "search",
  statuses = "status",
  useCases = "useCase",
  validationGroupsFailed = "validationGroupsFailed",
}

export interface DocumentListingURLParams
  extends DocumentListingFilter,
    Partial<PageInformation> {}
