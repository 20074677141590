import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

export interface AlertContextValue {
  readonly alerts: React.ReactNode[];
  readonly setAlerts: Dispatch<SetStateAction<React.ReactNode[]>>;
}

export const AlertContext = React.createContext<AlertContextValue>({
  alerts: [],
  setAlerts: () => {},
});

export const useAlertContext = () => useContext(AlertContext);

export const AlertContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const emptyList: React.ReactNode[] = [];
  const [alerts, setAlerts] = useState<React.ReactNode[]>(emptyList);

  return (
    <AlertContext.Provider
      value={{
        alerts,
        setAlerts,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
