import "./polyfills";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { KatSpinner } from "@amzn/katal-react";
import katalReady from "@amzn/katal-components-ready";

import { App } from "./components/App";
import { AppInitWrapper } from "./components/AppInitWrapper";
import "./i18n";
import "./logger";

import { whenDOMContentLoaded } from "./whenDOMContentLoaded";

katalReady(() => {
  whenDOMContentLoaded(() => {
    ReactDOM.render(
      <AppInitWrapper>
        <Suspense fallback={<KatSpinner size="large" />}>
          <App />
        </Suspense>
      </AppInitWrapper>,
      document.getElementById("root")
    );
  });
});
