import React, { FC, useCallback, useState } from "react";
import classnames from "classnames";
import styles from "src/components/UserActions/Handlers/Validation/OverrideMetadata/PurchaseOrder/styles.module.scss";
import { log, LogLevel } from "src/logger";
import { ValidationUserActionHandlerProps } from "src/components/UserActions/Handlers/Validation/validation_user_action_handler";
import { useTranslation } from "react-i18next";
import { useModalWrapper } from "src/components/Modal/ModalWrapperContext";
import LineItemMismatchTableRenderComponent from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/LineItem/LineItemMismatchTableRenderComponent";
import { LineItemMismatchField } from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/LineItem/line-item-mismatch-data-table-definition";
import {
  extractEditsByProperty,
  MismatchTableEditItems,
  MismatchTableEditItemsContext,
} from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/MismatchTableEditItemsContext";
import { KatButton } from "@amzn/katal-react";
import {
  DocumentDetailsOverride,
  OverriddenMetadataEntry,
  ReferencedDocumentType,
} from "src/components/UserActions/Handlers/Validation/types";

const purchaseOrderProperty = LineItemMismatchField.PURCHASE_ORDER.valueOf();
const itemProperty = LineItemMismatchField.ITEM.valueOf();

interface LineItemPurchaseOrderOverrides {
  readonly purchaseOrder: string;
  readonly lineItems: number[];
}

function extractPurchaseOrderOverrides(
  itemOverrides: MismatchTableEditItems
): [Set<string>, LineItemPurchaseOrderOverrides[]] {
  const referencedPurchaseOrders = new Set<string>();
  const overriddenPurchaseOrders = new Map<string, number[]>();

  extractEditsByProperty(
    purchaseOrderProperty,
    itemProperty,
    itemOverrides
  ).forEach((purchaseOrder, lineIndex) => {
    const previous = overriddenPurchaseOrders.get(purchaseOrder) || [];
    referencedPurchaseOrders.add(purchaseOrder);
    overriddenPurchaseOrders.set(purchaseOrder, [...previous, lineIndex]);
  });

  const purchaseOrderOverrides = Array.from(
    overriddenPurchaseOrders.entries()
  ).map(([purchaseOrder, lineItems]) => {
    return { purchaseOrder, lineItems } as LineItemPurchaseOrderOverrides;
  });

  return [referencedPurchaseOrders, purchaseOrderOverrides];
}

function buildDocumentDetails(
  purchaseOrders: Set<string>
): DocumentDetailsOverride {
  return {
    referencedDocuments: Array.from(purchaseOrders).map((purchaseOrder) => ({
      documentType: ReferencedDocumentType.PO,
      documentId: purchaseOrder,
    })),
  };
}

const POLineItemEdit: FC<ValidationUserActionHandlerProps> = ({
  onCancel,
  onSubmit,
  validationGroup,
}) => {
  const { t } = useTranslation();
  const { setVisible } = useModalWrapper();
  const [itemOverrides, setItemOverrides] = useState(
    new Map<number, Map<string, string>>()
  );

  const getMismatchEditContextValue = useCallback(
    () => ({ itemOverrides, setItemOverrides }),
    [itemOverrides, setItemOverrides]
  );

  const onUpdateClick = () => {
    if (itemOverrides.size > 0 && onSubmit) {
      const [referencedDocuments, purchaseOrderOverrides] =
        extractPurchaseOrderOverrides(itemOverrides);
      onSubmit({
        [OverriddenMetadataEntry.LineItemPurchaseOrder]: JSON.stringify(
          purchaseOrderOverrides
        ),
        documentDetails: JSON.stringify(
          buildDocumentDetails(referencedDocuments)
        ),
      });
    } else {
      log({
        level: LogLevel.WARNING,
        message: "No request has been configured for this user action.",
        operationNamespace: "ValidationUserActions.POLineItemEdit.update",
      });
    }
    setVisible(false);
  };

  return (
    <MismatchTableEditItemsContext.Provider
      value={getMismatchEditContextValue()}
    >
      <div className={classnames(styles.modalWrapper)}>
        <div className={classnames(styles.actionContainer)}>
          <div className={classnames(styles.buttonContainer)}>
            <KatButton
              id="cancel-update-po-line-item-button"
              onClick={onCancel}
              variant="secondary"
            >
              {t("aic_doc_page_po_header_edit_cancel")}
            </KatButton>
          </div>
          <div className={classnames(styles.buttonContainer)}>
            <KatButton id="update-po-line-item-button" onClick={onUpdateClick}>
              {t("aic_validate_user_actions_update_and_restart")}
            </KatButton>
          </div>
        </div>
      </div>
      <LineItemMismatchTableRenderComponent
        validationGroupId={validationGroup.validationGroupId}
        enableEditPurchaseOrder
      />
    </MismatchTableEditItemsContext.Provider>
  );
};

export default POLineItemEdit;
