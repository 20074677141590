import {
  setDocuments,
  selectAllDocuments,
  selectDocument,
  unselectAllDocument,
  unselectDocument,
} from "src/components/Contexts/DocumentList/actionFunctions";

import {
  ActionFunction,
  DocumentListAction,
  DocumentListActions,
  DocumentListState,
} from "src/components/Contexts/DocumentList/types";
import { DataTableSelectionActions } from "src/components/DataTable/DataTableDefinition";

const reducerMap: Record<
  DocumentListActions | DataTableSelectionActions,
  ActionFunction
> = {
  [DocumentListActions.ADD]: setDocuments,
  [DataTableSelectionActions.SELECT]: selectDocument,
  [DataTableSelectionActions.UNSELECT]: unselectDocument,
  [DataTableSelectionActions.SELECT_ALL]: selectAllDocuments,
  [DataTableSelectionActions.UNSELECT_ALL]: unselectAllDocument,
};

export default function documentsReducer(
  state: DocumentListState,
  emittedAction: DocumentListAction
): DocumentListState {
  const { type, payload } = emittedAction;

  return reducerMap[type](state, payload);
}
