import React, { FC } from "react";
import { KatStatustracker } from "@amzn/katal-react";
import { InboundStatus } from "src/api/interfaces/inbound_document";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { ConsolidatedDocument } from "src/api/interfaces/document_listing";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import TranslatedField from "src/components/TranslatedField/TranslatedField";
import TranslationKey from "src/components/TranslatedField/TranslationKey";

const COMPLETE_STATE = "complete";
const ERROR_STATE = "error";
const TODO_STATE = "todo";

const STATUS_TRACKER_FOR_UNALLOCATED = [
  ERROR_STATE,
  TODO_STATE,
  TODO_STATE,
  TODO_STATE,
];
const STATUS_TRACKER_FOR_INGESTED_PROCESSING_AND_SKIPPED = [
  COMPLETE_STATE,
  TODO_STATE,
  TODO_STATE,
  TODO_STATE,
];
const STATUS_TRACKER_FOR_ACCEPTED = [
  COMPLETE_STATE,
  COMPLETE_STATE,
  COMPLETE_STATE,
  TODO_STATE,
];
const STATUS_TRACKER_FOR_PENDING_ACTIONS_AND_TEMPORARILY_REJECTED = [
  COMPLETE_STATE,
  ERROR_STATE,
  TODO_STATE,
  TODO_STATE,
];
const STATUS_TRACKER_FOR_REJECTED = [
  COMPLETE_STATE,
  COMPLETE_STATE,
  ERROR_STATE,
  TODO_STATE,
];
const STATUS_TRACKER_FOR_FINALIZED_AND_CANCELLED = [
  COMPLETE_STATE,
  COMPLETE_STATE,
  COMPLETE_STATE,
  COMPLETE_STATE,
];

const INBOUND_STATUS_TO_STATUS_TRACKER_DEFINITION: Map<
  string,
  Array<string>
> = new Map([
  [
    InboundStatus.CANCELED.valueOf(),
    STATUS_TRACKER_FOR_FINALIZED_AND_CANCELLED,
  ],
  [
    InboundStatus.INGESTED.valueOf(),
    STATUS_TRACKER_FOR_INGESTED_PROCESSING_AND_SKIPPED,
  ],
  [InboundStatus.UNALLOCATED.valueOf(), STATUS_TRACKER_FOR_UNALLOCATED],
  [InboundStatus.ACCEPTED.valueOf(), STATUS_TRACKER_FOR_ACCEPTED],
  [InboundStatus.REJECTED.valueOf(), STATUS_TRACKER_FOR_REJECTED],
  [
    InboundStatus.PENDING_ACTIONS.valueOf(),
    STATUS_TRACKER_FOR_PENDING_ACTIONS_AND_TEMPORARILY_REJECTED,
  ],
  [
    InboundStatus.TEMPORARILY_REJECTED.valueOf(),
    STATUS_TRACKER_FOR_PENDING_ACTIONS_AND_TEMPORARILY_REJECTED,
  ],
  [
    InboundStatus.FINALIZED.valueOf(),
    STATUS_TRACKER_FOR_FINALIZED_AND_CANCELLED,
  ],
  [
    InboundStatus.PROCESSING.valueOf(),
    STATUS_TRACKER_FOR_INGESTED_PROCESSING_AND_SKIPPED,
  ],
  [
    InboundStatus.SKIPPED.valueOf(),
    STATUS_TRACKER_FOR_INGESTED_PROCESSING_AND_SKIPPED,
  ],
]);

const DocumentStatusTracker: FC<
  DataTableCellChildComponentProps<ConsolidatedDocument>
> = ({ content }) => {
  const childComponentOnly = false;
  const statusStyle: string[] = [
    styles.capitalizedText,
    styles.background_black,
  ];

  const translationKey = new TranslationKey(
    content.countryCode.valueOf(),
    content.documentType.valueOf(),
    content.documentSubtype.valueOf(),
    content.useCase.valueOf()
  );

  return (
    <div
      className={classnames(styles.display_flex, styles.flex_direction_column)}
    >
      <KatStatustracker
        steps={
          INBOUND_STATUS_TO_STATUS_TRACKER_DEFINITION.get(content.status) ||
          STATUS_TRACKER_FOR_INGESTED_PROCESSING_AND_SKIPPED
        }
      />
      <TranslatedField
        value={content.status}
        childComponentOnly={childComponentOnly}
        valueStyle={statusStyle}
        translationKey={translationKey}
      />
    </div>
  );
};

export default DocumentStatusTracker;
