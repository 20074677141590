import React, { FC, useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import SearchInMemoryBar from "src/components/SearchBar/SearchBarInMemory";
import styles from "src/styles.module.scss";
import {
  LineItemRepresentation,
  ValidationError,
} from "src/api/interfaces/line_item";
import publishMetric, { MetricAction } from "src/metrics";
import { ProductItemField } from "src/constants";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { KatIcon, KatToggle } from "@amzn/katal-react";
import ValidationSeverityDescriptionModal from "src/components/Pages/DocumentInbound/ValidationSeverityDescription/ValidationSeverityDescriptionModal";
import LineItemsRuleSeverityFilter from "./LineItemsRuleSeverityFilter";

export interface DocumentItemsTableFilterBarProps {
  documentLineItems: Array<any>;
  setFilteredLineItems: (lineItems: any) => void;
  expandErrorsHandler: (expand: boolean) => void;
  resetPage: () => void;
  expandErrors: boolean;
}

const DocumentItemsTableFilterBar: FC<DocumentItemsTableFilterBarProps> = ({
  documentLineItems,
  setFilteredLineItems,
  expandErrorsHandler,
  expandErrors,
  resetPage,
}) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const [selectedSeverities, setSelectedSeverities] = useState<Array<string>>(
    []
  );
  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  useEffect(() => {
    setHasValidationErrors(
      documentLineItems.some(
        (item) => item.validationErrors && item.validationErrors.length
      )
    );
  }, [documentLineItems]);

  const onMatchItemsSearch = useCallback(
    (matchedList: Array<any>) => {
      if (!isEmpty(matchedList) || !isEmpty(documentLineItems)) {
        setFilteredLineItems(matchedList);
        resetPage();
      }
    },
    [documentLineItems, resetPage, setFilteredLineItems]
  );

  const onMatchItemsFilter = useCallback(
    (matchedList: Array<any>): Array<any> => {
      if (selectedSeverities.length) {
        return matchedList.filter(
          (item) =>
            item.validationErrors &&
            item.validationErrors.some((validation: ValidationError) =>
              selectedSeverities.includes(validation.ruleSeverity)
            )
        );
      }
      return matchedList;
    },
    [selectedSeverities]
  );

  const onClearItemsSearch = useCallback(() => {
    setFilteredLineItems(documentLineItems);
    resetPage();
  }, [documentLineItems, resetPage, setFilteredLineItems]);

  const buildSearchableList = () => {
    const buildSearchableItem = (line: LineItemRepresentation) => {
      const newLine = {};
      Object.values(ProductItemField).forEach((field: ProductItemField) =>
        set(newLine, field, get(line, field))
      );
      if (!isEmpty(line.validationErrors)) {
        set(newLine, "validationErrors", line.validationErrors);
      }
      return newLine;
    };
    return documentLineItems.map(buildSearchableItem);
  };

  return (
    <div
      className={classnames(
        styles.flex,
        styles.flex_direction_row,
        styles.flex_nowrap,
        styles.align_items_center,
        styles.justify_space_between
      )}
    >
      <div
        className={classnames(
          styles.flex,
          styles.align_items_center,
          styles.w_50
        )}
      >
        <LineItemsRuleSeverityFilter
          onChange={setSelectedSeverities}
          metricsPublisher={() =>
            publishMetric(
              MetricAction.DOCUMENT_ITEMS_TAB_FILTER_RULE_SEVERITY_LINE_ITEMS_CLICK
            )
          }
          disabled={!hasValidationErrors}
        />
        <ValidationSeverityDescriptionModal
          ClickableComponent={({ onClick }) => (
            <KatIcon
              onClick={onClick}
              className={classnames(styles.info, styles.pointer)}
              slot="action"
              name="info_outline"
              size="small"
            />
          )}
        />
        <KatToggle
          className={classnames(styles.pl_4rem)}
          textChecked={t(
            "aic_doc_page_product_items_tab_collapse_items_errors"
          )}
          textUnchecked={t(
            "aic_doc_page_product_items_tab_expand_items_errors"
          )}
          checked={expandErrors}
          disabled={!hasValidationErrors}
          onChange={() => expandErrorsHandler(!expandErrors)}
        />
      </div>
      <SearchInMemoryBar
        title={t("aic_search_document_title")}
        searchableList={buildSearchableList()}
        metricsPublisher={() =>
          publishMetric(MetricAction.DOCUMENT_ITEMS_TAB_SEARCHES)
        }
        onMatch={onMatchItemsSearch}
        onMatchFilter={onMatchItemsFilter}
        onClear={onClearItemsSearch}
      />
    </div>
  );
};

export default DocumentItemsTableFilterBar;
