import React, { FC } from "react";
import InboundComplianceUserActions from "src/components/UserActions/InboundComplianceUserActions";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { InboundComplianceGroup } from "src/api/interfaces/inbound_compliance_group";
import InboundComplianceGroupDetailsFetcher from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundComplianceModals/Listing/InboundComplianceGroupDetailsFetcher";
import { InboundComplianceGroupDetailsContextProvider } from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundComplianceModals/Listing/InboundComplianceGroupDetailsContext";
import { useInboundDocument } from "src/components/Contexts/InboundDocumentContext";

export type FetchInboundComplianceActionsProps =
  DataTableCellChildComponentProps<InboundComplianceGroup>;

export const FetchInboundComplianceActions: FC<
  FetchInboundComplianceActionsProps
> = ({ content }: FetchInboundComplianceActionsProps) => {
  const inboundDocument = useInboundDocument();

  return (
    <div>
      <InboundComplianceGroupDetailsContextProvider>
        <InboundComplianceGroupDetailsFetcher
          documentId={inboundDocument.documentId}
          documentType={inboundDocument.documentType}
          countryCode={inboundDocument.countryCode}
          executionNumber={inboundDocument.inboundExecutionNumber}
          eventSubtype={content.eventSubtype}
        />
        <InboundComplianceUserActions content={content} />
      </InboundComplianceGroupDetailsContextProvider>
    </div>
  );
};
