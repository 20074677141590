import React, { FC, useEffect } from "react";

import client, { UNAUTHORIZED_HTTP_STATUS_CODE } from "src/api/client";
import { log, LogLevel } from "src/logger";
import { AxiosError } from "axios";
import { usePermissions } from "src/components/Contexts/PermissionsContext";
import {
  AuthorizationErrors,
  CountryPermission,
} from "src/api/interfaces/permissions";
import useUnauthorizedRedirect from "src/components/AICLayout/AICHeader/Permissions/WithRedirectWhenAuthorized";
import { useAuthorizationErrors } from "src/components/Contexts/AuthorizationErrorsContext";

const PermissionsFetcher: FC = () => {
  useUnauthorizedRedirect();

  const { setPermissions } = usePermissions();
  const { setAuthorizationErrors } = useAuthorizationErrors();

  useEffect(() => {
    const convertResultToPermissionsMap = (result: CountryPermission[]) => {
      const permissionsMap = new Map();
      result.forEach((countryPermission) => {
        const resourceMap = new Map();
        countryPermission.resources.forEach((resource) => {
          resourceMap.set(resource.resourceName, resource.operations);
        });
        permissionsMap.set(countryPermission.countryCode, resourceMap);
      });
      log({
        level: LogLevel.INFO,
        message: `Converted response to permissions map: ${permissionsMap}`,
        operationNamespace: "PermissionsFetcher.useEffect",
      });

      return permissionsMap;
    };

    const fetchPermissions = () => {
      client
        .getPermissions()
        .then(({ countriesPermission: result }) => {
          log({
            level: LogLevel.INFO,
            message: "Permissions fetch completed successfully",
            operationNamespace: "PermissionsFetcher.useEffect",
          });
          setPermissions({
            permissions: convertResultToPermissionsMap(result),
          });
        })
        .catch((error: AxiosError) => {
          log({
            level: LogLevel.ERROR,
            message: "There was an error while trying to fetch permissions",
            operationNamespace: "PermissionsContext.fetchPermissions",
            exception: error?.message,
          });
          if (error.response?.status === UNAUTHORIZED_HTTP_STATUS_CODE) {
            setAuthorizationErrors((prevState: AuthorizationErrors) => ({
              authorizationErrors: prevState.authorizationErrors.add(
                error.message
              ),
            }));
          }
        });
    };
    fetchPermissions();
  }, [setAuthorizationErrors, setPermissions]);

  return <div />;
};

export default PermissionsFetcher;
