import React, { Dispatch, FC, SetStateAction } from "react";
import { KatModal } from "@amzn/katal-react";
import classnames from "classnames";
import modal_wrapper_style from "./modal_wrapper_styles.module.scss";
import { ModalWrapperContext } from "./ModalWrapperContext";

export interface ModalWrapperProps {
  readonly noCloseIcon?: boolean;
  readonly footer?: React.ReactNode;
  readonly title: React.ReactNode;
  readonly visible: boolean;
  readonly setVisible: Dispatch<SetStateAction<boolean>>;
  readonly onClose?: (e: KatModal.CloseEvent) => void;
  readonly onOpen?: (e: KatModal.OpenEvent) => void;
  readonly classNames?: string[];
}

export const ModalWrapper: FC<ModalWrapperProps> = (props) => {
  const { setVisible, children, classNames: classNamesProp } = props;
  const classNames = classNamesProp || [];

  return (
    <KatModal
      noCloseIcon
      {...props}
      className={classnames(
        modal_wrapper_style.modal_wrapper_style,
        ...classNames
      )}
    >
      <ModalWrapperContext.Provider
        value={{
          setVisible,
        }}
      >
        {children}
      </ModalWrapperContext.Provider>
    </KatModal>
  );
};
