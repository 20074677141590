import { useContext, useEffect } from "react";
import { AxiosError } from "axios";
import client from "src/api/client";
import { useInboundDocument } from "src/components/Contexts/InboundDocumentContext";
import {
  GetHistoryRequest,
  GetHistoryResponse,
} from "src/api/interfaces/history";
import { RetrievalState } from "src/constants";
import {
  HistoryActions,
  HistoryState,
} from "src/components/Contexts/History/types";
import { HistoryContext } from "src/components/Contexts/History/Provider";
import { log, LogLevel } from "src/logger";
import { eq, every } from "lodash";
import publishMetric, { MetricAction } from "src/metrics";

const useHistory = (): HistoryState => {
  const { state, dispatch } = useContext(HistoryContext);
  const { countryCode, documentId, documentType } = useInboundDocument();

  useEffect(() => {
    const request: GetHistoryRequest = {
      countryCode,
      documentId,
      documentType,
    };

    const shouldRequestHistory = every([
      countryCode,
      documentId,
      documentType,
      eq(state.history.length, 0),
      !eq(state.retrieveState, RetrievalState.ERROR),
      !eq(state.retrieveState, RetrievalState.LOADING),
    ]);

    if (!shouldRequestHistory) return;

    dispatch({
      type: HistoryActions.SET,
      payload: { history: [], retrieveState: RetrievalState.LOADING },
    });

    client
      .getHistory(request)
      .then((data: GetHistoryResponse) => {
        log({
          level: LogLevel.INFO,
          message: "History fetched successfully",
          operationNamespace: "useHistory.getHistory",
        });
        const payload = new TextEncoder().encode(JSON.stringify(data));
        publishMetric(MetricAction.REQUEST_HISTORY_PAYLOAD_SIZE, {
          value: payload.length.toString(),
        });
        dispatch({
          type: HistoryActions.SET,
          payload: {
            history: data,
            retrieveState: RetrievalState.SUCCESS,
          },
        });
      })
      .catch((error: AxiosError) => {
        log({
          level: LogLevel.ERROR,
          message: "Error while trying to fetch document history",
          operationNamespace: "useHistory.getHistory",
          exception: error?.message,
          attributes: { request },
        });
        dispatch({
          type: HistoryActions.SET,
          payload: { history: [], retrieveState: RetrievalState.ERROR },
        });
      });
  }, [
    countryCode,
    documentType,
    documentId,
    state.history,
    state.retrieveState,
    dispatch,
  ]);
  return state;
};

export default useHistory;
