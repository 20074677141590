import React, { FC } from "react";
import { ValidationModalRenderComponentProps } from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/ValidationModalRenderComponent";
import MismatchTable from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/MismatchTable";
import getLineItemMismatchDataTableDefinition from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/LineItem/line-item-mismatch-data-table-definition";
import convertToLineItemMismatchTableFields from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/LineItem/line-item-mismatch-table-fields-extractor";
import { useInboundDocument } from "src/components/Contexts/InboundDocumentContext";
import { orderMismatchTableByAscendingItems } from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/mismatch-table-fields-extractor";

const LineItemMismatchTableRenderComponent: FC<
  ValidationModalRenderComponentProps & { enableEditPurchaseOrder?: boolean }
> = ({ enableEditPurchaseOrder = false, validationGroupId }) => {
  const inboundDocument = useInboundDocument();
  const lineItemMismatchDataTableDefinition =
    getLineItemMismatchDataTableDefinition(enableEditPurchaseOrder);
  return (
    <div>
      <MismatchTable
        validationGroupId={validationGroupId}
        dataTableDefinition={lineItemMismatchDataTableDefinition}
        dataTransformer={convertToLineItemMismatchTableFields(inboundDocument)}
        rowSorter={orderMismatchTableByAscendingItems}
      />
    </div>
  );
};

export default LineItemMismatchTableRenderComponent;
