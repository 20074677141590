import React, { FC } from "react";
import { get } from "lodash";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { getProcurementServiceDomain } from "src/api/config";
import { useInboundDocument } from "src/components/Contexts/InboundDocumentContext";

const ReferencedDocumentLink: FC<DataTableCellChildComponentProps<any>> = ({
  content: { referencedDocuments },
}: any) => {
  const { useCase } = useInboundDocument();

  const referencedDocumentMap = {
    PO: {
      default: (documentId: string) =>
        `${getProcurementServiceDomain()}/bp/po?poId=${documentId}`,
    },
  };
  const defaultUrl = (documentId: string) =>
    referencedDocumentMap.PO.default(documentId);

  return (
    <>
      {referencedDocuments && (
        <div>
          {referencedDocuments.map(
            ({ documentId, documentType }: Record<string, string>) => (
              <a
                href={get(
                  referencedDocumentMap,
                  `${documentType}.${useCase}`,
                  defaultUrl(documentId)
                )}
              >
                {documentId}
              </a>
            )
          )}
        </div>
      )}
    </>
  );
};

export default ReferencedDocumentLink;
