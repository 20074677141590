import React, { FC } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { KatButton } from "@amzn/katal-react";

const CONFIRMATION_DISCLAIMER =
  "aic_internal_bulk_operations_confirmation_modal_disclaimer";
const CLOSE_LABEL = "aic_internal_bulk_operations_confirmation_modal_close";
const CANCEL_LABEL = "aic_internal_bulk_operations_confirmation_modal_cancel";
const SUBMIT_LABEL = "aic_internal_bulk_operations_confirmation_modal_confirm";

const BulkOperationsConfirmationModalFooter: FC<
  WithTranslation & BulkOperationsConfirmationModalFooterProps
> = ({ t, submitHandler, cancelHandler, closeHandler }) => {
  return (
    <>
      <div
        className={classnames(
          styles.flex,
          styles.flex_direction_column,
          styles.flex_nowrap
        )}
      >
        {submitHandler ? (
          <p>
            <b>{t(CONFIRMATION_DISCLAIMER)}</b>
          </p>
        ) : null}
        <div
          className={classnames(
            styles.flex,
            styles.justify_end,
            styles.flex_direction_row,
            styles.flex_nowrap
          )}
        >
          {closeHandler ? (
            <KatButton
              id="bulk-operations-modal-close-button"
              className={classnames(styles.padding_10)}
              variant="secondary"
              onClick={closeHandler}
            >
              {t(CLOSE_LABEL)}
            </KatButton>
          ) : null}
          {cancelHandler ? (
            <KatButton
              id="bulk-operations-modal-cancel-button"
              className={classnames(styles.padding_10, styles.justify_end)}
              variant="secondary"
              onClick={cancelHandler}
            >
              {t(CANCEL_LABEL)}
            </KatButton>
          ) : null}
          {submitHandler ? (
            <KatButton
              id="bulk-operations-modal-ack-button"
              className={classnames(styles.padding_10, styles.justify_end)}
              variant="danger"
              onClick={submitHandler}
            >
              {t(SUBMIT_LABEL)}
            </KatButton>
          ) : null}
        </div>
      </div>
    </>
  );
};

export interface BulkOperationsConfirmationModalFooterProps {
  cancelHandler?: () => void;
  closeHandler?: () => void;
  submitHandler?: () => void;
}

export default withTranslation()(BulkOperationsConfirmationModalFooter);
