import React, { useCallback, useEffect, useState } from "react";
import useSearch, { SearchableValue } from "./hooks/useSearch";
import SearchBar from "./index";

export type SearchInMemoryBarProps = {
  title: string;
  placeholder?: string;
  searchableList: Array<SearchableValue>;
  metricsPublisher: () => void;
  onMatch: (matched: Array<SearchableValue>) => void;
  onMatchFilter?: (matched: Array<SearchableValue>) => Array<SearchableValue>;
  onClear: () => void;
};

const SearchInMemoryBar = ({
  title,
  placeholder,
  searchableList,
  metricsPublisher,
  onMatch,
  onMatchFilter,
  onClear,
}: SearchInMemoryBarProps) => {
  const [searchPattern, setSearchPattern] = useState("");

  const matched = useSearch(searchPattern, searchableList);

  useEffect(() => {
    onMatch(onMatchFilter ? onMatchFilter(matched) : matched);
    // Due to the fact the React does not differ object and arrays by its content
    // Every call to useSearch would cause a rerender even with the result array remaining the same
    // In order to avoid that only call the onMatch when the searchPattern changes is what makes more sense
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPattern, onMatch, onMatchFilter]);

  const onChange = useCallback(
    (search: string) => {
      metricsPublisher();
      setSearchPattern(search);
    },
    [metricsPublisher]
  );

  const onClearSearch = useCallback(() => {
    onClear();
    setSearchPattern("");
  }, [onClear]);

  return (
    <SearchBar
      searchablePattern={searchPattern}
      title={title}
      placeholder={placeholder}
      showClearButton
      onChange={onChange}
      onClear={onClearSearch}
    />
  );
};

export default SearchInMemoryBar;
