import { Dispatch, SetStateAction } from "react";
import {
  InboundDocument,
  convertToInboundDocument,
  DocumentRelationship,
  RelationshipType,
} from "./inbound_document";

export interface GetRelatedDocumentsRequest {
  readonly countryCode: string;
  readonly documentType: string;
  readonly documentId: string;
  readonly relatedDocumentType?: string;
}
export interface GetRelatedDocumentsResponse {
  readonly relatedDocuments: Array<DocumentRelationship>;
  readonly relatesToDocuments: Array<DocumentRelationship>;
}

export interface GetRelatedDocumentsByTypeResponse {
  readonly s3PresignedUrl: string;
  readonly type: string;
}

export interface RelatedDocuments {
  relatedDocuments: Array<InboundDocument>;
  setRelatedDocuments: Dispatch<SetStateAction<Array<InboundDocument>>>;
  relatesToDocuments: Array<InboundDocument>;
  setRelatesToDocuments: Dispatch<SetStateAction<Array<InboundDocument>>>;
}

function addRelationshipType(
  document: InboundDocument,
  type: RelationshipType
): DocumentRelationship {
  return {
    ...document,
    relationshipType: type,
  };
}

export function convertToRelatedDocuments(
  rawRelatedDocuments: any
): Array<DocumentRelationship> {
  return (
    rawRelatedDocuments?.relatedDocuments
      ?.map(convertToInboundDocument)
      .map((e: InboundDocument) =>
        addRelationshipType(e, RelationshipType.RELATED)
      ) || []
  );
}

export function convertToRelatesToDocuments(
  rawRelatedDocuments: any
): Array<DocumentRelationship> {
  return (
    rawRelatedDocuments?.relatesToDocuments
      ?.map(convertToInboundDocument)
      .map((e: InboundDocument) =>
        addRelationshipType(e, RelationshipType.RELATES_TO)
      ) || []
  );
}
