import DocumentReferences from "src/components/Pages/DocumentList/DocumentTable/DocumentReferences";
import DocumentExternalPartner from "src/components/Pages/DocumentList/DocumentTable/DocumentExternalPartner";
import DocumentId from "src/components/Pages/DocumentList/DocumentTable/DocumentId";
import {
  ColumnDefinition,
  DataTableDefinition,
} from "src/components/DataTable/DataTableDefinition";
import { ConsolidatedDocument } from "src/api/interfaces/document_listing";
import { DocumentField } from "src/constants";
import i18n from "../../../../i18n";
import DocumentStatusTracker from "./DocumentStatusTracker";
import styles from "../../../../styles.module.scss";
import dataTableStyles from "../../../DataTable/styles.module.scss";

const columnValueStyle: string[] = [
  styles.background_white,
  dataTableStyles.linkable_cell_content,
];

const newColumnsDefinition: ColumnDefinition[] = [
  {
    label: "aic_doc_listing_doc_type",
    property: DocumentField.DOCUMENT_TYPE.valueOf(),
    translateValue: true,
    valueStyle: columnValueStyle,
  },
  {
    label: "aic_doc_listing_subtype",
    property: DocumentField.DOCUMENT_SUBTYPE.valueOf(),
    translateValue: true,
    valueStyle: columnValueStyle,
  },
  {
    label: "aic_doc_listing_doc_id",
    property: DocumentField.DOCUMENT_ID.valueOf(),
    childComponentOnly: true,
    ChildComponent: DocumentId,
    valueStyle: columnValueStyle,
  },
  {
    label: "aic_doc_listing_use_case",
    property: DocumentField.USE_CASE.valueOf(),
    translateValue: true,
    valueStyle: columnValueStyle,
  },
  {
    label: "aic_doc_listing_issuance_date",
    property: DocumentField.ISSUANCE_DATE.valueOf(),
    formatter: (date: string) => new Date(date).toLocaleString(i18n.language),
    valueStyle: columnValueStyle,
  },
  {
    label: "aic_doc_listing_reference",
    property: DocumentField.REFERENCED_DOCUMENT.valueOf(),
    childComponentOnly: true,
    ChildComponent: DocumentReferences,
    valueStyle: columnValueStyle,
  },
  {
    label: "aic_doc_listing_partner",
    property: DocumentField.EXTERNAL_PARTNERS.valueOf(),
    childComponentOnly: true,
    ChildComponent: DocumentExternalPartner,
    valueStyle: columnValueStyle,
  },
  {
    label: "aic_doc_listing_total_cost",
    property: DocumentField.TOTAL_COST.valueOf(),
    formatter: (amount: string) => (amount ? `$ ${amount}` : null),
    valueStyle: columnValueStyle,
  },
  {
    label: "aic_doc_listing_status",
    property: DocumentField.STATUS.valueOf(),
    childComponentOnly: true,
    ChildComponent: DocumentStatusTracker,
  },
];

const buildDocumentDetailsURL = (content: ConsolidatedDocument): string => {
  const currentSearchParams = new URLSearchParams(window.location.search);
  const serializedSearchParams = encodeURIComponent(
    currentSearchParams.toString()
  );
  const lastSearchParamsEntry = serializedSearchParams
    ? `?lastSearchParams=${serializedSearchParams}`
    : "";
  return `/inbound-central/countries/${content.countryCode}/document-types/${content.documentType}/documents/${content.documentId}/validations${lastSearchParamsEntry}`;
};

const dataTableDefinition: DataTableDefinition<ConsolidatedDocument> = {
  columnsDefinition: newColumnsDefinition,
  rowURLBuilder: buildDocumentDetailsURL,
};

export default dataTableDefinition;
