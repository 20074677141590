import React, { FC, ReactNode, useCallback, useState } from "react";
import { DocumentCompliance } from "src/api/interfaces/document_validation";

const DEFAULT_DOCUMENT_CCOMPLIANCE: DocumentCompliance = {
  validationGroups: [],
  userActionsByValidationGroup: {},
  setDocumentCompliance: () => {},
  responseReceived: false,
};

export const DocumentComplianceContext =
  React.createContext<DocumentCompliance>(DEFAULT_DOCUMENT_CCOMPLIANCE);

export const useDocumentCompliance = () =>
  React.useContext(DocumentComplianceContext);

export const DocumentComplianceContextProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [documentCompliance, setDocumentCompliance] =
    useState<DocumentCompliance>(DEFAULT_DOCUMENT_CCOMPLIANCE);

  // here context value is just returning an object, but only
  // re-creating the object when its dependencies change ([state, setContext])
  const getDocumentComplianceContextValue = useCallback(
    () => ({
      ...documentCompliance,
      setDocumentCompliance,
    }),
    [documentCompliance, setDocumentCompliance]
  );
  return (
    <DocumentComplianceContext.Provider
      value={getDocumentComplianceContextValue()}
    >
      {children}
    </DocumentComplianceContext.Provider>
  );
};
