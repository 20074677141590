import React, { FC } from "react";
import DataTable from "src/components/DataTable";
import dataTableDefinition from "src/components/Pages/DocumentList/DocumentTable/document-listing-data-table-definition";
import { checkPermissionOnCountry } from "src/components/AICLayout/AICHeader/Permissions/PermissionCheckers";
import { Operation } from "src/api/interfaces/permissions";
import { usePermissions } from "src/components/Contexts/PermissionsContext";
import { useCountrySelection } from "src/components/Contexts/CountrySelectionContext";
import {
  bulkOperationRowSelectionEvaluator,
  getBulkOperationsAllowedActions,
} from "src/components/Pages/DocumentList/BulkOperations";
import { InboundStatus } from "src/api/interfaces/inbound_document";
import { useDocumentList } from "src/components/Contexts/DocumentList/Provider";
import useDocumentsFetcher from "src/components/Pages/DocumentList/Hooks/useDocumentsFetcher";
import PaginationInContext from "src/components/Pagination/PaginationInContext";
import useFilterOptionsFetcher from "../Hooks/useFilterOptionsFetcher";

const DocumentTable: FC = () => {
  useDocumentsFetcher();
  useFilterOptionsFetcher();

  const { permissions } = usePermissions();
  const { selectedCountry } = useCountrySelection();
  const {
    state: { documents, retrieveState },
    dispatch,
  } = useDocumentList();

  const documentsList = Object.values(documents);
  const shouldSelectAll = !!(
    documentsList.length &&
    documentsList.every((doc) => {
      return (
        documentsList[0].documentType === doc.documentType &&
        documentsList[0].countryCode === doc.countryCode &&
        doc.status === InboundStatus.PENDING_ACTIONS
      );
    })
  );

  const canPerformBulkOperations =
    checkPermissionOnCountry(
      permissions,
      selectedCountry?.countryCode,
      Operation.PerformBulkOperations
    ) &&
    !!getBulkOperationsAllowedActions(permissions, selectedCountry?.countryCode)
      .length;

  return (
    <>
      <DataTable
        id="document-listing"
        tableDefinition={dataTableDefinition}
        retrievalState={retrieveState}
        contents={Object.values(documents)}
        selectEnabled={canPerformBulkOperations}
        selectDispatcher={dispatch}
        shouldBeSelectable={bulkOperationRowSelectionEvaluator}
        shouldEnableSelectAll={shouldSelectAll}
      />
      <PaginationInContext />
    </>
  );
};

export default DocumentTable;
