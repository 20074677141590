import { ComplianceUserActionHandler } from "src/components/UserActions/Handlers/Compliance/types";
import client from "src/api/client";

const handleRevalidate: ComplianceUserActionHandler = async (
  documentDetails
) => {
  const { documentRevalidationStarted } = await client.revalidateDocument({
    countryCode: documentDetails.countryCode,
    documentId: documentDetails.documentId,
    documentType: documentDetails.documentType,
  });

  return documentRevalidationStarted;
};

export default handleRevalidate;
