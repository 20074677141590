import React, { FC, useContext } from "react";
import DataTable from "src/components/DataTable";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { DocumentComplianceContext } from "src/components/Contexts/DocumentComplianceContext";
import documentComplianceDataTableDefinition from "src/components/Pages/DocumentInbound/Tabs/Validations/DocumentCompliance/DocumentComplianceListing/document-compliance-data-table-definition";

const DocumentValidationListing: FC = () => {
  const { validationGroups } = useContext(DocumentComplianceContext);

  return (
    <div
      className={classnames(
        styles.flex,
        styles.flex_direction_row,
        styles.flex_nowrap,
        styles.w_80,
        styles.justify_start
      )}
    >
      <DataTable
        id="document-validation"
        tableDefinition={documentComplianceDataTableDefinition}
        contents={validationGroups}
      />
    </div>
  );
};

export default DocumentValidationListing;
