import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import client from "src/api/client";
import { GetFilterOptionsRequest } from "src/api/interfaces/filter_options";
import { useCountrySelection } from "src/components/Contexts/CountrySelectionContext";
import { useFilterOptions } from "src/components/Contexts/FilterOptions/Provider";
import {
  DomainName,
  FilterActions,
} from "src/components/Contexts/FilterOptions/types";
import { usePermissions } from "src/components/Contexts/PermissionsContext";
import { log, LogLevel } from "src/logger";
import { getFilterFromUrl } from "src/utils";

const useFilterOptionsFetcher = (): void => {
  const { permissions } = usePermissions();
  const { selectedCountry } = useCountrySelection();
  const { dispatch } = useFilterOptions();

  const [lastCountryCode, setLastCountryCode] = useState<string>("");
  const filterFromURL = getFilterFromUrl();

  useEffect(() => {
    const updateFilterOptionValues = () => {
      const filterOptionsSortedByDependency = [
        DomainName.DOCUMENT_TYPE,
        DomainName.DOCUMENT_SUBTYPE,
        DomainName.USE_CASE,
        DomainName.VALIDATION_GROUPS,
        DomainName.DOCUMENT_ID,
        DomainName.ISSUANCE_DATE,
        DomainName.ORCHESTRATION_EVENTS,
        DomainName.SEARCH,
        DomainName.STATUSES,
        DomainName.START_ISSUANCE_DATE,
        DomainName.END_ISSUANCE_DATE,
      ];

      filterOptionsSortedByDependency.forEach((domainName) => {
        const value = filterFromURL[domainName];
        dispatch({
          type: FilterActions.SET_SELECTED_VALUES,
          payload: { domainName, value },
        });
      });
    };

    const fetchFilterOptions = () => {
      const { countryCode } = selectedCountry!;

      setLastCountryCode(countryCode);

      const useCases = Array.from(permissions.get(countryCode)!.keys());

      const request: GetFilterOptionsRequest = {
        useCases,
        countryCode,
      };

      client
        .getFilterOptions(request)
        .then((result) => {
          log({
            level: LogLevel.INFO,
            message: "Filter Options fetch completed successfully",
            operationNamespace: "useFilterOptionsFetcher",
          });

          dispatch({
            type: FilterActions.SET_FILTERABLE_DATA,
            payload: { filterableData: result.filterableData },
          });
          setLastCountryCode(countryCode);

          updateFilterOptionValues();
        })
        .catch((error: AxiosError) => {
          log({
            level: LogLevel.ERROR,
            message: "There was an error while trying to fetch Filter Options",
            operationNamespace: "useFilterOptionsFetcher",
            exception: error?.message,
            attributes: { request },
          });
        });
    };

    const shouldFetchFilterOptions =
      selectedCountry &&
      permissions.get(selectedCountry.countryCode) &&
      lastCountryCode !== selectedCountry.countryCode;

    if (shouldFetchFilterOptions) {
      fetchFilterOptions();
    }
  }, [filterFromURL, dispatch, lastCountryCode, permissions, selectedCountry]);
};

export default useFilterOptionsFetcher;
