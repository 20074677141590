import {
  ComplianceUserActionType,
  UserAction,
  ValidationUserActionType,
} from "src/components/UserActions/types";
import { ValidationUserActionHandler } from "src/components/UserActions/Handlers/Validation/types";
import React, { FC } from "react";
import handleOverrideMetadata from "src/components/UserActions/Handlers/Validation/OverrideMetadata/override_metadata_handler";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import { ValidationGroup } from "src/api/interfaces/document_validation";

export const handlersByType: Map<
  ComplianceUserActionType | ValidationUserActionType,
  ValidationUserActionHandler
> = new Map().set(
  ValidationUserActionType.OVERRIDE_METADATA,
  handleOverrideMetadata
);

export interface ValidationUserActionHandlerProps {
  readonly onCancel?: () => void;
  readonly onSubmit?: (overriddenMetadata: { [key: string]: string }) => void;
  readonly validationGroup: ValidationGroup;
  readonly userAction?: UserAction;
}

const DefaultComponent: FC<ValidationUserActionHandlerProps> = () => <></>;

export const handleValidationUserAction = async (
  inboundDocument: InboundDocument,
  action: UserAction | undefined
): Promise<FC<ValidationUserActionHandlerProps>> => {
  if (!action) return DefaultComponent;

  const handler: ValidationUserActionHandler | undefined = handlersByType.get(
    action.type
  );

  if (handler) {
    return handler(inboundDocument, action.metadata);
  }
  return DefaultComponent;
};
