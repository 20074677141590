import { useCallback } from "react";
import { isArray, isObject, isString } from "lodash";

export type SearchableValue = Record<string, any> | Array<any> | string;

const useSearch = (pattern: string, list: Array<any>) => {
  const getValuesToSearch = (valueToSearch: SearchableValue) => {
    if (isArray(valueToSearch)) {
      return valueToSearch as Array<SearchableValue>;
    }

    if (isObject(valueToSearch)) {
      return Object.values(valueToSearch);
    }

    return [];
  };

  const searchInObj = useCallback(
    (objectToSearch: SearchableValue, searchString: string): boolean => {
      if (isString(objectToSearch)) {
        return objectToSearch
          .toLowerCase()
          .includes(searchString.toLowerCase());
      }
      return getValuesToSearch(objectToSearch).some((searchableValue: string) =>
        searchInObj(searchableValue, searchString)
      );
    },
    []
  );

  const search = useCallback(
    (patternString: string, listToSearch: Array<any>) => {
      return listToSearch.filter((object) => {
        return searchInObj(object, patternString);
      });
    },
    [searchInObj]
  );

  return search(pattern, list);
};

export default useSearch;
