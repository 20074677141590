import React, { FC, useCallback } from "react";
import { usePagination } from "src/components/Contexts/PaginationContext";
import {
  PageInformation,
  Pagination as PaginationInterface,
} from "src/api/interfaces/pagination";
import Pagination from "src/components/Pagination";

const PaginationInContext: FC = () => {
  const { pageInformation, setPagination } = usePagination();

  const onChange = useCallback(
    (newPageInformation: PageInformation) => {
      setPagination((prevPageInformation: PaginationInterface) => ({
        pageInformation: {
          ...prevPageInformation.pageInformation,
          ...newPageInformation,
        },
      }));
    },
    [setPagination]
  );

  return (
    <Pagination
      pageSize={pageInformation.pageSize}
      page={pageInformation.page}
      totalItems={pageInformation.total}
      onChange={onChange}
      updateUrl
    />
  );
};

export default PaginationInContext;
