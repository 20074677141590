import React from "react";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import ExpandableCard from "src/components/ExpandableCard/ExpandableCard";
import InfoTable from "src/components/InfoTable/InfoTable";

const TaxClassificationCard = ({
  title,
  taxClassification,
}: {
  title: string;
  taxClassification: Array<Record<string, unknown>>;
}) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const taxClassificationName = t(
    "aic_doc_page_product_items_tab_item_details_tax_classification_name"
  );
  const taxClassificationValue = t(
    "aic_doc_page_product_items_tab_item_details_tax_classification_value"
  );

  return (
    <ExpandableCard expanded title={title}>
      <InfoTable
        columns={[taxClassificationName, taxClassificationValue]}
        rows={taxClassification.map(
          ({ name, value }: Record<string, unknown>) => ({
            Name: (name as string).toString(),
            Value: (value as string).toString(),
          })
        )}
      />
    </ExpandableCard>
  );
};

export default TaxClassificationCard;
