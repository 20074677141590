import { RetrievalState } from "src/constants";
import { HistoryEntry } from "../../../api/interfaces/history";

export interface HistoryState {
  history: Array<HistoryEntry>;
  retrieveState: RetrievalState;
}

export enum HistoryActions {
  SET = 0,
}

export interface HistoryAction {
  type: HistoryActions;
  payload: any;
}

export type ActionFunction = (
  state: HistoryState,
  actionPayload: any
) => HistoryState;
