import React, { FC, ReactNode, useCallback, useState } from "react";
import { InboundComplianceGroupDetails } from "src/api/interfaces/inbound_compliance_group";

const DEFAULT_CONTEXT: InboundComplianceGroupDetails = {
  inboundComplianceGroupDetails: [],
  inMemoryInboundComplianceGroupDetails: [],
  setInboundComplianceGroupDetails: () => {},
};

export const InboundComplianceGroupDetailsContext =
  React.createContext<InboundComplianceGroupDetails>(DEFAULT_CONTEXT);

export const useInboundComplianceGroupDetails = () =>
  React.useContext(InboundComplianceGroupDetailsContext);

export const InboundComplianceGroupDetailsContextProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [inboundComplianceGroupDetails, setInboundComplianceGroupDetails] =
    useState<InboundComplianceGroupDetails>(DEFAULT_CONTEXT);

  const getInboundComplianceGroupDetailsContextValue = useCallback(
    () => ({
      ...inboundComplianceGroupDetails,
      setInboundComplianceGroupDetails,
    }),
    [inboundComplianceGroupDetails, setInboundComplianceGroupDetails]
  );

  return (
    <InboundComplianceGroupDetailsContext.Provider
      value={getInboundComplianceGroupDetailsContextValue()}
    >
      {children}
    </InboundComplianceGroupDetailsContext.Provider>
  );
};
