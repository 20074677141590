import React, { FC } from "react";
import { KatTableCell } from "@amzn/katal-react";
import {
  ColumnDefinition,
  DataTableCellChildComponentProps,
  DataTableSelectionAction,
  DataTableSelectionProps,
} from "src/components/DataTable/DataTableDefinition";
import Field, { FieldDefinition } from "src/components/Field/Field";
import { v1 } from "uuid";
import { getFromObject } from "src/utils";
import classnames from "classnames";
import { Link } from "react-router-dom";
import TranslatedField from "src/components/TranslatedField/TranslatedField";
import styles from "./styles.module.scss";

const DataTableCell: FC<DataTableCellProps<any>> = ({
  id,
  content,
  contents,
  columnDefinition: {
    formatter,
    property,
    translateValue,
    childComponentOnly,
    ChildComponent,
    valueStyle,
    valueStyleBuilder,
  },
  href,
  selectDispatcher,
  shouldBeSelectable,
}) => {
  const propertyValue = getFromObject(content, property);

  const fieldStyle = valueStyleBuilder
    ? valueStyleBuilder(content)
    : valueStyle;

  const childComponentProps: DataTableCellChildComponentProps<any> = {
    valueStyle: fieldStyle,
    content,
    contents,
    translateValue,
    selectDispatcher,
    shouldBeSelectable,
  };

  const fieldProps: FieldDefinition<any, any> = {
    value: propertyValue,
    valueStyle: fieldStyle,
    formatter,
    ChildComponent,
    childComponentOnly,
    childComponentProps,
  };
  const cellContent = translateValue ? (
    <TranslatedField {...fieldProps} />
  ) : (
    <Field {...fieldProps} />
  );

  return (
    <KatTableCell
      id={`${id}-data-table-cell-value-${property}`}
      key={`${id}-data-table-cell-value-${v1()}`}
      className={classnames({
        [styles.data_table_anchored_cell]: !!href,
      })}
    >
      {href ? (
        <Link className={classnames(styles.data_table_anchor)} to={href}>
          {cellContent}
        </Link>
      ) : (
        cellContent
      )}
    </KatTableCell>
  );
};

export interface DataTableCellProps<ContentType> {
  id: string;
  content: ContentType;
  contents: Array<ContentType>;
  columnDefinition: ColumnDefinition;
  href?: string;
  selectDispatcher?: (event: DataTableSelectionAction<ContentType>) => void;
  shouldBeSelectable?: (
    content: ContentType & DataTableSelectionProps,
    contents: Array<ContentType & DataTableSelectionProps>
  ) => boolean;
}

export default DataTableCell;
