import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { KatInput } from "@amzn/katal-react";
import TranslationKey, {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { FilterValue } from "../Contexts/FilterOptions/types";

export interface TranslatedTextInputProps {
  translationPrefix: string;
  initialValue?: FilterValue;
  classname?: string;
  disabled?: boolean;
  onChange: (values: string[]) => void;
}

const TranslatedTextInput: FC<TranslatedTextInputProps> = ({
  translationPrefix,
  initialValue,
  classname,
  disabled,
  onChange,
}) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const tKey = new TranslationKey();

  const translationKey = translationPrefix.toLowerCase();
  const onChangeWithValueSeparation = ({ target: { value } }: any) => {
    const entriesSeparator = ",";

    const entries = value
      .split(entriesSeparator)
      .map((entry: string) => entry.trim())
      .filter((entry: string) => entry.length);

    onChange(entries);
  };

  return (
    <KatInput
      type="search"
      value={initialValue as string}
      label={tKey.translateValue(translationKey, t)}
      placeholder={tKey.translateValue(`${translationKey}_placeholder`, t)}
      tooltip-position="top"
      onChange={onChangeWithValueSeparation}
      className={classname}
      tooltip-text={tKey.translateValue(`${translationKey}_tooltip`, t)}
      disabled={disabled}
    />
  );
};

export default TranslatedTextInput;
