import { useEffect } from "react";
import client, { UNAUTHORIZED_HTTP_STATUS_CODE } from "src/api/client";
import { log, LogLevel } from "src/logger";
import { AxiosError } from "axios";
import { useDocumentCompliance } from "src/components/Contexts/DocumentComplianceContext";
import { GetDocumentComplianceRequest } from "src/api/interfaces/document_validation";
import { useAuthorizationErrors } from "src/components/Contexts/AuthorizationErrorsContext";
import useUnauthorizedRedirect from "src/components/AICLayout/AICHeader/Permissions/WithRedirectWhenAuthorized";
import { AuthorizationErrors } from "src/api/interfaces/permissions";

const useDocumentComplianceFetcher = ({
  countryCode,
  documentType,
  documentId,
  executionNumber,
}: DocumentComplianceFetcherProps) => {
  useUnauthorizedRedirect();

  const { setDocumentCompliance } = useDocumentCompliance();
  const { setAuthorizationErrors } = useAuthorizationErrors();

  useEffect(() => {
    const isParametersPresent = (parameters: GetDocumentComplianceRequest) => {
      return (
        parameters &&
        parameters.countryCode &&
        parameters.documentType &&
        parameters.documentId &&
        parameters.executionNumber
      );
    };
    const params: GetDocumentComplianceRequest = {
      countryCode,
      documentType,
      documentId,
      executionNumber: executionNumber!,
    };
    const fetchDocumentCompliance = () => {
      client
        .getDocumentCompliance(params)
        .then((result) => {
          log({
            level: LogLevel.INFO,
            message: "Document Compliance fetch completed successfully",
            operationNamespace:
              "DocumentComplianceFetcher.getDocumentCompliance",
          });
          setDocumentCompliance((prevState) => ({
            ...prevState,
            validationGroups: result.validationGroups,
            responseReceived: true,
          }));
        })
        .catch((error: AxiosError) => {
          log({
            level: LogLevel.ERROR,
            message:
              "There was an error while trying to fetch Document Compliance",
            operationNamespace:
              "DocumentComplianceFetcher.getDocumentCompliance",
            exception: error?.message,
            attributes: { params },
          });
          if (error.response?.status === UNAUTHORIZED_HTTP_STATUS_CODE) {
            setAuthorizationErrors((prevState: AuthorizationErrors) => ({
              authorizationErrors: prevState.authorizationErrors.add(
                error.message
              ),
            }));
          }
        });
    };

    if (isParametersPresent(params)) {
      fetchDocumentCompliance();
    }
  }, [
    countryCode,
    documentType,
    documentId,
    executionNumber,
    setAuthorizationErrors,
    setDocumentCompliance,
  ]);

  useEffect(() => {
    const areParametersPresent = () => {
      return countryCode && documentType && documentId;
    };

    const getValidationUserActions = async () => {
      try {
        const userActionsByValidationGroup =
          await client.getValidationUserActions({
            country_code: countryCode,
            document_type: documentType,
            document_id: documentId,
          });

        log({
          level: LogLevel.INFO,
          message: "Validation user actions fetch completed successfully",
          operationNamespace: "ValidationUserActions.useEffect",
          attributes: { userActionsByValidationGroup },
        });
        setDocumentCompliance((prevState) => ({
          ...prevState,
          userActionsByValidationGroup,
        }));
      } catch (error: any) {
        log({
          level: LogLevel.ERROR,
          message:
            "There was an error while trying to fetch validation actions",
          operationNamespace: "ValidationUserActions.useEffect",
          exception: error?.message,
        });
      }
    };

    if (areParametersPresent()) {
      getValidationUserActions();
    }
  }, [countryCode, documentType, documentId, setDocumentCompliance]);
};

export interface DocumentComplianceFetcherProps {
  countryCode: string;
  documentType: string;
  documentId: string;
  executionNumber?: number;
}

export default useDocumentComplianceFetcher;
