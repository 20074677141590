import {
  setClearSelectedValues,
  setFilterableData,
  setSelectedValues,
  setShouldTriggerFetchDocuments,
} from "./actionFunctions";
import {
  FilterActions,
  FilterOptionsAction,
  FilterOptionsActionFunction,
  FilterOptionsState,
} from "./types";

const reducerMap: Record<FilterActions, FilterOptionsActionFunction> = {
  [FilterActions.CLEAR]: setClearSelectedValues,
  [FilterActions.SET_FILTERABLE_DATA]: setFilterableData,
  [FilterActions.SET_SELECTED_VALUES]: setSelectedValues,
  [FilterActions.SET_SHOULD_TRIGGER_FETCH_DOCUMENTS]:
    setShouldTriggerFetchDocuments,
};

export default function filterOptionsReducer(
  state: FilterOptionsState,
  emittedAction: FilterOptionsAction
): FilterOptionsState {
  const { type, payload } = emittedAction;

  return reducerMap[type](state, payload);
}
