import { FiltersDefinition, getDefaultSelectedValues } from "./definition";
import onChangeHandlerByFilter from "./onChangeHandlerByFilter";
import {
  DomainName,
  FilterOptionsActionFunction,
  FilterOptionsState,
  FilterValue,
} from "./types";

export const getInitialOptionValues = (
  filterableData: Record<string, string | string[]>
): Record<DomainName, string[]> => {
  // Fills with every domain that doesnt not depends on another domain
  // So the path defined in filterableData is only the domain name
  const filterValues = Object.values(DomainName).reduce((prev, domainName) => {
    return {
      ...prev,
      [domainName]:
        filterableData[domainName] ||
        FiltersDefinition[domainName].defaultOptionValues,
    };
  }, {} as Record<DomainName, string[]>);

  return filterValues;
};

export const setShouldTriggerFetchDocuments: FilterOptionsActionFunction = (
  state: FilterOptionsState,
  payload: {
    shouldTriggerFetchDocuments: boolean;
  }
) => {
  return {
    ...state,
    shouldTriggerFetchDocuments: payload.shouldTriggerFetchDocuments,
  };
};

export const setClearSelectedValues: FilterOptionsActionFunction = (
  state: FilterOptionsState,
  _: any
): FilterOptionsState => {
  return {
    filterableData: { ...state.filterableData },
    optionValues: getInitialOptionValues(state.filterableData),
    selectedValues: getDefaultSelectedValues(),
    shouldTriggerFetchDocuments: true,
  };
};

export const setFilterableData: FilterOptionsActionFunction = (
  state: FilterOptionsState,
  payload: {
    filterableData: Record<string, string[]>;
  }
): FilterOptionsState => {
  return {
    ...state,
    filterableData: { ...payload.filterableData },
    optionValues: getInitialOptionValues(payload.filterableData),
  };
};

export const setSelectedValues: FilterOptionsActionFunction = (
  state: FilterOptionsState,
  payload: {
    domainName: DomainName;
    value?: FilterValue;
    shouldTriggerFetchDocuments?: boolean;
  }
): FilterOptionsState => {
  const newState = onChangeHandlerByFilter[payload.domainName](
    state,
    payload.value
  );
  newState.shouldTriggerFetchDocuments = !!payload.shouldTriggerFetchDocuments;
  return newState;
};
