import {
  ColumnDefinition,
  DataTableDefinition,
} from "src/components/DataTable/DataTableDefinition";
import { InboundComplianceGroupDetailsField } from "src/constants";
import { InboundComplianceGroupDetail } from "src/api/interfaces/inbound_compliance_group";

const getUniqueReceiptTypeValues = (
  inboundComplianceGroupDetails: InboundComplianceGroupDetail[]
): string[] => {
  const keyMap: Set<string> = new Set();
  inboundComplianceGroupDetails.forEach((groupDetail) => {
    groupDetail.fields
      .map((field) => field?.receiptType)
      .filter((receiptType) => !!receiptType)
      .forEach((receiptType) => keyMap.add(receiptType));
  });

  return Array.from(keyMap).sort();
};

const convertToDynamicTableDefinition = (
  inboundComplianceGroupDetails: InboundComplianceGroupDetail[]
): DataTableDefinition<InboundComplianceGroupDetail> => {
  const columns: ColumnDefinition[] = [];
  const dynamicColumnsMap: string[] = getUniqueReceiptTypeValues(
    inboundComplianceGroupDetails
  );

  columns.push({
    label: "Item Id",
    property: InboundComplianceGroupDetailsField.ITEM_ID.valueOf(),
  });

  dynamicColumnsMap.forEach((receiptType) =>
    columns.push({
      label: receiptType,
      property: receiptType,
    })
  );

  return {
    columnsDefinition: columns,
    rowClickHandler: () => {},
  };
};

export default convertToDynamicTableDefinition;
