import { KatCheckbox } from "@amzn/katal-react";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useFilterOptions } from "src/components/Contexts/FilterOptions/Provider";
import {
  DomainName,
  FilterActions,
  FilterValue,
} from "src/components/Contexts/FilterOptions/types";
import TranslatedTextInput from "src/components/Inputs/TranslatedTextInput";
import TranslationKey, {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import styles from "src/styles.module.scss";

const TRUE_STRING = "True";

const ReferencedDocumentsFilter: FC = () => {
  const {
    state: {
      selectedValues: { referencedDocument, referencedDocumentsIsEmpty },
    },
    dispatch,
  } = useFilterOptions();
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const tKey = new TranslationKey();

  const referencedDocumentsIsEmptyOnChangeHandler = (
    e: KatCheckbox.ChangeEvent
  ) => {
    const {
      detail: { checked },
    } = e;
    if (checked) {
      dispatch({
        type: FilterActions.SET_SELECTED_VALUES,
        payload: {
          domainName: DomainName.REFERENCED_DOCUMENT,
          value: undefined,
        },
      });
    }

    dispatch({
      type: FilterActions.SET_SELECTED_VALUES,
      payload: {
        domainName: DomainName.REFERENCED_DOCUMENTS_IS_EMPTY,
        value: checked ? TRUE_STRING : undefined,
      },
    });
  };

  const referencedDocumentOnChangeHandler = (value?: FilterValue) => {
    dispatch({
      type: FilterActions.SET_SELECTED_VALUES,
      payload: {
        domainName: DomainName.REFERENCED_DOCUMENT,
        value,
      },
    });
  };

  return (
    <div
      className={classnames(
        styles.direction_inherit,
        styles.ma_16,
        styles.min_w_40
      )}
    >
      <TranslatedTextInput
        disabled={!!referencedDocumentsIsEmpty.length}
        key={DomainName.REFERENCED_DOCUMENT}
        initialValue={referencedDocument}
        onChange={referencedDocumentOnChangeHandler}
        translationPrefix={`aic_more_filters_side_bar_${DomainName.REFERENCED_DOCUMENT}`}
      />
      <KatCheckbox
        checked={!!referencedDocumentsIsEmpty}
        className={classnames(styles.mt_6)}
        label={tKey.translateValue(
          `aic_more_filters_side_bar_${DomainName.REFERENCED_DOCUMENTS_IS_EMPTY.toLowerCase()}`,
          t
        )}
        onChange={referencedDocumentsIsEmptyOnChangeHandler}
      />
    </div>
  );
};

export default ReferencedDocumentsFilter;
