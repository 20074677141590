import React, { PropsWithChildren, useState } from "react";
import { KatCard, KatIcon } from "@amzn/katal-react";
import style from "./expandable_card_styles.module.scss";

export interface ExpandableCardProps {
  title: string;
  expanded: boolean;
}

const ExpandableCard = ({
  expanded,
  title,
  children,
}: PropsWithChildren<ExpandableCardProps>) => {
  const [localExpanded, toggleExpansion] = useState(expanded);

  return (
    <KatCard noBodyPadding className={style.root}>
      <span className={style.title} slot="title">
        {title}
      </span>
      <KatIcon
        className={style.arrow}
        onClick={() => toggleExpansion(!localExpanded)}
        slot="action"
        name={localExpanded ? "expand_less" : "expand_more"}
        size="large"
      />
      {localExpanded && <div className={style.body}>{children}</div>}
    </KatCard>
  );
};
export default ExpandableCard;
