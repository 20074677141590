import cloneDeep from "lodash/cloneDeep";
import { RetrievalState } from "src/constants";
import { get } from "lodash";
import { LineItemRepresentation } from "../../../api/interfaces/line_item";
import { ActionFunction, DocumentLineState } from "./types";

const setDocumentLineItems: ActionFunction = (
  state: DocumentLineState,
  payload: {
    documentLineItems: Array<LineItemRepresentation>;
    retrieveState: RetrievalState;
  }
) => {
  const firstItem = get(payload.documentLineItems, 0);
  let documentLineItems = cloneDeep(payload.documentLineItems);
  // TODO: REMOVE THIS AFTER 01/10/2024
  if (firstItem && firstItem.index.toString() === "0") {
    documentLineItems = documentLineItems.map((item) => {
      item.index = (parseInt(item.index, 10) + 1).toString();
      return item;
    });
  }

  return {
    retrieveState: payload.retrieveState,
    documentLineItems,
  };
};

export default setDocumentLineItems;
