import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { KatDropdown, KatPagination, KatRow } from "@amzn/katal-react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { updateUrlParams } from "src/utils";
import {
  DEFAULT_PAGE_INFORMATION,
  PAGE_QS_KEY,
  PAGE_SIZE_QS_KEY,
  PER_PAGE_OPTIONS,
} from "src/components/Contexts/PaginationContext";
import { PageInformation } from "src/api/interfaces/pagination";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";

import styles from "src/styles.module.scss";

const Pagination: FC<PaginationProps> = ({
  onChange,
  perPage,
  page,
  pageSize,
  ExtraPaginationInfo,
  totalItems: total,
  updateUrl,
}) => {
  const history = useHistory();
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  useEffect(() => {
    onChange({ page, pageSize, total });
    if (updateUrl) {
      updateUrlParams(
        [
          [PAGE_QS_KEY, page.toString()],
          [PAGE_SIZE_QS_KEY, pageSize.toString()],
        ],
        history
      );
    }

    // No necessary to rerender because of methods
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, total, pageSize]);

  const handlePageChange = ({
    detail: { page: currentPage },
  }: KatPagination.PageChangedEvent) => {
    const newPageInfo = { total, pageSize, page: currentPage };
    onChange(newPageInfo);
  };

  const handleTotalPerPageChange = ({
    detail: { value },
  }: KatDropdown.ChangeEvent) => {
    const newPageInfo = {
      total,
      page: DEFAULT_PAGE_INFORMATION.page,
      pageSize: Number(value),
    };

    onChange(newPageInfo);
  };

  const finalPerPage = perPage || PER_PAGE_OPTIONS;
  const options: KatalDropdownOption[] = finalPerPage.map((number) => ({
    name: `${number} ${t("aic_doc_listing_pagination_results_per_page")}`,
    value: number.toString(),
  }));

  if (total === 0) {
    return <></>;
  }

  return (
    <KatRow
      className={classnames([
        styles.flex,
        styles.justify_center,
        styles.relative,
        styles.mt_16,
      ])}
    >
      <KatDropdown
        className={classnames([styles.absolute, styles.left_0])}
        options={options}
        value={pageSize.toString()}
        onChange={handleTotalPerPageChange}
      />
      <KatPagination
        totalItems={total}
        itemsPerPage={pageSize}
        page={page}
        onPageChanged={handlePageChange}
      />
      {ExtraPaginationInfo && <ExtraPaginationInfo />}
    </KatRow>
  );
};

export type PaginationProps = {
  totalItems: number;
  perPage?: Array<number>;
  page: number;
  pageSize: number;
  ExtraPaginationInfo?: FC<any>;
  onChange: (pageInfo: PageInformation) => void;
  updateUrl?: boolean;
};

export default Pagination;
