import cloneDeep from "lodash/cloneDeep";
import { RetrievalState } from "src/constants";
import { HistoryEntry } from "src/api/interfaces/history";
import { ActionFunction, HistoryState } from "./types";

const setHistory: ActionFunction = (
  state: HistoryState,
  payload: {
    history: Array<HistoryEntry>;
    retrieveState: RetrievalState;
  }
) => {
  return {
    retrieveState: payload.retrieveState,
    history: cloneDeep(payload.history),
  };
};

export default setHistory;
