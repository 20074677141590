import axios, { AxiosResponse } from "axios";

import {
  GetComplianceUserActionsRequest,
  GetMetadataUserActionsRequest,
  GetUserActionsMetadataResponse,
  GetUserActionsResponse,
  GetValidationUserActionsRequest,
} from "src/api/interfaces/compliance_user_actions";
import {
  convertToInboundDocument,
  GetInboundDocumentRequest,
  GetInboundDocumentResponse,
  RevalidateDocumentRequest,
  RevalidateDocumentResponse,
  SaveOverriddenMetadataRequest,
  SaveOverriddenMetadataResponse,
} from "src/api/interfaces/inbound_document";
import {
  convertToRelatedDocuments,
  GetRelatedDocumentsResponse,
  GetRelatedDocumentsRequest,
  GetRelatedDocumentsByTypeResponse,
  convertToRelatesToDocuments,
} from "src/api/interfaces/related_documents";
import {
  GetDownloadDocumentRequest,
  GetDownloadDocumentResponse,
} from "src/api/interfaces/download_document";
import {
  GetValidationResultRequest,
  GetValidationResultResponse,
} from "src/api/interfaces/validation_result";
import {
  GetInboundComplianceGroupRequest,
  GetInboundComplianceGroupResponse,
  GetInboundComplianceGroupDetailsResponse,
  GetInboundComplianceGroupDetailsRequest,
} from "src/api/interfaces/inbound_compliance_group";
import {
  GetDocumentComplianceRequest,
  GetDocumentComplianceResponse,
} from "src/api/interfaces/document_validation";
import {
  GetDocumentsRequest,
  GetDocumentsResult,
} from "src/api/interfaces/document_listing";
import {
  BulkOperationsRequest,
  BulkOperationsResponse,
} from "src/api/interfaces/bulk_operations";
import {
  GetFilterOptionsRequest,
  GetFilterOptionsResponse,
} from "src/api/interfaces/filter_options";
import {
  awsIAMAuthAxiosInterceptor,
  awsJWTAuthAxiosInterceptor,
  awsJWTAuthErrorAxiosInterceptor,
} from "src/api/AWSAxiosInterceptor";
import {
  GetDocumentLineItemsRequest,
  GetDocumentLineItemsResponse,
} from "src/api/interfaces/line_item";
import {
  GetHistoryRequest,
  GetHistoryResponse,
} from "src/api/interfaces/history";
import { GetPermissionsResult } from "src/api/interfaces/permissions";

import { transformKeysCamelCaseToSnakeCase } from "src/utils";
import { log, LogLevel } from "../logger";
import { getApiBaseUrl, isLocal, isStandalone } from "./config";

axios.defaults.baseURL = getApiBaseUrl();
const DEFAULT_RESPONSE_INTERCEPTOR = (response: AxiosResponse): AxiosResponse =>
  response;

if (isLocal()) {
  axios.interceptors.request.use(awsIAMAuthAxiosInterceptor);
} else if (isStandalone()) {
  axios.interceptors.request.use(awsJWTAuthAxiosInterceptor);
  axios.interceptors.response.use(
    DEFAULT_RESPONSE_INTERCEPTOR,
    awsJWTAuthErrorAxiosInterceptor
  );
}

export const UNAUTHORIZED_HTTP_STATUS_CODE = 401;

const commonPath = "/inbound-central/api";

const client = {
  getPermissions: async (): Promise<GetPermissionsResult> => {
    log({
      level: LogLevel.INFO,
      message: "Fetching permissions",
      operationNamespace: "client.getPermissions",
    });
    return axios
      .get<RequestResultData>(`${commonPath}/permissions`, {
        baseURL: getApiBaseUrl(),
      })
      .then(({ data }: AxiosResponse): GetPermissionsResult => data);
  },
  getDocuments: async ({
    page,
    page_size,
    countryCode,
    filter,
  }: GetDocumentsRequest): Promise<GetDocumentsResult> => {
    const params = {
      page,
      page_size,
      country_code: countryCode,
      search: filter?.search,
      issuance_date: filter?.issuanceDate,
      start_issuance_date: filter?.startIssuanceDate?.slice(0, 10),
      end_issuance_date: filter?.endIssuanceDate?.slice(0, 10),
      use_cases: (filter?.useCase as string[])?.join(","),
      document_subtype: filter?.documentSubtype,
      document_type: filter?.documentType,
      statuses: (filter?.statuses as string[])?.join(","),
      validation_groups: (filter?.validationGroups as string[])?.join(","),
      orchestration_events: (filter?.orchestrationEvents as string[])?.join(
        ","
      ),
      document_ids: (filter?.documentId as string[])?.join(","),
      partner_codes: (filter?.partnerCode as string[])?.join(","),
      referenced_documents: (filter?.referencedDocument as string[])?.join(","),
      referenced_documents_is_empty: filter?.referencedDocumentsIsEmpty,
    };

    log({
      level: LogLevel.INFO,
      message: "Fetching documents",
      operationNamespace: "client.getDocuments",
      attributes: { params },
    });
    return axios
      .get<RequestResultData>(`${commonPath}/documents`, {
        baseURL: getApiBaseUrl(),
        params,
      })
      .then(({ data }: AxiosResponse): GetDocumentsResult => data);
  },
  getFilterOptions: async ({
    useCases,
    countryCode,
  }: GetFilterOptionsRequest): Promise<GetFilterOptionsResponse> => {
    const params = {
      use_cases: useCases?.join(","),
    };
    return axios
      .get<RequestResultData>(`${commonPath}/filter-options/${countryCode}`, {
        baseURL: getApiBaseUrl(),
        params,
      })
      .then(({ data }: AxiosResponse): GetFilterOptionsResponse => data);
  },
  getComplianceUserActions: async (
    request: GetComplianceUserActionsRequest
  ): Promise<GetUserActionsResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Fetching compliance user actions",
      operationNamespace: "client.getComplianceUserActions",
      attributes: { request },
    });
    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${request.country_code}/document-types/${request.document_type}` +
          `/documents/${request.document_id}/compliance/user-actions`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(
        ({ data }: AxiosResponse): GetUserActionsResponse => ({
          userActions: data,
        })
      );
  },
  getValidationUserActions: async (
    request: GetValidationUserActionsRequest
  ): Promise<GetUserActionsResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Fetching validation user actions",
      operationNamespace: "client.getValidationUserActions",
      attributes: { request },
    });
    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${request.country_code}/document-types/${request.document_type}` +
          `/documents/${request.document_id}/validation/user-actions`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(({ data }: AxiosResponse): GetUserActionsResponse => data);
  },
  getMetadataUserActions: async (
    request: GetMetadataUserActionsRequest
  ): Promise<{ [key: string]: any }> => {
    log({
      level: LogLevel.INFO,
      message: "Fetching metadata user actions",
      operationNamespace: "client.getMetadataUserActions",
      attributes: { request },
    });
    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${request.countryCode}/document-types/${request.documentType}` +
          `/document-subtypes/${request.documentSubtype}/use-cases/${request.useCase}/user-action/metadata`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(({ data }: AxiosResponse): GetUserActionsMetadataResponse => data);
  },
  getInboundDocument: async (
    request: GetInboundDocumentRequest
  ): Promise<GetInboundDocumentResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Fetching document details request",
      operationNamespace: "client.getInboundDocument",
      attributes: { request },
    });
    const { countryCode, documentType, documentId } = request;

    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(({ data }: AxiosResponse): GetInboundDocumentResponse => {
        return { inboundDocument: convertToInboundDocument(data) };
      });
  },
  getHistory: async (
    request: GetHistoryRequest
  ): Promise<GetHistoryResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Fetching history request",
      operationNamespace: "client.getHistory",
      attributes: { request },
    });
    const { countryCode, documentType, documentId } = request;

    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/history`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(({ data }: AxiosResponse): GetHistoryResponse => data);
  },
  getDocumentLineItems: async (
    request: GetDocumentLineItemsRequest
  ): Promise<GetDocumentLineItemsResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Fetching document line items request",
      operationNamespace: "client.getDocumentLineItems",
      attributes: { request },
    });
    const { countryCode, documentType, documentId } = request;

    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/line-items`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(({ data }: AxiosResponse): GetDocumentLineItemsResponse => data);
  },
  getRelatedDocuments: async (
    request: GetRelatedDocumentsRequest
  ): Promise<GetRelatedDocumentsResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Fetching related documents from a document",
      operationNamespace: "client.getRelatedDocuments",
      attributes: { request },
    });
    const { countryCode, documentType, documentId } = request;

    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/related-documents`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(
        ({ data }: AxiosResponse): GetRelatedDocumentsResponse => ({
          relatedDocuments: convertToRelatedDocuments(data),
          relatesToDocuments: convertToRelatesToDocuments(data),
        })
      );
  },
  getRelatedDocumentsByType: async (
    request: GetRelatedDocumentsRequest
  ): Promise<GetRelatedDocumentsByTypeResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Fetching related documents by type from a document",
      operationNamespace: "client.getRelatedDocumentsByType",
      attributes: { request },
    });
    const { countryCode, documentType, documentId, relatedDocumentType } =
      request;

    return axios
      .get<any>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/related-documents/${relatedDocumentType}`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(
        ({ data }: AxiosResponse): GetRelatedDocumentsByTypeResponse => data
      );
  },
  getInboundComplianceGroups: async (
    request: GetInboundComplianceGroupRequest
  ): Promise<GetInboundComplianceGroupResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Fetching inbound compliance group",
      operationNamespace: "client.getInboundComplianceGroups",
      attributes: { request },
    });
    const { countryCode, documentType, documentId, executionNumber } = request;
    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/executions/${executionNumber}/inbound-compliance`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(
        ({ data }: AxiosResponse): GetInboundComplianceGroupResponse => data
      );
  },
  getInboundComplianceGroupDetails: async (
    request: GetInboundComplianceGroupDetailsRequest
  ): Promise<GetInboundComplianceGroupDetailsResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Requesting inbound compliance group details",
      operationNamespace: "client.getInboundComplianceGroupDetails",
      attributes: { request },
    });
    const {
      countryCode,
      documentType,
      documentId,
      eventSubtype,
      executionNumber,
    } = request;

    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/executions/${executionNumber}/inbound-compliance/${eventSubtype}`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(
        ({
          data: { inboundComplianceGroupDetails },
        }: AxiosResponse): GetInboundComplianceGroupDetailsResponse => ({
          inboundComplianceGroupDetails,
        })
      );
  },
  getDocumentCompliance: async (
    request: GetDocumentComplianceRequest
  ): Promise<GetDocumentComplianceResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Fetching document validation",
      operationNamespace: "client.getDocumentValidation",
      attributes: { request },
    });
    const { countryCode, documentType, documentId, executionNumber } = request;

    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/executions/${executionNumber}/validations`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(({ data }: AxiosResponse): GetDocumentComplianceResponse => data);
  },
  revalidateDocument: async (
    request: RevalidateDocumentRequest
  ): Promise<RevalidateDocumentResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Starting Document Revalidation",
      operationNamespace: "client.revalidateDocument",
      attributes: { request },
    });
    const { countryCode, documentType, documentId } = request;

    return axios
      .post<RequestResultData>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/revalidate`,
        undefined,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(({ data }: AxiosResponse): RevalidateDocumentResponse => data);
  },
  saveOverriddenMetadata: async (
    request: SaveOverriddenMetadataRequest
  ): Promise<SaveOverriddenMetadataResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Starting Saving Overridden Metadata",
      operationNamespace: "client.saveOverriddenMetadata",
      attributes: { request },
    });
    const { countryCode, documentType, documentId } = request;

    return axios
      .post<RequestResultData>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/save-overridden-metadata`,
        request,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(({ data }: AxiosResponse): SaveOverriddenMetadataResponse => data);
  },
  getDownloadDocumentUrl: async (
    request: GetDownloadDocumentRequest
  ): Promise<GetDownloadDocumentResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Requesting document from Aduana",
      operationNamespace: "client.getDownloadDocumentUrl",
      attributes: { request },
    });
    const { countryCode, documentType, documentId } = request;

    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/download`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(({ data }: AxiosResponse): GetDownloadDocumentResponse => data);
  },
  getValidationResult: async (
    request: GetValidationResultRequest
  ): Promise<GetValidationResultResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Requesting validation result",
      operationNamespace: "client.getValidationResult",
      attributes: { request },
    });
    const {
      countryCode,
      documentType,
      documentId,
      validationGroup,
      executionNumber,
    } = request;

    return axios
      .get<RequestResultData>(
        `${commonPath}/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/executions/${executionNumber}/validations/${validationGroup}`,
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(
        ({
          data: { validationResults, exceedsSizeLimits },
        }: AxiosResponse): GetValidationResultResponse => ({
          validationResults,
          exceedsSizeLimits,
        })
      );
  },
  performBulkOperations: async (
    request: BulkOperationsRequest
  ): Promise<BulkOperationsResponse> => {
    log({
      level: LogLevel.INFO,
      message: "Starting Perform Bulk Operations",
      operationNamespace: "client.performBulkOperations",
      attributes: { request },
    });

    return axios
      .post<RequestResultData>(
        `${commonPath}/user-actions/perform-bulk-operation`,
        transformKeysCamelCaseToSnakeCase(request),
        {
          baseURL: getApiBaseUrl(),
        }
      )
      .then(({ data }: AxiosResponse): BulkOperationsResponse => data);
  },
};

export default client;
