import React, { FC } from "react";
import Field, { FieldDefinition } from "src/components/Field/Field";
import { getTranslationKeyFromValue } from "src/utils";
import { useTranslation } from "react-i18next";
import TranslationKey, {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";

const TRANSLATION_NOT_FOUND_VALUE = "";

export interface TranslatedFieldDefinition<ValueType, ChildComponentPropsType>
  extends FieldDefinition<ValueType, ChildComponentPropsType> {
  translationKey?: TranslationKey;
}

const TranslatedField: FC<TranslatedFieldDefinition<any, any>> = ({
  label,
  value,
  labelStyle,
  valueStyle,
  formatter,
  childComponentOnly,
  ChildComponent,
  childComponentProps,
  translationKey,
}) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const getFieldValue = () => {
    if (!value) {
      return TRANSLATION_NOT_FOUND_VALUE;
    }

    const translatedValue = translationKey?.translateValue(value, t);
    if (translatedValue) {
      return translatedValue;
    }

    return t([value, getTranslationKeyFromValue(value)], value);
  };

  return (
    <Field
      label={label}
      value={getFieldValue()}
      valueStyle={valueStyle}
      labelStyle={labelStyle}
      ChildComponent={ChildComponent}
      childComponentOnly={childComponentOnly}
      childComponentProps={childComponentProps}
      formatter={formatter}
    />
  );
};

export default TranslatedField;
