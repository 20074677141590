import React, { FC } from "react";
import ValidationSeverityDescriptionItem from "src/components/Pages/DocumentInbound/ValidationSeverityDescription/ValidationSeverityDescriptionItem";
import { ValidationRuleSeverity } from "src/api/interfaces/document_validation";
import classnames from "classnames";
import styles from "src/styles.module.scss";

const ValidationSeverityDescription: FC = () => {
  return (
    <div
      className={classnames(styles.display_flex, styles.flex_direction_column)}
    >
      <ValidationSeverityDescriptionItem
        severityType={ValidationRuleSeverity.CRITICAL}
      />
      <ValidationSeverityDescriptionItem
        severityType={ValidationRuleSeverity.ERROR}
      />
      <ValidationSeverityDescriptionItem
        severityType={ValidationRuleSeverity.WARNING}
      />
      <ValidationSeverityDescriptionItem
        severityType={ValidationRuleSeverity.INFO}
      />
    </div>
  );
};

export default ValidationSeverityDescription;
