import { createContext, Dispatch, SetStateAction } from "react";

export type MismatchTableEditItems = Map<number, Map<string, string>>;

interface MismatchTableEditItemsContextInterface {
  readonly itemOverrides: MismatchTableEditItems;
  readonly setItemOverrides: Dispatch<SetStateAction<MismatchTableEditItems>>;
}

export const MismatchTableEditItemsContext =
  createContext<MismatchTableEditItemsContextInterface>({
    itemOverrides: new Map(),
    setItemOverrides: () => {},
  });

const isNumber = (val: string) => !!val && !Number.isNaN(Number(val));

function extractLineItemNumber(lineItemFieldValue: string): number | undefined {
  if (isNumber(lineItemFieldValue)) {
    return Number(lineItemFieldValue);
  }
  const lineItemFieldValueSplit = lineItemFieldValue.split(":");
  if (
    lineItemFieldValueSplit.length > 1 &&
    isNumber(lineItemFieldValueSplit[1])
  ) {
    return Number(lineItemFieldValueSplit[1]);
  }

  return undefined;
}

export function extractEditsByProperty(
  poProperty: string,
  lineItemIndexProperty: string,
  tableEdits: MismatchTableEditItems
): Map<number, string> {
  const editedPropertyMap = new Map<number, string>();

  tableEdits.forEach((rowEdits) => {
    if (rowEdits.has(poProperty) && rowEdits.has(lineItemIndexProperty)) {
      const po: string = rowEdits.get(poProperty)!;
      const lineItemNumber = extractLineItemNumber(
        rowEdits.get(lineItemIndexProperty)!
      );

      if (lineItemNumber !== undefined) {
        editedPropertyMap.set(lineItemNumber, po);
      }
    }
  });

  return editedPropertyMap;
}
