import React, { FC } from "react";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { actionTranslation } from "src/components/Pages/DocumentList/BulkOperations/index";
import {
  BulkOperationsActions,
  BulkOperationsResponseErrorDetail,
} from "src/api/interfaces/bulk_operations";
import { KatLink, KatList } from "@amzn/katal-react";
import { WithTranslation, withTranslation } from "react-i18next";

const ACTION_LABEL =
  "aic_internal_bulk_operations_confirmation_modal_action_label";
const AMOUNT_LABEL =
  "aic_internal_bulk_operations_confirmation_modal_amount_label";
const SUCCESSFUL_PROCESSED_LABEL =
  "aic_internal_bulk_operations_confirmation_modal_response_successfully_processed_amount";
const AFFECTED_DOCUMENTS_LABEL =
  "aic_internal_bulk_operations_confirmation_modal_documents_label";
const ERRORED_DOCUMENTS_LABEL =
  "aic_internal_bulk_operations_confirmation_modal_response_errored_documents";

const buildDocumentLink = (
  countryCode: string,
  documentType: string,
  documentId: string
) => {
  const inboundKey = `${countryCode}::${documentType}::${documentId}`;
  return (
    <KatLink
      href={`/inbound-central/countries/${countryCode}/document-types/${documentType}/documents/${documentId}/validations`}
    >
      {inboundKey}
    </KatLink>
  );
};

const BulkOperationsConfirmationModalBody: FC<
  WithTranslation & BulkOperationsConfirmationModalBodyProps
> = ({
  t,
  action,
  countryCode,
  documentType,
  documentList,
  totalSuccessDocuments,
  errorDetails,
}) => {
  return (
    <>
      <div
        className={classnames(
          styles.ma_10,
          styles.flex_direction_column,
          styles.flex_nowrap
        )}
      >
        <div
          className={classnames(
            styles.display_flex,
            styles.flex_direction_row,
            styles.flex_no_wrap,
            styles.justify_space_between,
            styles.ma_10
          )}
        >
          <div>
            <b>{t(ACTION_LABEL)}:</b>{" "}
            {t(actionTranslation[action as BulkOperationsActions])}
          </div>
          <div>
            <b>{t(AMOUNT_LABEL)}: </b>
            {documentList.length}
          </div>
        </div>
        <div className={classnames(styles.ma_10)}>
          {totalSuccessDocuments !== undefined ? (
            <>
              <b>{t(SUCCESSFUL_PROCESSED_LABEL)}:</b> {totalSuccessDocuments}
            </>
          ) : (
            <b>{`${t(AFFECTED_DOCUMENTS_LABEL)}: `}</b>
          )}
        </div>
        <div className={classnames(styles.ma_10)}>
          {totalSuccessDocuments && errorDetails?.length ? (
            <b>{`${t(ERRORED_DOCUMENTS_LABEL, { action })}: `}</b>
          ) : null}
        </div>
        <div className={classnames(styles.ma_10)}>
          <KatList variant="unordered">
            {errorDetails
              ? errorDetails.map((details) => {
                  return (
                    <li
                      id={`bulk-operations-modal-document-listing-results-${details.documentId}`}
                      key={`bulk-operations-modal-document-listing-results-${details.documentId}`}
                    >
                      {buildDocumentLink(
                        countryCode,
                        documentType,
                        details.documentId
                      )}
                      : {t(details.errorMessage, details.errorAttributes)}
                    </li>
                  );
                })
              : documentList.map((documentId) => {
                  return (
                    <li
                      id={`bulk-operations-modal-document-listing-confirmation-${documentId}`}
                      key={`bulk-operations-modal-document-listing-confirmation-${documentId}`}
                    >
                      {buildDocumentLink(countryCode, documentType, documentId)}
                    </li>
                  );
                })}
          </KatList>
        </div>
      </div>
    </>
  );
};

export interface BulkOperationsConfirmationModalBodyProps {
  action: string;
  countryCode: string;
  documentType: string;
  documentList: string[];
  errorDetails?: BulkOperationsResponseErrorDetail[];
  totalSuccessDocuments?: number;
}

export default withTranslation()(BulkOperationsConfirmationModalBody);
