import { KatBadge } from "@amzn/katal-react";
import React, { FC, useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import DataTable from "src/components/DataTable";
import PaginationInMemory from "src/components/Pagination/PaginationInMemory";
import usePageInformation from "src/components/Pagination/hooks/usePageInformation";
import useDocumentLineItems from "src/components/Pages/DocumentInbound/Hooks/useDocumentLineItems";
import useUserActionsMetadata from "src/components/Pages/DocumentInbound/Hooks/useUserActionsMetadata";
import { PageInformation } from "src/api/interfaces/pagination";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { isEmpty } from "lodash";
import LineItemDataTableRow from "./LineItemDataTableRow";
import { buildTableDefinition } from "./document-items-data-table-definition";
import DocumentItemsTableFilterBar from "./DocumentItemsTableFilterBar";

const DocumentItemsTable: FC = () => {
  const [expandErrors, setExpandErrors] = useState(false);
  const { documentLineItems, retrieveState } = useDocumentLineItems();
  const { metadata } = useUserActionsMetadata();
  const [tablePage, setTablePage] = useState<Array<any>>();
  const [filteredLineItems, setFilteredLineItems] = useState(documentLineItems);
  const { pageInformation, setPageInformation } = usePageInformation({
    updateUrl: true,
  });
  const { pageSize, page } = pageInformation;
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  useEffect(() => {
    if (!isEmpty(documentLineItems)) {
      setFilteredLineItems(documentLineItems);
    }
  }, [documentLineItems]);

  const onChangePage = useCallback(
    (items: Array<any>, newPageInformation: PageInformation) => {
      setTablePage(items);
      setPageInformation(newPageInformation);
    },
    [setPageInformation]
  );

  const resetPageHandler = useCallback(() => {
    setPageInformation({ page: 1, pageSize });
  }, [pageSize, setPageInformation]);

  const ExtraPaginationInfo = () => (
    <KatBadge
      className={classnames([
        styles.capitalizedText,
        styles.absolute,
        styles.right_0,
        styles.kat_component_border_radius_0,
      ])}
      label={t("aic_doc_listing_pagination_current_results", {
        filteredResultsSize: filteredLineItems.length,
        totalResultsSize: documentLineItems.length,
      })}
    />
  );

  return (
    <>
      <DocumentItemsTableFilterBar
        documentLineItems={documentLineItems}
        setFilteredLineItems={setFilteredLineItems}
        expandErrors={expandErrors}
        expandErrorsHandler={setExpandErrors}
        resetPage={resetPageHandler}
      />
      <DataTable
        id="product-items"
        tableDefinition={buildTableDefinition(metadata, tablePage)}
        contents={tablePage}
        retrievalState={retrieveState}
        CustomDataTableRow={LineItemDataTableRow}
        customDataTableRowProps={{ expanded: expandErrors }}
      />
      <PaginationInMemory
        page={page}
        pageSize={pageSize}
        items={filteredLineItems}
        onChange={onChangePage}
        ExtraPaginationInfo={ExtraPaginationInfo}
        updateUrl
      />
    </>
  );
};

export default DocumentItemsTable;
