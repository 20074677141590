import React, { FC } from "react";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { LineItemRepresentation } from "src/api/interfaces/line_item";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { isEmpty } from "lodash";
import CopyToClipboardButton from "../../../CopyToClipboardButton";

const ItemIdLabel: FC<
  DataTableCellChildComponentProps<LineItemRepresentation>
> = ({ content }) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const { externalId } = content;
  const id = isEmpty(externalId)
    ? t("aic_doc_page_product_items_tab_not_applicable")
    : externalId;

  return (
    <div>
      {id}
      {!isEmpty(externalId) ? (
        <CopyToClipboardButton
          label="product-id"
          translationPrefix="aic_doc_page_product_items_tab_product_id"
          value={externalId}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ItemIdLabel;
