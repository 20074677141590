export enum Operation {
  ViewInboundDocumentDetails = "View Inbound Document Details",
  ViewInboundDocumentListing = "View Inbound Document Listing",
  DownloadInboundDocuments = "Download Inbound Documents",
  PerformValidationActions = "Perform Validation Actions",
  PerformComplianceUserActions = "Perform Compliance Actions",
  PerformCriticalActions = "Perform Critical Actions",
  PerformBulkOperations = "Perform Bulk Operations",
  PerformInternalActions = "Perform Internal Actions",
}

export interface PermissionResource {
  resourceName: string;
  operations: Operation[];
}

export interface CountryPermission {
  countryCode: string;
  resources: PermissionResource[];
}

export interface GetPermissionsResult {
  countriesPermission: CountryPermission[];
}

export interface Permissions {
  readonly permissions: Map<string, Map<string, Operation[]>>;
  setPermissions: (updates: any) => void;
}

export interface AuthorizationErrors {
  authorizationErrors: Set<string>;
  setAuthorizationErrors: (updates: any) => void;
}
