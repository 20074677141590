import React from "react";
import {
  KatTable,
  KatTableBody,
  KatTableCell,
  KatTableHead,
  KatTableRow,
} from "@amzn/katal-react";

export interface InfoTableProps {
  columns: string[];
  rows: Record<string, string>[];
}

const InfoTable = ({ columns, rows }: InfoTableProps) => {
  return (
    <KatTable>
      <KatTableHead>
        <KatTableRow>
          {columns.map((column) => (
            <KatTableCell>{column}</KatTableCell>
          ))}
        </KatTableRow>
      </KatTableHead>
      <KatTableBody>
        {rows.map((row) => (
          <KatTableRow>
            {Object.keys(row).map((column) => (
              <KatTableCell>{row[column]}</KatTableCell>
            ))}
          </KatTableRow>
        ))}
      </KatTableBody>
    </KatTable>
  );
};

export default InfoTable;
