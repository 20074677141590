import React, { FC, ReactNode, useCallback, useState } from "react";
import {
  InboundDocument,
  InboundStatus,
} from "src/api/interfaces/inbound_document";

export const DEFAULT_INBOUND_DOCUMENT: InboundDocument = {
  countryCode: "",
  documentId: "",
  documentSubtype: "",
  documentType: "",
  useCase: "",
  executionNumber: 1,
  inboundExecutionNumber: 1,
  documentDetails: {
    documentType: "",
    officialDocumentId: "",
  },
  metadataOverrides: {},
  status: InboundStatus.INGESTED,
  setInboundDocument: () => {},
};

export const InboundDocumentContext = React.createContext<InboundDocument>(
  DEFAULT_INBOUND_DOCUMENT
);

export const useInboundDocument = () =>
  React.useContext(InboundDocumentContext);

export const InboundDocumentContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [inboundDocument, setInboundDocument] = useState<InboundDocument>(
    DEFAULT_INBOUND_DOCUMENT
  );

  // here context value is just returning an object, but only
  // re-creating the object when its dependencies change ([state, setContext])
  const getInboundDocumentContextValue = useCallback(
    () => ({
      ...inboundDocument,
      setInboundDocument,
    }),
    [inboundDocument, setInboundDocument]
  );
  return (
    <InboundDocumentContext.Provider value={getInboundDocumentContextValue()}>
      {children}
    </InboundDocumentContext.Provider>
  );
};
