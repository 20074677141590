import React, { FC, useState, useMemo, useCallback } from "react";
import { KatButton } from "@amzn/katal-react";
import handleGetDownloadDocumentUrl from "src/components/Pages/DocumentInbound/Tabs/Documents/DownloadDocumentButton/Handlers/get_download_document_url_handler";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import {
  InboundDocument,
  InboundStatus,
} from "src/api/interfaces/inbound_document";
import { useAlertContext } from "src/components/Alert/AlertContext";
import { alertHandler } from "src/components/Alert/handlers";
import withPermissionsOrDefault from "src/components/AICLayout/AICHeader/Permissions/WithPermissions";
import { Operation } from "src/api/interfaces/permissions";
import { useTranslation } from "react-i18next";
import { INITIAL_STATE, LOADING_STATE } from "./states";

const DOWNLOAD_BUTTON_STRING_TEMPLATE =
  "aic_doc_page_documents_tab_field_button_download";

const shouldShowButton = (inboundContent: InboundDocument) => {
  return inboundContent.status !== InboundStatus.NOT_INGESTED;
};

const DownloadDocumentButton: FC<DownloadDocumentButtonProps> = ({
  content,
}) => {
  const { t } = useTranslation();
  const [buttonState, setButtonState] = useState(INITIAL_STATE);
  const { setAlerts, alerts } = useAlertContext();

  const handleClick = useCallback(() => {
    setButtonState(LOADING_STATE);
    handleGetDownloadDocumentUrl(content)
      .then((getDownloadDocumentResponse) => {
        setButtonState(INITIAL_STATE);
        alertHandler.createNewAlertEntry({
          alerts,
          setAlerts,
          header: t("aic_doc_page_button_download_header_success"),
          fade: true,
          isSuccess: true,
          text: t("aic_doc_page_button_download_text_success"),
        });
        window.open(getDownloadDocumentResponse.s3PresignedUrl, "about:blank");
      })
      .catch(() => {
        alertHandler.createNewAlertEntry({
          alerts,
          setAlerts,
          header: t("aic_doc_page_button_download_header_error"),
          fade: true,
          isSuccess: false,
          text: t("aic_doc_page_button_download_text_error"),
        });
        setButtonState(INITIAL_STATE);
      });
  }, [content, t, setButtonState, alerts, setAlerts]);

  const actionComponent = useMemo(
    () =>
      shouldShowButton(content) && (
        <KatButton
          id={`download-document-button-${content.documentId}`}
          label={t(DOWNLOAD_BUTTON_STRING_TEMPLATE)}
          type="button"
          variant={buttonState.variant}
          onClick={handleClick}
          disabled={buttonState.loading}
          loading={buttonState.loading}
        />
      ),
    [content, buttonState, t, handleClick]
  );

  return <>{actionComponent}</>;
};

export type DownloadDocumentButtonProps =
  DataTableCellChildComponentProps<InboundDocument>;

export default withPermissionsOrDefault(
  DownloadDocumentButton,
  Operation.DownloadInboundDocuments
);
