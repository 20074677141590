import { ComplianceUserActionType } from "src/components/UserActions/types";
import { ComplianceUserActionHandler } from "src/components/UserActions/Handlers/Compliance/types";
import handleRevalidate from "src/components/UserActions/Handlers/Compliance/revalidate_handler";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import handleForceAccept from "src/components/UserActions/Handlers/Compliance/force_accept_handler";
import handleForceReject from "src/components/UserActions/Handlers/Compliance/force_reject_handler";

const handlersByAction: Map<string, ComplianceUserActionHandler> = new Map()
  .set(ComplianceUserActionType.FORCE_ACCEPT, handleForceAccept)
  .set(ComplianceUserActionType.FORCE_REJECT, handleForceReject)
  .set(ComplianceUserActionType.REVALIDATE, handleRevalidate);

const handleComplianceUserAction = async (
  documentDetails: InboundDocument,
  action: string
) => {
  const handler: ComplianceUserActionHandler | undefined =
    handlersByAction.get(action);

  if (handler) {
    return handler(documentDetails);
  }
  return false;
};

export default handleComplianceUserAction;
