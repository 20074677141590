import React, { FC } from "react";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import TranslatedSelectInput from "src/components/Inputs/TranslatedSelectInput";
import { useFilterOptions } from "src/components/Contexts/FilterOptions/Provider";
import {
  DomainName,
  FilterActions,
  FilterValue,
} from "src/components/Contexts/FilterOptions/types";
import { useHistory } from "react-router-dom";
import { updateUrlParams } from "src/utils";
import IssuanceDateFilter from "./IssuanceDateFilter";

const QuickFilters: FC = () => {
  const {
    state: {
      optionValues: { statuses: statusesOptions },
      selectedValues: { statuses },
    },
    dispatch,
  } = useFilterOptions();
  const history = useHistory();

  const onChangeHandler = (domainName: DomainName) => {
    return (value?: FilterValue) => {
      updateUrlParams([[domainName, value]], history);
      dispatch({
        type: FilterActions.SET_SELECTED_VALUES,
        payload: {
          domainName,
          value,
        },
      });
    };
  };

  return (
    <div
      className={classnames(
        styles.flex,
        styles.flex_direction_row,
        styles.flex_nowrap,
        styles.align_items_start,
        styles.justify_start,
        styles.mr_8
      )}
    >
      <TranslatedSelectInput
        translationPrefix="aic_filter_bar_quick_filter_status"
        onChange={onChangeHandler(DomainName.STATUSES)}
        initialValue={statuses}
        options={statusesOptions}
        classname={classnames(styles.mr_8, styles.w_210_px)}
        multiple
      />

      <IssuanceDateFilter />
    </div>
  );
};

export default QuickFilters;
