/* istanbul ignore file */
import KatalLogger, { Level } from "@amzn/katal-logger";
import { KatalReplacement } from "./constants";

export enum LogLevel {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

interface LogEntryPayload {
  datetime: string;
  service: string;
  operationNamespace: string;
  level: LogLevel;
  message: string;
  requestId: string;
  flowId: string;
  inboundId?: string;
  inboundKey?: string;
  splitedInboundKey?: string;
  internalServiceId?: string;
  action?: string;
  exception?: string;
  processingTime?: string;
  attributes?: {
    [key: string]: any;
  };
}

const katalLoggerConfig = {
  url: KatalReplacement.KATAL_LOGGER_URL,
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: false,
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();

export function log(logPayload: Partial<LogEntryPayload>) {
  const message = JSON.stringify({
    datetime: new Date(Date.now()).toISOString(),
    service: "AICPortalWebsite",
    ...logPayload,
  });
  switch (logPayload.level) {
    case LogLevel.WARNING:
      logger.warn(message);
      return;
    case LogLevel.ERROR:
      logger.error(message);
      return;
    case LogLevel.INFO:
    default:
      logger.info(message);
  }
}
