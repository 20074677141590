import { FiltersDefinition } from "./definition";
import { DomainName, FilterOptionsState, FilterValue } from "./types";

type OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
) => FilterOptionsState;

const PATH_SEPARATOR = "::";

const nonDependentFilterHandler = (
  state: FilterOptionsState,
  domainName: DomainName,
  value?: FilterValue
): FilterOptionsState => {
  return {
    ...state,
    selectedValues: {
      ...state.selectedValues,
      [domainName]:
        value ?? FiltersDefinition[domainName].defaultSelectedValues,
    },
  };
};

const dependentFilterHandler = (
  state: FilterOptionsState,
  path: string,
  dependents: DomainName[]
): FilterOptionsState => {
  const newState = {
    ...state,
    selectedValues: { ...state.selectedValues },
    optionValues: { ...state.optionValues },
  };

  const firstChild = dependents[0];

  // set the filter options only for first dependent filter
  newState.selectedValues[firstChild] =
    FiltersDefinition[firstChild].defaultSelectedValues;
  newState.optionValues[firstChild] =
    newState.filterableData[path] ??
    FiltersDefinition[firstChild].defaultOptionValues;

  // for other dependents, we just reset the selected values and options values
  dependents.slice(1).forEach((child) => {
    const definition = FiltersDefinition[child];
    newState.selectedValues[child] = definition.defaultSelectedValues;
    newState.optionValues[child] = definition.defaultOptionValues!;
  });

  return newState;
};

const dependentFilterHandlerWithMultiplePaths = (
  state: FilterOptionsState,
  paths: string[],
  dependents: DomainName[]
): FilterOptionsState => {
  const newState = {
    ...state,
    selectedValues: { ...state.selectedValues },
    optionValues: { ...state.optionValues },
  };

  dependents.forEach((dependent) => {
    newState.selectedValues[dependent] =
      FiltersDefinition[dependent].defaultSelectedValues;
    newState.optionValues[dependent] = paths
      .filter((path) => newState.filterableData[path])
      .flatMap((path) => newState.filterableData[path]);
  });

  return newState;
};

const documentTypeHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
) => {
  const domainName = DomainName.DOCUMENT_TYPE;
  const dependents = [
    DomainName.DOCUMENT_SUBTYPE,
    DomainName.USE_CASE,
    DomainName.VALIDATION_GROUPS,
    DomainName.ORCHESTRATION_EVENTS,
  ];
  const path = [value, DomainName.DOCUMENT_SUBTYPE].join(PATH_SEPARATOR);

  const newState = dependentFilterHandler(state, path, dependents);

  newState.selectedValues[domainName] =
    value ?? FiltersDefinition[domainName].defaultSelectedValues;

  return newState;
};

const documentSubtypeHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  const domainName = DomainName.DOCUMENT_SUBTYPE;
  const dependents = [
    DomainName.USE_CASE,
    DomainName.VALIDATION_GROUPS,
    DomainName.ORCHESTRATION_EVENTS,
  ];
  const path = [
    state.selectedValues[DomainName.DOCUMENT_TYPE],
    value,
    DomainName.USE_CASE,
  ].join(PATH_SEPARATOR);

  const newState = dependentFilterHandler(state, path, dependents);

  newState.selectedValues[domainName] =
    value ?? FiltersDefinition[domainName].defaultSelectedValues;

  return newState;
};

const useCaseHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  const domainName = DomainName.USE_CASE;
  const dependents = [
    DomainName.VALIDATION_GROUPS,
    DomainName.ORCHESTRATION_EVENTS,
  ];

  let newState = state;
  dependents.forEach((dependent) => {
    const paths = (value as string[]).map((useCase) => {
      return [
        state.selectedValues[DomainName.DOCUMENT_TYPE],
        state.selectedValues[DomainName.DOCUMENT_SUBTYPE],
        useCase,
        dependent,
      ].join(PATH_SEPARATOR);
    });

    newState = dependentFilterHandlerWithMultiplePaths(newState, paths, [
      dependent,
    ]);
  });

  newState.selectedValues[domainName] =
    value ?? FiltersDefinition[domainName].defaultSelectedValues;

  return newState;
};

const documentIdHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  return nonDependentFilterHandler(state, DomainName.DOCUMENT_ID, value);
};

const issuanceDateHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  return nonDependentFilterHandler(state, DomainName.ISSUANCE_DATE, value);
};

const startIssuanceDateHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  return nonDependentFilterHandler(
    state,
    DomainName.START_ISSUANCE_DATE,
    value
  );
};

const endIssuanceDateHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  return nonDependentFilterHandler(state, DomainName.END_ISSUANCE_DATE, value);
};

const orchestrationEventsHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  return nonDependentFilterHandler(
    state,
    DomainName.ORCHESTRATION_EVENTS,
    value
  );
};

const partnerCodeHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  return nonDependentFilterHandler(state, DomainName.PARTNER_CODE, value);
};

const referencedDocumentHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  return nonDependentFilterHandler(
    state,
    DomainName.REFERENCED_DOCUMENT,
    value
  );
};

const referencedDocumentsIsEmptyHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  return nonDependentFilterHandler(
    state,
    DomainName.REFERENCED_DOCUMENTS_IS_EMPTY,
    value
  );
};

const searchHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  return nonDependentFilterHandler(state, DomainName.SEARCH, value);
};

const statusesHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  return nonDependentFilterHandler(state, DomainName.STATUSES, value);
};

const validationGroupsHandler: OnChangeHandler = (
  state: FilterOptionsState,
  value?: FilterValue
): FilterOptionsState => {
  return nonDependentFilterHandler(state, DomainName.VALIDATION_GROUPS, value);
};

const onChangeHandlerByFilter: Record<DomainName, OnChangeHandler> = {
  [DomainName.DOCUMENT_ID]: documentIdHandler,
  [DomainName.DOCUMENT_SUBTYPE]: documentSubtypeHandler,
  [DomainName.DOCUMENT_TYPE]: documentTypeHandler,
  [DomainName.END_ISSUANCE_DATE]: endIssuanceDateHandler,
  [DomainName.ISSUANCE_DATE]: issuanceDateHandler,
  [DomainName.ORCHESTRATION_EVENTS]: orchestrationEventsHandler,
  [DomainName.PARTNER_CODE]: partnerCodeHandler,
  [DomainName.REFERENCED_DOCUMENT]: referencedDocumentHandler,
  [DomainName.REFERENCED_DOCUMENTS_IS_EMPTY]: referencedDocumentsIsEmptyHandler,
  [DomainName.SEARCH]: searchHandler,
  [DomainName.STATUSES]: statusesHandler,
  [DomainName.START_ISSUANCE_DATE]: startIssuanceDateHandler,
  [DomainName.USE_CASE]: useCaseHandler,
  [DomainName.VALIDATION_GROUPS]: validationGroupsHandler,
};

export default onChangeHandlerByFilter;
