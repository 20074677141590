import React, { FC } from "react";
import DocumentDetailCard from "src/components/Pages/DocumentInbound/Header/DocumentDetailsCard";
import AgentCard from "src/components/Pages/DocumentInbound/Header/AgentCard";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import {
  DocumentDetails,
  MetadataOverrides,
} from "src/api/interfaces/inbound_document";

const DocumentDetailHeader: FC<DocumentDetailsHeaderProps> = ({
  documentDetails,
  metadataOverrides,
}) => {
  return (
    <div
      className={classnames(
        styles.flex,
        styles.flex_direction_row,
        styles.flex_nowrap,
        styles.w_80,
        styles.justify_start,
        styles.align_items_stretch,
        styles.mt_6
      )}
    >
      <DocumentDetailCard
        documentDetails={documentDetails}
        metadataOverrides={metadataOverrides}
        className={classnames(styles.mr_16, styles.min_w_25)}
      />
      {documentDetails.issuer ? (
        <AgentCard
          agent={documentDetails.issuer}
          className={classnames(styles.mr_16, styles.min_w_25)}
        />
      ) : null}
      {documentDetails.recipient ? (
        <AgentCard
          agent={documentDetails.recipient}
          className={styles.min_w_25}
        />
      ) : null}
    </div>
  );
};

export interface DocumentDetailsHeaderProps {
  documentDetails: DocumentDetails;
  metadataOverrides?: MetadataOverrides;
}

export default DocumentDetailHeader;
