import { UserAction } from "src/components/UserActions/types";
import { Dispatch, SetStateAction } from "react";

export interface DocumentCompliance {
  readonly validationGroups: Array<ValidationGroup>;
  readonly userActionsByValidationGroup: { [key: string]: Array<UserAction> };
  readonly setDocumentCompliance: Dispatch<SetStateAction<DocumentCompliance>>;
  readonly responseReceived: boolean;
}

export interface ValidationGroup {
  readonly countryCode: string;
  readonly documentId: string;
  readonly documentType: string;
  readonly executionNumber: number;
  readonly validationGroupStatus: ValidationGroupStatus;
  readonly validationGroupId: string;
  readonly validationGroupName?: string;
  readonly description: string;
  readonly details?: string;
}

export enum ValidationGroupStatus {
  CRITICAL = "Critical",
  ERROR = "Error",
  INFO = "Info",
  PENDING = "Pending",
  SEVERE_ERROR = "SevereError",
  SKIPPED = "Skipped",
  SUCCESS = "Success",
}

export enum ValidationRuleSeverity {
  CRITICAL = "CRITICAL",
  INFO = "INFO",
  ERROR = "ERROR",
  SEVERE = "SEVERE",
  WARNING = "WARNING",
}

export interface GetDocumentComplianceRequest {
  readonly countryCode: string;
  readonly documentType: string;
  readonly documentId: string;
  readonly executionNumber: number;
}

export interface GetDocumentComplianceResponse {
  readonly validationGroups: Array<ValidationGroup>;
}
