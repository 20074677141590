import { MatchingRule } from "src/api/interfaces/validation_result";
import { get } from "lodash";

type DefaultRuleDetailsApplier = {
  (details: string, attributes: string, t: any): string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultDetails = (details: string, attributes: string, t: any) => details;

const casePackMatchDetails: DefaultRuleDetailsApplier = (
  details: string,
  attributes: string,
  t: any
): string => {
  const { metadata } = JSON.parse(attributes);
  const { casePackAttributes } = metadata;
  if (!casePackAttributes) {
    return details;
  }

  const conversionFactor = casePackAttributes?.conversionFactor;

  return `${details} - ${t("aic_matching_rule_details_case_pack_match", {
    conversionFactor,
  })}`;
};

const DEFAULT_RULE_DETAILS_MAP: PartialRecord<
  MatchingRule,
  DefaultRuleDetailsApplier
> = {
  [MatchingRule.CASE_PACK_MATCH]: casePackMatchDetails,
};

export const getRuleDetailsApplier = (rule: string) => {
  const applier = get(
    DEFAULT_RULE_DETAILS_MAP,
    rule as MatchingRule,
    defaultDetails
  );

  return applier;
};

export default DEFAULT_RULE_DETAILS_MAP;
