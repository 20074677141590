import { Dispatch, SetStateAction } from "react";
import { RetrievalState } from "src/constants";

export interface InboundCompliance {
  readonly inboundComplianceGroups: Array<InboundComplianceGroup>;
  readonly setInboundComplianceGroups: Dispatch<
    SetStateAction<InboundCompliance>
  >;
}

export interface GetInboundComplianceGroupRequest {
  readonly countryCode: string;
  readonly documentType: string;
  readonly documentId: string;
  readonly executionNumber: number;
}

export interface GetInboundComplianceGroupResponse {
  readonly inboundComplianceGroups: Array<InboundComplianceGroup>;
}

export interface GetInboundComplianceGroupDetailsRequest {
  readonly documentId: string;
  readonly documentType: string;
  readonly countryCode: string;
  readonly executionNumber: number;
  readonly eventSubtype: string;
}

export interface GetInboundComplianceGroupDetailsResponse {
  readonly inboundComplianceGroupDetails: InboundComplianceGroupDetail[];
}

export interface InboundComplianceGroup {
  readonly countryCode: string;
  readonly documentId: string;
  readonly documentType: string;
  readonly executionNumber: number;
  readonly status: InboundComplianceGroupStatus;
  readonly eventSubtype: string;
  readonly name?: string;
  readonly description: string;
  readonly descriptionParameters?: Record<string, string>;
}

export enum InboundComplianceGroupStatus {
  ERROR = "Error",
  PENDING = "Pending",
  SEVERE = "Severe",
  SKIPPED = "Skipped",
  SUCCESS = "Success",
}

export interface InboundComplianceGroupDetail {
  readonly itemId: string;
  readonly fields: Record<string, any>[];
}

export interface InboundComplianceGroupDetails {
  readonly groupDetailsRetrievalState?: RetrievalState;
  readonly inboundComplianceGroupDetails: InboundComplianceGroupDetail[];
  readonly inMemoryInboundComplianceGroupDetails: InboundComplianceGroupDetail[];
  readonly setInboundComplianceGroupDetails: (updates: any) => void;
}

export interface DynamicTableData {
  rowData: Record<string, string>[];
}
