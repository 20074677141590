import { useContext, useEffect } from "react";
import { AxiosError } from "axios";
import client from "src/api/client";
import { log, LogLevel } from "src/logger";
import { useInboundDocument } from "src/components/Contexts/InboundDocumentContext";
import { UserActionsMetadataContext } from "src/components/Contexts/UserActionsMetadata/Provider";
import {
  GetMetadataUserActionsRequest,
  GetUserActionsMetadataResponse,
} from "src/api/interfaces/compliance_user_actions";
import {
  UserActionsMetadataActions,
  UserActionsMetadataState,
} from "src/components/Contexts/UserActionsMetadata/types";

const useUserActionsMetadata = (): UserActionsMetadataState => {
  const { state, dispatch } = useContext(UserActionsMetadataContext);
  const { countryCode, documentType, useCase, documentSubtype } =
    useInboundDocument();

  useEffect(() => {
    const request: GetMetadataUserActionsRequest = {
      countryCode,
      documentType,
      documentSubtype,
      useCase,
    };
    client
      .getMetadataUserActions(request)
      .then((data: GetUserActionsMetadataResponse) => {
        log({
          level: LogLevel.INFO,
          message: "User actions metadata completed successfully",
          operationNamespace: "useUserActionsMetadata.getMetadataUserActions",
        });
        dispatch({
          type: UserActionsMetadataActions.ADD,
          payload: {
            metadata: data,
          },
        });
      })
      .catch((error: AxiosError) => {
        log({
          level: LogLevel.ERROR,
          message:
            "There was an error while trying to fetch user actions metadata",
          operationNamespace: "useUserActionsMetadata.getMetadataUserActions",
          exception: error?.message,
          attributes: { request },
        });
      });
  }, [countryCode, dispatch, documentSubtype, documentType, useCase]);
  return state;
};

export default useUserActionsMetadata;
