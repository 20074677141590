import React, { FC } from "react";
import {
  UserAction,
  ValidationUserActionType,
} from "src/components/UserActions/types";
import { ViewUserActionMetadataEntry } from "src/components/UserActions/Handlers/Validation/types";
import MismatchTableRenderComponent from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/MismatchTableRenderComponent";
import LineItemMismatchTableRenderComponent from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/LineItem/LineItemMismatchTableRenderComponent";

export interface ValidationModalRenderComponentProps {
  readonly userAction?: UserAction;
  readonly validationGroupId: string;
}

const VIEW_RENDER_COMPONENT_BY_ATTRIBUTE = new Map<
  ViewUserActionMetadataEntry,
  FC<ValidationModalRenderComponentProps>
>()
  .set(ViewUserActionMetadataEntry.Default, MismatchTableRenderComponent)
  .set(
    ViewUserActionMetadataEntry.LineItemMismatch,
    LineItemMismatchTableRenderComponent
  );

const ValidationModalRenderComponent: FC<
  ValidationModalRenderComponentProps
> = ({ userAction, validationGroupId }) => {
  const mappedComponent =
    userAction?.type === ValidationUserActionType.VIEW &&
    VIEW_RENDER_COMPONENT_BY_ATTRIBUTE.get(userAction?.metadata?.attribute);

  const RenderComponent = mappedComponent || MismatchTableRenderComponent;

  return (
    <div>
      <RenderComponent
        validationGroupId={validationGroupId}
        userAction={userAction}
      />
    </div>
  );
};

export default ValidationModalRenderComponent;
