import React, { FC, useCallback, useMemo } from "react";
import { KatLink } from "@amzn/katal-react";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import {
  InboundDocument,
  InboundStatus,
} from "src/api/interfaces/inbound_document";

export type DocumentIdCellProps =
  DataTableCellChildComponentProps<InboundDocument>;

const DocumentIdCell: FC<DocumentIdCellProps> = ({ content }) => {
  const href = useMemo(
    () =>
      `/inbound-central/countries/${content.countryCode}/document-types/${content.documentType}/documents/${content.documentId}`,
    [content]
  );

  const shouldGenerateLink = useCallback(() => {
    return content.status !== InboundStatus.NOT_INGESTED;
  }, [content]);

  return (
    <div>
      {useMemo(
        () =>
          shouldGenerateLink() ? (
            <KatLink
              label={content.documentId}
              target="_blank"
              href={href}
              rel="noreferrer"
            />
          ) : (
            content.documentId
          ),
        [content, shouldGenerateLink, href]
      )}
    </div>
  );
};

export default DocumentIdCell;
