import React, { FC } from "react";
import { UserAction } from "src/components/UserActions/types";
import styles from "src/components/UserActions/styles.module.scss";
import { KatDropdownButton } from "@amzn/katal-react";
import { useTranslation } from "react-i18next";
import { getTranslationKeyFromValue } from "src/utils";
import { useInboundDocument } from "../Contexts/InboundDocumentContext";
import useUserActionsMetadata from "../Pages/DocumentInbound/Hooks/useUserActionsMetadata";
import { get } from "lodash";

export interface KatalDropdownButtonOption {
  readonly label: string;
  readonly action?: string;
}

const USER_ACTIONS_DROPDOWN_OPTION: KatalDropdownButtonOption = {
  label: "aic_doc_page_user_action_dropdown_actions",
};

export interface ValidationUserActionsDropdownProps {
  readonly onActionSelected: (userAction: UserAction) => void;
  readonly userActions: UserAction[] | undefined;
}

export const ValidationUserActionsDropdown: FC<
  ValidationUserActionsDropdownProps
> = (props) => {
  const { t } = useTranslation();
  const { userActions, onActionSelected } = props;
  const documentDetails = useInboundDocument();
  const { metadata } = useUserActionsMetadata();

  const onAction = (event: KatDropdownButton.ActionEvent) => {
    event.preventDefault();
    const {
      detail: { action },
    } = event;

    if (action && userActions) {
      onActionSelected(userActions.filter(({ name }) => name === action)[0]);
    }
  };

  const shouldDisableActionButton = () => {
    const defaultActionWindow = "18"; // 18 months is the maximum time after the issuance of the document to perform any action
    const actionWindow = Number.parseInt(
      get(metadata, "actionWindowTime", defaultActionWindow)
    );

    const issuanceDate = new Date(
      Date.parse(documentDetails.documentDetails.issuanceDate!)
    );
    issuanceDate.setMonth(issuanceDate.getMonth() + actionWindow);

    return !userActions || issuanceDate.getTime() <= Date.now();
  };

  const computeOptions = (): KatalDropdownButtonOption[] => {
    const options: KatalDropdownButtonOption[] = [USER_ACTIONS_DROPDOWN_OPTION];

    userActions?.forEach((userAction) => {
      options.push({
        action: userAction.name,
        label: userAction.name || "",
      });
    });

    const translateLabel = (label: string): string => {
      const translationKey = getTranslationKeyFromValue(label);
      const translatedValue = t(translationKey);
      return translatedValue === translationKey ? t(label) : translatedValue;
    };

    return options.map((action) => ({
      action: action.action,
      label: translateLabel(action.label),
    }));
  };

  return (
    <KatDropdownButton
      id="document-compliance-user-actions"
      className={styles.compliance_actions_dropdown_button}
      options={computeOptions()}
      onAction={onAction}
      disabled={shouldDisableActionButton()}
      variant="secondary"
      size="base"
    />
  );
};
