import React, { FC } from "react";
import { KatDivider } from "@amzn/katal-react";
import classnames from "classnames";
import styles from "src/styles.module.scss";

export type SideBarHeaderProps = {
  title: string;
};

const SideBarHeader: FC<SideBarHeaderProps> = ({ title }) => {
  return (
    <header className={classnames(styles.ma_16)}>
      <h4>{title}</h4>
      <KatDivider variant="athens" />
    </header>
  );
};

export default SideBarHeader;
