import React, { FC, ReactNode, useCallback, useState } from "react";
import { PageInformation, Pagination } from "src/api/interfaces/pagination";
import { getUrlParam } from "src/utils";

export const PAGE_QS_KEY = "page";
export const PAGE_SIZE_QS_KEY = "pageSize";

export const DEFAULT_PAGE_INFORMATION: PageInformation = {
  page: 1,
  pageSize: 10,
  total: 0,
};

export const PER_PAGE_OPTIONS = [5, 10, 20, 50, 100];

const DEFAULT_PAGINATION: Pagination = {
  pageInformation: DEFAULT_PAGE_INFORMATION,
  setPagination: () => {},
};

export const PaginationContext =
  React.createContext<Pagination>(DEFAULT_PAGINATION);

export const usePagination = () => React.useContext(PaginationContext);

export const PaginationContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const page = Number(getUrlParam(PAGE_QS_KEY));
  const pageSize = Number(getUrlParam(PAGE_SIZE_QS_KEY));

  const [pagination, setPagination] = useState<Pagination>({
    ...DEFAULT_PAGINATION,
    pageInformation: {
      page: page || DEFAULT_PAGE_INFORMATION.page,
      pageSize: pageSize || DEFAULT_PAGE_INFORMATION.pageSize,
      total: DEFAULT_PAGE_INFORMATION.total,
    },
  });

  // here context value is just returning an object, but only
  // re-creating the object when its dependencies change ([state, setContext])
  const getPaginationContextValue = useCallback(
    () => ({
      ...pagination,
      setPagination,
    }),
    [pagination, setPagination]
  );
  return (
    <PaginationContext.Provider value={getPaginationContextValue()}>
      {children}
    </PaginationContext.Provider>
  );
};
