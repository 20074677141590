import React, { FC } from "react";
import Field, { FieldDefinition } from "src/components/Field/Field";
import TranslatedField from "src/components/TranslatedField/TranslatedField";
import { v1 } from "uuid";

export enum CardFieldSlot {
  BODY = "body",
  TITLE = "title",
  SUBTITLE = "subtitle",
  FOOTER = "footer",
  ACTION = "action",
}

export interface CardFieldProps<ValueType, ChildComponentPropsType>
  extends FieldDefinition<ValueType, ChildComponentPropsType> {
  slot: CardFieldSlot;
  translateValue?: boolean;
}

const CardField: FC<CardFieldProps<any, any>> = ({
  label,
  value,
  labelStyle,
  valueStyle,
  formatter,
  translateValue,
  childComponentOnly,
  ChildComponent,
  slot,
}) => {
  const fieldProps: FieldDefinition<any, any> = {
    label,
    value,
    valueStyle,
    labelStyle,
    formatter,
    ChildComponent,
    childComponentOnly,
  };
  const field = translateValue ? (
    <TranslatedField {...fieldProps} />
  ) : (
    <Field {...fieldProps} />
  );

  return slot.valueOf() === CardFieldSlot.BODY.valueOf() ? (
    <div key={`card-${value}-${v1()}`}>{field}</div>
  ) : (
    <div slot={slot} key={`card-${value}-${v1()}`}>
      {field}
    </div>
  );
};

export default CardField;
