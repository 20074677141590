enum AgentTypes {
  Issuer = "Issuer",
  Recipient = "Recipient",
}

export enum InboundStatus {
  // Ingestor statuses
  INGESTED = "ingested",
  UNALLOCATED = "unallocated",
  // DCO statuses
  ACCEPTED = "accepted",
  PENDING_ACTIONS = "pending actions",
  REJECTED = "rejected",
  TEMPORARILY_REJECTED = "temporarily rejected",
  // ICO statuses
  CANCELED = "canceled",
  FINALIZED = "finalized",
  PROCESSING = "processing",
  SKIPPED = "skipped",
  // Portal statuses
  NOT_INGESTED = "not ingested",
}

export enum InboundDocumentTabs {
  Validations = "validations",
  InboundCompliance = "inbound-compliance",
  Documents = "related-documents",
  ProductItems = "product-items",
  History = "history",
  OperationalActions = "ops-actions",
}

export interface InboundDocumentPageRequest {
  readonly countryCode: string;
  readonly documentType: string;
  readonly documentId: string;
  readonly inboundDocumentTab: string;
}

export interface GetInboundDocumentRequest {
  readonly countryCode: string;
  readonly documentType: string;
  readonly documentId: string;
}

export interface GetInboundDocumentResponse {
  readonly inboundDocument: InboundDocument;
}

export interface RevalidateDocumentRequest {
  readonly countryCode: string;
  readonly documentType: string;
  readonly documentId: string;
}

export interface RevalidateDocumentResponse {
  readonly documentRevalidationStarted: boolean;
}

export interface SaveOverriddenMetadataRequest {
  readonly countryCode: string;
  readonly documentType: string;
  readonly documentId: string;
  readonly shouldRestartComplianceProcess?: boolean;
  readonly attributes: { [key: string]: string };
}

export interface SaveOverriddenMetadataResponse {
  readonly overriddenMetadataIndexStarted: boolean;
}

export interface InboundDocument {
  readonly countryCode: string;
  readonly documentId: string;
  readonly documentSubtype: string;
  readonly documentType: string;
  readonly executionNumber: number;
  readonly inboundExecutionNumber: number;
  readonly useCase: string;
  readonly status: InboundStatus;
  readonly documentDetails: DocumentDetails;
  readonly metadataOverrides?: MetadataOverrides;
  readonly externalPartners?: Array<ExternalPartner>;
  readonly setInboundDocument: (updates: any) => void;
}

export enum RelationshipType {
  RELATED = "related",
  RELATES_TO = "relatesTo",
}

export interface DocumentRelationship extends InboundDocument {
  readonly relationshipType: RelationshipType;
}

export type MetadataOverrides = { [key: string]: any };

export interface DocumentDetails {
  readonly documentType: string;
  readonly issuer?: Agent;
  readonly recipient?: Agent;
  readonly referencedDocuments?: Array<ReferencedDocument>;
  readonly documentTotalCost?: string;
  readonly issuanceDate?: string;
  readonly metadataOverrides?: MetadataOverrides;
  readonly officialDocumentId?: string;
}

export interface Agent {
  readonly agentType: string;
  readonly address: string;
  readonly name: string;
  readonly taxRegistrationNumber: string;
  readonly taxRegistrationType?: string;
  readonly agentCodes: Array<string>;
}

export interface ExternalPartner {
  readonly countryCode: string;
  readonly metadata: Array<string>;
  readonly parentPartnerCode: string;
  readonly partnerCode: string;
  readonly partnerType: string;
  readonly status: string;
  readonly taxRegistrationType: string;
}

export interface ReferencedDocument {
  readonly documentId: string;
  readonly documentType: string;
}

function retrieveDocumentDetails(
  consolidated_inbound: any,
  issuer?: Agent,
  recipient?: Agent
): DocumentDetails {
  return {
    documentType: consolidated_inbound.documentType,
    issuer,
    recipient,
    referencedDocuments:
      consolidated_inbound.documentDetails.referencedDocuments,
    documentTotalCost: consolidated_inbound.documentDetails.documentTotalCost,
    issuanceDate: consolidated_inbound.documentDetails.issuanceDate,
    officialDocumentId: consolidated_inbound.documentDetails.officialDocumentId,
  };
}

function retrieveExternalPartners(
  rawExternalPartner: Array<any>
): Array<ExternalPartner> {
  const externalPartners: Array<ExternalPartner> = [];

  rawExternalPartner.forEach((externalPartner) => {
    externalPartners.push({
      countryCode: externalPartner.countryCode,
      metadata: externalPartner.metadata,
      parentPartnerCode: externalPartner.parentPartnerCode,
      partnerCode: externalPartner.partnerCode,
      partnerType: externalPartner.partnerType,
      status: externalPartner.status,
      taxRegistrationType: externalPartner.taxRegistrationType,
    });
  });

  return externalPartners;
}

function retrieveAgent(
  rawAgent: any,
  agentType: string,
  agentCodes: Array<string>
): Agent {
  const { address, name, taxRegistrationNumber } = rawAgent;

  const taxRegistrationType = rawAgent.taxRegistrationType || null;

  return {
    agentType,
    address,
    name,
    taxRegistrationNumber,
    taxRegistrationType,
    agentCodes,
  };
}

export function deserializeMetadataOverridesEntries(metadataOverrides?: {
  [key: string]: string;
}): MetadataOverrides {
  const deserializedMetadataOverrides: MetadataOverrides = {};

  if (metadataOverrides) {
    Object.keys(metadataOverrides)
      .filter((key) => metadataOverrides[key])
      .forEach((key) => {
        try {
          deserializedMetadataOverrides[key] = JSON.parse(
            metadataOverrides[key]
          );
        } catch (e) {
          deserializedMetadataOverrides[key] = metadataOverrides[key];
        }
      });
  }

  return deserializedMetadataOverrides;
}

export function convertToInboundDocument(
  consolidatedInbound: any
): InboundDocument {
  let issuer: Agent | undefined;
  let recipient: Agent | undefined;
  let externalPartners: Array<ExternalPartner> | undefined;
  let documentDetails: DocumentDetails | undefined;

  if (consolidatedInbound.externalPartner) {
    externalPartners = retrieveExternalPartners(
      consolidatedInbound.externalPartner
    );
  }

  if (consolidatedInbound.externalPartners) {
    externalPartners = retrieveExternalPartners(
      consolidatedInbound.externalPartners
    );
  }

  if (consolidatedInbound.documentDetails) {
    if (consolidatedInbound.documentDetails.issuer) {
      const rawIssuer = consolidatedInbound.documentDetails.issuer;
      const agentCodes = externalPartners
        ? externalPartners.map(
            (externalPartner) => externalPartner.parentPartnerCode
          )
        : [];

      issuer = retrieveAgent(rawIssuer, AgentTypes.Issuer, agentCodes);
    }
    if (consolidatedInbound.documentDetails.recipient) {
      const rawRecipient = consolidatedInbound.documentDetails.recipient;
      const agentCode =
        consolidatedInbound.documentDetails.recipient.warehouseCode;

      recipient = retrieveAgent(rawRecipient, AgentTypes.Recipient, [
        agentCode,
      ]);
    }

    documentDetails = retrieveDocumentDetails(
      consolidatedInbound,
      issuer,
      recipient
    );
  }

  return {
    executionNumber: consolidatedInbound.executionNumber || 1,
    inboundExecutionNumber: consolidatedInbound.inboundExecutionNumber || 1,
    countryCode: consolidatedInbound.countryCode,
    documentId: consolidatedInbound.documentId,
    documentSubtype: consolidatedInbound.documentSubtype,
    documentType: consolidatedInbound.documentType,
    status: consolidatedInbound.status,
    useCase: consolidatedInbound.useCase,
    metadataOverrides: deserializeMetadataOverridesEntries(
      consolidatedInbound.metadataOverrides
    ),
    documentDetails: documentDetails || { documentType: "" },
    externalPartners,
    setInboundDocument: () => {},
  };
}
