import React, { Dispatch, SetStateAction } from "react";

export interface ModalWrapperContextInterface {
  readonly setVisible: Dispatch<SetStateAction<boolean>>;
}

const DEFAULT_MODAL_WRAPPER_CONTEXT: ModalWrapperContextInterface = {
  setVisible: () => {},
};

export const ModalWrapperContext =
  React.createContext<ModalWrapperContextInterface>(
    DEFAULT_MODAL_WRAPPER_CONTEXT
  );

export const useModalWrapper = () => React.useContext(ModalWrapperContext);
