import { KatTableCell, KatTableHead, KatTableRow } from "@amzn/katal-react";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  ColumnDefinition,
  DataTableSelectionAction,
} from "src/components/DataTable/DataTableDefinition";
import classnames from "classnames";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import styles from "../../styles.module.scss";

const DataTableHead: FC<DataTableHeadProps<any>> = ({
  id,
  columnsDefinition,
  contents,
  selectEnabled,
  selectDispatcher,
  shouldEnableSelectAll,
}) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  return (
    <KatTableHead id={`${id}-data-table-header`}>
      <KatTableRow>
        {columnsDefinition.map(({ label, property, ChildComponent }, index) => {
          return (
            <KatTableCell
              key={`${id}-data-table-header-cell-value-${property}`}
              id={`${id}-data-table-header-cell-${property}`}
              className={classnames(styles.background_white)}
            >
              {selectEnabled && ChildComponent && index === 0 ? (
                <ChildComponent
                  content={property}
                  contents={contents}
                  selectDispatcher={selectDispatcher}
                  shouldEnableSelection={shouldEnableSelectAll}
                />
              ) : (
                <p
                  id={`${id}-data-table-header-cell-value-${property}`}
                  className={classnames(styles.background_white)}
                >
                  {t(label)}
                </p>
              )}
            </KatTableCell>
          );
        })}
      </KatTableRow>
    </KatTableHead>
  );
};

export interface DataTableHeadProps<ContentType> {
  id: string;
  columnsDefinition: Array<ColumnDefinition>;
  contents?: Array<ContentType>;
  selectEnabled?: boolean;
  selectDispatcher?: (event: DataTableSelectionAction<ContentType>) => void;
  shouldEnableSelectAll?: boolean;
}
export default DataTableHead;
