import { Dispatch, SetStateAction } from "react";

export interface GetValidationResultRequest {
  readonly documentId: string;
  readonly documentType: string;
  readonly countryCode: string;
  readonly executionNumber: number;
  readonly validationGroup: string;
}

export interface GetValidationResultResponse {
  readonly validationResults: ValidationResult[];
  readonly exceedsSizeLimits: boolean;
}

export interface ValidationResult {
  readonly comment: string;
  readonly rule: string | undefined;
  readonly severity: string;
  readonly ruleMetadata?: string | undefined;
  readonly resultMetadata?: string | undefined;
  readonly fields: Array<Field>;
  readonly validationStatus: string;
  readonly ruleDescription: string;
  readonly scope?: string;
}

export interface Field {
  readonly authoritativeData: string;
  readonly itemPrefix?: string;
  readonly itemValue: string;
  readonly itemIdentifier?: string;
  readonly value: string;
  readonly purpose?: string;
}

export interface ValidationResultsResponse {
  validationResults: ValidationResult[];
  exceedsSizeLimits: boolean;
}

export interface ValidationResultsContextInterface {
  validationResultsByGroup: Record<string, ValidationResultsResponse>;
  setValidationResultsByGroup: Dispatch<
    SetStateAction<Record<string, ValidationResultsResponse>>
  >;
}

export interface MismatchTableFields {
  readonly index: number;
  readonly item: string;
  readonly rule: string;
  readonly value: string;
  readonly expectedValue: string;
  readonly details: string;
  readonly sopDetails?: string;
  readonly ruleSeverity: string;
  readonly metadata?: Record<string, string>;
}

export interface MismatchTableData<T> {
  mismatchRowData: Array<T>;
}

export enum AuthoritativeDataPurpose {
  VALIDATION_SUBJECT = "VALIDATION_SUBJECT",
  REFERENCE_VALUE = "REFERENCE_VALUE",
}

export enum MatchingRule {
  AT_LEAST_N_OF_CONTAINS = "AT_LEAST_N_OF_CONTAINS",
  CONTAINS_ONLY = "CONTAINS_ONLY",
  CASE_PACK_MATCH = "CASE_PACK_MATCH",
  DOES_NOT_CONTAIN = "DOES_NOT_CONTAIN",
  EQUALS = "EQUALS",
  EQUAL_TO = "EQUAL_TO",
  FUZZY_MATCH_TO = "FUZZY_MATCH_TO",
  HIGHER_THAN = "HIGHER_THAN",
  HIGHER_THAN_OR_EQUAL_TO = "HIGHER_THAN_OR_EQUAL_TO",
  IS_IN = "IS_IN",
  IS_INTEGER = "IS_INTEGER",
  LINE_ITEM_MATCH = "LINE_ITEM_MATCH",
  LOWER_THAN = "LOWER_THAN",
  LOWER_THAN_OR_EQUAL_TO = "LOWER_THAN_OR_EQUAL_TO",
  MX_ADDRESS_MATCH = "MX_ADDRESS_MATCH",
  MX_MATCH_ADDRESS_TO = "MX_MATCH_ADDRESS_TO",
  NOT_EMPTY = "NOT_EMPTY",
  NOT_NULL = "NOT_NULL",
  NOT_NULL_OR_EMPTY = "NOT_NULL_OR_EMPTY",
  REGEX = "REGEX",
}
