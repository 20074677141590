import React, { FC, ReactNode, useCallback, useState } from "react";
import { CountrySelection } from "src/api/interfaces/country";

const DEFAULT_COUNTRY_SELECTION: CountrySelection = {
  availableCountries: [],
  setCountrySelection: () => {},
};

export const CountrySelectionContext = React.createContext<CountrySelection>(
  DEFAULT_COUNTRY_SELECTION
);

export const useCountrySelection = () =>
  React.useContext(CountrySelectionContext);

export const CountrySelectionContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [countrySelection, setCountrySelection] = useState<CountrySelection>(
    DEFAULT_COUNTRY_SELECTION
  );

  // here context value is just returning an object, but only
  // re-creating the object when its dependencies change ([state, setContext])
  const getCountrySelectionContextValue = useCallback(
    () => ({
      ...countrySelection,
      setCountrySelection,
    }),
    [countrySelection, setCountrySelection]
  );
  return (
    <CountrySelectionContext.Provider value={getCountrySelectionContextValue()}>
      {children}
    </CountrySelectionContext.Provider>
  );
};
