import React, { FC } from "react";
import { useAlertContext } from "src/components/Alert/AlertContext";
import classnames from "classnames";
import styles from "./styles.module.scss";

const AlertListBox: FC = () => {
  const { alerts } = useAlertContext();
  return <div className={classnames(styles.alert_list_box)}>{alerts}</div>;
};

export default AlertListBox;
