import i18n from "src/i18n";
import {
  ColumnDefinition,
  DataTableDefinition,
} from "src/components/DataTable/DataTableDefinition";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import { HistoryField } from "src/constants";

const historyColumnsDefinition: ColumnDefinition[] = [
  {
    label: "aic_doc_page_history_tab_header_item_user",
    property: HistoryField.USER.valueOf(),
  },
  {
    label: "aic_doc_page_history_tab_header_item_date",
    property: HistoryField.DATE.valueOf(),
    formatter: (date: string) => new Date(date).toLocaleString(i18n.language),
  },
  {
    label: "aic_doc_page_history_tab_header_item_title",
    property: HistoryField.TITLE.valueOf(),
  },
  {
    label: "aic_doc_page_history_tab_header_item_details",
    property: HistoryField.DETAILS.valueOf(),
  },
];

const historyDataTableDefinition: DataTableDefinition<InboundDocument> = {
  columnsDefinition: historyColumnsDefinition,
};

export default historyDataTableDefinition;
