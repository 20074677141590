import { useCallback, useEffect } from "react";
import client, { UNAUTHORIZED_HTTP_STATUS_CODE } from "src/api/client";
import { log, LogLevel } from "src/logger";
import { AxiosError } from "axios";
import {
  GetValidationResultRequest,
  ValidationResult,
  ValidationResultsResponse,
} from "src/api/interfaces/validation_result";
import { useValidationResults } from "src/components/Contexts/ValidationResultsContext";
import { useAuthorizationErrors } from "src/components/Contexts/AuthorizationErrorsContext";
import useUnauthorizedRedirect from "src/components/AICLayout/AICHeader/Permissions/WithRedirectWhenAuthorized";
import { AuthorizationErrors } from "src/api/interfaces/permissions";
import { ValidationGroup } from "src/api/interfaces/document_validation";

const useValidationResultFetcher = ({
  countryCode,
  documentType,
  documentId,
  executionNumber,
  validationGroups,
}: UseValidationResultsParams) => {
  useUnauthorizedRedirect();

  const { setValidationResultsByGroup } = useValidationResults();
  const { setAuthorizationErrors } = useAuthorizationErrors();

  const areParametersPresent = (parameters: GetValidationResultRequest) => {
    return (
      parameters &&
      parameters.countryCode &&
      parameters.documentType &&
      parameters.documentId &&
      parameters.executionNumber &&
      parameters.validationGroup
    );
  };

  const fetchValidationResults = useCallback(
    (params: GetValidationResultRequest) => {
      return new Promise<ValidationPromiseResult>((resolve) => {
        client
          .getValidationResult(params)
          .then((result) => {
            log({
              level: LogLevel.INFO,
              message: "Validation Result fetch completed successfully",
              operationNamespace:
                "ValidationResultFetcher.fetchValidationResults",
            });
            resolve({
              validationGroupId: params.validationGroup,
              ...result,
            });
          })
          .catch((error: AxiosError) => {
            log({
              level: LogLevel.ERROR,
              message:
                "There was an error while trying to fetch Validation Result",
              operationNamespace:
                "ValidationResultFetcher.fetchValidationResults",
              exception: error?.message,
              attributes: { params },
            });
            if (error.response?.status === UNAUTHORIZED_HTTP_STATUS_CODE) {
              setAuthorizationErrors((prevState: AuthorizationErrors) => ({
                authorizationErrors: prevState.authorizationErrors.add(
                  error.message
                ),
              }));
            }
          });
      });
    },
    [setAuthorizationErrors]
  );

  const fetchAllValidationResults = useCallback(() => {
    const promises: Array<Promise<ValidationPromiseResult>> = [];
    validationGroups.forEach(
      ({ validationGroupId: validationGroup }: ValidationGroup) => {
        const params: GetValidationResultRequest = {
          documentId,
          documentType,
          countryCode,
          executionNumber,
          validationGroup,
        };
        if (areParametersPresent(params)) {
          promises.push(fetchValidationResults(params));
        }
      }
    );
    return Promise.all(promises);
  }, [
    countryCode,
    documentId,
    documentType,
    executionNumber,
    fetchValidationResults,
    validationGroups,
  ]);

  useEffect(() => {
    fetchAllValidationResults().then((allValidationResults) => {
      const validationResultsByGroup: Record<
        string,
        ValidationResultsResponse
      > = {};

      allValidationResults.forEach(
        ({
          validationGroupId,
          validationResults,
          exceedsSizeLimits,
        }: ValidationPromiseResult) => {
          validationResultsByGroup[validationGroupId] = {
            validationResults,
            exceedsSizeLimits,
          };
        }
      );
      setValidationResultsByGroup(validationResultsByGroup);
    });
  }, [fetchAllValidationResults, setValidationResultsByGroup]);
};

export interface UseValidationResultsParams {
  readonly documentId: string;
  readonly documentType: string;
  readonly countryCode: string;
  readonly executionNumber: number;
  readonly validationGroups: Array<ValidationGroup>;
}

interface ValidationPromiseResult {
  readonly validationGroupId: string;
  readonly validationResults: Array<ValidationResult>;
  readonly exceedsSizeLimits: boolean;
}

export default useValidationResultFetcher;
