export enum ProcessEnvCustomVariables {
  PERSONAL_URL = "PERSONAL_URL",
  IS_PORTAL_HAVING_INTERNAL_ISSUES = "IS_PORTAL_HAVING_INTERNAL_ISSUES",
  NODE_ENV = "NODE_ENV",
  PROCUREMENT_SERVICE_DOMAIN = "PROCUREMENT_SERVICE_DOMAIN",
}

export default {
  getVariable: (field: ProcessEnvCustomVariables) => process.env[field],
};
