import {
  ColumnDefinition,
  DataTableDefinition,
} from "src/components/DataTable/DataTableDefinition";
import { MismatchTableFields } from "src/api/interfaces/validation_result";
import MismatchSeverityIndicator from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/MismatchSeverityIndicator";
import DataTableExpandableText from "src/components/DataTable/DataTableExpandableText";
import DataTableMarkdownModalSop from "src/components/DataTable/DataTableMarkdownModalSop";

export enum MismatchField {
  ITEM = "item",
  RULE = "rule",
  VALUE = "value",
  EXPECTED_VALUE = "expectedValue",
  DETAILS = "details",
  SOP_DETAILS = "sopDetails",
  RULE_SEVERITY = "ruleSeverity",
}

const mismatchColumnsDefinition: ColumnDefinition[] = [
  {
    label: "aic_mismatch_column_item",
    property: MismatchField.ITEM.valueOf(),
  },
  {
    label: "aic_mismatch_column_rule",
    property: MismatchField.RULE.valueOf(),
  },
  {
    label: "aic_mismatch_column_value",
    property: MismatchField.VALUE.valueOf(),
  },
  {
    label: "aic_mismatch_column_expected_value",
    property: MismatchField.EXPECTED_VALUE.valueOf(),
  },
  {
    label: "aic_mismatch_rule_severity_value",
    property: MismatchField.RULE_SEVERITY.valueOf(),
    translateValue: true,
    ChildComponent: MismatchSeverityIndicator,
    childComponentOnly: true,
  },
  {
    label: "aic_mismatch_column_details",
    property: MismatchField.DETAILS.valueOf(),
    ChildComponent: DataTableExpandableText,
    childComponentOnly: true,
  },
  {
    label: "",
    property: MismatchField.SOP_DETAILS.valueOf(),
    ChildComponent: DataTableMarkdownModalSop,
    childComponentOnly: true,
  },
];

const mismatchDataTableDefinition: DataTableDefinition<MismatchTableFields> = {
  columnsDefinition: mismatchColumnsDefinition,
  rowClickHandler: () => {},
};

export default mismatchDataTableDefinition;
