import React, { FC } from "react";
import { KatCard } from "@amzn/katal-react";
import { v1 } from "uuid";
import CardField, {
  CardFieldProps,
  CardFieldSlot,
} from "src/components/Card/CardField";
import classnames from "classnames";
import styles from "../../styles.module.scss";

const Card: FC<CardProps> = ({ content, className }) => {
  const getComponentsBySlot = (
    cardContent: Array<CardFieldProps<any, any>>,
    slot: CardFieldSlot
  ) => {
    return cardContent.filter((currentContent) => currentContent.slot === slot);
  };

  const renderCardField = (
    cardField: Array<CardFieldProps<any, any>>,
    key: string
  ) => {
    return cardField.map((currentCardField) => (
      <CardField
        key={`${key}-${v1()}`}
        slot={currentCardField.slot}
        label={currentCardField.label}
        value={currentCardField.value}
        translateValue={currentCardField.translateValue}
        valueStyle={currentCardField.valueStyle}
        labelStyle={currentCardField.labelStyle}
        ChildComponent={currentCardField.ChildComponent}
        childComponentOnly={currentCardField.childComponentOnly}
        formatter={currentCardField.formatter}
      />
    ));
  };

  const titleComponents: Array<CardFieldProps<any, any>> = getComponentsBySlot(
    content,
    CardFieldSlot.TITLE
  );
  const subtitleComponents: Array<CardFieldProps<any, any>> =
    getComponentsBySlot(content, CardFieldSlot.SUBTITLE);
  const bodyComponents: Array<CardFieldProps<any, any>> = getComponentsBySlot(
    content,
    CardFieldSlot.BODY
  );
  const actionComponents: Array<CardFieldProps<any, any>> = getComponentsBySlot(
    content,
    CardFieldSlot.ACTION
  );
  const footerComponents: Array<CardFieldProps<any, any>> = getComponentsBySlot(
    content,
    CardFieldSlot.FOOTER
  );

  return (
    <KatCard
      className={classnames(className, styles.box_shadow, styles.card_border)}
    >
      {renderCardField(titleComponents, "title")}
      {renderCardField(actionComponents, "action")}
      {renderCardField(subtitleComponents, "subtitle")}
      {renderCardField(bodyComponents, "body")}
      {renderCardField(footerComponents, "footer")}
    </KatCard>
  );
};

export interface CardProps {
  content: Array<CardFieldProps<any, any>>;
  className?: string;
}

export default Card;
