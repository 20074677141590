import { ValidationResult } from "src/api/interfaces/validation_result";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import { LineItemMismatchTableFields } from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/LineItem/line-item-mismatch-data-table-definition";
import { MismatchTableTransformer } from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/types";
import convertToMismatchTableFields from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/mismatch-table-fields-extractor";

const EMPTY_FIELD = "-";

export default function convertToLineItemMismatchTableFields(
  _inboundDocument: InboundDocument
): MismatchTableTransformer<LineItemMismatchTableFields> {
  return (validationResult: ValidationResult, index: number, t?: any) => {
    return {
      ...convertToMismatchTableFields(validationResult, index, t),
      purchaseOrder: EMPTY_FIELD,
    };
  };
}
