import React, { FC, useEffect, useState } from "react";
import {
  ComplianceUserActionType,
  KatalDropdownButtonOption,
  TypeToUserActionConfig,
  UserAction,
} from "src/components/UserActions/types";
import { KatBadge, KatDropdownButton, KatSpinner } from "@amzn/katal-react";
import client from "src/api/client";
import { log, LogLevel } from "src/logger";
import { useInboundDocument } from "src/components/Contexts/InboundDocumentContext";
import handleComplianceUserAction from "src/components/UserActions/Handlers/Compliance/compliance_user_action_handler";

import withPermissionsOrDefault from "src/components/AICLayout/AICHeader/Permissions/WithPermissions";
import { Operation } from "src/api/interfaces/permissions";
import { useTranslation } from "react-i18next";
import { usePermissions } from "src/components/Contexts/PermissionsContext";
import checkPermission from "src/components/AICLayout/AICHeader/Permissions/PermissionCheckers";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import { alertHandler } from "src/components/Alert/handlers";
import { useAlertContext } from "src/components/Alert/AlertContext";
import useUserActionsMetadata from "src/components/Pages/DocumentInbound/Hooks/useUserActionsMetadata";
import { get } from "lodash";
import styles from "./styles.module.scss";

export const USER_ACTIONS_DROPDOWN_OPTION: KatalDropdownButtonOption = {
  label: "aic_doc_page_user_action_dropdown_actions",
};

enum ActionApplicationStatus {
  SUCCESS,
  FAILED,
  NOT_APPLIED,
}

const SUCCESS_BADGE_PROPS: KatBadge._OwnProps = {
  type: "success",
  label: "aic_doc_page_badge_compliance_user_action_success",
};

const FAILED_BADGE_PROPS: KatBadge._OwnProps = {
  type: "warning",
  label: "aic_doc_page_badge_compliance_user_action_error",
};

const ComplianceUserActions: FC = () => {
  const { t } = useTranslation();
  const { permissions } = usePermissions();
  const { setAlerts, alerts } = useAlertContext();
  const { metadata } = useUserActionsMetadata();

  const getAuthorized = (
    permissionsMap: Map<string, Map<string, Operation[]>>,
    documentDetails: InboundDocument,
    action: UserAction
  ) => {
    const userActionConfig = TypeToUserActionConfig.get(action.type);

    if (!userActionConfig) {
      return false;
    }

    return checkPermission(
      permissionsMap,
      documentDetails.countryCode,
      documentDetails.useCase,
      userActionConfig.userActionOperation
    );
  };

  // short-term solution. Check https://sim.amazon.com/issues/AICPLAT-1364
  const getSortedAdditionalOptions = (
    options: KatalDropdownButtonOption[]
  ): KatalDropdownButtonOption[] => {
    if (
      options.length === 3 &&
      options[0].action === ComplianceUserActionType.FORCE_ACCEPT &&
      options[1].action === ComplianceUserActionType.FORCE_REJECT
    ) {
      return [options[0], options[2], options[1]];
    }
    return options;
  };

  const [options, setOptions] = useState<KatalDropdownButtonOption[]>([
    {
      label: t(USER_ACTIONS_DROPDOWN_OPTION.label),
    },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [applicationStatus, setApplicationStatus] =
    useState<ActionApplicationStatus>(ActionApplicationStatus.NOT_APPLIED);
  const documentDetails = useInboundDocument();

  const shouldDisableActionButton = () => {
    const defaultActionWindow = "18"; // 18 months is the maximum time after the issuance of the document to perform any action
    const actionWindow = Number.parseInt(
      get(metadata, "actionWindowTime", defaultActionWindow)
    );
    const issuanceDate = new Date(
      Date.parse(documentDetails.documentDetails.issuanceDate!)
    );
    issuanceDate.setMonth(issuanceDate.getMonth() + actionWindow);

    return options.length <= 1 || issuanceDate.getTime() <= Date.now();
  };

  const onAction = (event: KatDropdownButton.ActionEvent) => {
    event.preventDefault();
    const {
      detail: { action },
    } = event;

    if (!action) {
      return;
    }

    setLoading(true);

    handleComplianceUserAction(documentDetails, action)
      .then(() => {
        log({
          level: LogLevel.INFO,
          message: "Compliance user action handled successfully",
          operationNamespace:
            "ComplianceUserActions.handleComplianceUserAction",
          attributes: { action, documentDetails },
        });
        setLoading(false);
        setApplicationStatus(ActionApplicationStatus.SUCCESS);
        alertHandler.createNewAlertEntry({
          alerts,
          setAlerts,
          header: t("aic_doc_page_alert_compliance_user_action_header_success"),
          fade: true,
          isSuccess: true,
          text: t("aic_doc_page_alert_compliance_user_action_text_success"),
        });
      })
      .catch((error) => {
        log({
          level: LogLevel.ERROR,
          message: "There was an error while handling compliance user actions",
          operationNamespace:
            "ComplianceUserActions.handleComplianceUserAction",
          exception: error?.message,
          attributes: { action, documentDetails },
        });
        setLoading(false);
        setApplicationStatus(ActionApplicationStatus.FAILED);
        alertHandler.createNewAlertEntry({
          alerts,
          setAlerts,
          header: t("aic_doc_page_alert_compliance_user_action_header_error"),
          fade: true,
          isSuccess: false,
          text: t("aic_doc_page_alert_compliance_user_action_text_error"),
        });
      });
  };

  useEffect(() => {
    const getComplianceUserActions = async () => {
      try {
        const { userActions } = await client.getComplianceUserActions({
          country_code: documentDetails.countryCode,
          document_type: documentDetails.documentType,
          document_id: documentDetails.documentId,
        });

        log({
          level: LogLevel.INFO,
          message: "Compliance user actions fetch completed successfully",
          operationNamespace: "ComplianceUserActions.useEffect",
          attributes: { userActions },
        });

        const additionalOptions: KatalDropdownButtonOption[] = userActions
          .map((action) => {
            const isAuthorized = getAuthorized(
              permissions,
              documentDetails,
              action
            );

            const complianceUserActionType = TypeToUserActionConfig.get(
              action.type
            );

            const label = complianceUserActionType
              ? complianceUserActionType.label
              : "";

            const katalDropdownOption = {
              action: action.type,
              label: "",
            };

            if (isAuthorized) {
              katalDropdownOption.label = label;
            }

            return katalDropdownOption;
          })
          .filter((action) => !!action.label);
        const translatedAdditionalOptions: KatalDropdownButtonOption[] =
          additionalOptions.map((action) => ({
            action: action.action,
            label: t(action.label),
          }));
        const sortedAdditionalOptions = getSortedAdditionalOptions(
          translatedAdditionalOptions
        );
        setOptions([
          {
            label: t(USER_ACTIONS_DROPDOWN_OPTION.label),
          },
          ...sortedAdditionalOptions,
        ]);
      } catch (error: any) {
        log({
          level: LogLevel.ERROR,
          message:
            "There was an error while trying to fetch compliance actions",
          operationNamespace: "ComplianceUserActions.useEffect",
          exception: error?.message,
        });
      }
    };

    if (
      documentDetails.countryCode &&
      documentDetails.documentType &&
      documentDetails.documentId
    ) {
      getComplianceUserActions();
    }
  }, [
    documentDetails.countryCode,
    documentDetails.documentType,
    documentDetails.documentId,
    t,
    documentDetails,
    permissions,
  ]);

  if (applicationStatus !== ActionApplicationStatus.NOT_APPLIED) {
    const badgeProps =
      applicationStatus === ActionApplicationStatus.SUCCESS
        ? SUCCESS_BADGE_PROPS
        : FAILED_BADGE_PROPS;

    return (
      <KatBadge
        label={t(badgeProps.label)}
        type={badgeProps.type}
        variant={badgeProps.variant}
      />
    );
  }

  return loading ? (
    <KatSpinner />
  ) : (
    <KatDropdownButton
      id="document-compliance-user-actions"
      className={styles.compliance_actions_dropdown_button}
      options={options}
      onAction={onAction}
      disabled={shouldDisableActionButton()}
      variant="primary"
      size="base"
    />
  );
};

export default withPermissionsOrDefault(
  ComplianceUserActions,
  Operation.PerformComplianceUserActions
);
