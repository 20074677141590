import React, { FC } from "react";
import {
  DataTableCellChildComponentProps,
  DataTableSelectionActions,
  DataTableSelectionProps,
  SELECT_ALL_PROPERTY,
} from "src/components/DataTable/DataTableDefinition";
import { KatCheckbox } from "@amzn/katal-react";

const DataTableCheckbox: FC<
  DataTableCellChildComponentProps<any & DataTableSelectionProps>
> = ({
  content: rowContent,
  contents,
  selectDispatcher,
  shouldBeSelectable,
  shouldEnableSelection,
}) => {
  const isAllSelected =
    !!contents &&
    contents.length === contents.filter((row) => row.selected).length;

  const handleOnChange = (event: KatCheckbox.ChangeEvent) => {
    const {
      detail: { checked },
    } = event;

    if (!selectDispatcher) return;

    if (rowContent === SELECT_ALL_PROPERTY) {
      for (let i = 0; i < contents!.length; i += 1) {
        contents![i].selected = checked;
      }

      selectDispatcher({
        type: checked
          ? DataTableSelectionActions.SELECT_ALL
          : DataTableSelectionActions.UNSELECT_ALL,
        payload: checked ? contents : undefined,
      });
    } else {
      selectDispatcher({
        type: checked
          ? DataTableSelectionActions.SELECT
          : DataTableSelectionActions.UNSELECT,
        payload: rowContent,
      });
    }
  };

  const shouldBeActive = () => {
    return shouldBeSelectable
      ? shouldBeSelectable(rowContent, contents!)
      : shouldEnableSelection ?? true;
  };

  return (
    <KatCheckbox
      onChange={handleOnChange}
      disabled={!shouldBeActive()}
      checked={rowContent.selected || isAllSelected}
    />
  );
};

export default DataTableCheckbox;
