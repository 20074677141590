import {
  ColumnDefinition,
  DataTableDefinition,
} from "src/components/DataTable/DataTableDefinition";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import { ProductItemErrorField } from "src/constants";
import MismatchSeverityIndicator from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/MismatchSeverityIndicator";
import DataTableExpandableText from "src/components/DataTable/DataTableExpandableText";
import styles from "src/styles.module.scss";
import { ValidationError } from "src/api/interfaces/line_item";
import { ValidationRuleSeverity } from "src/api/interfaces/document_validation";
import DataTableMarkdownModalSop from "src/components/DataTable/DataTableMarkdownModalSop";

export const valueStyleBuilder = ({ ruleSeverity }: ValidationError) => {
  if (
    [ValidationRuleSeverity.CRITICAL, ValidationRuleSeverity.ERROR].includes(
      ruleSeverity as ValidationRuleSeverity
    )
  ) {
    return [styles.color_crimson];
  }
  return [];
};

const productItemsErrorColumnsDefinition: ColumnDefinition[] = [
  {
    label: "aic_doc_page_product_items_error_tab_header_item_rule_id",
    property: ProductItemErrorField.RULE.valueOf(),
    valueStyleBuilder,
  },
  {
    label: "aic_doc_page_product_items_error_tab_header_item_value",
    property: ProductItemErrorField.VALUE.valueOf(),
    valueStyleBuilder,
  },
  {
    label: "aic_doc_page_product_items_error_tab_header_item_expected_value",
    property: ProductItemErrorField.EXPECTED_VALUE.valueOf(),
    valueStyleBuilder,
  },
  {
    label: "aic_doc_page_product_items_error_tab_header_item_severity",
    property: ProductItemErrorField.RULE_SEVERITY.valueOf(),
    translateValue: true,
    ChildComponent: MismatchSeverityIndicator,
    childComponentOnly: true,
  },
  {
    label: "aic_doc_page_product_items_error_tab_header_item_description",
    property: ProductItemErrorField.DESCRIPTION.valueOf(),
    ChildComponent: DataTableExpandableText,
    childComponentOnly: true,
    valueStyleBuilder,
  },
  {
    label: "",
    property: ProductItemErrorField.SOP_DETAILS.valueOf(),
    ChildComponent: DataTableMarkdownModalSop,
    childComponentOnly: true,
  },
];

const documentItemsErrorDataTableDefinition: DataTableDefinition<InboundDocument> =
  {
    columnsDefinition: productItemsErrorColumnsDefinition,
  };

export default documentItemsErrorDataTableDefinition;
