import React, { FC } from "react";
import { KatTooltip } from "@amzn/katal-react";
import { isEmpty } from "lodash";

export interface LabelListProps {
  list?: Array<string> | null;
  sizeToShow: number;
}
const LabelList: FC<LabelListProps> = ({ list, sizeToShow }) => {
  const firstsItemsToShow = list?.slice(0, sizeToShow) || [];
  const remainingItems = list?.slice(sizeToShow, list.length);
  const separator = ", ";

  const firstsItemsLabel = isEmpty(firstsItemsToShow)
    ? "-"
    : firstsItemsToShow.join(separator);
  const remainingItemsLabel = remainingItems?.join(separator);

  return (
    <>
      {isEmpty(remainingItems) ? (
        <span> {firstsItemsLabel} </span>
      ) : (
        <KatTooltip
          label={remainingItemsLabel!}
          trigger-text={`${firstsItemsToShow.join(separator)}, ...`}
          position="right"
        />
      )}
    </>
  );
};

export default LabelList;
