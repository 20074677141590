import React, { FC } from "react";
import { KatBox } from "@amzn/katal-react";
import DynamicTable from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundComplianceModals/Listing/DynamicTable";

export const DefaultRenderComponent: FC = () => {
  return (
    <div>
      <KatBox>
        <DynamicTable />
      </KatBox>
    </div>
  );
};

export default DefaultRenderComponent;
