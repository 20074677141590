import classnames from "classnames";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { KatIcon, KatPopover } from "@amzn/katal-react";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { log, LogLevel } from "src/logger";
import styles from "../../../styles.module.scss";

export interface CopyToClipboardButtonProps {
  readonly label: string;
  readonly translationPrefix: string;
  readonly value: string;
}

const TEXT_COPIED_MESSAGE_TIME = 3000;

const CopyToClipboardButton: FC<CopyToClipboardButtonProps> = (props) => {
  const { label, translationPrefix, value } = props;

  const [textCopied, setTextCopied] = useState(false);
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const copyToClipboardHandler = (event: React.MouseEvent<KatIcon.Element>) => {
    const sendSuccessMessage = () => {
      setTextCopied(true);

      const timer = setTimeout(() => {
        clearTimeout(timer);
        setTextCopied(false);
      }, TEXT_COPIED_MESSAGE_TIME);
    };

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          sendSuccessMessage();
        })
        .catch((err) => {
          log({
            level: LogLevel.ERROR,
            message: `Failed to copy data from ${label} to clipboard using navigator.clipboard API`,
            attributes: { value, error: err },
          });
        });
    }
    event?.preventDefault?.();
  };

  return (
    <KatPopover
      variant={textCopied ? "success" : "info"}
      position="top"
      trigger-type="hover"
    >
      <button className="kat-no-style" type="button" slot="trigger">
        <KatIcon
          name={textCopied ? "checkmark" : "content_copy"}
          size="tiny"
          onClick={copyToClipboardHandler}
          className={classnames(
            styles.ml_10,
            styles.doc_page_copy_document_id_icon
          )}
        />
      </button>
      {textCopied
        ? t(`${translationPrefix}_copied_message`)
        : t(`${translationPrefix}_info`)}
    </KatPopover>
  );
};

export default CopyToClipboardButton;
