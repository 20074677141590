import { InboundDocument } from "src/api/interfaces/inbound_document";
import { InboundComplianceGroupField } from "src/constants";
import InboundComplianceGroupStatusIndicator from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundCompliance/InboundComplianceListing/InboundComplianceGroupStatusIndicator";
import { FetchInboundComplianceActions } from "src/components/UserActions/FetchInboundComplianceActions";
import {
  ColumnDefinition,
  DataTableDefinition,
} from "src/components/DataTable/DataTableDefinition";

const InboundComplianceColumnsDefinition: ColumnDefinition[] = [
  {
    label: "aic_doc_page_inbound_compliance_tab_header_name",
    property:
      InboundComplianceGroupField.INBOUND_COMPLIANCE_GROUP_NAME.valueOf(),
    translateValue: true,
  },
  {
    label: "aic_doc_page_inbound_compliance_tab_header_description",
    property: InboundComplianceGroupField.DESCRIPTION.valueOf(),
    translateValue: true,
  },
  {
    label: "aic_doc_page_inbound_compliance_tab_header_status",
    property:
      InboundComplianceGroupField.INBOUND_COMPLIANCE_GROUP_STATUS.valueOf(),
    ChildComponent: InboundComplianceGroupStatusIndicator,
    childComponentOnly: true,
  },
  {
    label: "aic_doc_page_inbound_compliance_tab_header_actions",
    property: "",
    childComponentOnly: true,
    ChildComponent: FetchInboundComplianceActions,
  },
];

const InboundComplianceDataTableDefinition: DataTableDefinition<InboundDocument> =
  {
    columnsDefinition: InboundComplianceColumnsDefinition,
  };

export default InboundComplianceDataTableDefinition;
