import setDocumentLineItems from "./actionFunctions";
import {
  ActionFunction,
  UserActionsMetadataAction,
  UserActionsMetadataActions,
  UserActionsMetadataState,
} from "./types";

const reducerMap: Record<UserActionsMetadataActions, ActionFunction> = {
  [UserActionsMetadataActions.ADD]: setDocumentLineItems,
};

export default function userActionsMetadataReducer(
  state: UserActionsMetadataState,
  emittedAction: UserActionsMetadataAction
): UserActionsMetadataState {
  const { type, payload } = emittedAction;

  return reducerMap[type](state, payload);
}
