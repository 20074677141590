import React, { FC } from "react";
import { KatButton, KatPopover } from "@amzn/katal-react";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import styles from "src/styles.module.scss";
import { isEmpty } from "lodash";
import Markdown from "markdown-to-jsx";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import classnames from "classnames";

const DataTableMarkdownModalSop: FC<DataTableCellChildComponentProps<any>> = ({
  content,
}) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const text = content.sopDetails?.valueOf();

  return (
    <>
      {!isEmpty(text) && (
        <>
          <KatPopover
            kat-aria-behavior="tooltip"
            position="left-start"
            trigger-type="click"
          >
            <KatButton slot="trigger" variant="primary">
              {t("aic_mismatch_column_sop_details_button")}
            </KatButton>
            <div
              className={classnames(
                styles.justify_center,
                styles.align_items_center,
                styles.display_flex,
                styles.max_w_400,
                styles.max_h_600
              )}
            >
              <Markdown>{text}</Markdown>
            </div>
          </KatPopover>
        </>
      )}
    </>
  );
};

export default DataTableMarkdownModalSop;
