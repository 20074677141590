import React, { FC } from "react";
import { KatStatusindicator } from "@amzn/katal-react";
import styles from "src/styles.module.scss";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { ValidationRuleSeverity } from "src/api/interfaces/document_validation";
import { useTranslation } from "react-i18next";
import { getTranslatedValue } from "src/utils";
import { MismatchTableFields } from "src/api/interfaces/validation_result";

const INFO_TYPE = "information";
const ALERT_TYPE = "alert";
const ERROR_TYPE = "error";

const VALIDATION_RULE_SEVERITY_TO_STATUS_INDICATOR_TYPE: Map<
  string,
  KatStatusindicator.Variant
> = new Map([
  [ValidationRuleSeverity.CRITICAL.valueOf(), ERROR_TYPE],
  [ValidationRuleSeverity.ERROR.valueOf(), ERROR_TYPE],
  [ValidationRuleSeverity.INFO.valueOf(), INFO_TYPE],
  [ValidationRuleSeverity.SEVERE.valueOf(), ERROR_TYPE],
  [ValidationRuleSeverity.WARNING.valueOf(), ALERT_TYPE],
]);

const MismatchSeverityIndicator: FC<MismatchTableDataProps> = ({
  content,
  translateValue,
}) => {
  const { t } = useTranslation();
  const validationRuleSeverityText = content.ruleSeverity.valueOf();
  const translatedValidationRuleSeverityText = translateValue
    ? getTranslatedValue(validationRuleSeverityText, t)
    : validationRuleSeverityText;

  const statusIndicatorType: KatStatusindicator.Variant =
    VALIDATION_RULE_SEVERITY_TO_STATUS_INDICATOR_TYPE.get(
      validationRuleSeverityText
    ) || INFO_TYPE;
  return (
    <KatStatusindicator
      label={translatedValidationRuleSeverityText}
      variant={statusIndicatorType}
      className={styles.capitalizedText}
    />
  );
};

export type MismatchTableDataProps =
  DataTableCellChildComponentProps<MismatchTableFields>;

export default MismatchSeverityIndicator;
