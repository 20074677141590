/* istanbul ignore file */

import React from "react";
import { KatButton } from "@amzn/katal-react";
import classnames from "classnames";
import client from "src/api/client";
import operational_styles from "./operational_styles.module.scss";

const OperationalActions = ({
  countryCode,
  documentType,
  documentId,
}: {
  countryCode: string;
  documentType: string;
  documentId: string;
}) => {
  const handleGetInternalDocument = async (relatedDocumentType: string) => {
    const relatedDocument = await client.getRelatedDocumentsByType({
      countryCode,
      documentId,
      documentType,
      relatedDocumentType,
    });

    window.open(relatedDocument.s3PresignedUrl, "about:blank");
  };

  const buttons = [
    {
      label: "Download Document Representation",
      type: "document-representation",
    },
    {
      label: "Download Complete Document Representation",
      type: "complete-document-details",
    },
  ];

  return (
    <div className={classnames(operational_styles.root)}>
      <div>
        <header className={classnames(operational_styles.header)}>
          <h2>Related internal documents</h2>
        </header>
        <div>
          {buttons.map(({ label, type }) => (
            <KatButton
              className={classnames(operational_styles.action_button)}
              label={label}
              onClick={() => handleGetInternalDocument(type)}
            >
              {label}
            </KatButton>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OperationalActions;
