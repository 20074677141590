import { InboundStatus } from "src/api/interfaces/inbound_document";

export enum DomainName {
  DOCUMENT_ID = "documentId",
  DOCUMENT_SUBTYPE = "documentSubtype",
  DOCUMENT_TYPE = "documentType",
  END_ISSUANCE_DATE = "endIssuanceDate",
  ISSUANCE_DATE = "issuanceDate",
  ORCHESTRATION_EVENTS = "orchestrationEvents",
  PARTNER_CODE = "partnerCode",
  REFERENCED_DOCUMENT = "referencedDocument",
  REFERENCED_DOCUMENTS_IS_EMPTY = "referencedDocumentsIsEmpty",
  SEARCH = "search",
  START_ISSUANCE_DATE = "startIssuanceDate",
  STATUSES = "statuses",
  USE_CASE = "useCase",
  VALIDATION_GROUPS = "validationGroups",
}

export enum FilterType {
  MULTI_VALUE,
  SINGLE_VALUE,
}

export type FilterValue = string | string[];

export type FilterValues = Record<DomainName, FilterValue>;

export enum TimeRangeOptions {
  FIFTEEN_DAYS = "now-15d/d",
  THIRTY_DAYS = "now-30d/d",
  FORTY_FIVE_DAYS = "now-45d/d",
  NINETY_DAYS = "now-90d/d",
  SIX_MONTHS = "now-180d/d",
  ONE_YEAR = "now-365d/d",
}

export const InboundStatusOptionsInternal = [
  InboundStatus.ACCEPTED,
  InboundStatus.CANCELED,
  InboundStatus.FINALIZED,
  InboundStatus.INGESTED,
  InboundStatus.PENDING_ACTIONS,
  InboundStatus.PROCESSING,
  InboundStatus.REJECTED,
  InboundStatus.SKIPPED,
  InboundStatus.TEMPORARILY_REJECTED,
  InboundStatus.UNALLOCATED,
];

export const InboundStatusOptionsExternal = [
  InboundStatus.CANCELED,
  InboundStatus.FINALIZED,
  InboundStatus.PENDING_ACTIONS,
  InboundStatus.PROCESSING,
  InboundStatus.REJECTED,
  InboundStatus.SKIPPED,
  InboundStatus.TEMPORARILY_REJECTED,
];

export enum FilterActions {
  CLEAR,
  SET_FILTERABLE_DATA,
  SET_SELECTED_VALUES,
  SET_SHOULD_TRIGGER_FETCH_DOCUMENTS,
}

export interface FilterOptionsAction {
  type: FilterActions;
  payload: any;
}

export interface FilterOptionsState {
  selectedValues: FilterValues;
  optionValues: Record<DomainName, string[]>;
  filterableData: Record<string, string[]>;
  shouldTriggerFetchDocuments: boolean;
}

export type FilterOptionsActionFunction = (
  state: FilterOptionsState,
  payload: any
) => FilterOptionsState;
