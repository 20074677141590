import React, { FC } from "react";
import { usePermissions } from "src/components/Contexts/PermissionsContext";
import { useInboundDocument } from "src/components/Contexts/InboundDocumentContext";
import checkPermission from "src/components/AICLayout/AICHeader/Permissions/PermissionCheckers";
import { Operation } from "src/api/interfaces/permissions";

const DefaultNotAllowedElement: FC = () => {
  return <div />;
};

const withPermissionsOrDefault = (
  Component: FC<any>,
  operation: Operation,
  NotAllowedElement?: FC<any>
): FC<any> => {
  return (props) => {
    const { permissions } = usePermissions();
    const { useCase, countryCode } = useInboundDocument();

    const isAuthorized = checkPermission(
      permissions,
      countryCode,
      useCase,
      operation
    );

    if (isAuthorized) {
      return <Component {...props} />;
    }
    return NotAllowedElement === undefined ? (
      <DefaultNotAllowedElement />
    ) : (
      <NotAllowedElement {...props} />
    );
  };
};

export default withPermissionsOrDefault;
