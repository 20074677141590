import React from "react";
import { useTranslation } from "react-i18next";
import { get, isNull, omitBy } from "lodash";
import classnames from "classnames";
import { KatModal } from "@amzn/katal-react";
import styles from "src/styles.module.scss";
import { LineItemRepresentation } from "src/api/interfaces/line_item";
import useDocumentLineItems from "src/components/Pages/DocumentInbound/Hooks/useDocumentLineItems";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import AmountDetailsCard from "./AmountDetailsCard";
import ProductIdCard from "./ProductIdCard";
import TaxDetailsCard from "./TaxDetailsCard";
import TaxClassificationCard from "./TaxClassificationCard";

export type DocumentItemDetailsModalProps = {
  visible: boolean;
  onClose: () => void;
  content: LineItemRepresentation;
};

const DocumentItemDetailsModal = ({
  visible,
  onClose,
  content: contentProp,
}: DocumentItemDetailsModalProps) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const { documentLineItems } = useDocumentLineItems();
  const content = omitBy(
    documentLineItems[parseInt(contentProp.index, 10) - 1],
    isNull
  );

  const notApplicable = t("aic_doc_page_product_items_tab_not_applicable");
  const amountDetailsTitle = t(
    "aic_doc_page_product_items_tab_item_details_amount_details_title"
  );
  const productIdTitle = t(
    "aic_doc_page_product_items_tab_item_details_productid_title"
  );
  const taxDetailsTitle = t(
    "aic_doc_page_product_items_tab_item_details_tax_details_title"
  );
  const taxClassificationTitle = t(
    "aic_doc_page_product_items_tab_item_details_tax_classification_details_title"
  );

  const externalId = get(content, "externalId", notApplicable);
  const amazonIdentifiers = get(content, "amazonIdentifiers", notApplicable);

  return (
    <KatModal visible={visible} onClose={onClose}>
      <div>
        <div className={classnames(styles.ma_16)}>
          <AmountDetailsCard
            title={amountDetailsTitle}
            amountDetails={content.amountDetails}
          />
        </div>
        <div className={classnames(styles.ma_16)}>
          <ProductIdCard
            title={productIdTitle}
            externalId={externalId}
            amazonIdentifiers={amazonIdentifiers}
          />
        </div>
        {content.taxDetails && (
          <div className={classnames(styles.ma_16)}>
            <TaxDetailsCard
              title={taxDetailsTitle}
              taxDetails={content.taxDetails}
            />
          </div>
        )}
        {content.taxClassificationDetails && (
          <div className={classnames(styles.ma_16)}>
            <TaxClassificationCard
              title={taxClassificationTitle}
              taxClassification={content.taxClassificationDetails}
            />
          </div>
        )}
      </div>
    </KatModal>
  );
};
export default DocumentItemDetailsModal;
