import { useEffect, useState } from "react";
import client, { UNAUTHORIZED_HTTP_STATUS_CODE } from "src/api/client";
import { log, LogLevel } from "src/logger";
import { AxiosError } from "axios";
import { RetrievalState } from "src/constants";
import { usePagination } from "src/components/Contexts/PaginationContext";
import {
  GetDocumentsRequest,
  GetDocumentsResult,
} from "src/api/interfaces/document_listing";
import { usePermissions } from "src/components/Contexts/PermissionsContext";
import { AuthorizationErrors } from "src/api/interfaces/permissions";
import { useAuthorizationErrors } from "src/components/Contexts/AuthorizationErrorsContext";
import { useHistory } from "react-router-dom";
import useUnauthorizedRedirect from "src/components/AICLayout/AICHeader/Permissions/WithRedirectWhenAuthorized";
import FilterManager from "src/components/Pages/DocumentList/Filters/filterManager";
import { useDocumentList } from "src/components/Contexts/DocumentList/Provider";
import { DocumentListActions } from "src/components/Contexts/DocumentList/types";

const useDocumentListFetcher = (): void => {
  useUnauthorizedRedirect();

  const permissions = usePermissions();
  const { setPagination } = usePagination();
  const history = useHistory();
  const { setAuthorizationErrors } = useAuthorizationErrors();
  const [lastSearchParams, setLastSearchParams] = useState<string>("");
  const [lastDocumentRequest, setLastDocumentRequest] =
    useState<GetDocumentsRequest>();

  const { state: documentsState, dispatch: documentsDispatch } =
    useDocumentList();

  useEffect(() => {
    const handleError = (error: AxiosError, params: GetDocumentsRequest) => {
      log({
        level: LogLevel.ERROR,
        message: "There was an error while trying to fetch documents",
        operationNamespace: "DocumentListingFetcher.fetchDocuments",
        exception: error?.message,
        attributes: { params },
      });

      if (error.response?.status === UNAUTHORIZED_HTTP_STATUS_CODE) {
        setAuthorizationErrors((prevState: AuthorizationErrors) => ({
          authorizationErrors: prevState.authorizationErrors.add(error.message),
        }));
      }
      documentsDispatch({
        type: DocumentListActions.ADD,
        payload: {
          documents: [],
          retrieveState: RetrievalState.ERROR,
        },
      });
    };

    const handleSuccess = (result: GetDocumentsResult) => {
      log({
        level: LogLevel.INFO,
        message: "Documents fetch completed successfully",
        operationNamespace: "DocumentListingFetcher.fetchDocuments",
      });
      setPagination({
        pageInformation: result.pagination,
      });
      documentsDispatch({
        type: DocumentListActions.ADD,
        payload: {
          documents: result.documents,
          retrieveState: RetrievalState.SUCCESS,
        },
      });
    };

    const fetchDocuments = (params: GetDocumentsRequest) => {
      documentsDispatch({
        type: DocumentListActions.ADD,
        payload: {
          documents: [],
          retrieveState: RetrievalState.LOADING,
        },
      });

      setLastDocumentRequest(params);
      setLastSearchParams(history.location.search);

      client
        .getDocuments(params)
        .then((result: GetDocumentsResult) => handleSuccess(result))
        .catch((error: AxiosError) => handleError(error, params));
    };

    const filterManager = new FilterManager(permissions, history);

    const getDocumentsRequests =
      filterManager.getDocumentsRequest(lastDocumentRequest);

    const shouldFetchDocuments =
      documentsState.retrieveState !== RetrievalState.LOADING &&
      !!getDocumentsRequests;

    if (shouldFetchDocuments) {
      fetchDocuments(getDocumentsRequests);
    }
  }, [
    documentsDispatch,
    documentsState.retrieveState,
    history,
    lastDocumentRequest,
    lastSearchParams,
    permissions,
    setAuthorizationErrors,
    setPagination,
  ]);
};

export default useDocumentListFetcher;
