import { KatAlert, KatLink } from "@amzn/katal-react";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { isVendorCentral } from "src/api/config";
import { ValidationGroup } from "src/api/interfaces/document_validation";
import { ModalWrapper } from "src/components/Modal/ModalWrapper";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";

export interface MigrationToAICPortalWarningModalProps {
  readonly countryCode: string;
  readonly documentType: string;
  readonly useCase: string;
  readonly documentId: string;
  readonly validationGroups: ValidationGroup[];
  readonly apiResponseReceived: boolean;
}

const enabledUseCases: any = {
  BR: {
    nfe: {
      "domestic-retail": {
        externalPortalUrl:
          "https://vendorcentral.amazon.com.br/hz/vendor/members/ip-vendor-portal/invoices/{documentId}",
        internalPortalUrl:
          "https://inboundportal.aka.amazon.com/invoices/{documentId}",
        popupMessage: "aic_br_nfe_domestic_retail_aic_migration_popup_message",
      },
    },
  },
};

const MigrationToAICPortalWarningModal: FC<
  MigrationToAICPortalWarningModalProps
> = (props) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const [visible, setVisible] = useState(true);
  const {
    countryCode,
    documentType,
    useCase,
    documentId,
    validationGroups,
    apiResponseReceived,
  } = props;

  const getConfigForUseCase = () => {
    const documentTypesAvailablesForCountry =
      enabledUseCases[countryCode] || {};
    const useCasesAvailablesForDocumentType =
      documentTypesAvailablesForCountry[documentType] || {};
    return useCasesAvailablesForDocumentType[useCase] || undefined;
  };

  const configForUseCase = getConfigForUseCase();

  if (!!configForUseCase && apiResponseReceived && !validationGroups.length) {
    let link = isVendorCentral()
      ? configForUseCase.externalPortalUrl
      : configForUseCase.internalPortalUrl;
    link = link?.replace("{documentId}", documentId);

    return (
      <ModalWrapper
        title={t("aic_migration_to_aic_portal_popup_header")}
        visible={visible}
        setVisible={setVisible}
        noCloseIcon={false}
      >
        <KatAlert persistent variant="danger">
          {t("aic_br_nfe_domestic_retail_aic_migration_popup_message")}{" "}
          <KatLink label={link} href={link} target="_blank" />
        </KatAlert>
      </ModalWrapper>
    );
  }

  return <></>;
};

export default MigrationToAICPortalWarningModal;
