import { Document } from "src/api/interfaces/document";
import { RetrievalState } from "src/constants";
import {
  DataTableSelectionActions,
  DataTableSelectionProps,
} from "src/components/DataTable/DataTableDefinition";

export interface DocumentListState {
  documents: Record<string, DocumentRow>;
  retrieveState: RetrievalState;
}

export interface DocumentRow extends Document, DataTableSelectionProps {}

export enum DocumentListActions {
  ADD = 4,
}

export interface DocumentListAction {
  type: DocumentListActions | DataTableSelectionActions;
  payload: any;
}

export type ActionFunction = (
  state: DocumentListState,
  actionPayload: any
) => DocumentListState;
