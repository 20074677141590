import { DataTableSelectionAction } from "src/components/DataTable/DataTableDefinition";

export interface BulkOperations {
  readonly setBulkOperations: (updates: any) => void;
  readonly dispatcher: (action: DataTableSelectionAction<any>) => void;
}

export interface BulkOperationsRequestInformation {
  readonly countryCode: string;
  readonly documentType: string;
  readonly action?: string;
  readonly documentIds: string[];
  readonly useCases: Record<string, number>;
}

export interface BulkOperationsRequest {
  readonly countryCode: string;
  readonly documentType: string;
  readonly documentIds: string[];
  readonly action: string;
  readonly useCases: string;
}

export interface BulkOperationsResponseErrorDetail {
  readonly countryCode: string;
  readonly documentType: string;
  readonly documentId: string;
  readonly errorMessage: string;
  readonly errorAttributes: Record<string, string>;
}

export interface BulkOperationsResponse {
  readonly totalSuccessDocuments: number;
  readonly totalErrorDocuments: number;
  readonly errorDetails: BulkOperationsResponseErrorDetail[];
}

export enum BulkOperationsActions {
  Revalidate = "revalidate",
  ForceAccept = "force-accept",
  ForceReject = "force-reject",
}
