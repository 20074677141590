import setDocumentLineItems from "./actionFunctions";
import {
  ActionFunction,
  DocumentLineItemsAction,
  DocumentLineItemsActions,
  DocumentLineState,
} from "./types";

const reducerMap: Record<DocumentLineItemsActions, ActionFunction> = {
  [DocumentLineItemsActions.ADD]: setDocumentLineItems,
};

export default function documentLineItemsReducer(
  state: DocumentLineState,
  emittedAction: DocumentLineItemsAction
): DocumentLineState {
  const { type, payload } = emittedAction;

  return reducerMap[type](state, payload);
}
