import React, { FC, ReactNode, useCallback, useState } from "react";
import {
  ValidationResultsContextInterface,
  ValidationResultsResponse,
} from "src/api/interfaces/validation_result";

const DEFAULT_VALIDATION_RESULTS: ValidationResultsContextInterface = {
  validationResultsByGroup: {},
  setValidationResultsByGroup: () => {},
};

export const ValidationResultsContext =
  React.createContext<ValidationResultsContextInterface>(
    DEFAULT_VALIDATION_RESULTS
  );

export const useValidationResults = () =>
  React.useContext(ValidationResultsContext);

export const ValidationResultsContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [validationResultsByGroup, setValidationResultsByGroup] = useState<
    Record<string, ValidationResultsResponse>
  >({});

  const getValidationResultsContextValue = useCallback(
    () => ({
      validationResultsByGroup,
      setValidationResultsByGroup,
    }),
    [validationResultsByGroup, setValidationResultsByGroup]
  );

  return (
    <ValidationResultsContext.Provider
      value={getValidationResultsContextValue()}
    >
      {children}
    </ValidationResultsContext.Provider>
  );
};
