import client from "src/api/client";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import { GetDownloadDocumentResponse } from "src/api/interfaces/download_document";

const handleGetDownloadDocumentUrl = async (
  inboundDocument: InboundDocument
): Promise<GetDownloadDocumentResponse> => {
  return client.getDownloadDocumentUrl({
    countryCode: inboundDocument.countryCode,
    documentId: inboundDocument.documentId,
    documentType: inboundDocument.documentType,
  });
};

export default handleGetDownloadDocumentUrl;
