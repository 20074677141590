import { KatAlert } from "@amzn/katal-react";

import React, { FC, useContext } from "react";
import { InboundDocumentContext } from "src/components/Contexts/InboundDocumentContext";
import InboundDocumentBreadcrumb from "src/components/Pages/DocumentInbound/InboundDocumentBreadcrumb";
import classnames from "classnames";

import DocumentStatusBadge from "src/components/Pages/DocumentInbound/DocumentStatusBadge";
import DocumentDetailHeader from "src/components/Pages/DocumentInbound/Header/DocumentDetailsHeader";
import ComplianceUserActions from "src/components/UserActions/ComplianceUserActions";
import TranslationKey, {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import Markdown from "markdown-to-jsx";
import { useTranslation } from "react-i18next";
import styles from "../../../styles.module.scss";

const InboundDocumentHeader: FC = () => {
  const inboundDocument = useContext(InboundDocumentContext);
  const { t, i18n } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const COMMON_CLASSNAMES = [
    styles.flex,
    styles.flex_direction_row,
    styles.flex_nowrap,
  ];

  const keyBuilder = new TranslationKey(
    inboundDocument.countryCode,
    inboundDocument.documentType,
    inboundDocument.documentSubtype,
    inboundDocument.useCase
  );

  let bannerHeaderKey: string | undefined;
  let bannerBodyKey: string | undefined;
  let bannerVariant: KatAlert.Variant | undefined;

  const keyPrefix = keyBuilder.buildInboundRouteKey("document_banner", "aic");

  // Order matters here. Danger should take place over warning and warning over info
  const bannerTypes: KatAlert.Variant[] = [
    "success",
    "info",
    "warning",
    "danger",
  ];

  bannerTypes.forEach((bannerType) => {
    const headerKey = `${keyPrefix}_${bannerType}_header`;
    const bodyKey = `${keyPrefix}_${bannerType}_body`;

    if (i18n.exists(headerKey) && i18n.exists(bodyKey)) {
      bannerHeaderKey = headerKey;
      bannerBodyKey = bodyKey;
      bannerVariant = bannerType;
    }
  });

  return (
    <div className={classnames(styles.mt_6)}>
      <div className={classnames(...COMMON_CLASSNAMES, styles.w_100)}>
        <div
          className={classnames(
            ...COMMON_CLASSNAMES,
            styles.w_70,
            styles.justify_start
          )}
        >
          <InboundDocumentBreadcrumb />
        </div>
      </div>
      {bannerBodyKey && bannerHeaderKey && bannerVariant ? (
        <div className={classnames(styles.padding_10)}>
          <KatAlert
            persistent={false}
            header={t(bannerHeaderKey)}
            variant={bannerVariant}
          >
            <Markdown>{t(bannerBodyKey)}</Markdown>
          </KatAlert>
        </div>
      ) : (
        <></>
      )}
      <div className={classnames(...COMMON_CLASSNAMES, styles.w_100)}>
        <div
          className={classnames(
            ...COMMON_CLASSNAMES,
            styles.w_80,
            styles.justify_start
          )}
        >
          <DocumentDetailHeader
            documentDetails={inboundDocument.documentDetails}
            metadataOverrides={inboundDocument.metadataOverrides}
          />
        </div>
        <div
          className={classnames(
            ...COMMON_CLASSNAMES,
            styles.w_20,
            styles.justify_end
          )}
        >
          <div
            className={classnames(
              styles.flex,
              styles.flex_direction_column,
              styles.justify_space_between
            )}
          >
            <div
              className={classnames(
                styles.flex,
                styles.align_items_start,
                styles.justify_end
              )}
            >
              <DocumentStatusBadge content={inboundDocument} translateValue />
            </div>
            <div className={classnames(styles.flex, styles.align_items_end)}>
              <ComplianceUserActions />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboundDocumentHeader;
