import React, { FC, useEffect, useState } from "react";
import { KatDropdown } from "@amzn/katal-react";
import { useTranslation } from "react-i18next";
import TranslationKey, {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { useCountrySelection } from "src/components/Contexts/CountrySelectionContext";
import { getTranslatedValue } from "src/utils";
import i18n from "src/i18n";
import { FilterValue } from "../Contexts/FilterOptions/types";

const VALUE_SEPARATOR = ",";

export interface SelectFilterProps {
  translationPrefix: string;
  classname?: string;
  initialValue?: FilterValue;
  multiple?: boolean;
  options: string[];
  onChange: (selectedValues?: FilterValue) => void;
  searchable?: boolean;
}

const TranslatedSelectInput: FC<SelectFilterProps> = ({
  translationPrefix,
  initialValue,
  onChange,
  options,
  classname,
  multiple,
  ...props
}) => {
  const defaultEmptyValue = "";
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const [selectedValue, setSelectedValue] = useState(initialValue);
  const { selectedCountry } = useCountrySelection();

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const tKey = new TranslationKey(selectedCountry?.countryCode);

  const optionsDedupedByName: Record<string, KatalDropdownOption> =
    options.reduce((prev, optionValue) => {
      const translationKeyString =
        `${translationPrefix}_option_${optionValue}`.toLowerCase();

      const optionName = tKey.translateWithDefault(
        translationKeyString,
        getTranslatedValue(optionValue, t, optionValue),
        t
      );
      const previousValue = prev.hasOwnProperty(optionName)
        ? VALUE_SEPARATOR + prev[optionName].value
        : defaultEmptyValue;

      return {
        ...prev,
        [optionName]: {
          name: optionName,
          value: optionValue + previousValue,
        },
      };
    }, {} as Record<string, KatalDropdownOption>);

  let valueToDedupedOptionValue: Record<string, string> = {};
  Object.values(optionsDedupedByName).forEach(
    ({ value: dedupedOptionValue }) => {
      valueToDedupedOptionValue = dedupedOptionValue
        .split(VALUE_SEPARATOR)
        .reduce((prev, singleValue) => {
          return { ...prev, [singleValue]: dedupedOptionValue };
        }, valueToDedupedOptionValue);
    }
  );

  const onChangeHandler = ({ detail }: KatDropdown.ChangeEvent) => {
    const { value, values } = detail;
    const flattedValues = values
      ?.map((singleValue) => singleValue.split(VALUE_SEPARATOR))
      .flat();
    const uniqueFlattedValues = Array.from(new Set(flattedValues).keys());

    const updatedValue = multiple ? uniqueFlattedValues : value;

    setSelectedValue(updatedValue);

    onChange(updatedValue);
  };

  const getValuesDeduped = () => {
    return (selectedValue as string[]).map(
      (value) => valueToDedupedOptionValue[value]
    );
  };

  const lowerTranslationPrefix = translationPrefix.toLowerCase();

  return (
    <KatDropdown
      locale={i18n.language}
      size="large"
      value={multiple ? undefined : (selectedValue as string)}
      values={multiple ? getValuesDeduped() : undefined}
      onChange={onChangeHandler}
      options={Object.values(optionsDedupedByName)}
      className={classname}
      placeholder={tKey.translateValue(
        `${lowerTranslationPrefix}_placeholder`,
        t
      )}
      label={tKey.translateValue(lowerTranslationPrefix, t)}
      tooltip-text={tKey.translateValue(`${lowerTranslationPrefix}_tooltip`, t)}
      multiple={multiple}
      disabled={!options.length}
      showClear
      {...props}
    />
  );
};

export default TranslatedSelectInput;
