import React, { FC, useState } from "react";
import { useModalWrapper } from "src/components/Modal/ModalWrapperContext";
import { KatButton, KatInput } from "@amzn/katal-react";
import { ValidationUserActionHandlerProps } from "src/components/UserActions/Handlers/Validation/validation_user_action_handler";
import { log, LogLevel } from "src/logger";
import { useTranslation } from "react-i18next";
import { sanitizeAlphaNumericString } from "src/utils";
import {
  DocumentDetailsOverride,
  OverriddenMetadataEntry,
  ReferencedDocumentType,
} from "src/components/UserActions/Handlers/Validation/types";
import classnames from "classnames";
import styles from "src/components/UserActions/Handlers/Validation/OverrideMetadata/PurchaseOrder/styles.module.scss";

const NON_ALPHANUMERICAL_OR_HYPHEN_REGEX = /[^0-9a-z-:]/gi;

const buildDocumentDetailsWithReferencedDocumentsOverride = (
  valueOverride: string,
  typeOverride?: string
): DocumentDetailsOverride => {
  return {
    referencedDocuments: [
      {
        documentType: typeOverride ?? ReferencedDocumentType.PO,
        documentId: valueOverride,
      },
    ],
  };
};

const POHeaderEdit: FC<ValidationUserActionHandlerProps> = ({
  onCancel,
  onSubmit,
  userAction,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const { setVisible } = useModalWrapper();
  const handleOnChangeValue = ({ target: { value: newValue } }: any) => {
    if (
      userAction?.metadata?.inputTransformationType &&
      userAction?.metadata?.inputTransformationType === "none"
    ) {
      setValue(newValue);
    } else {
      setValue(
        sanitizeAlphaNumericString(newValue, NON_ALPHANUMERICAL_OR_HYPHEN_REGEX)
      );
    }
  };

  const update = () => {
    if (value && onSubmit) {
      onSubmit({
        [OverriddenMetadataEntry.HeaderPurchaseOrder]: JSON.stringify(value),
        documentDetails: JSON.stringify(
          buildDocumentDetailsWithReferencedDocumentsOverride(
            value,
            userAction?.metadata?.attributeType
          )
        ),
      });
    } else {
      log({
        level: LogLevel.WARNING,
        message: "No request has been configured for this user action.",
        operationNamespace: "ValidationUserActions.POHeaderEdit.update",
      });
    }
    setVisible(false);
  };

  return (
    <>
      <KatInput
        id="update-po-header-input"
        value={value}
        onInput={handleOnChangeValue}
        type="text"
        label={t(userAction?.label ?? "aic_doc_page_po_header_edit_label")}
      />

      <div className={classnames(styles.actionContainer)}>
        <div className={classnames(styles.buttonContainer)}>
          <KatButton
            id="cancel-update-po-header-button"
            onClick={onCancel}
            variant="secondary"
          >
            {t("aic_doc_page_po_header_edit_cancel")}
          </KatButton>
        </div>
        <div className={classnames(styles.buttonContainer)}>
          <KatButton id="update-po-header-button" onClick={update}>
            {t("aic_validate_user_actions_update_and_restart")}
          </KatButton>
        </div>
      </div>
    </>
  );
};

export default POHeaderEdit;
