import React, { FC, useContext, useMemo } from "react";
import DataTable from "src/components/DataTable";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { InboundComplianceContext } from "src/components/Contexts/InboundComplianceContext";
import { useTranslation } from "react-i18next";
import InboundComplianceDataTableDefinition from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundCompliance/InboundComplianceListing/inbound-compliance-data-table-definition";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { getTranslatedValue } from "src/utils";

const InboundComplianceGroupListing: FC = () => {
  const { inboundComplianceGroups } = useContext(InboundComplianceContext);
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const translatedComplianceGroups = useMemo(() => {
    return inboundComplianceGroups.map((group) => {
      return {
        ...group,
        name: t(group.name || ""),
        description: t(group.description, group.descriptionParameters),
        status: getTranslatedValue(group.status.valueOf(), t),
      };
    });
  }, [inboundComplianceGroups, t]);

  return (
    <div
      className={classnames(
        styles.flex,
        styles.flex_direction_row,
        styles.flex_nowrap,
        styles.w_80,
        styles.justify_start
      )}
    >
      <DataTable
        id="inbound-compliance"
        tableDefinition={InboundComplianceDataTableDefinition}
        contents={translatedComplianceGroups}
      />
    </div>
  );
};

export default InboundComplianceGroupListing;
