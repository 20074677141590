import { ReactNode, RefObject } from "react";
import {
  FilterType,
  FilterValues,
} from "src/components/Contexts/FilterOptions/types";
import { RouteComponentProps } from "react-router-dom";
import { FiltersDefinition } from "./components/Contexts/FilterOptions/definition";

const PROPERTY_SEPARATOR = ".";
const EMPTY_STRING = "";
const DECOMPOSE_ACCENTS_AND_LETTERS = "NFD";
const ACCENT_CHARACTERS_REGEX = /[\u0300-\u036f]/g;
const NON_ALPHANUMERICAL_OR_HYPHEN_REGEX = /[^0-9a-z-]/gi;

export const TRANSLATION_KEY_PREFIX = "aic_translated_value";
export const TRANSLATION_KEY_VENDOR_CENTRAL_PREFIX = "aic_vc_translated_value";
export const TRANSLATION_KEY_SEPARATOR = "_";

export function format(
  value: string,
  formatter?: (value: string) => ReactNode
) {
  return formatter && value ? formatter(value) : value;
}

export function getFromObject(object: any, path: string) {
  const pathVariables = path.split(PROPERTY_SEPARATOR);
  let response = null;
  if (pathVariables.length > 0) {
    let currentObject = object;
    for (
      let i = 0;
      i < pathVariables.length &&
      currentObject !== null &&
      currentObject !== undefined;
      i += 1
    ) {
      currentObject = currentObject[pathVariables[i]];
    }
    response = currentObject;
  }
  return response;
}

export function normalizeStringForTranslation(value: string): string {
  const SPACE_REGEX_PATTERN = / /g;
  const HYPHEN_REGEX_PATTERN = /-/g;

  let newString: string = value == null ? EMPTY_STRING : value;
  newString = newString.toLowerCase();
  newString = newString.replace(SPACE_REGEX_PATTERN, TRANSLATION_KEY_SEPARATOR);
  newString = newString.replace(
    HYPHEN_REGEX_PATTERN,
    TRANSLATION_KEY_SEPARATOR
  );
  return newString;
}

export function getTranslationKeyFromValue(value: string): string {
  return [TRANSLATION_KEY_PREFIX, normalizeStringForTranslation(value)].join(
    TRANSLATION_KEY_SEPARATOR
  );
}

export function getTranslatedValue(
  value: string,
  t: any,
  defaultValue?: string
): string {
  const fallbackValue = defaultValue !== undefined ? defaultValue : value;
  return t([value, getTranslationKeyFromValue(value)], fallbackValue);
}

export function getFilterFromUrl(): FilterValues {
  const params = new URLSearchParams(window.location.search);
  const filters: FilterValues = Object.entries(FiltersDefinition).reduce(
    (prev, [domainName, filterDefinition]) => {
      const filterValue =
        filterDefinition.type === FilterType.MULTI_VALUE
          ? params.getAll(domainName)
          : params.get(domainName) || filterDefinition.defaultSelectedValues;
      return {
        ...prev,
        [domainName]: filterValue,
      };
    },
    {} as FilterValues
  );
  return filters;
}

export function updateUrlParams(
  paramsToUpdate: Array<[string, string | string[] | undefined]>,
  history: RouteComponentProps["history"]
): void {
  const urlParams = new URLSearchParams(history.location.search);
  const initialUrlParamsString = urlParams.toString();

  paramsToUpdate.forEach(([key, value]) => {
    urlParams.delete(key);
    if (value?.length) {
      if (typeof value === "string") {
        urlParams.append(key, value);
      } else {
        (value as string[]).forEach((arrayValue) => {
          urlParams.append(key, arrayValue);
        });
      }
    }
  });

  if (urlParams.toString() !== initialUrlParamsString) {
    history.push({
      pathname: history.location.pathname,
      search: `?${urlParams.toString()}`,
    });
  }
}

export function getUrlParam(key: string): string | undefined {
  const param = new URLSearchParams(window.location.search);
  return param.get(key) || undefined;
}

export function sanitizeAlphaNumericString(
  value: string,
  nonAlphaNumericRegex = NON_ALPHANUMERICAL_OR_HYPHEN_REGEX
): string {
  return value
    .toUpperCase()
    .normalize(DECOMPOSE_ACCENTS_AND_LETTERS)
    .replace(ACCENT_CHARACTERS_REGEX, EMPTY_STRING)
    .replace(nonAlphaNumericRegex, EMPTY_STRING);
}

export function transformKeysCamelCaseToSnakeCase(
  object: any
): Record<string, unknown> {
  const out: Record<string, unknown> = {};
  Object.keys(object).forEach((key) => {
    out[key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)] =
      object[key];
  });

  return out;
}

export function handleClickOutside(
  ref: RefObject<HTMLDivElement>,
  callback: (event: any) => void
): (event: any) => void {
  return (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback(event);
    }
  };
}
