import React, { FC } from "react";
import { CountrySelectionContextProvider } from "src/components/Contexts/CountrySelectionContext";
import AICHeader from "src/components/AICLayout/AICHeader/AICHeader";
import { AlertContextProvider } from "src/components/Alert/AlertContext";
import AlertListBox from "src/components/Alert/AlertListBox";
import PermissionsFetcher from "src/components/AICLayout/AICHeader/Permissions/PermissionsFetcher";
import { PermissionsContextProvider } from "src/components/Contexts/PermissionsContext";
import { AuthorizationErrorsContextProvider } from "src/components/Contexts/AuthorizationErrorsContext";
import { CookiesProvider } from "react-cookie";

const AICLayout: FC<AICLayoutProps> = ({
  disableCountryChange,
  fixedCountry,
  disableUrlSearchParamsUpdate,
  children,
}) => {
  return (
    <CookiesProvider>
      <AuthorizationErrorsContextProvider>
        <PermissionsContextProvider>
          <CountrySelectionContextProvider>
            <AlertContextProvider>
              <PermissionsFetcher />
              <AICHeader
                fixedCountry={fixedCountry}
                disableCountryChange={disableCountryChange}
                disableUrlSearchParamsUpdate={disableUrlSearchParamsUpdate}
              />
              {children}
              <AlertListBox />
            </AlertContextProvider>
          </CountrySelectionContextProvider>
        </PermissionsContextProvider>
      </AuthorizationErrorsContextProvider>
    </CookiesProvider>
  );
};

export interface AICLayoutProps {
  disableCountryChange?: boolean;
  fixedCountry?: string;
  disableUrlSearchParamsUpdate?: boolean;
}

export default AICLayout;
