import React, { FC, useContext, useMemo } from "react";
import DataTable from "src/components/DataTable";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import RelatedDocumentsFetcher from "src/components/Pages/DocumentInbound/Tabs/Documents/RelatedDocumentsListing/RelatedDocumentsFetcher";
import { WithTranslation, withTranslation } from "react-i18next";
import { InboundDocumentContext } from "src/components/Contexts/InboundDocumentContext";
import documentDataTableDefinition from "src/components/Pages/DocumentInbound/Tabs/Documents/RelatedDocumentsListing/document-data-table-definition";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import { useRelatedDocuments } from "src/components/Contexts/RelatedDocumentsContext";
import relatedDocumentDataTableDefinition from "src/components/Pages/DocumentInbound/Tabs/Documents/RelatedDocumentsListing/related-document-data-table-definition";

const shouldRenderRelatedDocumentsTable = (
  documents: Array<InboundDocument>
) => {
  return documents && documents.length > 0;
};

const RelatedDocumentsListing: FC<WithTranslation> = ({ t }) => {
  const inboundDocument = useContext(InboundDocumentContext);
  const { relatedDocuments, relatesToDocuments } = useRelatedDocuments();

  const documents = useMemo(
    () => [...relatedDocuments, ...relatesToDocuments],
    [relatedDocuments, relatesToDocuments]
  );

  const Title = useMemo(
    () => (
      <div className={classnames(styles.mt_32)} id="related-documents-title">
        <h3>{t("aic_related_documents_title")}</h3>
      </div>
    ),
    [t]
  );

  const RelatedDocumentsTableComponent = useMemo(
    () =>
      shouldRenderRelatedDocumentsTable(documents) && (
        <div>
          {Title}
          <div
            className={classnames(
              styles.flex,
              styles.flex_direction_row,
              styles.flex_nowrap,
              styles.w_80,
              styles.justify_start
            )}
          >
            <DataTable
              id="related-documents"
              tableDefinition={relatedDocumentDataTableDefinition}
              contents={documents}
            />
          </div>
        </div>
      ),
    [documents, Title]
  );

  return (
    <div>
      <RelatedDocumentsFetcher
        countryCode={inboundDocument.countryCode}
        documentId={inboundDocument.documentId}
        documentType={inboundDocument.documentType}
      />
      <div
        className={classnames(
          styles.flex,
          styles.flex_direction_row,
          styles.flex_nowrap,
          styles.w_80,
          styles.justify_start
        )}
      >
        <DataTable
          id="main-document"
          tableDefinition={documentDataTableDefinition}
          contents={[inboundDocument]}
        />
      </div>

      {RelatedDocumentsTableComponent}
    </div>
  );
};

export default withTranslation()(RelatedDocumentsListing);
