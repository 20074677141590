import React from "react";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import ExpandableCard from "src/components/ExpandableCard/ExpandableCard";

const ProductIdCard = ({
  title,
  externalId,
  amazonIdentifiers,
}: {
  title: string;
  externalId: string;
  amazonIdentifiers: Array<string>;
}) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const productIdLabel = t(
    "aic_doc_page_product_items_tab_item_details_productid_productid"
  );
  const amazonIdentifiersLabel = t(
    "aic_doc_page_product_items_tab_item_details_productid_amazon_identifier"
  );

  return (
    <ExpandableCard expanded title={title}>
      <div>
        <strong>{productIdLabel}</strong>: {externalId}
      </div>
      <div>
        <strong>{amazonIdentifiersLabel}</strong>: {amazonIdentifiers}
      </div>
    </ExpandableCard>
  );
};

export default ProductIdCard;
