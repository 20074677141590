import React, { FC, MouseEventHandler } from "react";
import { KatButton, KatBox } from "@amzn/katal-react";
import QuickFilters from "src/components/Pages/DocumentList/Filters/QuickFilters";
import DocumentListingSearchBar from "src/components/Pages/DocumentList/Filters/SearchBar";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { useFilterOptions } from "src/components/Contexts/FilterOptions/Provider";
import {
  DomainName,
  FilterActions,
} from "src/components/Contexts/FilterOptions/types";
import { updateUrlParams } from "src/utils";

export type FilterBarProps = {
  moreFilterHandler: MouseEventHandler;
};

const FilterBar: FC<FilterBarProps> = ({ moreFilterHandler }) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const history = useHistory();
  const { dispatch } = useFilterOptions();

  const clearHandler = () => {
    dispatch({
      type: FilterActions.CLEAR,
    });
    const paramsToUpdate: Array<[DomainName, undefined]> = Object.values(
      DomainName
    ).map((domainName) => [domainName, undefined]);
    updateUrlParams(paramsToUpdate, history);
  };

  return (
    <KatBox
      variant="white"
      className={classnames(styles.w_100, styles.padding_8, styles.pt_16)}
    >
      <div
        className={classnames(
          styles.flex,
          styles.ma_8,
          styles.w_100,
          styles.justify_space_between
        )}
      >
        <div
          className={classnames(
            styles.flex,
            styles.align_items_start,
            styles.w_100
          )}
        >
          <div
            className={classnames(styles.mt_6, styles.mr_8, styles.ws_no_wrap)}
          >
            <h5>{t("aic_filter_bar_title")}</h5>
          </div>
          <QuickFilters />
          <div>
            <KatButton
              className={classnames(styles.mr_8)}
              label={t("aic_filter_bar_more_filters_label")}
              type="button"
              onClick={moreFilterHandler}
            />
            <KatButton
              className={classnames(styles.mr_8)}
              label={t("aic_filter_bar_clear_filters_label")}
              variant="secondary"
              type="button"
              onClick={clearHandler}
            />
          </div>
        </div>
        <DocumentListingSearchBar />
      </div>
    </KatBox>
  );
};

export default FilterBar;
