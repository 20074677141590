import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { KatBreadcrumb, KatBreadcrumbItem, KatLink } from "@amzn/katal-react";
import { DocumentListingURLParamsKey } from "src/api/interfaces/document_listing_filter";
import { DocumentListingInboundCentralPath } from "src/components/App";
import { InboundDocumentContext } from "src/components/Contexts/InboundDocumentContext";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { getUrlParam } from "src/utils";
import styles from "../../../styles.module.scss";
import CopyToClipboardButton from "./CopyToClipboardButton";

const InboundDocumentBreadcrumb: FC = () => {
  const {
    countryCode,
    documentId,
    documentSubtype,
    documentType,
    useCase,
    documentDetails,
  } = useContext(InboundDocumentContext);
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const getDocumentId = () => {
    return documentDetails.officialDocumentId ?? documentId;
  };

  const buildPortalPageUrl = (searchParams: URLSearchParams) => {
    return `${DocumentListingInboundCentralPath}?${searchParams.toString()}`;
  };

  const buildLastSearchParams = () => {
    const serializedLastSearchParams = getUrlParam("lastSearchParams");
    if (serializedLastSearchParams) {
      return new URLSearchParams(serializedLastSearchParams);
    }
    return new URLSearchParams();
  };

  const buildSearchparamsWithCountryCode = () => {
    return new URLSearchParams({
      [DocumentListingURLParamsKey.countryCode]: countryCode,
    });
  };

  const buildSearchParamsWithDocumentType = () => {
    const searchParams = buildSearchparamsWithCountryCode();
    searchParams.set(DocumentListingURLParamsKey.documentTypes, documentType);

    return searchParams;
  };

  const buildSearchParamsWithDocumentSubtype = () => {
    const searchParams = buildSearchParamsWithDocumentType();
    searchParams.set(
      DocumentListingURLParamsKey.documentSubtypes,
      documentSubtype
    );

    return searchParams;
  };

  const buildSearchParamsWithUseCase = () => {
    const searchParams = buildSearchParamsWithDocumentSubtype();
    searchParams.set(DocumentListingURLParamsKey.useCases, useCase);

    return searchParams;
  };

  const buildSearchParamsWithDocumentId = () => {
    const searchParams = buildSearchParamsWithUseCase();
    searchParams.set(DocumentListingURLParamsKey.documentIds, documentId);

    return searchParams;
  };

  return (
    <>
      <KatBreadcrumb>
        <KatBreadcrumbItem
          label={t("aic_doc_page_breadcrumb_document_list")}
          href={buildPortalPageUrl(buildLastSearchParams())}
        />
        <KatBreadcrumbItem
          label={countryCode}
          href={buildPortalPageUrl(buildSearchparamsWithCountryCode())}
        />
        <KatBreadcrumbItem
          label={t(documentType)}
          href={buildPortalPageUrl(buildSearchParamsWithDocumentType())}
        />
        <KatBreadcrumbItem
          label={t(documentSubtype)}
          href={buildPortalPageUrl(buildSearchParamsWithDocumentSubtype())}
        />
        <KatBreadcrumbItem
          label={t(useCase)}
          href={buildPortalPageUrl(buildSearchParamsWithUseCase())}
        />
        <div>
          <KatLink
            label={getDocumentId()}
            href={buildPortalPageUrl(buildSearchParamsWithDocumentId())}
          />
          <CopyToClipboardButton
            label="document-id"
            value={getDocumentId()}
            translationPrefix="aic_doc_page_breadcrumb_document_list_document_id_popover"
          />
        </div>
        <KatBreadcrumbItem label="" />
        <KatBreadcrumbItem
          className={styles.capitalizedText}
          label={t("aic_doc_page_breadcrumb_details")}
        />
      </KatBreadcrumb>
    </>
  );
};

export default InboundDocumentBreadcrumb;
