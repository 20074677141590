import { GetDocumentsRequest } from "src/api/interfaces/document_listing";
import { Permissions } from "src/api/interfaces/permissions";
import {
  DomainName,
  FilterType,
  FilterValues,
} from "src/components/Contexts/FilterOptions/types";
import { RouteComponentProps } from "react-router-dom";
import { PageInformation } from "src/api/interfaces/pagination";
import { FiltersDefinition } from "src/components/Contexts/FilterOptions/definition";
import {
  DEFAULT_PAGE_INFORMATION,
  PAGE_QS_KEY,
  PAGE_SIZE_QS_KEY,
} from "src/components/Contexts/PaginationContext";
import { COUNTRY_QS_KEY } from "src/components/AICLayout/AICHeader/CountrySelector/CountrySelector";

type RouterHistory = RouteComponentProps["history"];

const EMPTY_ITERATOR = new Map().keys();
const NO_USE_CASES: string[] = [];

class FilterManager {
  private urlParams: URLSearchParams;

  private pageInformation?: Partial<PageInformation>;

  private countryCode?: string;

  private filters?: FilterValues;

  constructor(permissions: Permissions, history: RouterHistory) {
    const urlParams = new URLSearchParams(history.location.search);
    this.urlParams = urlParams;

    this.pageInformation = this.parsePageInformation();
    this.countryCode = this.parseCountryCode();
    this.filters = this.parseFilters(permissions);
  }

  private getUseCasesFromPermissions(
    permissionsContext: Permissions,
    useCaseFromFilter: string[] | null
  ): string[] {
    if (!this.countryCode) {
      return NO_USE_CASES;
    }
    const allAllowedUseCases = Array.from(
      permissionsContext.permissions.get(this.countryCode)?.keys() ||
        EMPTY_ITERATOR
    );

    const selectedUseCases = allAllowedUseCases.filter((useCase) =>
      useCaseFromFilter?.includes(useCase)
    );

    return selectedUseCases.length ? selectedUseCases : allAllowedUseCases;
  }

  private parsePageInformation(): Partial<PageInformation> | undefined {
    const pageAsString = this.urlParams.get(PAGE_QS_KEY);
    const pageSizeAsString = this.urlParams.get(PAGE_SIZE_QS_KEY);

    if (pageAsString && pageSizeAsString) {
      return {
        page: parseInt(pageAsString, 10),
        pageSize: parseInt(pageSizeAsString, 10),
      };
    }

    return undefined;
  }

  private parseCountryCode(): string | undefined {
    return this.urlParams.get(COUNTRY_QS_KEY) ?? undefined;
  }

  private parseFilters(permissions: Permissions): FilterValues | undefined {
    const filters = Object.values(DomainName).reduce((prev, domainName) => {
      const filterDefinition = FiltersDefinition[domainName];
      return {
        ...prev,
        [domainName]:
          filterDefinition.type === FilterType.MULTI_VALUE
            ? this.urlParams.getAll(domainName)
            : this.urlParams.get(domainName),
      };
    }, {} as FilterValues);
    const useCases = this.getUseCasesFromPermissions(
      permissions,
      filters[DomainName.USE_CASE] as string[]
    );
    if (useCases.length) {
      filters[DomainName.USE_CASE] = useCases;
      return filters;
    }
    return undefined;
  }

  private isParametersEmpty(): boolean {
    return !this.pageInformation || !this.countryCode || !this.filters;
  }

  private shouldClearPagination(
    lastDocumentRequest?: GetDocumentsRequest
  ): boolean {
    /* to avoid clear pagination when page is loaded for the first time or URL history has changed,
      we check if it keeps the last page requested and changes countryCode or search criteria */
    return (
      !!lastDocumentRequest &&
      this.pageInformation?.page === lastDocumentRequest?.page
    );
  }

  private filtersHaveChanged(lastDocumentsRequest?: GetDocumentsRequest) {
    if (!lastDocumentsRequest?.filter && !!this.filters) {
      return true;
    }
    return !Object.keys(this.filters!).every((key) => {
      const domainName = key as DomainName;
      return (
        this.filters![domainName]?.toString() ===
        lastDocumentsRequest!.filter![domainName]?.toString()
      );
    });
  }

  private haveParamsBeenUpdated(
    lastDocumentRequest?: GetDocumentsRequest
  ): boolean {
    return (
      this.countryCode !== lastDocumentRequest?.countryCode ||
      this.pageInformation?.page !== lastDocumentRequest?.page ||
      this.pageInformation?.pageSize !== lastDocumentRequest?.page_size ||
      this.filtersHaveChanged(lastDocumentRequest)
    );
  }

  public getDocumentsRequest(
    lastDocumentsRequest?: GetDocumentsRequest
  ): GetDocumentsRequest | undefined {
    if (
      this.isParametersEmpty() ||
      !this.haveParamsBeenUpdated(lastDocumentsRequest)
    ) {
      return undefined;
    }

    const pageValue = this.shouldClearPagination(lastDocumentsRequest)
      ? DEFAULT_PAGE_INFORMATION.page
      : this.pageInformation!.page!;

    return {
      page: pageValue,
      page_size: this.pageInformation!.pageSize!,
      countryCode: this.countryCode!,
      filter: this.filters,
    };
  }
}

export default FilterManager;
