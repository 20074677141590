import React from "react";
import AlertItem from "src/components/Alert/AlertItem";
import DataTable from "src/components/DataTable";
import { useTranslation } from "react-i18next";
import { useValidationResults } from "src/components/Contexts/ValidationResultsContext";
import { MismatchTableData } from "src/api/interfaces/validation_result";
import { DataTableDefinition } from "src/components/DataTable/DataTableDefinition";
import {
  MismatchTableRowSorter,
  MismatchTableTransformer,
} from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/types";

const ALERT_LABEL = "aic_mismatch_exceeds_size_limit_alert";

export interface MismatchTableProps<T> {
  readonly dataTableDefinition: DataTableDefinition<T>;
  readonly dataTransformer: MismatchTableTransformer<T>;
  readonly rowSorter?: MismatchTableRowSorter<T>;
  readonly validationGroupId: string;
}

export default function MismatchTable<T>({
  dataTableDefinition,
  dataTransformer,
  rowSorter,
  validationGroupId,
}: MismatchTableProps<T>) {
  const { t } = useTranslation();
  const { validationResultsByGroup } = useValidationResults();
  const validationResults =
    validationResultsByGroup[validationGroupId]?.validationResults || [];
  const exceedsSizeLimits =
    validationResultsByGroup[validationGroupId]?.exceedsSizeLimits || false;

  const mismatchTableData: MismatchTableData<T> = {
    mismatchRowData: validationResults.map((validationResult, index) =>
      dataTransformer(validationResult, index, t)
    ),
  };

  if (rowSorter) {
    mismatchTableData.mismatchRowData.sort(rowSorter);
  }

  return (
    <>
      {exceedsSizeLimits && (
        <AlertItem description={t(ALERT_LABEL)} variant="warning" />
      )}
      <DataTable
        id="mismatch-table"
        tableDefinition={dataTableDefinition}
        contents={mismatchTableData.mismatchRowData}
      />
    </>
  );
}
