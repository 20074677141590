import React, { FC, useCallback, useState } from "react";
import { useInboundComplianceGroupDetails } from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundComplianceModals/Listing/InboundComplianceGroupDetailsContext";
import DataTable from "src/components/DataTable";
import { InboundComplianceGroupDetailsField as GroupDetails } from "src/constants";
import { DynamicTableData } from "src/api/interfaces/inbound_compliance_group";
import convertToDynamicTableDefinition from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundComplianceModals/Listing/dynamic-data-table-definition";
import convertToDynamicTableFields from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundComplianceModals/Listing/dynamic-table-fields-extractor";
import usePageInformation from "src/components/Pagination/hooks/usePageInformation";
import PaginationInMemory from "src/components/Pagination/PaginationInMemory";
import { PageInformation } from "src/api/interfaces/pagination";

export const DynamicTable: FC = () => {
  const { inboundComplianceGroupDetails, groupDetailsRetrievalState } =
    useInboundComplianceGroupDetails();
  const dynamicDataTableDefinition = convertToDynamicTableDefinition(
    inboundComplianceGroupDetails
  );
  const dynamicDataTableFields: DynamicTableData = { rowData: [] };
  const { pageInformation, setPageInformation } = usePageInformation({
    updateUrl: false,
  });

  const [tablePage, setTablePage] = useState<Array<any>>();

  dynamicDataTableFields.rowData = inboundComplianceGroupDetails
    .map((entry) =>
      convertToDynamicTableFields(entry, dynamicDataTableDefinition)
    )
    .sort((firstField, secondField) =>
      firstField[GroupDetails.ITEM_ID.valueOf()] <
      secondField[GroupDetails.ITEM_ID.valueOf()]
        ? -1
        : 1
    );

  const onChangePage = useCallback(
    (items: Array<any>, newPageInformation: PageInformation) => {
      setTablePage(items);
      setPageInformation(newPageInformation);
    },
    [setPageInformation, setTablePage]
  );

  return (
    <div>
      <DataTable
        id="dynamic-table"
        contents={tablePage}
        tableDefinition={dynamicDataTableDefinition}
        retrievalState={groupDetailsRetrievalState}
      />
      <PaginationInMemory
        page={pageInformation.page}
        pageSize={pageInformation.pageSize}
        items={dynamicDataTableFields.rowData}
        onChange={onChangePage}
        updateUrl={false}
      />
    </div>
  );
};

export default DynamicTable;
