import { KatButton } from "@amzn/katal-react";

export interface ButtonState {
  variant: KatButton.Variant;
  loading: boolean;
  message: string;
  isError: boolean;
}

export const INITIAL_STATE: ButtonState = {
  variant: "primary",
  loading: false,
  message: "",
  isError: false,
};

export const LOADING_STATE: ButtonState = {
  variant: "secondary",
  loading: true,
  message: "",
  isError: false,
};
