import React, { FC, useState } from "react";
import { KatAlert } from "@amzn/katal-react";
import { Transition } from "react-transition-group";
import classnames from "classnames";
import styles from "./styles.module.scss";

export interface AlertProps {
  description?: string;
  header?: string;
  variant: KatAlert.Variant;
  fade?: boolean;
}

const FADE_TIMEOUT = 5000;

const AlertItem: FC<AlertProps> = ({
  description,
  header,
  variant,
  fade = false,
}) => {
  const [inProp, setInProp] = useState(true);
  if (fade) {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      setInProp(false);
    }, FADE_TIMEOUT);
  }

  return (
    <Transition
      in={inProp}
      timeout={{ enter: 0, exit: FADE_TIMEOUT / 10 }}
      unmountOnExit
      appear
    >
      {(state) => (
        <div
          className={classnames({
            [styles.alert_default_style]: true,
            [styles.alert_static_style]: true,
            [styles.alert_entered]: state === "entered",
            [styles.alert_entering]: state === "entering",
            [styles.alert_exited]: state === "exited",
            [styles.alert_exiting]: state === "exiting",
          })}
        >
          <KatAlert
            description={description}
            header={header}
            variant={variant}
            persistent
          />
        </div>
      )}
    </Transition>
  );
};

export default AlertItem;
