import React from "react";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { DocumentLineItemsListContextProvider } from "src/components/Contexts/DocumentLineItems/Provider";
import { UserActionsMetadataContextProvider } from "src/components/Contexts/UserActionsMetadata/Provider";
import DocumentItemsTable from "../DocumentItemsTable";

const DocumentItemsListing = () => {
  return (
    <DocumentLineItemsListContextProvider>
      <UserActionsMetadataContextProvider>
        <div className={classnames(styles.ma_16)}>
          <DocumentItemsTable />
        </div>
      </UserActionsMetadataContextProvider>
    </DocumentLineItemsListContextProvider>
  );
};

export default DocumentItemsListing;
