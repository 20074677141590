import React, { FC, useEffect, useState } from "react";
import classnames from "classnames";
import AICLayout from "src/components/AICLayout/AICLayout";
import { PaginationContextProvider } from "src/components/Contexts/PaginationContext";
import InternalIssueMessage from "src/components/InternalIssueMessage";
import BulkOperations from "src/components/Pages/DocumentList/BulkOperations";
import SideBarFilter from "src/components/Pages/DocumentList/Filters/SideBarFilter";
import FilterBar from "src/components/Pages/DocumentList/Filters/FilterBar";
import { DocumentListContextProvider } from "src/components/Contexts/DocumentList/Provider";
import { FilterOptionsContextProvider } from "src/components/Contexts/FilterOptions/Provider";
import DocumentTable from "./DocumentTable";
import styles from "../../../styles.module.scss";

const DocumentListing: FC = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    setSidebarVisible(sidebarVisible);
  }, [sidebarVisible]);

  const moreFilterHandler = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <AICLayout>
      <PaginationContextProvider>
        <FilterOptionsContextProvider>
          <DocumentListContextProvider>
            <InternalIssueMessage
              persistent
              variant="info"
              contextStringId="aic_internal_issue_message_description"
              headerStringId="aic_internal_issue_message_header"
            />
            <div
              className={classnames(
                styles.ma_10,
                styles.display_flex,
                styles.flex_direction_column
              )}
            >
              <div
                className={classnames(
                  styles.flex,
                  styles.flex_nowrap,
                  styles.flex_direction_row,
                  styles.w_100,
                  styles.justify_space_between
                )}
              >
                <div className={classnames(styles.w_100)}>
                  <div
                    className={classnames(
                      styles.flex,
                      styles.flex_direction_row,
                      styles.flex_nowrap,
                      styles.w_100,
                      styles.align_items_center,
                      styles.justify_space_between
                    )}
                  >
                    <FilterBar moreFilterHandler={moreFilterHandler} />
                  </div>
                  <div
                    className={classnames(
                      styles.flex,
                      styles.justify_space_between,
                      styles.align_items_end
                    )}
                  >
                    <div
                      className={classnames(
                        styles.flex,
                        styles.align_items_center,
                        styles.justify_start,
                        styles.mt_16
                      )}
                    >
                      <BulkOperations />
                    </div>
                  </div>
                </div>
              </div>
              <DocumentTable />
            </div>
            <SideBarFilter
              visible={sidebarVisible}
              onCancel={() => setSidebarVisible(false)}
              onSubmit={() => setSidebarVisible(false)}
            />
          </DocumentListContextProvider>
        </FilterOptionsContextProvider>
      </PaginationContextProvider>
    </AICLayout>
  );
};

export default DocumentListing;
