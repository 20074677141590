import React, { FC, ReactNode, useCallback, useState } from "react";
import { AuthorizationErrors } from "src/api/interfaces/permissions";

const DEFAULT_AUTHORIZATION_ERROR_CONTEXT: AuthorizationErrors = {
  authorizationErrors: new Set(),
  setAuthorizationErrors: () => {},
};

export const AuthorizationErrorsContext =
  React.createContext<AuthorizationErrors>(DEFAULT_AUTHORIZATION_ERROR_CONTEXT);

export const useAuthorizationErrors = () =>
  React.useContext(AuthorizationErrorsContext);

export const AuthorizationErrorsContextProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [authorizationErrors, setAuthorizationErrors] =
    useState<AuthorizationErrors>(DEFAULT_AUTHORIZATION_ERROR_CONTEXT);

  // here context value is just returning an object, but only
  // re-creating the object when its dependencies change ([state, setContext])
  const getAuthorizationErrorContextValue = useCallback(
    () => ({
      ...authorizationErrors,
      setAuthorizationErrors,
    }),
    [authorizationErrors, setAuthorizationErrors]
  );
  return (
    <AuthorizationErrorsContext.Provider
      value={getAuthorizationErrorContextValue()}
    >
      {children}
    </AuthorizationErrorsContext.Provider>
  );
};
