import React, { FC, useEffect } from "react";
import client, { UNAUTHORIZED_HTTP_STATUS_CODE } from "src/api/client";
import { log, LogLevel } from "src/logger";
import { AxiosError } from "axios";
import { GetRelatedDocumentsRequest } from "src/api/interfaces/related_documents";
import { useAuthorizationErrors } from "src/components/Contexts/AuthorizationErrorsContext";
import useUnauthorizedRedirect from "src/components/AICLayout/AICHeader/Permissions/WithRedirectWhenAuthorized";
import { AuthorizationErrors } from "src/api/interfaces/permissions";
import { useRelatedDocuments } from "src/components/Contexts/RelatedDocumentsContext";

const RelatedDocumentsFetcher: FC<GetRelatedDocumentsRequest> = ({
  countryCode,
  documentType,
  documentId,
}: GetRelatedDocumentsRequest) => {
  useUnauthorizedRedirect();

  const { setRelatedDocuments, setRelatesToDocuments } = useRelatedDocuments();
  const { setAuthorizationErrors } = useAuthorizationErrors();

  useEffect(() => {
    const areParametersPresent = (parameters: GetRelatedDocumentsRequest) => {
      return (
        parameters &&
        parameters.countryCode &&
        parameters.documentType &&
        parameters.documentId
      );
    };
    const params: GetRelatedDocumentsRequest = {
      documentId,
      documentType,
      countryCode,
    };
    const fetchRelatedDocuments = () => {
      client
        .getRelatedDocuments(params)
        .then((result) => {
          log({
            level: LogLevel.INFO,
            message: "Related Documents fetch completed successfully",
            operationNamespace: "RelatedDocumentsFetcher.fetchRelatedDocuments",
          });

          setRelatedDocuments(result.relatedDocuments);
          setRelatesToDocuments(result.relatesToDocuments);
        })
        .catch((error: AxiosError) => {
          log({
            level: LogLevel.ERROR,
            message:
              "There was an error while trying to fetch Related Documents",
            operationNamespace: "RelatedDocumentsFetcher.fetchRelatedDocuments",
            exception: error?.message,
            attributes: { params },
          });

          if (error.response?.status === UNAUTHORIZED_HTTP_STATUS_CODE) {
            setAuthorizationErrors((prevState: AuthorizationErrors) => ({
              authorizationErrors: prevState.authorizationErrors.add(
                error.message
              ),
            }));
          }
        });
    };
    if (areParametersPresent(params)) {
      fetchRelatedDocuments();
    }
  }, [
    countryCode,
    documentId,
    documentType,
    setAuthorizationErrors,
    setRelatedDocuments,
    setRelatesToDocuments,
  ]);

  return <div />;
};

export default RelatedDocumentsFetcher;
