import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { updateUrlParams } from "src/utils";
import {
  DomainName,
  FilterActions,
} from "src/components/Contexts/FilterOptions/types";
import { useFilterOptions } from "src/components/Contexts/FilterOptions/Provider";
import { FiltersDefinition } from "src/components/Contexts/FilterOptions/definition";
import SearchBar from "src/components/SearchBar";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import styles from "src/styles.module.scss";

const DocumentListingSearchBar: FC = () => {
  const history = useHistory();
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const {
    state: { selectedValues: filter },
    dispatch,
  } = useFilterOptions();

  const search = (newSearchValue?: string) => {
    updateUrlParams([[DomainName.SEARCH, newSearchValue]], history);

    dispatch({
      type: FilterActions.SET_SELECTED_VALUES,
      payload: {
        domainName: DomainName.SEARCH,
        value: newSearchValue,
      },
    });
  };

  const handleSearchChange = (searchPattern: string) => {
    search(searchPattern);
  };

  const handleClearSearch = () => {
    search(
      FiltersDefinition[DomainName.SEARCH].defaultSelectedValues as string
    );
  };

  const shouldShowClearButton = () => {
    return !!filter.search;
  };

  return (
    <SearchBar
      title={t("aic_search_document_title")}
      placeholder={t("aic_search_document_place_holder")}
      searchablePattern={filter.search as string}
      onChange={handleSearchChange}
      onClear={handleClearSearch}
      showClearButton={shouldShowClearButton()}
      classes={[styles.mr_8]}
    />
  );
};

export default DocumentListingSearchBar;
