export const dateIsGreaterThanNow = (date: Date) => date.valueOf() > Date.now();

export const isDateDisabledWhenStartDate =
  (startDate: string) =>
  (calendarDate: Date): boolean =>
    Date.parse(startDate) > calendarDate.valueOf() ||
    dateIsGreaterThanNow(calendarDate);

export const isDateDisabledWhenEndDate =
  (endDate: string) =>
  (calendarDate: Date): boolean =>
    Date.parse(endDate) < calendarDate.valueOf() ||
    dateIsGreaterThanNow(calendarDate);
