import React, { FC } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import classnames from "classnames";
import { Country } from "src/api/interfaces/country";
import { useCountrySelection } from "src/components/Contexts/CountrySelectionContext";
import styles from "src/styles.module.scss";
import { KatAlert } from "@amzn/katal-react";

import { KatalReplacement } from "src/constants";
import { isLocal } from "src/api/config";
import env, { ProcessEnvCustomVariables } from "src/env";

const affectedCountries = ["MX"];

const isPortalHavingInternalIssues = () => {
  return isLocal()
    ? env.getVariable(
        ProcessEnvCustomVariables.IS_PORTAL_HAVING_INTERNAL_ISSUES
      )
    : KatalReplacement.IS_PORTAL_HAVING_INTERNAL_ISSUES;
};

const shouldShowInternalIssueMessage = (
  selectedCountry: Country | undefined
) => {
  return (
    selectedCountry?.countryCode &&
    affectedCountries.includes(selectedCountry.countryCode) &&
    isPortalHavingInternalIssues() === "true"
  );
};

const InternalIssueMessage: FC<InternalIssueMessageProps & WithTranslation> = ({
  persistent,
  variant,
  headerStringId,
  contextStringId,
  t,
}) => {
  const { selectedCountry } = useCountrySelection();

  const component = (
    <div className={classnames(styles.padding_10)}>
      <KatAlert
        persistent={persistent}
        header={t(headerStringId)}
        variant={variant}
      >
        {t(contextStringId)}.
      </KatAlert>
    </div>
  );

  return shouldShowInternalIssueMessage(selectedCountry) ? component : <></>;
};

export interface InternalIssueMessageProps {
  persistent: boolean;
  variant: KatAlert.Variant;
  headerStringId: string;
  contextStringId: string;
}

export default withTranslation()(InternalIssueMessage);
