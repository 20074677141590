import { log, LogLevel } from "src/logger";
import { Operation } from "src/api/interfaces/permissions";

function checkPermission(
  permissions: Map<string, Map<string, Operation[]>>,
  countryCode: string | undefined,
  useCase: string,
  operation: Operation
): boolean {
  const authorizedOperations = permissions.get(countryCode || "")?.get(useCase);

  log({
    level: LogLevel.INFO,
    message: "Checking authorization.",
    attributes: {
      countryCode,
      useCase,
      authorizedOperations,
      operation,
    },
    operationNamespace: "PermissionCheckers.usePermissionChecker",
  });

  return !!authorizedOperations?.includes(operation);
}

export function checkPermissionOnCountry(
  permissions: Map<string, Map<string, Operation[]>>,
  countryCode: string | undefined,
  operation: Operation
): boolean {
  let operationsJoined: Operation[] = [];

  permissions.get(countryCode || "")?.forEach((operations) => {
    operationsJoined = operationsJoined.concat(operations);
  });

  return operationsJoined.includes(operation);
}

export default checkPermission;
