import { isMidwayCookieSet, setMidwayCookie } from "src/cookies";
import { randomBytes } from "crypto";
import { isDirectAccess } from "src/api/config";
import { log, LogLevel } from "src/logger";

export const MIDWAY_DOMAIN = "midway-auth.amazon.com";
const AIC_DOMAIN = "inbound-central.aic.intech.amazon.dev";
const ID_TOKEN_QUERY_PARAM = "id_token";
const SIZE = 64;
const ENCODING = "hex";

type QueryParameters = { [key: string]: string };

export function generateNonce(): string {
  return randomBytes(SIZE).toString(ENCODING);
}

function buildCommonQueryParameters(): QueryParameters {
  return {
    response_type: "id_token",
    client_id: window.location.host,
    redirect_uri: window.location.href,
    scope: "openid",
    nonce: generateNonce(),
  };
}

function buildSSOUrl() {
  const urlSearchParams = new URLSearchParams(buildCommonQueryParameters());

  return `https://${MIDWAY_DOMAIN}/SSO?${urlSearchParams.toString()}`;
}

function buildRedirectUrl(): string {
  const queryParams = buildCommonQueryParameters();
  queryParams.response_type = "id_token";

  const urlSearchParams = new URLSearchParams(queryParams);

  return `https://${MIDWAY_DOMAIN}/login?next=/SSO/redirect?${encodeURIComponent(
    urlSearchParams.toString()
  )}`;
}

export function retrieveMidwayToken(): void {
  const url = new URL(window.location.href);
  const idToken = url.searchParams.get(ID_TOKEN_QUERY_PARAM);

  if (idToken) {
    setMidwayCookie(idToken);
    url.searchParams.delete(ID_TOKEN_QUERY_PARAM);
    window.location.replace(url);
  } else {
    fetch(buildSSOUrl(), { credentials: "include" })
      .then((response) => {
        if (response.ok) {
          const tokenPromise = response.text();
          tokenPromise.then(setMidwayCookie);
        } else {
          window.alert("Midway authentication missing, redirecting you...");
          window.location.replace(buildRedirectUrl());
        }
      })
      .catch((reason) => {
        log({
          level: LogLevel.ERROR,
          message: "Fetch to SSO rejected",
          operationNamespace: "MidwayConnector",
          attributes: {
            reason,
          },
        });
        window.location.replace(buildRedirectUrl());
      });
  }
}

export const renewMidwayTokenOrRedirect = (): void => {
  if (isDirectAccess()) {
    window.location.replace(`https://${AIC_DOMAIN}`);
  }

  if (!isMidwayCookieSet()) {
    retrieveMidwayToken();
  }
};
