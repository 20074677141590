import React, { FC, useState } from "react";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import { ValidationRuleSeverity } from "src/api/interfaces/document_validation";
import TranslatedSelectInput from "src/components/Inputs/TranslatedSelectInput";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";

interface LineItemsRuleSeverityFilterProps {
  onChange: (value: any) => void;
  metricsPublisher: () => void;
  disabled?: boolean;
}

const LineItemsRuleSeverityFilter: FC<LineItemsRuleSeverityFilterProps> = ({
  onChange,
  metricsPublisher,
  disabled,
}) => {
  const [selectedSeverities, setSelectedSeverities] = useState([]);
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const onChangeHandler = (value: any) => {
    metricsPublisher();
    onChange(value);
    setSelectedSeverities(value);
  };

  return (
    <>
      <div className={classnames(styles.mb_zero_important, styles.ma_6)}>
        <h5>
          {t("aic_doc_page_product_items_tab_rule_severity_filter_label")}
        </h5>
      </div>
      <TranslatedSelectInput
        classname={classnames(styles.mr_8, styles.w_fixed_250_px)}
        translationPrefix="aic_doc_page_product_items_tab_rule_severity_filter"
        options={disabled ? [] : Object.values(ValidationRuleSeverity)}
        onChange={onChangeHandler}
        initialValue={selectedSeverities}
        multiple
      />
    </>
  );
};

export default LineItemsRuleSeverityFilter;
