import React, { FC, ReactNode } from "react";
import classnames from "classnames";
import { format } from "src/utils";
import styles from "src/styles.module.scss";
import { isString } from "lodash";

const HTTPS_URL_PREFIX = "https://";
const NOT_FOUND_INDEX = -1;

const addAnchorMarkup = (textContent: string) => {
  if (!isString(textContent)) {
    return textContent;
  }

  const chunks = [];
  let end = 0;
  let begin = textContent.indexOf(HTTPS_URL_PREFIX);

  if (begin === NOT_FOUND_INDEX) {
    return textContent;
  }

  while (begin !== NOT_FOUND_INDEX) {
    chunks.push(<>{textContent.substring(end, begin)}</>);

    end = textContent.indexOf(" ", begin);
    if (end < 0) {
      end = textContent.length;
    }

    const url = textContent.substring(begin, end);
    chunks.push(
      <a href={url} target="_blank" rel="noreferrer">
        {url}
      </a>
    );

    begin = textContent.indexOf(HTTPS_URL_PREFIX, end);
  }

  return <> {chunks} </>;
};

const Field: FC<FieldDefinition<any, any>> = ({
  value,
  label,
  labelStyle,
  valueStyle,
  formatter,
  childComponentOnly,
  ChildComponent,
  childComponentProps,
}) => {
  const formattedValue =
    !!formatter && !childComponentOnly ? format(value, formatter) : value;

  const urlRenderedValue = addAnchorMarkup(formattedValue);

  const labelText =
    Array.isArray(labelStyle) && labelStyle.length ? (
      <span className={classnames(labelStyle)}>{label}:&nbsp;</span>
    ) : (
      <span>{label ? `${label}: ` : null}&nbsp;</span>
    );
  const valueText =
    Array.isArray(valueStyle) && valueStyle.length ? (
      <span className={classnames(valueStyle)}>{urlRenderedValue}</span>
    ) : (
      <span>{urlRenderedValue}</span>
    );

  const shouldShowLabelText = !!label && !childComponentOnly;
  const shouldShowValueText = urlRenderedValue && !childComponentOnly;

  return (
    <div className={classnames(styles.display_block)}>
      {ChildComponent ? (
        <>
          <ChildComponent {...childComponentProps} />
          {!childComponentOnly ? <br /> : null}
        </>
      ) : null}
      {shouldShowLabelText || shouldShowValueText ? (
        <div
          className={classnames(
            styles.flex,
            styles.flex_direction_row,
            styles.flex_nowrap
          )}
        >
          {shouldShowLabelText ? labelText : null}
          {shouldShowValueText ? valueText : null}
        </div>
      ) : null}
    </div>
  );
};

export interface FieldDefinition<ValueType, ChildComponentPropsType> {
  value: ValueType;
  label?: string;
  valueStyle?: Array<string>;
  labelStyle?: Array<string>;
  formatter?: (value: ValueType) => ReactNode;
  ChildComponent?: FC<ChildComponentPropsType>;
  childComponentOnly?: boolean;
  childComponentProps?: ChildComponentPropsType;
}

export default Field;
