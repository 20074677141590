import React from "react";
import { useTranslation } from "react-i18next";
import { get, isNull, omitBy } from "lodash";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import ExpandableCard from "src/components/ExpandableCard/ExpandableCard";
import InfoTable from "src/components/InfoTable/InfoTable";
import { TaxDetails } from "src/api/interfaces/line_item";

const TaxDetailsCard = ({
  title,
  taxDetails,
}: {
  title: string;
  taxDetails: Array<TaxDetails>;
}) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  const notApplicable = t("aic_doc_page_product_items_tab_not_applicable");

  const taxNameLabel = t(
    "aic_doc_page_product_items_tab_item_details_tax_details_name_column"
  );
  const taxRateLabel = t(
    "aic_doc_page_product_items_tab_item_details_tax_details_rate_column"
  );
  const taxAmountLabel = t(
    "aic_doc_page_product_items_tab_item_details_tax_details_amount_column"
  );

  return (
    <ExpandableCard expanded title={title}>
      <InfoTable
        columns={[taxNameLabel, taxRateLabel, taxAmountLabel]}
        rows={taxDetails
          .map((tax: TaxDetails) => omitBy(tax, isNull))
          .map((tax: TaxDetails) => ({
            Name: get(tax, "taxName", notApplicable),
            Rate: get(tax, "taxRate", notApplicable),
            Amount: get(tax, "baseUnitCost", notApplicable),
          }))}
      />
    </ExpandableCard>
  );
};

export default TaxDetailsCard;
