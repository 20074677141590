import React, { FC, useState } from "react";
import classnames from "classnames";
import { KatIcon } from "@amzn/katal-react";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import publishMetric, { MetricAction } from "src/metrics";
import DocumentItemDetailsModal from "./DocumentItemDetailsModal";
import styles from "./document_item_details_styles.module.scss";

const DocumentItemDetails: FC<DataTableCellChildComponentProps<any>> = ({
  content,
}: any) => {
  const [visible, setVisible] = useState(false);

  const onClickDetails = (event: React.MouseEvent<KatIcon.Element>) => {
    publishMetric(MetricAction.DOCUMENT_ITEMS_TAB_DETAILS_CLICK);
    setVisible(true);
    event.stopPropagation();
  };

  return (
    <>
      <KatIcon
        onClick={onClickDetails}
        className={classnames(styles.info)}
        slot="action"
        name="info_outline"
        size="small"
      />
      <DocumentItemDetailsModal
        onClose={() => setVisible(false)}
        content={content}
        visible={visible}
      />
    </>
  );
};
export default DocumentItemDetails;
