import { OverriddenMetadataEntry, ReferencedDocumentType } from "../types";

export const HEADER_OVERRIDDEN_METADATA_KEY_BY_DOCUMENT_TYPE: Map<
  string,
  string
> = new Map().set(
  ReferencedDocumentType.PO,
  OverriddenMetadataEntry.HeaderPurchaseOrder
);

export const LINE_ITEM_OVERRIDDEN_METADATA_KEY_BY_DOCUMENT_TYPE: Map<
  string,
  string
> = new Map().set(
  ReferencedDocumentType.PO,
  OverriddenMetadataEntry.LineItemPurchaseOrder
);

export default HEADER_OVERRIDDEN_METADATA_KEY_BY_DOCUMENT_TYPE;
