import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { KatButton } from "@amzn/katal-react";
import classnames from "classnames";
import styles from "src/styles.module.scss";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import side_bar_style from "./side_bar_styles.module.scss";

export type SideBarFooterProps = {
  onSubmit: (e: any) => void;
  onCancel: (e: any) => void;
};

const SideBarFooter: FC<SideBarFooterProps> = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  return (
    <div
      className={classnames(styles.ma_16, side_bar_style.side_bar_footer_style)}
    >
      <KatButton
        label={t("aic_side_bar_apply_button")}
        type="button"
        onClick={onSubmit}
      />
      <KatButton
        label={t("aic_side_bar_cancel_button")}
        type="button"
        onClick={onCancel}
      />
    </div>
  );
};

export default SideBarFooter;
