import React from "react";

import DataTable from "src/components/DataTable";
import { LineItemRepresentation } from "src/api/interfaces/line_item";
import documentItemsErrorDataTableDefinition from "./document-items-error-data-table-definition";

const DocumentItemsErrorTable = ({
  content,
}: {
  content: LineItemRepresentation;
}) => {
  return (
    <DataTable
      id={`product-items-error-${content.index}`}
      tableDefinition={documentItemsErrorDataTableDefinition}
      contents={content.validationErrors}
    />
  );
};

export default DocumentItemsErrorTable;
