import React, { FC, ReactNode, useCallback, useState } from "react";
import { InboundCompliance } from "src/api/interfaces/inbound_compliance_group";

const DEFAULT_CONTEXT: InboundCompliance = {
  inboundComplianceGroups: [],
  setInboundComplianceGroups: () => {},
};

export const InboundComplianceContext =
  React.createContext<InboundCompliance>(DEFAULT_CONTEXT);

export const useInboundCompliance = () =>
  React.useContext(InboundComplianceContext);

export const InboundComplianceContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [inboundCompliance, setInboundComplianceGroups] =
    useState<InboundCompliance>(DEFAULT_CONTEXT);

  // here context value is just returning an object, but only
  // re-creating the object when its dependencies change ([state, setContext])
  const getInboundComplianceContextValue = useCallback(
    () => ({
      ...inboundCompliance,
      setInboundComplianceGroups,
    }),
    [inboundCompliance, setInboundComplianceGroups]
  );

  return (
    <InboundComplianceContext.Provider
      value={getInboundComplianceContextValue()}
    >
      {children}
    </InboundComplianceContext.Provider>
  );
};
