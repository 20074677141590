import {
  ColumnDefinition,
  DataTableDefinition,
} from "src/components/DataTable/DataTableDefinition";
import { MismatchTableFields } from "src/api/interfaces/validation_result";
import MismatchSeverityIndicator from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/MismatchSeverityIndicator";
import { MismatchField } from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/mismatch-data-table-definition";
import buildMismatchTableEditItemInput from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/MismatchTableEditItemInput";
import { sanitizeAlphaNumericString as sanitize } from "src/utils";

export interface LineItemMismatchTableFields extends MismatchTableFields {
  readonly purchaseOrder: string;
}

export enum LineItemMismatchField {
  PURCHASE_ORDER = "purchaseOrder",
  ITEM = "item",
}

function buildEditableColumnDefinition(
  columnTemplate: ColumnDefinition & { editable: boolean }
): ColumnDefinition {
  const columnDefinition: ColumnDefinition = columnTemplate;
  const { editable, property } = columnTemplate;

  if (editable) {
    columnDefinition.childComponentOnly = true;
    columnDefinition.ChildComponent =
      buildMismatchTableEditItemInput<LineItemMismatchTableFields>(
        property,
        sanitize
      );
  }

  return columnDefinition;
}

export default function getLineItemMismatchDataTableDefinition(
  enableEditPurchaseOrder = false
): DataTableDefinition<LineItemMismatchTableFields> {
  return {
    columnsDefinition: [
      {
        label: "aic_mismatch_column_item",
        property: MismatchField.ITEM.valueOf(),
      },
      buildEditableColumnDefinition({
        label: "aic_mismatch_column_po",
        property: LineItemMismatchField.PURCHASE_ORDER.valueOf(),
        editable: enableEditPurchaseOrder,
        translateValue: false,
      }),
      {
        label: "aic_mismatch_column_rule",
        property: MismatchField.RULE.valueOf(),
      },
      {
        label: "aic_mismatch_column_value",
        property: MismatchField.VALUE.valueOf(),
      },
      {
        label: "aic_mismatch_column_expected_value",
        property: MismatchField.EXPECTED_VALUE.valueOf(),
      },
      {
        label: "aic_mismatch_rule_severity_value",
        property: MismatchField.RULE_SEVERITY.valueOf(),
        translateValue: true,
        ChildComponent: MismatchSeverityIndicator,
        childComponentOnly: true,
      },
      {
        label: "aic_mismatch_column_details",
        property: MismatchField.DETAILS.valueOf(),
        translateValue: true,
      },
    ],
  };
}
