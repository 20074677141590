import React, { FC, ReactNode, useReducer } from "react";
import { RetrievalState } from "src/constants";
import { HistoryState } from "./types";
import historyReducer from "./reducer";

export const DEFAULT_HISTORY_LISTING: HistoryState = {
  history: [],
  retrieveState: RetrievalState.SUCCESS,
};

export const HistoryContext = React.createContext<{
  state: HistoryState;
  dispatch: React.Dispatch<any>;
}>({ state: DEFAULT_HISTORY_LISTING, dispatch: () => {} });

export const HistoryContextProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(historyReducer, DEFAULT_HISTORY_LISTING);

  return (
    <HistoryContext.Provider value={{ state, dispatch }}>
      {children}
    </HistoryContext.Provider>
  );
};
