import { RetrievalState } from "src/constants";
import { LineItemRepresentation } from "../../../api/interfaces/line_item";

export interface DocumentLineState {
  documentLineItems: Array<LineItemRepresentation>;
  retrieveState: RetrievalState;
}

export type DocumentLineItemRow = LineItemRepresentation;

export enum DocumentLineItemsActions {
  ADD = 0,
}

export interface DocumentLineItemsAction {
  type: DocumentLineItemsActions;
  payload: any;
}

export type ActionFunction = (
  state: DocumentLineState,
  actionPayload: any
) => DocumentLineState;
