import { Operation } from "src/api/interfaces/permissions";

export enum ComplianceUserActionType {
  FORCE_ACCEPT = "force-accept",
  FORCE_REJECT = "force-reject",
  REVALIDATE = "revalidate",
}

export enum ValidationUserActionType {
  OVERRIDE_METADATA = "override-metadata",
  VIEW = "view",
}

export enum OverrideBy {
  LINE_ITEM = "lineItem",
  HEADER = "header",
}

export const TypeToUserActionConfig: Map<string, UserActionConfig> = new Map<
  ComplianceUserActionType,
  UserActionConfig
>()
  .set(ComplianceUserActionType.FORCE_ACCEPT, {
    label: "aic_doc_page_user_action_dropdown_option_force_accept",
    userActionOperation: Operation.PerformCriticalActions,
  })
  .set(ComplianceUserActionType.FORCE_REJECT, {
    label: "aic_doc_page_user_action_dropdown_option_force_reject",
    userActionOperation: Operation.PerformCriticalActions,
  })
  .set(ComplianceUserActionType.REVALIDATE, {
    label: "aic_doc_page_user_action_dropdown_option_revalidate",
    userActionOperation: Operation.PerformComplianceUserActions,
  });

export interface UserAction {
  readonly type: ComplianceUserActionType | ValidationUserActionType;
  readonly metadata?: { [key: string]: any };
  readonly name?: string;
  readonly label?: string;
}

export interface KatalDropdownButtonOption {
  readonly label: string;
  readonly action?: ComplianceUserActionType | ValidationUserActionType;
}

export interface UserActionConfig {
  readonly label: string;
  readonly userActionOperation: Operation;
}
