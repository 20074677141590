import React, { FC } from "react";
import MismatchTable from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/MismatchTable";
import { ValidationModalRenderComponentProps } from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/ValidationModalRenderComponent";
import mismatchDataTableDefinition from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/mismatch-data-table-definition";
import convertToMismatchTableFields, {
  orderMismatchTableByAscendingItems,
} from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/mismatch-table-fields-extractor";

const MismatchTableRenderComponent: FC<ValidationModalRenderComponentProps> = ({
  validationGroupId,
}) => {
  return (
    <div>
      <MismatchTable
        validationGroupId={validationGroupId}
        dataTableDefinition={mismatchDataTableDefinition}
        dataTransformer={convertToMismatchTableFields}
        rowSorter={orderMismatchTableByAscendingItems}
      />
    </div>
  );
};

export default MismatchTableRenderComponent;
