import React, { FC, useState } from "react";
import { KatButton } from "@amzn/katal-react";
import { ModalWrapper } from "src/components/Modal/ModalWrapper";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { InboundComplianceGroup } from "src/api/interfaces/inbound_compliance_group";
import DefaultRenderComponent from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundComplianceModals/Listing/DefaultRenderComponent";
import { useInboundComplianceGroupDetails } from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundComplianceModals/Listing/InboundComplianceGroupDetailsContext";
import { Operation } from "src/api/interfaces/permissions";
import withPermissionsOrDefault from "src/components/AICLayout/AICHeader/Permissions/WithPermissions";
import { useTranslation } from "react-i18next";
import { getTranslatedValue } from "src/utils";

export type InboundComplianceUserActionsProps =
  DataTableCellChildComponentProps<InboundComplianceGroup>;

const REVIEW_LABEL = "aic_doc_page_validation_user_actions_review_label";
const REVIEW_VARIANT = "secondary";

const InboundComplianceUserActions: FC<InboundComplianceUserActionsProps> = ({
  content,
}: InboundComplianceUserActionsProps) => {
  const { t } = useTranslation();
  const { inboundComplianceGroupDetails } = useInboundComplianceGroupDetails();
  const [handlerModalVisible, setHandlerModalVisible] =
    useState<boolean>(false);

  const wrapperTitle = getTranslatedValue(content.name as string, t);

  const shouldDisplayInboundComplianceUserActionsButton = () => {
    return inboundComplianceGroupDetails?.length > 0;
  };

  const userActionsButton =
    shouldDisplayInboundComplianceUserActionsButton() ? (
      <>
        <ModalWrapper
          title={wrapperTitle}
          visible={handlerModalVisible}
          setVisible={setHandlerModalVisible}
          onClose={() => setHandlerModalVisible(false)}
        >
          <DefaultRenderComponent />
        </ModalWrapper>
        <KatButton
          id={`inbound-compliance-${content.eventSubtype}-button`}
          label={t(REVIEW_LABEL)}
          variant={REVIEW_VARIANT}
          onClick={() => setHandlerModalVisible(true)}
        />
      </>
    ) : (
      <span />
    );

  return userActionsButton;
};

export default withPermissionsOrDefault(
  InboundComplianceUserActions,
  Operation.ViewInboundDocumentDetails
);
