import { isStandalone } from "src/api/config";
import {
  normalizeStringForTranslation,
  TRANSLATION_KEY_SEPARATOR,
  TRANSLATION_KEY_PREFIX,
  TRANSLATION_KEY_VENDOR_CENTRAL_PREFIX,
} from "src/utils";

// useSuspense parameter is required to pass the tests
export const DEFAULT_TRANSLATION_CONFIG = { useSuspense: false };
export const DEFAULT_TRANSLATION_NS = "fallback";

const INVALID_KEY_VALUE = "";
const TRANSLATION_NOT_FOUND_VALUE = "";
const DEFAULT_ATTRIBUTE_VALUE = "";

class TranslationKey {
  countryCode: string;

  documentType: string;

  documentSubType: string;

  useCase: string;

  constructor(
    countryCode = DEFAULT_ATTRIBUTE_VALUE,
    documentType = DEFAULT_ATTRIBUTE_VALUE,
    documentSubType = DEFAULT_ATTRIBUTE_VALUE,
    useCase = DEFAULT_ATTRIBUTE_VALUE
  ) {
    this.countryCode = countryCode;
    this.documentType = documentType;
    this.documentSubType = documentSubType;
    this.useCase = useCase;
  }

  buildInboundRouteKey(value: string, translationKeyPrefix?: string): string {
    if (
      this.countryCode &&
      this.documentType &&
      this.documentSubType &&
      this.useCase
    ) {
      return [
        translationKeyPrefix ?? TRANSLATION_KEY_PREFIX,
        normalizeStringForTranslation(this.countryCode),
        normalizeStringForTranslation(this.documentType),
        normalizeStringForTranslation(this.documentSubType),
        normalizeStringForTranslation(this.useCase),
        normalizeStringForTranslation(value),
      ].join(TRANSLATION_KEY_SEPARATOR);
    }
    return INVALID_KEY_VALUE;
  }

  buildInboundRouteVendorCentralKey(value: string): string {
    if (!isStandalone()) {
      return this.buildInboundRouteKey(
        value,
        TRANSLATION_KEY_VENDOR_CENTRAL_PREFIX
      );
    }
    return INVALID_KEY_VALUE;
  }

  buildCountryCodeKey(value: string, translationKeyPrefix?: string): string {
    if (this.countryCode) {
      return [
        translationKeyPrefix ?? TRANSLATION_KEY_PREFIX,
        normalizeStringForTranslation(this.countryCode),
        normalizeStringForTranslation(value),
      ].join(TRANSLATION_KEY_SEPARATOR);
    }
    return INVALID_KEY_VALUE;
  }

  buildCountryCodeVendorCentralKey(value: string): string {
    if (!isStandalone()) {
      return this.buildCountryCodeKey(
        value,
        TRANSLATION_KEY_VENDOR_CENTRAL_PREFIX
      );
    }
    return INVALID_KEY_VALUE;
  }

  static buildSimpleVendorCentralKey(value: string): string {
    if (!isStandalone()) {
      return TranslationKey.buildSimpleKey(
        value,
        TRANSLATION_KEY_VENDOR_CENTRAL_PREFIX
      );
    }
    return INVALID_KEY_VALUE;
  }

  static buildSimpleKey(value: string, translationKeyPrefix?: string): string {
    return [
      translationKeyPrefix ?? TRANSLATION_KEY_PREFIX,
      normalizeStringForTranslation(value),
    ].join(TRANSLATION_KEY_SEPARATOR);
  }

  translateValue(value: string, t: any): string {
    if (!value) {
      return TRANSLATION_NOT_FOUND_VALUE;
    }

    const possibleTranslationKeys: string[] = [
      this.buildInboundRouteVendorCentralKey(value),
      this.buildInboundRouteKey(value),
      this.buildCountryCodeVendorCentralKey(value),
      this.buildCountryCodeKey(value),
      TranslationKey.buildSimpleVendorCentralKey(value),
      TranslationKey.buildSimpleKey(value),
      value,
    ];

    const filteredPossibleTranslationKeys = possibleTranslationKeys.filter(
      (key: string) => key.length
    );
    return t(filteredPossibleTranslationKeys, TRANSLATION_NOT_FOUND_VALUE);
  }

  translateWithDefault(value: string, defaultValue: string, t: any): string {
    return this.translateValue(value, t) || defaultValue;
  }
}

export default TranslationKey;
