import { log, LogLevel } from "src/logger";
import AlertItem from "src/components/Alert/AlertItem";
import React, { Dispatch, SetStateAction } from "react";

export interface CreateNewAlertRequest {
  readonly fade: boolean;
  readonly header: string;
  readonly text: string;
  readonly isSuccess: boolean;
  readonly alerts: React.ReactNode[];
  readonly setAlerts: Dispatch<SetStateAction<React.ReactNode[]>>;
}

interface AlertProps {
  description: string;
  header: string;
  isSuccess: boolean;
  fade: boolean;
}

export const createAlert: (props: AlertProps) => React.ReactNode = (
  props: AlertProps
) => {
  return (
    <AlertItem
      description={props.description}
      header={props.header}
      variant={props.isSuccess ? "success" : "danger"}
      fade={props.fade}
    />
  );
};

export const alertHandler = {
  createNewAlertEntry: ({
    fade,
    header,
    text,
    isSuccess,
    alerts,
    setAlerts,
  }: CreateNewAlertRequest): React.ReactNode => {
    const newAlert = createAlert({
      header,
      fade,
      description: text,
      isSuccess,
    });
    setAlerts([...alerts, newAlert]);

    log({
      level: LogLevel.INFO,
      message: "Created new alert",
      operationNamespace: "alert.createNewAlertEntry",
    });

    return newAlert;
  },
};
