import React, { FC } from "react";
import DataTableExpandableRow from "src/components/DataTable/DataTableExpandableRow";
import DataTableRow, {
  DataTableRowProps,
} from "src/components/DataTable/DataTableRow";
import publishMetric, { MetricAction } from "src/metrics";
import DocumentItemsErrorTable from "./DocumentItemsErrorTable";

const LineItemDataTableRow: FC<DataTableRowProps<any>> = ({
  id,
  content,
  contents,
  columnsDefinition,
  rowClickHandler,
  rowURLBuilder,
  selectDispatcher,
  shouldBeSelectable,
  customDataTableRowProps,
}) => {
  const { validationErrors } = content;
  const hasValidationErrors = validationErrors && validationErrors.length;

  const DataTableRowComponent = hasValidationErrors
    ? DataTableExpandableRow
    : DataTableRow;

  return (
    <DataTableRowComponent
      id={id}
      content={content}
      contents={contents}
      columnsDefinition={columnsDefinition}
      rowClickHandler={rowClickHandler}
      rowURLBuilder={rowURLBuilder}
      selectDispatcher={selectDispatcher}
      shouldBeSelectable={shouldBeSelectable}
      onExpandHandler={(expanded) => {
        if (!expanded) {
          publishMetric(
            MetricAction.DOCUMENT_ITEMS_TAB_EXPAND_ERRORED_LINE_ITEMS_CLICK
          );
        }
      }}
      expandedProp={customDataTableRowProps?.expanded}
    >
      {hasValidationErrors && <DocumentItemsErrorTable content={content} />}
    </DataTableRowComponent>
  );
};

export default LineItemDataTableRow;
