import React, { FC, useContext, useEffect } from "react";
import { GetInboundDocumentRequest } from "src/api/interfaces/inbound_document";
import client, { UNAUTHORIZED_HTTP_STATUS_CODE } from "src/api/client";
import { log, LogLevel } from "src/logger";
import { AxiosError } from "axios";
import { InboundDocumentContext } from "src/components/Contexts/InboundDocumentContext";
import { useAuthorizationErrors } from "src/components/Contexts/AuthorizationErrorsContext";
import useUnauthorizedRedirect from "src/components/AICLayout/AICHeader/Permissions/WithRedirectWhenAuthorized";
import { AuthorizationErrors } from "src/api/interfaces/permissions";

const InboundDocumentFetcher: FC<InboundDocumentFetcherProps> = ({
  countryCode,
  documentType,
  documentId,
}: InboundDocumentFetcherProps) => {
  useUnauthorizedRedirect();

  const { setInboundDocument } = useContext(InboundDocumentContext);
  const { setAuthorizationErrors } = useAuthorizationErrors();

  useEffect(() => {
    const fetchInboundDocument = () => {
      const params: GetInboundDocumentRequest = {
        countryCode,
        documentType,
        documentId,
      };

      client
        .getInboundDocument(params)
        .then((result) => {
          log({
            level: LogLevel.INFO,
            message: "Inbound Document fetch completed successfully",
            operationNamespace: "InboundDocumentFetcher.fetchInboundDocument",
          });
          setInboundDocument(result.inboundDocument);
        })
        .catch((error: AxiosError) => {
          log({
            level: LogLevel.ERROR,
            message:
              "There was an error while trying to fetch Inbound Document",
            operationNamespace: "InboundDocumentFetcher.fetchInboundDocument",
            exception: error?.message,
            attributes: { params },
          });
          if (error.response?.status === UNAUTHORIZED_HTTP_STATUS_CODE) {
            setAuthorizationErrors((prevState: AuthorizationErrors) => ({
              authorizationErrors: prevState.authorizationErrors.add(
                error.message
              ),
            }));
          }
        });
    };
    fetchInboundDocument();
  }, [
    countryCode,
    documentType,
    documentId,
    setAuthorizationErrors,
    setInboundDocument,
  ]);

  return <div />;
};

interface InboundDocumentFetcherProps {
  countryCode: string;
  documentType: string;
  documentId: string;
}

export default InboundDocumentFetcher;
