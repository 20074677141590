import { useEffect, useState } from "react";
import { forIn, get, isEmpty, parseInt } from "lodash";
import { ValidationError } from "src/api/interfaces/line_item";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { useValidationResults } from "src/components/Contexts/ValidationResultsContext";
import {
  ValidationResult,
  ValidationResultsResponse,
} from "src/api/interfaces/validation_result";
import convertToMismatchTableFields from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/mismatch-table-fields-extractor";

const useDocumentLineItemsErrors = (): Record<
  number,
  Array<ValidationError>
> => {
  const { validationResultsByGroup } = useValidationResults();
  const [documentItemsErrorsMap, setDocumentItemsErrorsMap] = useState<
    Record<number, Array<ValidationError>>
  >({});

  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const getDocumentLineItemsErrors = (
    validationResults: Record<string, ValidationResultsResponse>
  ) => {
    const results: Array<ValidationResult> = [];

    forIn(validationResults, (result: ValidationResultsResponse) => {
      const lineItemValidationResults = result.validationResults.filter(
        (validation) => validation.scope === "productLineItem"
      );
      results.push(...lineItemValidationResults);
    });

    return results;
  };

  useEffect(() => {
    const itemsErrors = getDocumentLineItemsErrors(validationResultsByGroup);
    const itemsErrorsMap: Record<number, Array<ValidationError>> = {};

    if (!isEmpty(documentItemsErrorsMap) || isEmpty(itemsErrors)) {
      return;
    }

    itemsErrors.forEach((result, i) => {
      const convertedResult = convertToMismatchTableFields(result, i, t);

      const rawIndex = get(convertedResult, "metadata.itemValue", "0");
      const index = parseInt(JSON.parse(rawIndex), 10) - 1;

      itemsErrorsMap[index] = itemsErrorsMap[index] || [];
      itemsErrorsMap[index].push({ ...convertedResult });
    });

    setDocumentItemsErrorsMap(itemsErrorsMap);
  }, [t, documentItemsErrorsMap, validationResultsByGroup]);

  return documentItemsErrorsMap;
};

export default useDocumentLineItemsErrors;
