import React, { FC } from "react";
import { getFromObject } from "src/utils";

import classnames from "classnames";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { ConsolidatedDocument } from "src/api/interfaces/document_listing";
import styles from "../../../../styles.module.scss";

const REFERENCED_DOCUMENT = "documentDetails.referencedDocuments";
const START_INDEX = 0;
const REFERENCED_DOCUMENT_MAX_SIZE = 25;

const DocumentReferences: FC<
  DataTableCellChildComponentProps<ConsolidatedDocument>
> = ({ content }) => {
  const getDocumentReferences = (
    consolidatedDocument: ConsolidatedDocument
  ) => {
    const propertyValue = getFromObject(
      consolidatedDocument,
      REFERENCED_DOCUMENT
    );
    let response = "";
    if (Array.isArray(propertyValue) && propertyValue.length > 0) {
      const { documentType, documentId } = propertyValue[0];
      response = `${documentType}: ${documentId || "--"}`;
    }
    return response;
  };

  let alternativeText = "";
  let documentReferences = getDocumentReferences(content);
  if (
    documentReferences !== null &&
    documentReferences.length > REFERENCED_DOCUMENT_MAX_SIZE
  ) {
    alternativeText = documentReferences;
    documentReferences = documentReferences
      ?.substr(START_INDEX, REFERENCED_DOCUMENT_MAX_SIZE)
      .concat("...");
  }
  return (
    <p className={classnames(styles.capitalizedText)} title={alternativeText}>
      {documentReferences}
    </p>
  );
};

export default DocumentReferences;
