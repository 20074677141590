import React, { FC } from "react";
import { KatSpinner } from "@amzn/katal-react";

import "./index.scss";
import { RetrievalState } from "src/components/DataTable/DataTableDefinition";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";

const DataTableWarning: FC<DataTableWarningProps<any>> = ({
  id,
  retrievalState,
  contents,
}) => {
  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );
  if (retrievalState === RetrievalState.LOADING) {
    return (
      <div
        id={`${id}-data-table-content-loading`}
        className="data-table-listing-loading"
      >
        <KatSpinner />
      </div>
    );
  }
  if (retrievalState === RetrievalState.ERROR) {
    return (
      <div
        id={`${id}-data-table-content-error`}
        className="data-table-listing-error"
      >
        <h3>{t("aic_data_table_generic_error_message")}</h3>
      </div>
    );
  }
  if (!contents?.length) {
    return (
      <div
        id={`${id}-data-table-empty-content`}
        className="data-table-listing-empty"
      >
        <h3>{t("aic_data_table_generic_no_content_message")}</h3>
      </div>
    );
  }
  return <></>;
};

export interface DataTableWarningProps<ContentType> {
  id: string;
  retrievalState?: RetrievalState;
  contents?: Array<ContentType>;
}

export default DataTableWarning;
