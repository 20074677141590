import React, { FC, useMemo } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Unauthorized from "src/components/Pages/Unauthorized/Unauthorized";
import { renewMidwayTokenOrRedirect } from "src/midway/MidwayConnector";
import InboundDocument from "./Pages/DocumentInbound";
import { isStandalone } from "../api/config";
import PageNotFound from "./Pages/NotFound/PageNotFound";
import DocumentListing from "./Pages/DocumentList";

export const DocumentListingRootPath = "/";
export const UnauthorizedPagePath = "/unauthorized";
export const DocumentListingInboundCentralPath = "/inbound-central";
export const DocumentListingIndexExternalPath =
  "/inbound-central/index-external.html";
export const DocumentListingIndexInternalPath =
  "/inbound-central/index-internal.html";
export const InboundDocumentPath =
  "/inbound-central/countries/:countryCode/document-types/:documentType/documents/:documentId";
export const InboundDocumentPathWithInboundDocumentTab =
  "/inbound-central/countries/:countryCode/document-types/:documentType/documents/:documentId/:inboundDocumentTab";

export const App: FC = () => {
  useMemo(() => {
    if (isStandalone()) {
      renewMidwayTokenOrRedirect();
    }
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={DocumentListingRootPath}
          component={DocumentListing}
        />
        <Route
          path={DocumentListingIndexExternalPath}
          component={DocumentListing}
        />
        <Route
          path={DocumentListingIndexInternalPath}
          component={DocumentListing}
        />
        <Route exact path={InboundDocumentPath} component={InboundDocument} />
        <Route
          path={InboundDocumentPathWithInboundDocumentTab}
          component={InboundDocument}
        />
        <Route
          path={DocumentListingInboundCentralPath}
          component={DocumentListing}
        />
        <Route path={UnauthorizedPagePath} component={Unauthorized} />
        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};
