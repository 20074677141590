import React, { FC } from "react";

import { InboundComplianceContextProvider } from "src/components/Contexts/InboundComplianceContext";
import InboundComplianceFetcher from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundCompliance/InboundComplianceFetcher";
import InboundComplianceGroupListing from "src/components/Pages/DocumentInbound/Tabs/InboundCompliance/InboundCompliance/InboundComplianceListing";
import { GetInboundComplianceGroupRequest } from "src/api/interfaces/inbound_compliance_group";

const InboundCompliance: FC<InboundComplianceProps> = ({
  countryCode,
  documentType,
  documentId,
  executionNumber,
}) => {
  return (
    <InboundComplianceContextProvider>
      <InboundComplianceFetcher
        countryCode={countryCode}
        documentId={documentId}
        documentType={documentType}
        executionNumber={executionNumber}
      />
      <InboundComplianceGroupListing />
    </InboundComplianceContextProvider>
  );
};

export default InboundCompliance;

type InboundComplianceProps = GetInboundComplianceGroupRequest;
