import { useCallback, useContext, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { cloneDeep, eq, every, forIn, isEmpty, isUndefined } from "lodash";
import client from "src/api/client";
import { useInboundDocument } from "src/components/Contexts/InboundDocumentContext";
import {
  GetDocumentLineItemsRequest,
  GetDocumentLineItemsResponse,
  LineItemRepresentation,
  ValidationError,
} from "src/api/interfaces/line_item";
import { RetrievalState } from "src/constants";
import {
  DocumentLineItemsActions,
  DocumentLineState,
} from "src/components/Contexts/DocumentLineItems/types";
import { DocumentLineItemListContext } from "src/components/Contexts/DocumentLineItems/Provider";
import { log, LogLevel } from "src/logger";
import publishMetric, { MetricAction } from "src/metrics";
import useDocumentLineItemsErrors from "src/components/Pages/DocumentInbound/Hooks/useDocumentLineItemsErrors";

const useDocumentLineItems = (): DocumentLineState => {
  const { state, dispatch } = useContext(DocumentLineItemListContext);
  const { countryCode, documentId, documentType } = useInboundDocument();
  const lineItemsErrorsMap = useDocumentLineItemsErrors();
  const [requestError, setRequestError] = useState<AxiosError>();

  const setDocumentLineItemErrors = useCallback(
    (
      lineItems: Array<LineItemRepresentation>,
      lineItemsErrors: Record<number, Array<ValidationError>>
    ) => {
      forIn(lineItemsErrors, (lineErrors: Array<ValidationError>, i: any) => {
        if (!isUndefined(lineItems[i])) {
          lineItems[i].validationErrors = cloneDeep(lineErrors);
        }
      });
      return lineItems;
    },
    []
  );

  useEffect(() => {
    const request: GetDocumentLineItemsRequest = {
      countryCode,
      documentId,
      documentType,
    };

    const shouldSetLineErrors = every([
      !isEmpty(state.documentLineItems),
      !isEmpty(lineItemsErrorsMap),
      state.documentLineItems.every((item) => isEmpty(item.validationErrors)),
    ]);

    if (shouldSetLineErrors) {
      const lineItems = setDocumentLineItemErrors(
        state.documentLineItems,
        lineItemsErrorsMap
      );

      dispatch({
        type: DocumentLineItemsActions.ADD,
        payload: {
          documentLineItems: lineItems,
          retrieveState: RetrievalState.SUCCESS,
        },
      });
    }

    const shouldRequestDocumentItems = every([
      countryCode,
      documentId,
      documentType,
      eq(state.documentLineItems.length, 0),
      !eq(requestError?.response?.status, 404),
      !eq(state.retrieveState, RetrievalState.ERROR),
      !eq(state.retrieveState, RetrievalState.LOADING),
    ]);

    if (!shouldRequestDocumentItems) return;

    dispatch({
      type: DocumentLineItemsActions.ADD,
      payload: {
        documentLineItems: [],
        retrieveState: RetrievalState.LOADING,
      },
    });

    client
      .getDocumentLineItems(request)
      .then((data: GetDocumentLineItemsResponse) => {
        log({
          level: LogLevel.INFO,
          message: "Document Line items fetch completed successfully",
          operationNamespace: "useDocumentLineItems.getDocumentLineItems",
        });
        const payload = new TextEncoder().encode(JSON.stringify(data));
        publishMetric(MetricAction.REQUEST_LINE_ITEMS_PAYLOAD_SIZE, {
          value: payload.length.toString(),
        });

        dispatch({
          type: DocumentLineItemsActions.ADD,
          payload: {
            documentLineItems: data.lineItemsRepresentation,
            retrieveState: RetrievalState.SUCCESS,
          },
        });
      })
      .catch((error: AxiosError) => {
        log({
          level: LogLevel.ERROR,
          message:
            "There was an error while trying to fetch document line items",
          operationNamespace: "useDocumentLineItems.getDocumentLineItems",
          exception: error?.message,
          attributes: { request },
        });
        setRequestError(error);
        const retrieveState = eq(error.response?.status, 404)
          ? RetrievalState.SUCCESS
          : RetrievalState.ERROR;

        dispatch({
          type: DocumentLineItemsActions.ADD,
          payload: {
            documentLineItems: [],
            retrieveState,
          },
        });
      });
  }, [
    countryCode,
    dispatch,
    documentId,
    documentType,
    setDocumentLineItemErrors,
    lineItemsErrorsMap,
    state.documentLineItems,
    state.retrieveState,
    requestError?.response?.status,
  ]);

  return state;
};

export default useDocumentLineItems;
