import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { PageInformation } from "src/api/interfaces/pagination";
import {
  DEFAULT_PAGE_INFORMATION,
  PAGE_QS_KEY,
  PAGE_SIZE_QS_KEY,
} from "src/components/Contexts/PaginationContext";
import { getUrlParam, updateUrlParams } from "src/utils";

export type UsePageInformationParams = {
  updateUrl?: boolean;
};

const usePageInformation = (params?: UsePageInformationParams) => {
  const urlPage = Number(getUrlParam(PAGE_QS_KEY));
  const urlPageSize = Number(getUrlParam(PAGE_SIZE_QS_KEY));

  const history = useHistory();
  const [pageInformation, setLocalPageInformation] = useState<PageInformation>({
    ...DEFAULT_PAGE_INFORMATION,
    page: urlPage || DEFAULT_PAGE_INFORMATION.page,
    pageSize: urlPageSize || DEFAULT_PAGE_INFORMATION.pageSize,
  });

  const setPageInformation = useCallback(
    (newPageInformation: Partial<PageInformation>) => {
      const aggregatePageInfor = { ...pageInformation, ...newPageInformation };
      setLocalPageInformation(aggregatePageInfor);
      if (params?.updateUrl) {
        updateUrlParams(
          [
            [PAGE_QS_KEY, aggregatePageInfor.page.toString()],
            [PAGE_SIZE_QS_KEY, aggregatePageInfor.pageSize.toString()],
          ],
          history
        );
      }
    },
    // All properties are already defined on the list, but the lint wants me to add the object that can generate an infinity loop, since react does not differ object by value
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      history,
      pageInformation.page,
      pageInformation.pageSize,
      pageInformation.total,
      params?.updateUrl,
    ]
  );

  return { pageInformation, setPageInformation };
};

export default usePageInformation;
