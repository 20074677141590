import {
  OverriddenMetadataEntry,
  ValidationUserActionHandler,
} from "src/components/UserActions/Handlers/Validation/types";
import POHeaderEdit from "src/components/UserActions/Handlers/Validation/OverrideMetadata/PurchaseOrder/POHeaderEdit";
import POLineItemEdit from "src/components/UserActions/Handlers/Validation/OverrideMetadata/PurchaseOrder/POLineItemEdit";

const EDIT_COMPONENT_BY_ATTRIBUTE = new Map()
  .set(OverriddenMetadataEntry.HeaderPurchaseOrder, POHeaderEdit)
  .set(OverriddenMetadataEntry.LineItemPurchaseOrder, POLineItemEdit);

const handleOverrideMetadata: ValidationUserActionHandler = async (
  _,
  { attribute }
) => {
  return EDIT_COMPONENT_BY_ATTRIBUTE.get(attribute);
};

export default handleOverrideMetadata;
