import React, { FC } from "react";
import { KatTableRow } from "@amzn/katal-react";
import classnames from "classnames";
import {
  ColumnDefinition,
  DataTableSelectionAction,
  DataTableSelectionProps,
} from "./DataTableDefinition";
import DataTableCell from "./DataTableCell";

import styles from "../../styles.module.scss";

const DataTableRow: FC<DataTableRowProps<any>> = ({
  id,
  content,
  contents,
  columnsDefinition,
  rowClickHandler,
  rowURLBuilder,
  selectDispatcher,
  shouldBeSelectable,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customDataTableRowProps,
}) => {
  const handleRowClick = () => {
    rowClickHandler!(content);
  };
  const buildRowURL = () => {
    return rowURLBuilder!(content);
  };

  const buildTableCell = (
    columnDefinition: ColumnDefinition,
    index: number
  ) => (
    <DataTableCell
      key={`${id}-data-table-cell-${columnDefinition.property}-${index}`}
      id={id}
      columnDefinition={columnDefinition}
      content={content}
      contents={contents}
      href={
        rowURLBuilder && !columnDefinition.shouldDisableLink
          ? buildRowURL()
          : undefined
      }
      selectDispatcher={selectDispatcher}
      shouldBeSelectable={shouldBeSelectable}
    />
  );

  return (
    <KatTableRow
      id={`${id}-data-table-row`}
      onClick={rowClickHandler ? handleRowClick : undefined}
      className={classnames({
        [styles.pointer]: !!rowClickHandler,
        [styles.background_white]: true,
      })}
    >
      {columnsDefinition.map((columnDefinition, index) =>
        buildTableCell(columnDefinition, index)
      )}
    </KatTableRow>
  );
};

export interface DataTableRowProps<ContentType> {
  id: string;
  content: ContentType;
  contents: Array<ContentType>;
  columnsDefinition: Array<ColumnDefinition>;
  rowClickHandler?: (content: ContentType) => void;
  rowURLBuilder?: (content: ContentType) => string;
  selectDispatcher?: (event: DataTableSelectionAction<ContentType>) => void;
  shouldBeSelectable?: (
    content: ContentType & DataTableSelectionProps,
    contents: Array<ContentType & DataTableSelectionProps>
  ) => boolean;
  customDataTableRowProps?: any;
}

export default DataTableRow;
