import React, { FC, useContext, useState } from "react";
import { KatInput } from "@amzn/katal-react";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { MismatchTableEditItemsContext } from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/MismatchTableEditItemsContext";
import { LineItemMismatchField } from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/LineItem/line-item-mismatch-data-table-definition";

export type MismatchTableEditItemInputProps<T> =
  DataTableCellChildComponentProps<T & { index: number }>;

export default function buildMismatchTableEditItemInput<T>(
  property: string,
  sanitize = (value: string) => value
): FC<MismatchTableEditItemInputProps<T>> {
  type ItemFields = T & { index: number };

  return ({ content }) => {
    const initialContent =
      String(content[property as keyof ItemFields]) === "-"
        ? ""
        : String(content[property as keyof ItemFields]);
    const lineItemIdentifier = String(
      content[LineItemMismatchField.ITEM as keyof ItemFields]
    );

    const { setItemOverrides } = useContext(MismatchTableEditItemsContext);
    const [screenText, setScreenText] = useState(initialContent);

    const handleOnInput = ({ target: { value: input } }: any) => {
      const isInitialContent = !input || input === initialContent;
      const sanitized = isInitialContent ? initialContent : sanitize(input);
      setScreenText(sanitized);
      setItemOverrides((tableOverrides) => {
        const rowOverrides =
          tableOverrides.get(content.index) || new Map<string, string>();

        if (!isInitialContent) {
          rowOverrides.set(property, sanitized);
          rowOverrides.set(LineItemMismatchField.ITEM, lineItemIdentifier);
        } else {
          rowOverrides.delete(property);
          rowOverrides.delete(LineItemMismatchField.ITEM);
        }

        if (rowOverrides.size > 0) {
          tableOverrides.set(content.index, rowOverrides);
        } else {
          tableOverrides.delete(content.index);
        }

        return tableOverrides;
      });
    };

    return (
      <KatInput
        id={`mismatch-table-edit-${property}-${content.index}`}
        value={screenText}
        onInput={handleOnInput}
        type="text"
      />
    );
  };
}
