import React, { FC } from "react";
import { LineItemRepresentation } from "src/api/interfaces/line_item";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import LabelList from "src/components/LabelList";
import CopyToClipboardButton from "../../../CopyToClipboardButton";

const AmazonIdentifiersLabel: FC<
  DataTableCellChildComponentProps<LineItemRepresentation>
> = ({ content }) => {
  const amazonIdentifier =
    content.amazonIdentifiers && content.amazonIdentifiers.length > 0
      ? content.amazonIdentifiers[0]
      : undefined;
  return (
    <>
      <LabelList list={content.amazonIdentifiers} sizeToShow={1} />
      {amazonIdentifier ? (
        <CopyToClipboardButton
          label="amazon-identifier"
          translationPrefix="aic_doc_page_product_items_tab_amazon_identifier"
          value={amazonIdentifier}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AmazonIdentifiersLabel;
