import React, { FC } from "react";
import classnames from "classnames";

import { DocumentListingInboundCentralPath } from "src/components/App";
import { withTranslation, WithTranslation } from "react-i18next";
import logo from "../../../images/aic_logo.png";
import styles from "../../../styles.module.scss";

const PortalLogo: FC<WithTranslation> = ({ t }) => {
  return (
    <div>
      <a
        href={DocumentListingInboundCentralPath}
        title={t("aic_app_page_title_text")}
      >
        <img
          className={classnames(styles.w_50)}
          src={logo}
          alt={t("aic_app_page_title_text")}
        />
      </a>
    </div>
  );
};

export default withTranslation()(PortalLogo);
